import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PhoenixChatService } from '../../chat.service';

@Component({
    selector: 'phx-direct-conversation-select',
    templateUrl: 'direct-conversation-select.component.html',
    styleUrls: ['./conversation-select.global.scss']
})

export class DirectConversationSelectComponent {
    public conversationLoaded: boolean = false;
    public searchStr: string = '';
    public conversationTitle: any;

    @Input() currentConversation: string = '';
    @Input() conversations: Array<any>;
    @Input('directConversationLoading') directConversationLoading: boolean = false;
    @Input('isClientApp') isClientApp: boolean = false;

    @Output() conversationSelected = new EventEmitter();
    @Output() scrollEnd = new EventEmitter();
    @Output() onSearchStringInput = new EventEmitter();

    constructor(public phoenixChatService: PhoenixChatService) { }

    ngOnChanges() {
        if (this.conversations != null) {
            this.conversationLoaded = true;
            this.conversations = this.phoenixChatService.processConversations(this.conversations);
        }
    }

    public selectConversation(conversation: any) {
        this.conversationSelected.emit(conversation);
    }

    public bidScrolled() {
        this.scrollEnd.emit();
    }

    public searchStringInput() {
        this.onSearchStringInput.emit(this.searchStr);
    }
}
