import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

// import * as EventEmitter from 'node:events';

@Component({
  selector: 'phx-icon',
  templateUrl: './phoenix-icon.component.html',
  styleUrls: ['./phoenix-icon.component.scss']
})
export class PhoenixIconComponent implements OnInit {
  @Input('src') src: string;
  @Input('size') size: string;
  @Input('maxSize') maxSize: string;
  @Input('extraClass') extraClass: string;
  @Input('color') color: string;
  @Input('ml') ml: string;
  @Input('mb') mb: string;
  @Input('mr') mr: string;
  @Input('h') h: string;
  @Input('w') w: string;
  @Input('url') url: string;
  @Input('customClass') customClass: string;
  @Input('isTooltipComponent') isTooltipComponent: boolean = false;
  public calculatedStyle = '';

  constructor(private sanitizer: DomSanitizer) {
  }

  async ngOnInit() {

    if (this.url && this.url.length == 0) {
      this.url = null;
    }

    if (!this.hasFileExtension) {
      //default is svg
      this.src = this.src + '.svg';
    }

    if (!this.customClass && this.src) {
      if (this.isImage) {
        this.customClass = 'standardImage';
      } else {
        this.customClass = 'standardIcon';
      }
    }

    if (this.isImage) {
      this.customClass += ' image-' + this.specificIconClass;
    } else {
      this.customClass += ' allIcons';
      this.customClass += ' icon-' + this.specificIconClass;
    }


    this.calculateStyle();
  }

  get isImage() {
    const imageExtensionRegex = /\.(jpeg|jpg|gif|png|bmp|tiff|webp)$/i;
    return imageExtensionRegex.test(this.src) || this.customClass?.indexOf('standardImage') > -1 || this.url != null;
  }

  get hasFileExtension(): boolean {
    return /\.[0-9a-z]+$/i.test(this.src);
  }

  get specificIconClass(): string {
    return this.src.replace(/\.[^/.]+$/, '');
  }

  calculateStyle() {
    this.calculatedStyle = '';
    if (this.size) {
      this.calculatedStyle += 'width:' + this.size + 'px;height:' + this.size + 'px;';
    }
    if (this.maxSize) {
      this.calculatedStyle += 'max-width:' + this.size + 'px;max-height:' + this.size + 'px;';
    }

    if (this.h) {
      this.calculatedStyle += 'height:' + this.h + 'px;';
    }
    if (this.w) {
      this.calculatedStyle += 'width:' + this.w + 'px;';
    }

    if (this.mr) {
      this.calculatedStyle += 'margin-right:' + this.mr + 'px;';
    }
    if (this.ml) {
      this.calculatedStyle += 'margin-left:' + this.ml + 'px;';
    }
    if (this.mb) {
      this.calculatedStyle += 'margin-bottom:' + this.mb + 'px;';
    }
    if (this.color) {
      this.calculatedStyle += 'background-color: var(--bcs-' + this.color + ');';
    }

    if (!this.url) {
      if (this.isImage) {
        this.calculatedStyle += 'background-image:url("assets/images/' + this.src + '");';
      } else {
        this.calculatedStyle += ' -webkit-mask-image:url("assets/images/' + this.src + '"); ';
        this.calculatedStyle += ' mask-image:url("assets/images/' + this.src + '"); ';
      }
    }


  }

  getSafeUrl(url: string): SafeUrl {
    if (!url) {
      return null;
    }
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  protected readonly toString = toString;
}
