import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CommonValueAccessorBase } from '../utils/CommonValueAccessorBase';
import { Observable } from 'rxjs';

// import { ValueAccessorBase } from 'phoenix-common';

@Component({
    selector: 'phx-upload',
    templateUrl: 'phoenix-upload.html',
    styleUrls: ['phoenix-upload.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PhoenixUploadComponent),
            multi: true
        }
    ]
})
export class PhoenixUploadComponent extends CommonValueAccessorBase<any> implements OnInit {
    @Input() public disabled: boolean = false;
    @Input() public isMultiple = false;
    @Input() public fileType: string;
    @Input() public standAlone = true;
    @Input() public hideButton = false; // Hides button if single file was selected
    @Input() public checkRemoved: Observable<void>; // Removes all selected files
    @Input() public isVendorApp = true; // LAWRENCE - Added this to filter through styling. If it is VendorApp we keep old styling


    @ViewChild('file', { static: false }) file: ElementRef;

    @Output('onFileUpload') onFileUpload = new EventEmitter();
    @Output('onMultipleFilesUpload') onMultipleFilesUpload = new EventEmitter();

    public fileObject: File;
    public fileList: Array<File> = [];

    public faTimes = faTimes;

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.checkRemoved?.subscribe((data) => {
            if (!this.isMultiple) {
                this.removeFile(0);
            }
        });
    }

    public fileChange(event) {
        if (event && event.target && event.target.files && event.target.files.length > 0) {
            if (this.isMultiple) {
                // eslint-disable-next-line no-unsafe-optional-chaining
                for (let file of event?.target?.files) {
                    if (this.fileList.find(f => f.name === file.name)) {
                        continue;
                    }
                    this.fileList.push(file);
                }
                this.value = this.fileList;
                this.onMultipleFilesUpload.emit(this.value);
            } else {
                this.value = event.target.files[0];
                this.onFileUpload.emit(this.value);
            }
        }
    }

    public removeFile(i) {
        if (this.isMultiple) {
            this.fileList.splice(i, 1);
            this.value = this.fileList;
            this.onMultipleFilesUpload.emit(this.value);
        } else {
            this.file.nativeElement.value = null;
            this.value = null;
            this.onFileUpload.emit(this.value);
        }
    }

    public afterSetValue() {
    }
}
