import { Injectable } from '@angular/core';
import { Utils } from '../shared/utils/utils';
import { Location } from '../models/location-model';

declare const google;

@Injectable({providedIn: "root"})
export class GoogleAPIService {

    public debug: boolean = true;

    private autocompleteService: any;
    private placesService: any;
    private geocoderService: any;
    private directionsService: any;

    private autoCompleteCache: { [id: number]: any } = {};
    private placeDetailsCache: { [id: string]: any } = {};
    private geocoderCache: { [id: string]: any } = {};
    private reverseGeocoderCache: { [id: string]: any } = {};
    private routesCache: { [key: string]: any } = {};

    private wait: boolean = false;

    constructor() {
        this.autocompleteService = new google.maps.places.AutocompleteService();
        this.placesService = new google.maps.places.PlacesService(document.createElement("div"));
        this.geocoderService = new google.maps.Geocoder();
        this.directionsService = new google.maps.DirectionsService();
    }

    private log(message: string) {
        if (this.debug) {

        }
    }

    public getPlaceDetails(placeId: string): Promise<any> {
        let promise = Utils.retry((resolve, reject) => {
            if (placeId) {
                if (this.placeDetailsCache[placeId]) {
                    this.log("Hitting place details cache ...");
                    resolve(this.placeDetailsCache[placeId]);
                } else {
                    this.placesService.getDetails({ placeId: placeId }, (result, status) => {
                        if ((status == google.maps.places.PlacesServiceStatus.OK) && (result && result.geometry)) {
                            this.log("Populate place details cache ...");
                            this.placeDetailsCache[placeId] = result;
                            resolve(result);
                        } else {
                            reject(status);
                        }
                    });
                }
            } else {
                reject("PlaceId param missing");
            }
        });

        return promise;
    }

    public async getPlaceLatLng(placeId: string): Promise<any> {
        let promise = Utils.retry((resolve, reject) => {
            if (placeId) {
                if (this.placeDetailsCache[placeId]) {
                    this.log("Hitting place details cache ...");
                    let result: any = this.placeDetailsCache[placeId];
                    let latLngObj = {
                        lat: result.geometry.location.lat(),
                        lng: result.geometry.location.lng()
                    }
                    resolve(latLngObj);
                } else {
                    this.placesService.getDetails({ placeId: placeId }, (result, status) => {
                        if ((status == google.maps.places.PlacesServiceStatus.OK) && (result && result.geometry)) {
                            this.log("Populate place details cache ...");
                            this.placeDetailsCache[placeId] = result;
                            let latLngObj = {
                                lat: result.geometry.location.lat(),
                                lng: result.geometry.location.lng()
                            }
                            resolve(latLngObj);
                        } else {
                            reject(status)
                        }
                    });
                }
            } else {
                reject("PlaceId param missing");
            }
        });
        return promise;
    }

    public getAutoCompleteResult(searchTerm: string): Promise<Array<any>> {
        let promise: Promise<Array<any>> = Utils.retry((resolve, reject) => {
            let cities = [];
            if (!searchTerm) {
                reject('Search term is missing');
                return;
            }

            let resolveResponse = (predictions, status) => {
                switch (status) {
                    case google.maps.places.PlacesServiceStatus.OK:
                    this.log("Populate autocomplete cache ...");
                    this.autoCompleteCache[searchTerm] = predictions;

                    for (let pred of predictions) {
                        let city = {
                            address: pred.description,
                            id: pred.place_id,
                            location: {
                                lat: 0,
                                lng: 0
                            }
                        };

                        cities.push(city);
                    }

                    resolve(cities);
                    break;

                    case google.maps.places.PlacesServiceStatus.ZERO_RESULTS:
                    resolve([]);
                    break;

                    default:
                    reject(status);
                    break;
                }
            };

            if (this.autoCompleteCache[searchTerm]) {
                this.log("Hitting autocomplete cache ...");
                let predictions = this.autoCompleteCache[searchTerm];
                resolveResponse(predictions, predictions.length > 0 ? google.maps.places.PlacesServiceStatus.OK : google.maps.places.PlacesServiceStatus.ZERO_RESULTS);
                return;
            }

            this.autocompleteService.getPlacePredictions({ input: searchTerm }, resolveResponse);
        });

        return promise;
    }

    public async getLocation(lat: number, lng: number): Promise<string> {
        if (lat && lng) {
            const address = await this.getCurrentAddress(lat, lng);
            return address;
        }

        return 'Current location not available';
    }

    public async getReverseLocation(address: string): Promise<{ lat: number, lng: number }> {
        let promise: Promise<{ lat: number, lng: number }> = Utils.retry((resolve, reject) => {

            if (this.reverseGeocoderCache[address]) {
                this.log("Hitting reverse geocoder cache ...");
                resolve(this.reverseGeocoderCache[address]);
            } else {
                this.geocoderService.geocode({
                    'address': address
                }, (result, status) => {
                    if (status == google.maps.GeocoderStatus.OK) {
                        this.log("Populating reverse geocoder cache ...");

                        if (result[0].geometry) {
                            let location = {
                                lat: result[0].geometry.location.lat(),
                                lng: result[0].geometry.location.lng()
                            }
                            this.reverseGeocoderCache[address] = location;
                            resolve(location);
                        } else {
                            reject('Reverse geolocation for address [' + address + '] failed');
                        }
                    } else {

                        reject('Reverse geolocation for address [' + address + '] failed');
                    }
                });
            }
        });

        return promise;
    }

    private async getCurrentAddress(lat: number, lng: number): Promise<string> {

        await this.sleep();

        const obj = new google.maps.LatLng(lat, lng);
        let location: string = "" + lat + ":" + lng;

        let promise: Promise<string> = Utils.retry((resolve, reject) => {
            let address: string = '';
            if (this.geocoderCache[location]) {
                this.log("Hitting geocoder cache ...");
                address = this.geocoderCache[location];
                this.wait = false;
                resolve(address);
            } else {
                this.geocoderService.geocode({
                    'location': obj
                }, (results, status) => {
                    if (status == google.maps.GeocoderStatus.OK) {
                        this.log("Populating geocoder cache ...");
                        this.geocoderCache[location] = results[0].formatted_address;
                        address = this.geocoderCache[location];
                        resolve(address);
                        this.wait = false;
                        return;
                    } else {
                        this.wait = true;
                        reject('Current location [' + location + '] not available');
                    }
                });
            }
        });

        return promise;
    }

    private async sleep(): Promise<void> {
        while (this.wait) {
            let delay: number = Math.floor(Math.random() * 1000);
            await (new Promise(resolve => setTimeout(resolve, delay)));
            this.wait = false;
        }
    }

    public async getRoute(request: { origin: any, destination: any, travelMode: any }) : Promise<any> {
        let key: string = "" + request.origin.lat() + ":" + request.origin.lng() + ":" + request.destination.lat() + ":" + request.destination.lng();

        let promise = Utils.retry((resolve, reject) => {
            if (this.routesCache[key]) {
                let data = this.routesCache[key];
                resolve(data);
            } else {
                this.directionsService.route(request, (data, status) => {
                    if (status == 'OK') {
                        this.routesCache[key] = data;
                        resolve(data);
                    } else {
                        reject(null);
                    }
                });
            }
        });

        return promise;
    }

    public async fixLocation(location: Location): Promise<Location> {
        if (location.lat && location.long) {
            return location;
        }

        let address: string = "";
        if (location.address) {
            address += location.address + " ";
        }
        if (location.city) {
            address += location.city + " ";
        }
        if (location.state) {
            address += ", " + location.state;
        }
        if (address) {
            let res: { lat: number, lng: number } = await this.getReverseLocation(address);
            location.lat = res.lat;
            location.long = res.lng;
        }

        return location;
    }
}
