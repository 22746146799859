import { Injectable } from '@angular/core';
import { PhoenixWebService } from '@capp/providers/web.service';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { AppService } from '@capp/app/services/app.service';
import { DocumentParams } from '@capp/app/shared/models/interfaces/Document';


export enum CompanyCacheOption {
  Documents = 'Documents',
  Activities = 'Activities',
  Contacts = 'Contacts',
  Profile = 'Profile',
  Certificates = 'Certificates',
  UpcomingEmails = 'UpcomingEmails'
}




@Injectable({ providedIn: 'root' })
export class CompanyService implements Resolve<any> {

  public data = new Map();
  public dataPromises = new Map();
  public currentlyViewedCompanyId: number;
  public isVendor: boolean;
  public companyComplianceLevelId: number;
  public vendorOrTenantId: number;
  public vendorOrTenantName = '';
  public companyName = '';

  get name() {
    return this.companyName;
  }

  get networkId() {
    return this.vendorOrTenantId;
  }

  get complianceLevelId() {
    return this.companyComplianceLevelId;
  }

  public setComplianceLevelId(complianceLevelId :number) {
    this.companyComplianceLevelId = complianceLevelId;
  }

  get networkName() {
    return this.vendorOrTenantName;
  }

  setCompanyName(name: string) {
    this.companyName = name;
  }

  async getUpdatedComplianceLevel(companyId: number): Promise<number> {
    return await this.phoenixWebService.getCompanyComplianceLevel(companyId)
  }

  constructor(public phoenixWebService: PhoenixWebService, private appService: AppService) {
  }

  async resolve(route: ActivatedRouteSnapshot) {
    const accountId = route.params['accountId'];
    const isVendor = route.params['recordType'].toString().toLowerCase() == 'v';
    await this.setCurrentCompany(Number(accountId), isVendor);
  }

  async setCurrentCompany(certusCompanyId, isVendor) {
    this.currentlyViewedCompanyId = certusCompanyId;
    this.isVendor = isVendor;
    this.data = new Map();
    this.dataPromises = new Map();
    Object.values(CompanyCacheOption).forEach(cacheOption => {
      const dataPromise = this.fetchData(cacheOption);
      this.dataPromises.set(cacheOption, dataPromise);
      dataPromise.then(data => {
        this.data.set(cacheOption, data);
      })
      .catch(error => {
        console.log(`Error in getting data for cache option ${cacheOption}: ${error}`);
      });
    });
  }

  async fetchData(cacheOption: CompanyCacheOption) {
    return this.grabFreshData[cacheOption]()
    .then(freshData => {
      this.data.set(cacheOption, freshData);
      return freshData;
    }).catch(error => {
      console.log(`Error in getting data for cache option ${cacheOption}: ${error}`);
    });
  }

  grabFreshData = {
    [CompanyCacheOption.Documents]: async () => {
      return await this.phoenixWebService.getDocuments(this.currentlyViewedCompanyId, this.isVendor, null);
    },
    [CompanyCacheOption.Activities]: async () => {
      if (this.isVendor) {
        return await this.phoenixWebService.getCompanyActivities(this.currentlyViewedCompanyId);
      } else {
        return await this.phoenixWebService.getTenantCompanyActivities(this.currentlyViewedCompanyId);
      }
    },
    [CompanyCacheOption.Profile]: async () => {
      const profile = await this.phoenixWebService.getCompanyById(this.currentlyViewedCompanyId);
      this.companyComplianceLevelId = profile.complianceLevelId;
      this.companyName = profile?.name;
      this.vendorOrTenantId = profile?.entityId;
      return profile;
    },
    [CompanyCacheOption.Contacts]: async () => {
      return await this.phoenixWebService.getContactsAndAgents(this.currentlyViewedCompanyId);
    },
    [CompanyCacheOption.UpcomingEmails]: async () => {
      return await this.phoenixWebService.getUpcomingEmailsByCompany(this.isVendor, this.currentlyViewedCompanyId);
    },
    [CompanyCacheOption.Certificates]: async () => {
      let certificates = await this.phoenixWebService.getCertificatesByCompanyId(this.currentlyViewedCompanyId);

      return certificates.map(cert => {
          if (this.isVendor) {
            cert['jobTypes'] = cert['networkTypes'];
            cert['jobId'] = this.convertArrayToInt(cert, 'networkId');
          } else {
            cert['leaseTypes'] = cert['networkTypes'];
            cert['leaseId'] = this.convertArrayToInt(cert, 'networkId');
          }
          return cert;
        }
      );
    }
  };


  get profile() {
    return this.data.get(CompanyCacheOption.Profile);
  }

  async getProfile(refresh: boolean = false): Promise<any> {
    return await this.getDataByCacheOption(refresh, CompanyCacheOption.Profile);
  }


  get UpcomingEmails() {
    return this.data.get(CompanyCacheOption.UpcomingEmails);
  }

  async getUpcomingEmails(refresh: boolean = false): Promise<any> {
    return await this.getDataByCacheOption(refresh, CompanyCacheOption.UpcomingEmails);
  }

  async getDocuments(refresh: boolean = false): Promise<any> {
    return await this.getDataByCacheOption(refresh, CompanyCacheOption.Documents);
  }

  async getFilteredDocuments(params: DocumentParams): Promise<any> {
    return await this.phoenixWebService.getDocuments(this.currentlyViewedCompanyId, this.isVendor, params);
  }

  get documents() {
    return this.data.get(CompanyCacheOption.Documents);
  }

  async getActivities(refresh: boolean = false): Promise<any> {
    return await this.getDataByCacheOption(refresh, CompanyCacheOption.Activities);
  }

  get activities() {
    return this.data.get(CompanyCacheOption.Activities);
  }

  async getContacts(refresh: boolean = false): Promise<any> {
    return await this.getDataByCacheOption(refresh, CompanyCacheOption.Contacts);
  }

  get contacts() {
    return this.data.get(CompanyCacheOption.Contacts);
  }


  async getCertificates(refresh: boolean = false): Promise<any> {
    return await this.getDataByCacheOption(refresh, CompanyCacheOption.Certificates);
  }

  get certificates() {
    return this.data.get(CompanyCacheOption.Certificates);
  }

  async getDataByCacheOption(refresh: boolean, option: CompanyCacheOption) {
    if (refresh) {
      return await this.fetchData(option);
    }
    return await this.getValueFromPromise(option);
  }

  async getValueFromPromise(cacheOption) {
    if (this.data.has(cacheOption)) {
      // Return the data if it's already loaded
      return Promise.resolve(this.data.get(cacheOption));
    } else if (this.dataPromises.has(cacheOption)) {
      // Wait for the data to be loaded if the promise exists
      return this.dataPromises.get(cacheOption);
    } else {
      throw new Error('Data not available and not loading');
    }
  }

  convertArrayToInt(obj, propName) {
    const prop = obj[propName];
    // Check if the property is an array and has exactly one element
    if (Array.isArray(prop) && prop.length === 1) {
      // Convert the single array element to an integer
      return prop[0];
    } else if (prop === null || (Array.isArray(prop) && prop.length === 0)) {
      // Set the property to null if it's null or an empty array
      return null;
    }
    return prop;
  }

  // Helper function to replace Vendor or Tenant with current label for table header
  adjustColumnNameToFitLabels(presetColumn: any, isVendor: boolean, label: string): any {
    const titleName = isVendor ? "Vendor" : "Tenant";
    presetColumn.map(c => {
      if(c.title.includes(titleName)) {
        c.title.replace(titleName, label);
      }
      //this is a band aid... when data is returned from the server via datatable it must uncapitalize the first letter of columns to fit json conventions.
      //unfortunately we named custom columns with the first letter capitalized and this is the simplest fix
      c.variable = this.uncapitalizeFirstLetter(c.variable);
    });
    return presetColumn;
  }

  uncapitalizeFirstLetter(str: string): string {
    if (!str) return str;
    return str.charAt(0).toLowerCase() + str.slice(1);
  }

}
