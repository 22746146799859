import { NgModule } from '@angular/core';
import { NgxSliderModule } from 'ngx-slider-v2';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';
import { GalleryModule } from 'ng-gallery';
import { LightboxModule } from 'ng-gallery/lightbox';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { RatingModule } from 'ngx-bootstrap/rating';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { AppIdentifier, PhoenixCommonModule } from 'phoenix-common';
import { MetersToMilesPipe } from '../../../../phoenix-common/src/lib/shared/pipes/meters-miles.pipe';
import { LabelPipe } from '../../../../phoenix-common/src/lib/shared/pipes/label.pipe';
import { PluralPipe } from '../../../../phoenix-common/src/lib/shared/pipes/plural.pipe';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CallbackPipe } from '../../../../phoenix-common/src/lib/shared/pipes/callback.pipe';
import { SubSettingsMenuComponent } from '@capp/app/common/sub-settings-menu/sub-settings-menu.component';
import { RouterModule } from '@angular/router';
import { PhoenixCoiUploadComponent } from '@capp/app/common/phoenix-coi-upload/phoenix-coi-upload.component';
import { NgxUploaderModule } from 'ngx-uploader';
import { LineClampComponent } from '@capp/app/common/line-clamp/line-clamp.component';
import { CertificateHolderFormComponent } from '@capp/app/common/certificate-holder-form/certificate-holder-form.component';
import { AdditionalInsuredEntitiesFormComponent } from '@capp/app/common/additional-insured-entities-form/additional-insured-entities-form.component';

@NgModule({
  declarations: [
    SubSettingsMenuComponent,
    CallbackPipe,
    LabelPipe,
    PluralPipe,
    MetersToMilesPipe,
    PhoenixCoiUploadComponent,
    LineClampComponent,
    CertificateHolderFormComponent,
    AdditionalInsuredEntitiesFormComponent
  ],
  imports: [
    FontAwesomeModule,
    CommonModule,
    ModalModule,
    FormsModule,
    TypeaheadModule.forRoot(),
    RatingModule,
    CollapseModule.forRoot(),
    PaginationModule.forRoot(),
    NgxSliderModule,
    ProgressbarModule.forRoot(),
    NgSelectModule,
    ReactiveFormsModule,
    PopoverModule.forRoot(),
    TooltipModule.forRoot(),
    PhoenixCommonModule,
    GalleryModule,
    LightboxModule,
    BsDatepickerModule.forRoot(),
    DragDropModule,
    RouterModule,
    NgxUploaderModule
  ],
  providers: [
    BsModalService,
    { provide: 'appIdentifier', useValue: AppIdentifier.CLIENT },
    PhoenixCommonModule
  ],
  exports: [
    RouterModule,
    DragDropModule,
    SubSettingsMenuComponent,
    CallbackPipe,
    LabelPipe,
    PluralPipe,
    CollapseModule,
    RatingModule,
    FontAwesomeModule,
    PaginationModule,
    NgxSliderModule,
    ProgressbarModule,
    NgSelectModule,
    MetersToMilesPipe,
    FormsModule,
    ReactiveFormsModule,
    PopoverModule,
    TooltipModule,
    CommonModule,
    TypeaheadModule,
    ModalModule,
    PhoenixCommonModule,
    GalleryModule,
    LightboxModule,
    BsDatepickerModule,
    PhoenixCoiUploadComponent,
    LineClampComponent,
    CertificateHolderFormComponent,
    AdditionalInsuredEntitiesFormComponent
  ]
})
export class ClientWebappCommonModule {
}
