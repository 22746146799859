import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-sub-settings-menu',
    templateUrl: './sub-settings-menu.component.html',
    styleUrls: ['./sub-settings-menu.component.scss'],
})
export class SubSettingsMenuComponent {

    @Input()
    public menuItems: any[];

  getRouterLink(menuItem: any): string {
    return menuItem.routerLink;
  }
}
