import { Message } from '@twilio/conversations';

export class ChatMessage {
    public content: string;
    public sender: any = null;
    public timestamp: Date = null;
    public type: ChatMessageType;

    public twilioChatMessage: Message;

    public fromJSON(json: any): ChatMessage {
        this.content = json["content"];
        this.sender = json["sender"];
        this.timestamp = json["timestamp"];
        this.type = json["type"];
        this.twilioChatMessage = json["twilioChatMessage"];
        if (this.twilioChatMessage?.attributes?.["type"] == "change-quota") {
            this.type = ChatMessageType.ChatSystemMessage;
        }
        return this;
    }

    public toJSON() {
        return {};
    }
}

export enum ChatMessageType {
    ChatMessageText = 0,
    ChatMessageFile = 1,
    ChatMessageTyping = 2,
    ChatSystemMessage = 3
}
