<ng-template #backRowContent>
  <button (click)="onBtnClick()" class="back-to-my-vendors-btn" type="button">
    < Back to My {{ 'Company' | plural:companyService.isVendor }}
  </button>
</ng-template>


<ng-template #topBarLeftContent>
  <div class="vendor-header-container">
    <div [ngTemplateOutlet]="complianceIcon"></div>
    <div class="vendor-name">{{ this.companyService.name }}</div>
    <div *ngIf="companyService.isVendor" [ngTemplateOutlet]="chatButton" class="chat-btn"></div>
  </div>
</ng-template>

<ng-template #topBarTabContent>
  <phx-tab-container>
    <phx-tab link="info">{{ 'Company' | singular: companyService.isVendor }} Profile</phx-tab>
    <phx-tab link="contacts">Contacts</phx-tab>
    <phx-tab link="compliance">{{ 'Certificate' | plural: companyService.isVendor }} & Compliance</phx-tab>
    <phx-tab link="documents">
      Documents
      <phx-counter
        [counterContainerStyles]="{
          'margin-top': '-20px',
        }"
        hoverMessageText="documents that need review"
        [counter]="taskService.activeDocumentCount">
      </phx-counter>
    </phx-tab>
    <phx-tab link="archives">Archives</phx-tab>
    <phx-tab link="activity">
      Activity
      <phx-counter
        [counterContainerStyles]="{
          'margin-top': '-20px',
        }"
        hoverMessageText="emails that need review"
        [counter]="taskService.activeActivityCount">
      </phx-counter>
    </phx-tab>
  </phx-tab-container>
</ng-template>

<div [ngTemplateOutlet]="vendorContent"></div>
<ng-template #vendorContent>
  <div class="vendor-content">
    <router-outlet></router-outlet>
  </div>

</ng-template>
<ng-template #chatButton>
  <phx-button (click)="directMessageVendor($event)" *ngIf="
      userPermissionsService.canReadMessages() && companyService.networkId > 0
    " btnIcon="message-white" class="message-button"
              color="light-blue"
              data-id="clientMyVendorsMessageBtn" text="Chat">
  </phx-button>
</ng-template>
<ng-template #complianceIcon>
  <div [ngSwitch]="companyService.complianceLevelId" class="compliance">
    <phx-icon *ngSwitchCase="4" customClass="standardImage" src="compliant-amber"></phx-icon>
    <phx-icon *ngSwitchCase="3" customClass="standardImage" src="compliant"></phx-icon>
    <phx-icon *ngSwitchCase="2" customClass="standardImage" src="compliant-amber"></phx-icon>
    <phx-icon *ngSwitchCase="1" customClass="standardImage" src="non-compliant"></phx-icon>
  </div>
</ng-template>
