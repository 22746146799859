export const environment = {
  production: false,
  certusApiUri: 'https://test.api.bcscertus.com/v1/',
  certusApiPublicKey: 'EC0E12D3-FEBD-44A1-8C52-85C1567E56A5',
  webServiceUri: 'https://test-phoenix-api.bcsaudit.com',
  middlewareUri: 'https://test-phoenix-middleware.bcsaudit.com',
  cognitoUserPoolId: 'us-east-1_cLcbdRXdS',
  cognitoUserPoolWebClientId: '420cmj64dfdantrqaqk82hrsi',
  STRIPE_KEY: 'pk_test_KD6YblVD3OD6wLXANKV9tAko',
  cognitoEndpoint: 'https://test-client-phoenix.auth.us-east-1.amazoncognito.com',
  callbackUrl: 'https://test-client.bcsaudit.com/callback'
};
