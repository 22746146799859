

<div *ngIf="!url && !isTooltipComponent" class="phx-icon"
     [style] = "calculatedStyle"
     [class]="customClass?.toString() + ' ' + extraClass?.toString()">
  <ng-content></ng-content>
</div>

<div *ngIf="!url && isTooltipComponent" class="phx-icon"
     [style] = "calculatedStyle"
     [class]="customClass?.toString() + ' ' + extraClass?.toString()" data-tooltip>
  <ng-content></ng-content>
</div>


<img *ngIf="url" class="phx-img" alt="" [style] = "calculatedStyle" [src]="getSafeUrl(url)" [ngClass]="customClass?.toString() + ' ' + extraClass?.toString()"  />
