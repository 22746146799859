import { Pipe, PipeTransform } from '@angular/core';
import { ClientDataService } from '@capp/app/services/clientData.service';

@Pipe({ name: 'plural' })
export class PluralPipe implements PipeTransform {
  constructor(private clientData: ClientDataService) { }

  transform(labelType:string, isVendor: boolean = false): string {
    return this.clientData.getLabel(labelType, true, isVendor);
  }
}
