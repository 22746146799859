import { Component, Input, Output, EventEmitter, forwardRef} from '@angular/core';
import { CommonValueAccessorBase } from '../utils/CommonValueAccessorBase';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'phx-image-upload',
  templateUrl: 'phoenix-image-upload.html',
  styleUrls: ['phoenix-image-upload.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoenixImageUploadComponent),
      multi: true
    }
]
})
export class PhoenixImageUploadComponent extends CommonValueAccessorBase<any>{
  // TODO ADD DISABLE WHEN UPLOADING TO AVOID SPAM
  //@Input() public disabled: boolean = false;

  @Output() public imageUploaded: EventEmitter<any> = new EventEmitter<any>();

  public image: any;

  private maxSizeOptions = {
    height: 1500,
    width: 1500
  }

  private avatarObject = {
    rawImage: null,
    tagImage: null
  }

  private availableTypeOfFiles = {
    "image/png": true,
    "image/jpeg": true
  }

  constructor() {
    super();
  }

  public fileChange(event) {
    if (event && event.target && event.target.files && event.target.files.length > 0) {
      const file = (event.target as HTMLInputElement).files[0];
      if (!this.availableTypeOfFiles[file.type]) {
        let msg = "File must be a image of type: PNG or JPEG";
        alert(msg);
       // this.appService.showAlertMessage(msg, "danger");
        return;
      }

      let image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
        if ((image.width <= this.maxSizeOptions.width) && (image.height <= this.maxSizeOptions.height)) {
          let reader = new FileReader();
          reader.onloadend = (event) => {
            let data: any = event.target;
            const previewImage: string = data.result;
            this.avatarObject.tagImage = previewImage;
            let index = previewImage.indexOf(',');
            this.avatarObject.rawImage = previewImage.substr(index + 1);
            let fileAndAvatar = {
                file: file,
                image: this.avatarObject
            }
            this.imageUploaded.next(fileAndAvatar);
          }
          reader.readAsDataURL(file);
        } else {
          let msg = "You have exceed the maximum image size, which is: " + this.maxSizeOptions.width + "x" + this.maxSizeOptions.height;
          alert(msg);//using this alert is temporary. I'm going to make app service shared between vendor/client webapp
          //we can't use appService for now without having a circ dep.
       //   this.appService.showAlertMessage(msg, "danger");
        }
      };

      this.value = file;
    } else {
      this.value = null;
    }
  }

  public afterSetValue() {
  }
}
