import { Injectable, Inject } from '@angular/core';
import { ENVIRONMENT } from '../tokens/environment.token';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  constructor(@Inject(ENVIRONMENT) private config: any) {}

  getEnvironment(): any {
    return this.config;
  }
}
