<accordion class="phx-accordion" [isAnimated]="true">
  <ng-container *ngFor="let item of itemsData; let i=index;">
    <accordion-group panelClass="mt-3">
      <button
        class="btn btn-link btn-block align-items-center d-flex w-100  shadow-none"
        accordion-heading type="button">
        <div class="triangle ml-1"></div>

        <ng-template
          ngFor let-item [ngForOf]="[item]" [ngForTemplate]="titleTemplate"></ng-template>
      </button>
      <ng-template
        ngFor let-item [ngForOf]="[item]" [ngForTemplate]="bodyTemplate"></ng-template>
    </accordion-group>
  </ng-container>
</accordion>
