<div class="left-menu">
  <div class="left-menu-header">
    <br/>
    <phx-icon customClass="standardImage" h="42" mb="10" mr="0" src="bcs-new-logo" w="158"></phx-icon>
    <div (click)="showSwitchClientsDropdown()" *ngIf="!switchClientsDropdownShown; else switchClientsDropdown"
         class="header-title px-4 mt-10">
      <span class="mr-2">{{ appService.clientName }}</span>
      <phx-icon *ngIf="clientSwitcherClients?.length > 0" mr="10" size="8" src="filled-dropdown-arrow"></phx-icon>
    </div>
    <ng-template #switchClientsDropdown>
      <ng-select (change)="switchClients()" [(ngModel)]="currentClientId"
                 [closeOnSelect]="true"
                 [items]="clientSwitcherClients" bindLabel="name" bindValue="phoenixClientId"
                 class="w-100 form-control p-0 jobtype-field header-dropdown">
      </ng-select>
    </ng-template>
  </div>
  <div class="left-menu-body">
    <div *ngIf="appService.clientStatus != 'DELINQUENT'">
      <div class="menu-item" data-testid="vendors-menu-tab" routerLinkActive="active">
        <a class="parent-item-my-vendor parent-item">
          <div class="parent-item-container">
            <phx-icon customClass="menu-icon" h="25" mr="10" src="my-vendors" w="25"></phx-icon>
            <div>My {{ 'Vendor' | plural }}</div>
          </div>
        </a>
        <div class="dropdown-content">
          <a class="dropdown-item" data-testid="vendors-menu-item" id="my-accounts" routerLink="my-accounts/v"
             routerLinkActive="active-sublink">
            {{ 'Vendor' | plural }}
          </a>
          <a [ngClass]="!appService.hasVendors ? 'disabled' : ''" class="dropdown-item" data-testid="jobs-menu-item"
             id="jobs" routerLink="my-accounts/j" routerLinkActive="active-sublink">
            {{ 'Job' | plural }}
          </a>
        </div>
      </div>
      <div *ngIf="userPermissionsService.canReadTenants()" class="menu-item" data-testid="tenants-menu-tab"
           routerLinkActive="active">
        <a class="parent-item">
          <div class="parent-item-container">
            <phx-icon customClass="menu-icon" h="25" mr="10" src="my-tenants" w="25"></phx-icon>
            <div>My {{ 'Tenant' | plural }}</div>
          </div>
        </a>
        <div class="dropdown-content">
          <a class="dropdown-item" data-testid="my-tenants-tab" id="my-tenants" routerLink="my-accounts/t"
             routerLinkActive="active-sublink">
            {{ 'Tenant' | plural }}
          </a>
          <a [ngClass]="!appService.hasTenants ? 'disabled' : ''" class="dropdown-item" data-testid="my-leases-tab"
             id="leases" routerLink="my-accounts/l" routerLinkActive="active-sublink">
            {{ 'Lease' | plural }}
          </a>
        </div>
      </div>
      <div class="menu-item" routerLinkActive="active">
        <a class="parent-item" routerLink="marketplace">
          <div class="parent-item-container">
            <phx-icon customClass="menu-icon" h="25" mr="10" src="bcs" w="25"></phx-icon>
            <div>BCS Network</div>
          </div>
        </a>
      </div>
      <div *ngIf="userPermissionsService.canReadJobs()" class="menu-item" routerLinkActive="active">
        <a class="parent-item" routerLink="job-posting">
          <div class="parent-item-container">
            <phx-icon customClass="menu-icon" h="25" mr="10" src="job-postings" w="25"></phx-icon>
            <div>My Postings</div>
          </div>
        </a>
      </div>
      <div *ngIf="userPermissionsService.canReadMessages()" class="menu-item" routerLinkActive="active">
        <a class="parent-item" routerLink="messages">
          <div class="parent-item-container">
            <phx-icon customClass="menu-icon" h="25" mr="10" src="messages" w="25"></phx-icon>
            <div>Messages</div>
          </div>
        </a>
      </div>
      <div *ngIf="userPermissionsService.canReadLocations()" class="menu-item" data-testid="locations-tab"
           routerLinkActive="active">
        <a class="parent-item" routerLink="locations">
          <div class="parent-item-container">
            <phx-icon customClass="menu-icon" h="25" mr="10" src="locations" w="25"></phx-icon>
            <div>My {{ 'Location' | plural }}</div>
          </div>
        </a>
      </div>
      <div *ngIf="userPermissionsService.canReadJobs()" class="menu-item" routerLinkActive="active">
        <a class="parent-item" routerLink="my-tasks">
          <div class="parent-item-container">
            <phx-icon customClass="menu-icon" h="25" mr="10" src="task" w="25"></phx-icon>
            <div>My Tasks</div>
          </div>
        </a>
      </div>
      <div *ngIf="appService.canSeeAnalytics" class="menu-item" routerLinkActive="active">
        <a [routerLink]="['/main/analytics', !appService.hasVendors ? 'tenant-analytics' : 'vendor-analytics']"
           class="parent-item">
          <div class="parent-item-container">
            <phx-icon customClass="menu-icon" h="25" mr="10" src="analytics" w="25"></phx-icon>
            <div>Analytics</div>
          </div>
        </a>
      </div>
    </div>
    <div *ngIf="userPermissionsService.canReadClientSettings()"
         class="menu-item"
         data-testid="settings-tab"
         routerLinkActive="active">
      <a class="parent-item" routerLink="company-profile">
        <div class="parent-item-container">
          <phx-icon customClass="menu-icon" h="25" mr="10" src="gear" w="25"></phx-icon>
          <div>Settings</div>
        </div>
      </a>
    </div>
    <br />
  </div>
</div>


<ng-template #template>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Company Info</h4>
    <button (click)="modalRef.hide()" aria-label="Close" class="close pull-right" type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-4">Company Name</div>
      <div class="col-8">
        <input [(ngModel)]="companyName" class="form-control" id="vendor-name" placeholder="Company Name"
               type="text">
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-4">
        Upload Logo
      </div>
      <div class="col-8">
        <phx-upload (onFileUpload)="onFileUpload($event)"></phx-upload>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <phx-button (click)="modalRef.hide()" btnIcon="cancel-white" color="secondary" data-id="clientSidebarCancelBtn"
                size="medium" text="Cancel">
    </phx-button>
    <phx-button (click)="saveClientInfo()" [loading]="loading" btnIcon="circle-white-tick" color="primary"
                data-id="clientSidebarSaveBtn" size="medium" text="Save">
    </phx-button>
  </div>
</ng-template>
