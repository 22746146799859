import { Component, Input } from '@angular/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgForOf, NgIf } from '@angular/common';

@Component({
  selector: 'phoenix-skeleton-loading',
  standalone: true,
  imports: [
    NgxSkeletonLoaderModule,
    NgForOf,
    NgIf
  ],
  templateUrl: './phoenix-skeleton-loading.component.html',
  styleUrl: './phoenix-skeleton-loading.component.scss'
})
export class PhoenixSkeletonLoadingComponent {
  @Input('row') row: number = 1;
  @Input('col') col: number = 1;
  @Input('height') height: number = 1;
  @Input('width') width: number = 1;
  @Input('type') type: string = 'line';

  counter(i: number) {
    return new Array(i);
  }
}
