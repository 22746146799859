<div [formGroup]="certificateHolderForm" class="cert-holder-form-container">
  <div [ngClass]="inputContainerClass" class="form-input-container">
    <label class="bold" for="cert-holder-name">Name</label>
    <phx-input
      (changeEventEmitter)="onNameChange($event)"
      [disabled]="disabled"
      [hasError]="hasNameError"
      [inputValue]="this.certificateHolderForm.get('name').value"
      id="cert-holder-name"
      inputType="text"
      placeholder="Certificate Holder Name"
      size="large">
    </phx-input>
  </div>
  <div [ngClass]="inputContainerClass" class="form-input-container">
    <label class="bold" for="cert-holder-address1">Address</label>
    <phx-location-typeahead (onLocationChange)="onLocationSelect($event)"
                        [hasError]="hasLocationError"
                        [inputValue]="certificateHolderForm.get('address1').value"
                        [readonly]="disabled"
                        [doNotValidate]="true"
                        classes="phx-input"
                        controlName="address1"
                        formControlName="address1"
                        id="cert-holder-address1"
                        placeholder="Address"
                        style="width: 256px">
    </phx-location-typeahead>
  </div>
  <div [ngClass]="inputContainerClass" class="form-input-container">
    <label class="bold" for="cert-holder-address2">Address 2</label>
    <phx-input
      (changeEventEmitter)="onAddress2Change($event)"
      [disabled]="disabled"
      [inputValue]="this.certificateHolderForm.get('address2').value"
      id="cert-holder-address2"
      inputType="text"
      placeholder="Address 2"
      size="large">
    </phx-input>
  </div>
  <div [ngClass]="inputContainerClass" class="form-input-container">
    <label class="bold" for="cert-holder-city">City</label>
    <phx-input
      (changeEventEmitter)="onCityChange($event)"
      [disabled]="disabled"
      [hasError]="hasCityError"
      [inputValue]="this.certificateHolderForm.get('city').value"
      id="cert-holder-city"
      inputType="text"
      placeholder="City"
      size="large">
    </phx-input>
  </div>
  <div [ngClass]="inputContainerClass" class="form-input-container">
    <label class="bold" for="cert-holder-state">State</label>
    <phx-input
      (changeEventEmitter)="onStateChange($event)"
      [disabled]="disabled"
      [hasError]="hasStateError"
      [inputValue]="this.certificateHolderForm.get('state').value"
      id="cert-holder-state"
      inputType="text"
      placeholder="State"
      size="large">
    </phx-input>
  </div>
  <div [ngClass]="inputContainerClass" class="form-input-container">
    <label class="bold" for="cert-holder-zip">Zip</label>
    <phx-input
      (changeEventEmitter)="onZipChange($event)"
      [disabled]="disabled"
      [hasError]="hasZipCodeError"
      [inputValue]="this.certificateHolderForm.get('zipCode').value"
      id="cert-holder-zip"
      inputType="text"
      placeholder="Zip"
      size="large">
    </phx-input>
  </div>
</div>
