import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-phoenix-common',
  template: `
    <p>
      phoenix-common works!
    </p>
  `,
  styles: [
  ]
})
export class PhoenixCommonComponent implements OnInit {

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  ngOnInit(): void {
  }

}
