import { Component, OnInit, TemplateRef } from '@angular/core';
import { AuthService } from '../../../../../phoenix-common/src/lib/auth/auth-service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AppService } from '../../services/app.service';
import { GlobalConstants } from '../../../../../phoenix-common/src/lib/shared/utils/Constants';
import { User } from '@capp/app/shared/models/user';
import { UserService } from '@capp/app/services/user.service';

@Component({
  selector: 'account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss']
})
export class AccountSettingsComponent implements OnInit {
  public modalRef: BsModalRef;
  public image;
  public GlobalConstants = GlobalConstants;
  public user: User;
  public userAvatarURL: any;

  constructor(public appService: AppService,
              private authService: AuthService,
              private modalService: BsModalService,
              private userService: UserService) {
  }

  async ngOnInit(): Promise<void> {
    await this.setUserProfileImage();
  }

  async setUserProfileImage() {
    const user = this.appService.getLoggedUser();
    this.user = await this.userService.getUserByUserId(user?.cognitoId);
    this.userAvatarURL = this.user?.avatarUrl ? this.user?.avatarUrl : this.appService?.clientAvatarURL;
  }

  public async logout() {
    this.authService.logout();
  }

  public contactUs(modal: TemplateRef<any>) {
    this.modalRef = this.modalService.show(modal);
  }
}
