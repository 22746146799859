import { Injectable } from '@angular/core';
import { PhoenixChatService } from 'phoenix-common';
import { UserPermissionsService } from '@capp/providers/user-permissions.service';

@Injectable({ providedIn: 'root' })
export class ClientDataService {
  public loggedClientObject: any;
  public loggedClient: any;
  public canSeeBilling;
  public tenantInsReqCategories;
  public vendorInsReqCategories;
  public locations;
  public serviceTypes;
  public vendorAccountProperties;
  public tenantAccountProperties;
  public vendorSubaccountProperties;
  public tenantSubaccountProperties;
  public leaseTypes;
  public jobTrades;

  public jobTypes;

  constructor(public userPermissionsService: UserPermissionsService, private phoenixChatService: PhoenixChatService) {
  }

  get client() {
    return this.getLoggedClient();
  }

  getLabel(type, plural, isVendor) {
    if (type == 'Company') {
      type = isVendor ? 'Vendor' : 'Tenant';
    } else if (type == 'Certificate') {
      type = isVendor ? 'Job' : 'Lease';
    }
    if (plural) {
      return this.loggedClientObject.clientLabelNames?.find(
        (x) => x.labelType == type
      ).plural;
    }
    return this.loggedClientObject.clientLabelNames?.find(
      (x) => x.labelType == type
    ).singular;
  }

  setLoggedInClient(client: any) {
    this.loggedClientObject = client;
    this.userPermissionsService.setClientInfo(this.loggedClientObject);
    this.phoenixChatService.setSelfIdentity({
      'name': this.loggedClientObject.clientName,
      'logoUrl': this.loggedClientObject.clientAvatarUrl
    });
  }

  public getLoggedClient(callback: (client: any) => void = null): any {
    return this.loggedClient;
  }

  public getData(isVendor) {
    let dictionary: { [key: string]: any };
    if (isVendor) {
      dictionary = {
        "accountProperties": this.vendorAccountProperties,
        "subaccountProperties": this.vendorSubaccountProperties,
        "reqCategories": this.vendorInsReqCategories,
        "locations": this.locations,
        "serviceTypes": this.serviceTypes,
        "subaccount_types": this.jobTypes,
        "trades": this.jobTrades
      };
    } else {
      dictionary = {
        "accountProperties": this.tenantAccountProperties,
        "subaccountProperties": this.tenantSubaccountProperties,
        "reqCategories": this.tenantInsReqCategories,
        "locations": this.locations,
        "subaccount_types": this.leaseTypes,
      };
    }

    return dictionary;
  }

  async updateAccountPropertiesCache(isVendor: boolean, phoenixWebService: any) {
    const type = isVendor ? 'vendors' : 'tenants';
    const res = await phoenixWebService.getProperties(type);
    if (isVendor) {
      this.vendorAccountProperties = res;
    } else {
      this.tenantAccountProperties = res;
    }
  }

  async updateSubAccountPropertiesCache(isVendor:boolean, phoenixWebService: any) {
    const type = isVendor ? 'jobs' : 'leases';
    const res = await phoenixWebService.getProperties(type);
    if (isVendor) {
      this.vendorSubaccountProperties = res;
    } else {
      this.tenantSubaccountProperties = res;
    }
  }
}
