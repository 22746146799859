import { ControlValueAccessor } from '@angular/forms';


export abstract class CommonValueAccessorBase<T> implements ControlValueAccessor {
	private innerValue: T;
	public disabled: boolean;

	private changed = new Array<(value: T) => void>();
	private touched = new Array<() => void>();

	get value(): T {
		return this.innerValue;
	}


	set value(value: T) {
		if (this.innerValue !== value) {
			this.innerValue = value;
			this.changed.forEach(f => f(value));
			this.afterSetValue();
		}
	}

	public abstract afterSetValue();

	touch() {
		this.touched.forEach(f => f());
	}

	writeValue(value: T) {
		this.innerValue = value;
		this.afterSetValue();
	}

	registerOnChange(fn: (value: T) => void) {
		this.changed.push(fn);
	}

	registerOnTouched(fn: () => void) {
		this.touched.push(fn);
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
}
