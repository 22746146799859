import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { UserPermission } from 'phoenix-common';
import { UserPermissionsService } from './user-permissions.service';

@Injectable({
  providedIn: 'root'
})
class UserPermissionsGuardService {
  constructor(private userPermissionsService: UserPermissionsService,
              private router: Router) {
  }

  async canActivate(activatedRoute: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): Promise<boolean> {
    return new Promise<boolean>(async (resolve): Promise<void> => {
      const permission: UserPermission = activatedRoute.data.permission;
      if (this.userPermissionsService.hasPermission(permission.securable, permission.operation)) {
        resolve(true);
      } else {
        let redirectUrl: string;
        if (activatedRoute.data.unauthorizedRedirect && !routerState.url.includes(activatedRoute.data.unauthorizedRedirect)) {
          redirectUrl = activatedRoute.data.unauthorizedRedirect;
        } else if (!routerState.url.includes('my-accounts')) {
          redirectUrl = '/main/my-accounts/v';
        } else {
          redirectUrl = '/main/marketplace';
        }
        await this.router.navigateByUrl(redirectUrl);
        resolve(false);
      }
    });
  }
}

/**
 * This is an async method that provides a way to inject the ActivatedRouteSnapshot and RouterStateSnapshot into the canActivate method.
 * @param activatedRouteSnapshot an ActivatedRouteSnapshot object to determine what route to use
 * @param routerStateSnapshot an RouterStateSnapshot that allows us to provide parameters to the can activate function from our routing files
 */
export const UserPermissionGuard: CanActivateFn = async (activatedRouteSnapshot: ActivatedRouteSnapshot, routerStateSnapshot: RouterStateSnapshot): Promise<boolean> => {
  return await inject(UserPermissionsGuardService).canActivate(activatedRouteSnapshot, routerStateSnapshot);
}
