import { inject, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@capp/app/auth/auth-guard.provider';
import { MainComponent } from '@capp/app/app-components/main/main.component';
import { SessionComponent } from '@capp/app/app-components/session/session.component';
import { ErrorPageComponent } from '@capp/app/app-components/error-page/error-page.component';
import { SSOComponent } from '@capp/app/sso/sso.component';
import { IRouteData, UserPermissionsOperation, UserPermissionsSecurable } from 'phoenix-common';
import { UserPermissionGuard } from '@capp/providers/user-permissions-guard.service';
import { DynamicRedirectComponent } from '@capp/app/DynamicRedirectComponent';

const routes: Routes = [
  { path: '', redirectTo: 'main', pathMatch: 'full' },
  {
    path: 'main', canActivate: [() => inject(AuthGuard).canActivate()], component: MainComponent, children: [
      { path: '', component: DynamicRedirectComponent },
      {
        path: 'my-accounts/:recordType',
        loadChildren: () => import('@capp/app/features/sidebar-features/my-accounts/my-accounts.module').then(m => m.MyAccountsModule)
      },
      {
        path: 'profile',
        loadChildren: () => import('@capp/app/features/profile-features/user-profile/user-profile.module').then(m => m.UserProfileModule)
      },
      {
        path: 'company-profile',
        loadChildren: () => import('@capp/app/features/sidebar-features/client-settings/company-profile.module').then(m => m.CompanyProfileModule)
      },
      {
        path: 'company-profile/settings/manage-staff/user-profile',
        canActivate: [UserPermissionGuard],
        data: {
          permission: {
            securable: UserPermissionsSecurable.USERS, operation: UserPermissionsOperation.UPDATE
          },
          unauthorizedRedirect: '/main/company-profile/settings/properties'
        } as IRouteData,
        loadChildren: () => import('@capp/app/features/sidebar-features/client-settings/manage-staff/user-profile/user-profile.module').then(m => m.UserProfileModule)
      },
      {
        path: 'locations',
        loadChildren: () => import('@capp/app/features/sidebar-features/locations/locations.module').then(m => m.LocationsModule)
      },
      {
        path: 'analytics',
        loadChildren: () => import('@capp/app/features/sidebar-features/analytics/analytics.module').then(m => m.AnalyticsModule)
      },
      {
        path: 'job-posting',
        loadChildren: () => import('@capp/app/features/sidebar-features/job-posting/job-posting.module').then(m => m.JobPostingModule)
      },
      {
        path: 'vendor-job',
        loadChildren: () => import('@capp/app/features/sidebar-features/job-posting/job-posting.module').then(m => m.JobPostingModule)
      },
      {
        path: 'tenant-lease',
        loadChildren: () => import('@capp/app/features/sidebar-features/job-posting/job-posting.module').then(m => m.JobPostingModule)
      },
      {
        path: 'marketplace',
        loadChildren: () => import('@capp/app/features/sidebar-features/marketplace/marketplace.module').then(m => m.MarketplaceModule)
      },
      {
        path: 'messages',
        loadChildren: () => import('@capp/app/features/sidebar-features/messages/messages.module').then(m => m.MessagesModule)
      },
      {
        path: 'my-tasks',
        loadChildren: () => import('@capp/app/features/sidebar-features/my-tasks/my-tasks.module').then(m => m.MyTasksModule)
      },
      { path: 'session', component: SessionComponent },
      { path: 'error-page', component: ErrorPageComponent },
      { path: '**', redirectTo: 'error-page' } // Catch-all route for incorrect routes
    ]
  },
  { path: 'login', loadChildren: () => import('@capp/app/login/login.module').then(m => m.LoginModule) },
  { path: 'review', loadChildren: () => import('@capp/app/review/review.module').then(m => m.ReviewModule) },
  { path: 'onboarding', loadChildren: () => import('@capp/app/onboarding/onboarding.module').then(m => m.OnboardingModule) },
  { path: 'callback/:cognitoAppClientId', component: SSOComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
