import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'phx-button',
  templateUrl: './phoenix-button.component.html',
  styleUrls: ['./phoenix-button.component.scss']
})
export class PhoenixButtonComponent {
  @Input('color') color: string = 'green'; //white, blue, primary, orange-secondary, green, red, ghost-white, ghost-red, ghost-orange, ghost-orange-pink, aquamarine, light-pink, light-pink-white, ghost-green
  @Input('text') text: string;
  @Input('btnIcon') btnIcon: string;
  @Input('size') size: string = 'big'; //big, large, medium, small, extra-small
  @Input('disabled') disabled: boolean = false;
  @Input('loading') loading: boolean = false;
  @Input('custom-width') customWidth: string = null;

  @Input('dropDown') dropDown: Array<{ id: string, title: string, iconClass: string, disabled: boolean }> = [];
  @Output('dropDownOutput') dropDownOutput = new EventEmitter();
  @Input('popover') outsidePopover: any = null;
  @ViewChild('popover') popover: any;

  @Output() click = new EventEmitter<any>();

  // Add this method to handle click events
  onClick(event: any): void {
    if (!this.outsidePopover) {
      event.stopPropagation();
    }
    if (this.disabled) {
      event.stopPropagation();
      event.preventDefault();
      return;
    }

    // Emit an event or perform actions
    this.click.emit(event);
  }

  public clickDropdownOpt(opt: any) {
    if (this.disabled) {
      return;
    }
    if (this.dropDown.length) {
      this.dropDownOutput.emit({
        id: opt.id
      });
      this.popover.hide();
    }
  }
}
