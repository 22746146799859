import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  AuthenticationDetails,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession
} from 'amazon-cognito-identity-js';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthenticatedUser } from '../shared/models/authenticated-user-model';
import { firstValueFrom } from 'rxjs';
import { ClientTokenInfo } from '../shared/models/client-token-info';
import { EnvironmentService } from '../services/environment.service';

const jwtHelper = new JwtHelperService();

declare const aptrinsic: any;

interface LoggedInEventData {
  user: CognitoUser | any,
  client: any
}

interface TokenData {
  id_token: string,
  access_token: string,
  refresh_token: string
}

@Injectable({ providedIn: 'root' })
export class AuthService {
  private refreshInterval: any;
  private timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  private readonly cognitoEndpoint: string;
  private readonly callbackUrl: string;
  private readonly userPoolId: string;
  private readonly storageKeyPrefix = 'CognitoIdentityServiceProvider';
  private readonly cognitoAppClientId = 'cognitoAppClientId';
  private cognitoUserOnlyToBeUsedByCompleteNewPasswordChallenge: CognitoUser = null;

  constructor(
    private router: Router,
    private http: HttpClient,
    private environmentService: EnvironmentService,
  ) {
    const environment = environmentService.getEnvironment();
    this.cognitoEndpoint = environment.cognitoEndpoint;
    this.callbackUrl = environment.callbackUrl;
    this.userPoolId = environment.cognitoUserPoolId;
  }

  public refreshSession(reloadPage = false): void {
    const lastAuthUser = this.getLastAuthUser();
    const cognitoUser = this.getStandardLoginCognitoUser(lastAuthUser);
    const refreshToken = this.getRefreshToken();
    const cognitoRefreshToken = new CognitoRefreshToken({ RefreshToken: refreshToken });
    cognitoUser.refreshSession(cognitoRefreshToken, (_: any, session: any) => {
      if (session) {
        if (reloadPage) {
          location.reload();
        }
      }
    });
  }

  public async login(username: string, password: string): Promise<CognitoUser> {
    // We need to avoid storing keys from other users.
    // This can happen when a user does not log out and navigates directly to login.
    this.removeAllCognitoKeysFromLocalStorage();

    const authDetails = new AuthenticationDetails({
      Username: username,
      Password: password
    });

    const cognitoUser = this.getStandardLoginCognitoUser(username);

    // This needs to be a Promise because the Cognito SDK uses callbacks to confirm authentication.
    // We are awaiting the promise here so that all login events
    // can happen before we progress in the app from the login page.
    const result: Promise<CognitoUser> = new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authDetails, {
        onSuccess: async currentSession => {
          const loggedClient = await this.getLoggedClientInfo(currentSession.getIdToken().getJwtToken());
          this.identifyUserInGainSight({ user: cognitoUser, client: loggedClient });
          this.setRefreshCheckingEvery5Minutes();
          resolve(cognitoUser);
        },
        // The newPasswordRequired callback provides attributes that can be checked against (userAttributes, requiredAttributes).
        // We don't need to use those attributes in our auth flow though
        // see https://www.npmjs.com/package/amazon-cognito-identity-js
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        newPasswordRequired: (_: any) => {
          // Putting this attribute on the CognitoUser object to signal to the login page
          // that we need to use the ForceNewPassword component
          cognitoUser.challengeName = 'NEW_PASSWORD_REQUIRED';
          // This is a special CognitoUser object which contains internal Session data which
          // the Cognito SDK uses to identify the valid user to confirm the password change from initial login.
          // Using this CognitoUser anywhere else would cause authentication issues.
          // It will always be null unless a new user is created and the force password mechanism applies.
          this.cognitoUserOnlyToBeUsedByCompleteNewPasswordChallenge = cognitoUser;
          resolve(cognitoUser);
        },
        onFailure: err => {
          reject(err);
        }
      });
    });

    return await result;
  }

  public logout(shouldReturnToUrl = false): void {
    // The Cognito SDK does not remove all the keys it stores.
    // This is just extra cleanup to ensure that everything still works fine when the next login occurs.
    this.removeAllCognitoKeysFromLocalStorage();

    if (shouldReturnToUrl) {
      const redirectUrl = window.location.href;
      this.router.navigate(['login'], { queryParams: { return: redirectUrl ?? '' } }).then(() => {
        window.location.reload();
      });
    } else {
      this.router.navigate(['login']).then(() => {
        window.location.reload();
      });
    }
    this.logoutFromGainSight();
    if (this.refreshInterval) {
      window.clearInterval(this.refreshInterval);
    }
  }

  public getCurrentUser(): AuthenticatedUser {
    const idToken = this.getIdToken();
    const decodedToken = jwtHelper.decodeToken(idToken);
    return this.getUserAttributesFromToken(decodedToken);
  }

  async confirmForgotPasswordCode(email: string, code: any, password: any): Promise<any> {
    const cognitoUser = this.getStandardLoginCognitoUser(email);

    return new Promise<any>((resolve, reject) => {
      cognitoUser.confirmPassword(code, password, {
        onSuccess(result) {
          resolve(result);
        },
        onFailure(err) {
          reject(err);
        }
      });
    });
  }

  async sendForgotPasswordCode(email: string): Promise<any> {
    const cognitoUser = this.getStandardLoginCognitoUser(email);

    return new Promise<any>((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: function(data) {
          resolve(data);
        },
        onFailure: function(err) {
          reject(err);
        }
      });
    });
  }

  async completeNewPasswordChallenge(newPassword: string): Promise<void> {
    const cognitoUser = this.cognitoUserOnlyToBeUsedByCompleteNewPasswordChallenge;
    return new Promise<any>((resolve, reject) => {
      cognitoUser.completeNewPasswordChallenge(newPassword, {}, {
        onSuccess: function(data) {
          resolve(data);
        },
        onFailure: function(err) {
          reject(err);
        }
      });
    });
  }

  async changePassword(email: string, oldPassword: string, newPassword: string) {
    const cognitoUser = this.getStandardLoginCognitoUser(email);
    return new Promise<any>((resolve, reject) => {
      cognitoUser.changePassword(oldPassword, newPassword, (err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
  }

  public getCurrentUserPermissions(): any {
    const idToken = this.getIdToken();
    if (!idToken) {
      return null;
    }
    const token = jwtHelper.decodeToken(idToken);
    return JSON.parse(token?.permissions);
  }

  public identifyUserInGainSight(loggedInEventData: LoggedInEventData): void {
    if (!this.environmentService.getEnvironment()?.production) {
      return;
    }
    const user = this.getUserAttributesFromToken(this.getDecodedToken());
    aptrinsic('identify',
      {
        id: loggedInEventData?.user?.username ?? loggedInEventData?.user?.cognitoId,
        email: user?.email,
        signedUpAt: Date.now(),
        firstName: user?.name
      },
      {
        id: loggedInEventData.client?.clientId,
        name: loggedInEventData.client?.clientName,
        clientType: loggedInEventData.client?.clientType,
        CSM: loggedInEventData.client?.csm,
        activeAccounts: loggedInEventData.client?.activeAccounts,
        activeLocations: loggedInEventData.client?.activeLocations,
        upgradedToProfessionalDate: loggedInEventData.client?.upgradedToProfessionalDate,
        workflowEmail: loggedInEventData.client?.workflowEmail,
        activeUsers: loggedInEventData.client?.activeUsers,
        payingByCheck: loggedInEventData.client?.clientSubscriptionInformation?.isPayingByCheck
      });
  }

  public logoutFromGainSight(): void {
    aptrinsic('reset');
  }

  public isSessionValid(): boolean {
    const idToken = this.getIdToken();
    const tokenExpired = jwtHelper.isTokenExpired(idToken);

    return !tokenExpired;
  }

  public getDecodedToken(): ClientTokenInfo {
    const idToken = this.getIdToken();
    return jwtHelper.decodeToken(idToken);
  }

  public ensureSessionIsEstablished(): void {
    const idTokenIsPresent = !!this.getIdToken();
    const accessTokenIsPresent = !!this.getAccessToken();
    const refreshTokenIsPresent = !!this.getRefreshToken();
    const lastAuthUserIsPresent = !!this.getLastAuthUser();
    if (idTokenIsPresent &&
      accessTokenIsPresent &&
      refreshTokenIsPresent &&
      lastAuthUserIsPresent) {
      this.reEstablishCognitoSession();
    }
  }

  /**
   * This method should only be called from the AuthService and the BaseWebService.
   * Please avoid getting the IdToken unless you must make a web request to the backend.
   *
   * @returns the idToken as a string
   */
  public getIdToken(): string {
    const idTokenLookupKey = `${this.getTokenLookupKey()}.idToken`;
    return localStorage.getItem(idTokenLookupKey);
  }

  public async authenticateWithAccessCode(code: string,
                                          state: string,
                                          cognitoAppClientId: string): Promise<any> {
    this.setCognitoUserPoolClientId(cognitoAppClientId);
    const url = `${this.cognitoEndpoint}/oauth2/token`;

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    const body = new HttpParams()
      .set('grant_type', 'authorization_code')
      .set('client_id', cognitoAppClientId)
      .set('redirect_uri', `${this.callbackUrl}/${cognitoAppClientId}`)
      .set('state', state)
      .set('code', code);

    const result = new Promise<any>((resolve): void => {
      this.http.post(url, body.toString(), { headers: headers })
        .subscribe(async (result: TokenData): Promise<any> => {
          const tokenData = result;
          this.setTokenDataInLocalStorage(tokenData, cognitoAppClientId);

          const user = this.getCurrentUser();
          const loggedClient = await this.getLoggedClientInfo(tokenData?.id_token);
          this.identifyUserInGainSight({ user: user, client: loggedClient });
          this.setRefreshCheckingEvery5Minutes();
          this.reEstablishCognitoSession();

          resolve(tokenData);
        });
    });

    return await result;
  }

  private setTokenDataInLocalStorage(tokenData: TokenData, ssoCognitoAppClientId: string) {
    const userName = this.getUserAttributesFromToken(jwtHelper.decodeToken(tokenData?.id_token)).cognitoId;
    const lastAuthUserKey = `${this.storageKeyPrefix}.${ssoCognitoAppClientId}.LastAuthUser`;
    const idTokenStorageKey = `${this.storageKeyPrefix}.${ssoCognitoAppClientId}.${userName}.idToken`;
    const refreshTokenStorageKey = `${this.storageKeyPrefix}.${ssoCognitoAppClientId}.${userName}.refreshToken`;
    const accessTokenStorageKey = `${this.storageKeyPrefix}.${ssoCognitoAppClientId}.${userName}.accessToken`;
    localStorage.setItem(lastAuthUserKey, userName);
    localStorage.setItem(idTokenStorageKey, tokenData?.id_token);
    localStorage.setItem(refreshTokenStorageKey, tokenData?.refresh_token);
    localStorage.setItem(accessTokenStorageKey, tokenData?.access_token);
  }

  private reEstablishCognitoSession() {
    const cognitoUser = this.getStandardLoginCognitoUser(this.getLastAuthUser());
    const sessionData = {
      IdToken: new CognitoIdToken({
        IdToken: this.getIdToken()
      }),
      AccessToken: new CognitoAccessToken({
        AccessToken: this.getAccessToken()
      }),
      RefreshToken: new CognitoRefreshToken({
        RefreshToken: this.getRefreshToken()
      })
    };
    const userSession = new CognitoUserSession(sessionData);
    cognitoUser.setSignInUserSession(userSession);
  }

  private setRefreshCheckingEvery5Minutes() {
    if (this.refreshInterval) {
      window.clearInterval(this.refreshInterval);
    }
    this.refreshInterval = setInterval(async () => {
      this.refreshSession();
    }, 1000 * 60 * 5);
  }

  private getUserAttributesFromToken(jwtDecoded: any): AuthenticatedUser {
    if (!jwtDecoded) {
      return null;
    }
    const user = new AuthenticatedUser();
    user.certusSystemUserId = jwtDecoded.certusSystemUserId;
    user.email = jwtDecoded.email;
    user.name = jwtDecoded.name;
    user.phone = jwtDecoded['custom:phoneNumber'];
    user.title = jwtDecoded['custom:jobTitle'];
    user.id = jwtDecoded.clientId;
    user.cognitoId = jwtDecoded.sub ?? jwtDecoded['cognito:username'];
    user.certusUsername = jwtDecoded.certusUsername;
    user.isBcsAdmin = jwtDecoded.isBcsAdmin?.toLowerCase() === 'true';
    return user;
  }

  private async getLoggedClientInfo(idToken: string): Promise<any> {
    const environment = this.environmentService.getEnvironment();
    const url = environment?.middlewareUri + '/api/clients/info';
    let headers = new HttpHeaders();
    headers = headers.set('TimeZone', this.timeZone);
    headers = headers.set('Content-Type', 'application/json');
    if (idToken && idToken != 'null') {
      if (jwtHelper.isTokenExpired(idToken)) {
        this.logout();
        throw new Error('Access denied due to expired token');
      }
      headers = headers.set('Authorization', 'Bearer ' + idToken);
    }
    return await firstValueFrom(this.http.get(url, { headers: headers }));
  }

  private getLastAuthUser(): string {
    const cognitoAppId = this.getCognitoUserPoolClientId();
    const lastAuthUserLookupKey = `${this.storageKeyPrefix}.${cognitoAppId}.LastAuthUser`;
    return localStorage.getItem(lastAuthUserLookupKey);
  }

  private getTokenLookupKey(): string {
    const cognitoAppId = this.getCognitoUserPoolClientId();
    const lastAuthUser = this.getLastAuthUser();
    return `${this.storageKeyPrefix}.${cognitoAppId}.${lastAuthUser}`;
  }

  private getAccessToken(): string {
    const accessTokenLookupKey = `${this.getTokenLookupKey()}.accessToken`;
    return localStorage.getItem(accessTokenLookupKey);
  }

  private getRefreshToken(): string {
    const refreshTokenLookupKey = `${this.getTokenLookupKey()}.refreshToken`;
    return localStorage.getItem(refreshTokenLookupKey);
  }

  /**
   * The Cognito SDK does not remove all the keys it stores. This is just extra
   * cleanup to ensure that everything still works fine when the next login occurs.
   * @private
   */
  private removeAllCognitoKeysFromLocalStorage(): void {
    const cognitoAppId = this.getCognitoUserPoolClientId();
    localStorage.removeItem(this.cognitoAppClientId);
    Object.keys(localStorage).forEach(key => {
      if (key.startsWith(`${this.storageKeyPrefix}.${cognitoAppId}`)) {
        localStorage.removeItem(key);
      }
    });
  }

  private getStandardLoginCognitoUser(emailOrCognitoUserName: string): CognitoUser {
    const cognitoUserPoolClientId = this.getCognitoUserPoolClientId();
    const poolData = {
      UserPoolId: this.userPoolId,
      ClientId: cognitoUserPoolClientId
    };

    const cognitoUserPool = new CognitoUserPool(poolData);
    return new CognitoUser({
      Username: emailOrCognitoUserName,
      Pool: cognitoUserPool
    });
  }

  private getCognitoUserPoolClientId(): string {
    return localStorage.getItem(this.cognitoAppClientId) ??
      this.environmentService.getEnvironment()?.cognitoUserPoolWebClientId;
  }

  private setCognitoUserPoolClientId(cognitoUserPoolClientId: string) {
    localStorage.setItem(this.cognitoAppClientId, cognitoUserPoolClientId);
  }
}
