<div class="conversation-list-wrapper" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="100"
     (scrolled)="bidScrolled()" [scrollWindow]="false">
  <div *ngIf="conversationLoaded" [ngClass]="isClientApp ? 'search-container' : ''"
       class="conversation-accordion border-bottom p-2">
    <div *ngIf="!isClientApp" class="input-group search-input box-shadow">
      <div class="input-group-prepend bg-transparent">
                <span class="input-group-text" style="border: none; background-color: #ffff; ">
                    <phx-icon customClass="standardImage" src="search"></phx-icon>
                </span>
      </div>
      <input type="text" class="form-control" placeholder="Search messages" style="border: none;"
             [(ngModel)]="searchStr" [disabled]="directConversationLoading" (input)="searchStringInput()">
    </div>
    <div *ngIf="isClientApp" class="search-input-container" style="height: fit-content;">
      <div class="input-group search-input">
        <div class="input-group-prepend serach-icon-container input-group-text serach-icon-container users-search-icon"
             style="border-right: none;">
          <phx-icon customClass="standardImage" src="search"></phx-icon>
        </div>
        <input type="text" class="form-control users-input" placeholder="Search" (input)="searchStringInput()"
               [(ngModel)]="searchStr">
      </div>
    </div>
  </div>
  <div class="conversation-accordion" [ngClass]="isClientApp ? 'conversations-container' : 'border-bottom'"
       *ngFor="let conversation of conversations">
    <div *ngIf="!isClientApp" class="conversation"
         [ngClass]="{selected: currentConversation && currentConversation.conversationId == conversation.conversationId}"
         (click)="selectConversation(conversation)">
      <div class="mx-3">
        <phx-icon src="profile-placeholder.png" size="50" [url]="conversation?.logoUrl"></phx-icon>
      </div>
      <div class="mr-3 middle-container">
        <div class="conversation-title">{{conversation.title}}</div>
        <div class="d-flex group-details">
          <!-- <div class="message-caption">{{conversation.messageCaption}}</div> -->
        </div>
      </div>
      <div class="mr-3">
      </div>
    </div>
    <div *ngIf="isClientApp" class="new-conversation-select"
         [ngClass]="currentConversation == conversation.conversationId ? 'selected' : ''"
         (click)="selectConversation(conversation)">
      <div class="mx-3">
        <phx-icon src="profile-placeholder.png" size="50" [url]="conversation?.logoUrl"></phx-icon>
      </div>
      <div class="mr-3 middle-container">
        <div class="new-conversation-title">{{conversation.title}}</div>
        <div class="d-flex group-details">
          <!-- <div class="message-caption">{{conversation.messageCaption}}</div> -->
        </div>
      </div>
      <div class="last-updated-date">
        <div class="">{{conversation?.lastUpdated | date: 'MMM dd'}}</div>
      </div>
    </div>
  </div>
  <div style="width: 100%; height: 80px; display: flex;">
    <phx-loader class="h-100 w-100 mt-3" size="big" [inPlace]="true" *ngIf="directConversationLoading"></phx-loader>
  </div>
</div>
