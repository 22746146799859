import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SafePipe } from './pipes/safe.pipe';
import { CKEditorModule } from 'ckeditor4-angular';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';

import { CollapseModule } from 'ngx-bootstrap/collapse';
import { PhoenixButtonComponent } from './phoenix-button/phoenix-button.component';
import { ConversationSelectComponent } from './phoenix-chat/components/conversation-select/conversation-select.component';
import { DirectConversationSelectComponent } from './phoenix-chat/components/conversation-select/direct-conversation-select.component';
import { PhoenixCommonComponent } from './phoenix-common.component';
import { PhoenixDataTableComponent } from './phoenix-datatable/phoenix-datatable.component';
import { PhoenixLoaderComponent } from './phoenix-loader/phoenix-loader.component';
import { PhoenixStepperComponent } from './phoenix-stepper/phoenix-stepper.component';
import { ConversationFilterPipe } from './pipes/conversation-filter.pipe';
import { LeaseTypePipe } from './pipes/lease-type-pipe';
import { JobTradePipe } from './pipes/job-trade.pipe';
import { JobTypesPipe } from './pipes/job-types.pipe';
import { CurrentConversationComponent } from './phoenix-chat/components/conversation-select/current-conversation.component';
import { PhoenixCommonService } from './phoenix-common.service';
import { PhoenixMessagePanelComponent } from './phoenix-chat/components/message-panel/message-panel.component';
import { ChatMessageComponent } from './phoenix-chat/components/chat-message/chat-message.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { RatingModule } from 'ngx-bootstrap/rating';
import { NgxUploaderModule } from 'ngx-uploader';
import { PhoenixSwitchButtonComponent } from './phoenix-switch-button/phoenix-switch-button.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { PhoenixMapsComponent } from './phoenix-maps/phoenix-maps.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { GoogleAPIService } from './providers/google.api.provider';
import { FormInputNumberComponent } from './form-input-number/form-input-number.component';
import { PhoenixUploadComponent } from './phoenix-upload/phoenix-upload';
import { PhoenixNotificationComponent } from './phoenix-notification/phoenix-notification.component';
import { PhonePipe } from './pipes/phone.pipe';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PhoenixDropdownFilterComponent } from './phoenix-dropdown-filter/phoenix-dropdown-filter.component';
import { PhoenixMultiSelectComponent } from './phoenix-multi-select/phoenix-multi-select.component';
import { PhoenixCkeditorComponent } from './phoenix-ckeditor/phoenix-ckeditor.component';
import { PhoenixAttachmentsComponent } from './phoenix-attachments/phoenix-attachments.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PhoenixImageUploadComponent } from './phoenix-image-upload/phoenix-image-upload';
import { PhoenixFilterComponent } from './phoenix-filter/phoenix-filter.component';
import { PhoenixSelectComponent } from './phoenix-select/phoenix-select.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { LocationTypeAheadComponent } from './location-typeahead/location-typeahead.component';
import { PhoenixSearchComponent } from './phoenix-search/phoenix-search.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { NgxSliderModule } from 'ngx-slider-v2';
import { PhoenixIconComponent } from './phoenix-icon/phoenix-icon.component';
import { PhoenixTabComponent } from './phoenix-tabs/phoenix-tab/phoenix-tab.component';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { PhoenixTabContainerComponent } from './phoenix-tabs/phoenix-tab-container/phoenix-tab-container.component';
import { PhoenixCheckboxComponent } from './phoenix-checkbox/phoenix-checkbox.component';
import { PhoenixSingleSelectComponent } from './phoenix-single-select/phoenix-single-select.component';
import { PhoenixInputComponent } from './phoenix-input/phoenix-input.component';
import { PhoenixSkeletonLoadingComponent } from './phoenix-skeleton-loading/phoenix-skeleton-loading.component';
import { PhoenixAccordionComponent } from './phoenix-accordion/phoenix-accordion.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import {
  PhoenixInlineEditComponent
} from './phoenix-inline-edit/phoenix-inline-edit.component';
import { AutofocusDirective } from './shared/directives/ng-autofocus-directive';
import { PhoenixProgressStatusComponent } from './phoenix-progress-status/phoenix-progress-status.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PhoenixProgressBarComponent } from './phoenix-progress-bar/phoenix-progress-bar.component';
import { PhoenixCircularRadioButtonComponent } from './phoenix-circular-button/phoenix-circular-radio-button.component';
import { PhoenixCounterComponent } from './phoenix-counter/phoenix-counter.component';
import { PhoenixTooltipLinkComponent } from './phoenix-tooltip-link/phoenix-tooltip-link.component';
import { AlertModule } from 'ngx-bootstrap/alert';
import { PhoenixVendorDataTableComponent } from './phoenix-vendor-datatable/phoenix-vendor-data-table.component';

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    PhoenixSingleSelectComponent,
    PhoenixCheckboxComponent,
    PhoenixTabContainerComponent,
    PhoenixTabComponent,
    PhoenixIconComponent,
    PhoenixSearchComponent,
    LocationTypeAheadComponent,
    PhoenixSelectComponent,
    PhoenixFilterComponent,
    PhoenixImageUploadComponent,
    PhoenixCommonComponent,
    PhoenixDataTableComponent,
    PhoenixStepperComponent,
    PhoenixButtonComponent,
    PhoenixMessagePanelComponent,
    PhoenixLoaderComponent,
    PhoenixSwitchButtonComponent,
    PhoenixMapsComponent,
    LeaseTypePipe,
    JobTypesPipe,
    JobTradePipe,
    ConversationFilterPipe,
    SafePipe,
    ConversationSelectComponent,
    DirectConversationSelectComponent,
    CurrentConversationComponent,
    ChatMessageComponent,
    FormInputNumberComponent,
    PhoenixUploadComponent,
    PhoenixNotificationComponent,
    PhonePipe,
    PhoenixDropdownFilterComponent,
    PhoenixMultiSelectComponent,
    PhoenixCkeditorComponent,
    PhoenixAttachmentsComponent,
    AutofocusDirective,
    PhoenixInlineEditComponent,
    PhoenixAccordionComponent,
    PhoenixProgressBarComponent,
    PhoenixCircularRadioButtonComponent,
    PhoenixCounterComponent,
    PhoenixTooltipLinkComponent,
    PhoenixVendorDataTableComponent
  ],
  providers: [
    PhoenixCommonService,
    GoogleAPIService
  ],
    imports: [
        FontAwesomeModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        CollapseModule.forRoot(),
        TooltipModule.forRoot(),
        PopoverModule.forRoot(),
        RatingModule,
        NgxUploaderModule,
        GoogleMapsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        InfiniteScrollModule,
        CKEditorModule,
        LottieModule.forRoot({ player: playerFactory }),
        NgSelectModule,
        TypeaheadModule,
        NgxSliderModule,
        RouterLinkActive,
        RouterLink,
        PhoenixInputComponent,
        PhoenixSkeletonLoadingComponent,
        AccordionModule,
        PhoenixProgressStatusComponent,
        BsDropdownModule,
        NgOptimizedImage,
        AlertModule
    ],
  exports: [
    PhoenixSingleSelectComponent,
    PhoenixCheckboxComponent,
    PhoenixTabContainerComponent,
    PhoenixTabComponent,
    PhoenixIconComponent,
    PhoenixFilterComponent,
    PhoenixImageUploadComponent,
    ReactiveFormsModule,
    FontAwesomeModule,
    FormInputNumberComponent,
    PhoenixCommonComponent,
    PhoenixDataTableComponent,
    CollapseModule,
    PhoenixStepperComponent,
    JobTypesPipe,
    JobTradePipe,
    ConversationFilterPipe,
    SafePipe,
    PhoenixButtonComponent,
    PhoenixLoaderComponent,
    ConversationSelectComponent,
    DirectConversationSelectComponent,
    CurrentConversationComponent,
    PhoenixMessagePanelComponent,
    PhoenixSwitchButtonComponent,
    PhoenixMapsComponent,
    PhoenixUploadComponent,
    PhoenixNotificationComponent,
    PhonePipe,
    PhoenixDropdownFilterComponent,
    PhoenixMultiSelectComponent,
    PhoenixCkeditorComponent,
    PhoenixAttachmentsComponent,
    PhoenixSelectComponent,
    LocationTypeAheadComponent,
    PhoenixSearchComponent,
    PhoenixInputComponent,
    PhoenixSkeletonLoadingComponent,
    AutofocusDirective,
    PhoenixInlineEditComponent,
    PhoenixAccordionComponent,
    PhoenixProgressBarComponent,
    PhoenixCircularRadioButtonComponent,
    PhoenixCounterComponent,
    PhoenixTooltipLinkComponent,
    PhoenixVendorDataTableComponent
  ]
})
export class PhoenixCommonModule {
}
