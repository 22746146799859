import { EventEmitter, Injectable, SecurityContext } from '@angular/core';
import { PhoenixWebService } from '@capp/providers/web.service';
import { DomSanitizer } from '@angular/platform-browser';
import { marked } from 'marked';

interface Conversation {
  answer: string | null; // Answer, can be a string or null
  question: string | null; // Question, can be a string or null
}

export interface AIThreadInfo {
  certificateName: string;
  companyName: string;
  lastUpdatedDate: string;
}

interface ConversationThread {
  [key: string]: {
    conversations: Conversation[];
    threadInfo: AIThreadInfo;
  };
}

export enum AIConversationType {
  Company = 1,
  Certificate = 2
}

@Injectable({ providedIn: 'root' })
export class RiskBotService {
  companyId: number = null;
  certificateId: number = null;
  isRiskBotChatShown = false;
  isInitialRiskBotLoad = false;
  aiIsThinking = false;
  conversationThreads: ConversationThread = {};
  isCertificateConversation = false;
  toggleRiskBotEventEmitter = new EventEmitter<any>;
  isVendor: boolean;
  private previousCompanyId: number;
  private previousCertificateId: number;

  constructor(private phoenixWebService: PhoenixWebService,
              private sanitizer: DomSanitizer) {
  }

  async getAISummary(id: number,
                     type: AIConversationType,
                     conversationMessage: string = null,
                     isInitial = false) {
    if (type === AIConversationType.Company) {
      this.previousCompanyId = this.companyId;
      this.companyId = id;
      this.isCertificateConversation = false;
    } else {
      this.previousCertificateId = this.certificateId;
      this.certificateId = id;
      this.isCertificateConversation = true;
    }

    const previousId = type === AIConversationType.Company ? this.previousCompanyId : this.previousCertificateId;
    const currentId = type === AIConversationType.Company ? this.companyId : this.certificateId;

    if (isInitial) {
      const hasNoMessage = !conversationMessage?.length;
      const hasThread = !!this.conversationThreads[currentId]?.conversations;

      if (hasThread && hasNoMessage) {
        return; // Exit early if the condition is met
      }
      this.isInitialRiskBotLoad = true;
    } else {
      this.aiIsThinking = true;
    }

    const params = {
      [type === AIConversationType.Company ? 'companyId' : 'certificateId']: currentId,
      conversationMessage: previousId === currentId ? conversationMessage : null
    };

    const result = type === AIConversationType.Company ? await this.phoenixWebService.getAiCompanySummary(params)
                                                       : await this.phoenixWebService.getAiCertificateSummary(params);

    if (type === AIConversationType.Company) {
      await this.addResponseMessageToConversationThread(result.result, result, AIConversationType.Company);
    } else {
      await this.addResponseMessageToConversationThread(result.result, result, AIConversationType.Certificate);
    }

    if (isInitial) {
      this.isInitialRiskBotLoad = false;
    } else {
      this.aiIsThinking = false;
    }
  }

  toggleChatBot() {
    this.toggleRiskBotEventEmitter?.emit();
  }

  setRiskBotChatShownFlag(isItShown: boolean) {
    this.isRiskBotChatShown = isItShown;
  }

  addQuestionMessageToConversationThread(questionMessage: string,
                                         aiConversationType: AIConversationType) {
    // TODO: LAW - We need to store these in 2 different data structures because of data crossover issues
    const id = aiConversationType == AIConversationType.Company ? this.companyId : this.certificateId;
    const newConversation: Conversation = {
      question: questionMessage,
      answer: null
    };

    if (!this.conversationThreads[id]) {
      this.conversationThreads[id] = {
        threadInfo: null,
        conversations: []
      };
    }

    this.conversationThreads[id]?.conversations?.push(newConversation);
  }

  setIsVendor(isVendor: boolean) {
    this.isVendor = isVendor;
  }

  private async addResponseMessageToConversationThread(answerMessage: string,
                                                       aiResults: AIThreadInfo,
                                                       aiConversationType: AIConversationType) {
    // TODO: LAW - We need to store these in 2 different data structures because of data crossover issues
    const id = aiConversationType == AIConversationType.Company ? this.companyId : this.certificateId;
    const newConversation: Conversation = {
      question: null,
      answer: await this.convertMarkedHTML(answerMessage)
    };

    if (!this.conversationThreads[id]) {
      this.conversationThreads[id] = {
        threadInfo: aiResults,
        conversations: []
      };
    }

    this.conversationThreads[id]?.conversations?.push(newConversation);
  }

  private async convertMarkedHTML(markdown: string): Promise<string> {
    const parser = new DOMParser();
    const html: Document = parser.parseFromString(await this.markdownToHTML(markdown), 'text/html');
    return this.sanitizer.sanitize(SecurityContext.HTML, html.body.innerHTML);
  }

  private async markdownToHTML(markdown: string) {
    return marked.parse(markdown);
  }
}
