import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Subject, throwError } from 'rxjs';

import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from '../auth/auth-service';
import { EnvironmentService } from '../services/environment.service';

const jwtHelper = new JwtHelperService();

export interface ErrorResponse {
  status: number,
  message: string
}

export class CommonBaseWebService {
  public static errorSubject = new Subject<ErrorResponse>();
  public webServiceUri: string;
  public middleWareUri: string;
  public timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  protected basePathWebService = '';

  constructor(protected http: HttpClient, protected authService: AuthService, protected environmentService: EnvironmentService, basePath?: string) {
    this.webServiceUri = environmentService.getEnvironment()?.webServiceUri;
    this.middleWareUri = environmentService.getEnvironment()?.middlewareUri;

    if (this.webServiceUri.endsWith('/')) {
      this.webServiceUri = this.webServiceUri.substring(0, this.webServiceUri.length - 1);
    }

    if (basePath) {
      if (!basePath.startsWith('/')) {
        basePath = '/' + basePath;
      }
      this.basePathWebService = this.webServiceUri + basePath;
      if (!this.basePathWebService.endsWith('/')) {
        this.basePathWebService += '/';
      }
    }
  }

  protected extractData(res: Response) {
    return res || {};
  }

  protected handleError(error: Response | any) {
    let errorObj = error;
    const err = errorObj[Object.keys(errorObj)[0]];
    CommonBaseWebService.errorSubject.next({
      status: error.status,
      message: err
    });

    if (errorObj) {
      return throwError(errorObj);
    } else if (error && error.text) {
      return throwError(error.text().trim());
    } else {
      return throwError(error);
    }
  }

  protected async apiGetCall(url: string, headers?: HttpHeaders): Promise<any> {
    if (!headers) {
      headers = new HttpHeaders();
    }
    headers = headers.set('TimeZone', this.timeZone);
    headers = headers.set('Content-Type', 'application/json');

    const token = this.authService.getIdToken();
    if (token && token != 'null') {
      if (jwtHelper.isTokenExpired(token)) {
        this.authService.logout();
      }
      headers = headers.set('Authorization', 'Bearer ' + token);
    }

    return this.http
               .get(url, { headers: headers })
               .pipe(map(this.extractData), catchError(this.handleError.bind(this)))
               .toPromise();
  }
}
