import { Inject, Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UploadFile } from 'ngx-uploader';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { Client, Conversation, Message, Paginator, Participant, User } from '@twilio/conversations';
import { AppIdentifier } from '../utils/constants';

const jwtHelper = new JwtHelperService();

@Injectable({ providedIn: 'root' })
export class PhoenixChatService {
  public twilioUser: User;
  public appIdentifier: string;
  public selfIdentity: any;
  public connected: boolean = false;
  public connecting: boolean = false;
  public connectRetryCount: number = 0;
  public connectMaxRetryCount: number = 3;
  public twilioAccessToken: string = null;
  public twilioClient: Client;
  public channelMembersMap: { [key: string]: any } = {};
  public channelsCache: { [key: string]: any } = {};
  public activeChannel: Conversation;
  public typingSource = new Subject<Participant>();
  public typingObservable: Observable<Participant> = this.typingSource.asObservable();
  public chatMessageSource = new Subject<Message>();
  public chatMessageObservable: Observable<Message> = this.chatMessageSource.asObservable();
  public chatMessageHistorySource = new Subject<Array<Message>>();
  public chatMessageHistoryObservable: Observable<Array<Message>> = this.chatMessageHistorySource.asObservable();
  public currentConversation: any;
  public currentConversationSource = new ReplaySubject<any>(1);
  public userProfileLoadingMap: { [key: string]: boolean } = {};
  public userProfilesCache = {};
  public userProfilesCacheSource = new BehaviorSubject<any>({});
  public userProfilesCacheObservable: Observable<any> = this.userProfilesCacheSource.asObservable();

  constructor(@Inject('appIdentifier') private appId: AppIdentifier) {
    this.appIdentifier = this.appId;
  }

  public async getUserProfile(userId: string): Promise<any> {
    let webService = null;
    try {
      if (!this.userProfilesCache[userId] && !this.userProfileLoadingMap[userId]) {
        if (this.appIdentifier == AppIdentifier.CLIENT) {
          webService = (<any> window).clientWebService;
        } else if (this.appIdentifier == AppIdentifier.VENDOR) {
          webService = (<any> window).vendorWebService;
        }
        this.userProfileLoadingMap[userId] = true;
        this.userProfilesCache[userId] = await webService?.getUserById(userId);
        this.userProfileLoadingMap[userId] = false;
        this.userProfilesCacheSource.next({ ...this.userProfilesCache });
      }
      return this.userProfilesCache[userId];
    } catch (err) {
      this.userProfileLoadingMap[userId] = false;
      this.userProfilesCache[userId] = null;
    }
  }

  public async getTwilioToken(forceTokenRenew: boolean = false): Promise<any> {
    let twilioToken: any;
    if (!forceTokenRenew) {
      twilioToken = localStorage.getItem(this.appIdentifier + '-twilioToken');
    }
    if (!twilioToken || twilioToken === 'undefined' || jwtHelper.isTokenExpired(twilioToken)) {
      let twilioTokenRes = null;
      if (this.appIdentifier == AppIdentifier.CLIENT) {
        twilioTokenRes = await (<any> window).clientWebService.twillioAuth();
      } else if (this.appIdentifier == AppIdentifier.VENDOR) {
        twilioTokenRes = await (<any> window).vendorWebService.twillioAuth();
      }
      if (twilioTokenRes) {
        twilioToken = twilioTokenRes['twilioToken'];
        localStorage.setItem(this.appIdentifier + '-twilioToken', twilioToken);
      }
    }
    return twilioToken;
  }

  public async connect(reconnect = false): Promise<void> {
    if (!this.connecting) {
      try {
        this.connecting = true;
        this.twilioAccessToken = await this.getTwilioToken(reconnect);
        // init subscriptions
        this.twilioClient = new Client(this.twilioAccessToken);
        this.twilioClient.on(('tokenAboutToExpire'), async () => {
          this.twilioAccessToken = await this.getTwilioToken(true);
          await this.twilioClient.updateToken(this.twilioAccessToken);
        });
        this.twilioUser = this.twilioClient.user;
        this.connected = true;
        this.connecting = false;
      } catch (error) {
        this.connecting = false;
        if (this.connectRetryCount < this.connectMaxRetryCount) {
          this.connectRetryCount++;
          await this.connect();
        } else {
          console.log('Failed to connect to twilio', error);
        }
      }
    }
  }

  public async getTwilioChannelBySid(sid: string): Promise<Conversation> {
    if (!this.channelsCache[sid]) {
      let channel: Conversation = await this.twilioClient.getConversationBySid(sid);
      this.channelsCache[channel.sid] = channel;
      return channel;
    }
    return this.channelsCache[sid];
  }

  public init(): void {
    this.twilioClient.getSubscribedConversations().then(async (paginator: Paginator<Conversation>): Promise<void> => {
      if (paginator.items.length > 0) {
        await this.setActiveChannel(paginator.items[0]);
      }
    });
  }

  public async removeAllListeners(): Promise<void> {
    await this.setActiveChannel(null);
  }

  public async getConversationById(conversationId: string): Promise<void> {
    let channel = await this.getTwilioChannelBySid(conversationId);
    let users = await channel.getParticipants();
    for (let user of users) {
      this.channelMembersMap[user.identity] = user;
    }
    await this.setActiveChannel(channel);
  }

  public async setActiveChannel(channel: Conversation): Promise<boolean> {
    if (this.activeChannel && channel && this.activeChannel.sid == channel.sid) {
      return false;
    }

    if (this.activeChannel) {
      this.activeChannel.removeAllListeners();
    }

    this.activeChannel = channel;
    if (this.activeChannel) {
      await this.getActiveChannelHistory();
      this.activeChannel.on('messageAdded', (message) => {
        this.activeChannel.setAllMessagesRead();
        this.chatMessageSource.next(message);
      });

      this.activeChannel.on('typingStarted', (member: Participant) => {
        this.typingSource.next(member);
      });
    }

    return true;
  }

  public async getActiveChannelHistory(): Promise<void> {
    if (this.activeChannel) {
      let chatMessageHistoryRes = await this.activeChannel.getMessages(100);
      if (this.activeChannel) {
        await this.activeChannel.setAllMessagesRead();
        this.chatMessageHistorySource.next(chatMessageHistoryRes.items);
      }
    }
  }

  public async sendMessage(message: string): Promise<void> {
    if (this.activeChannel && message && message.length > 0) {
      await this.activeChannel.sendMessage(message, { userId: this.selfIdentity ? this.selfIdentity['userId'] : '' });
    }
  }

  public async sendSystemMessage(message: string, attr: any): Promise<void> {
    if (this.activeChannel && message && message.length > 0) {
      await this.activeChannel.sendMessage(message, attr);
    }
  }

  public async sendFileMessage(file: UploadFile): Promise<void> {
    if (this.activeChannel && file) {
      let formData: FormData = new FormData();
      formData.append('file', file.nativeFile);
      await this.activeChannel.sendMessage(formData, { fileName: file.name });
    }
  }

  public async sendTyping(): Promise<void> {
    if (this.activeChannel) {
      await this.activeChannel.typing();
    }
  }

  public setCurrentConversation(conversation: any): void {
    this.currentConversation = conversation;
    this.currentConversationSource.next(this.currentConversation);
  }

  public processConversations(conversations: Array<any>): Array<any> {
    for (let conversation of conversations) {
      if (this.appIdentifier == AppIdentifier.CLIENT) {
        conversation.title = conversation.vendor.name || 'N/A';
        conversation.logoUrl = conversation?.vendor?.logoUrl || '/projects/phoenix-common/src/lib/assets/images/profile-placeholder.png';
      }
      if (this.appIdentifier == AppIdentifier.VENDOR) {
        conversation.title = conversation.client.name || 'N/A';
        conversation.logoUrl = conversation?.client?.config[0]?.logoUrl || '/projects/phoenix-common/src/lib/assets/images/profile-placeholder.png';
      }
    }
    return conversations;
  }

  public processJobPostings(jobPostings: Array<any>): Array<any> {
    if (this.appIdentifier == AppIdentifier.CLIENT) {
      for (let job of jobPostings) {
        for (let bid of job.bids) {
          bid.title = bid.vendor.name || 'N/A';
          bid.logoUrl = bid?.vendor?.logoUrl || '/projects/phoenix-common/src/lib/assets/images/profile-placeholder.png';
        }
      }
    } else if (this.appIdentifier == AppIdentifier.VENDOR) {
      for (let bid of jobPostings) {
        bid.title = bid?.job?.client?.name || 'N/A';
        bid.logoUrl = bid?.job?.client?.config[0]?.logoUrl || '/projects/phoenix-common/src/lib/assets/images/profile-placeholder.png';
        bid.client = bid?.job?.client;
      }
    }
    return jobPostings;
  }

  public setSelfIdentity(identity: { 'name'?: string, 'logoUrl'?: string, 'userId'?: string }): void {
    this.selfIdentity = { ...this.selfIdentity, ...identity };
  }
}
