<div class="flex-row d-flex">

  <div class="d-flex" (click)="edit()" *ngIf="!isEditing" [hidden]="isEditing">
    <ng-container [ngTemplateOutlet]="actionTemplate"></ng-container>
  </div>

  <div class="d-flex" *ngIf="isEditing" [hidden]="!isEditing">
    <ng-container [ngTemplateOutlet]="editTemplate"></ng-container>
    <div class="fs-14 ml-2 link mt-2 d-flex" (click)="confirm()">Save</div>
    <div class="fs-14 ml-2 link mt-2 d-flex" (click)="cancel()">Cancel</div>
  </div>
</div>
