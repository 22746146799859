import { AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { PhoenixWebService } from '@capp/providers/web.service';
import { UserPermissionsService } from '@capp/providers/user-permissions.service';
import { Location } from '@angular/common';
import { AppService } from '@capp/app/services/app.service';
import { CompanyService } from '@capp/app/services/company.service';
import { filter } from 'rxjs/operators';
import { NavigationService } from '@capp/app/services/navigation.service';
import { TaskService } from '@capp/app/services/task.service';

@Component({
  selector: 'app-account-profile',
  templateUrl: './account-profile.component.html',
  styleUrls: ['./account-profile.component.scss']
})
export class AccountProfileComponent implements OnInit, AfterViewInit, OnDestroy {

  public loading = false;
  public connectionLoaded = false;

  public searchParams: any = {};
  public complianceLevel: any = null;
  public activityCounter = 0;

  @ViewChild(RouterOutlet) routerOutlet: RouterOutlet;
  @ViewChild('topBarTabContent', { static: false }) topBarTabContent: TemplateRef<any>;
  @ViewChild('topBarCenterContent', { static: false }) topBarCenterContent: TemplateRef<any>;
  @ViewChild('topBarLeftContent', { static: false }) topBarLeftContent: TemplateRef<any>;
  @ViewChild('topBarBottomMiddleContent', { static: false }) topBarBottomMiddleContent: TemplateRef<any>;
  @ViewChild('backRowContent', { static: false }) backbarContent: TemplateRef<any>;

  constructor(private router: Router,
              private phoenixWebService: PhoenixWebService,
              public userPermissionsService: UserPermissionsService,
              public location: Location,
              public appService: AppService,
              public companyService: CompanyService,
              public navigateService: NavigationService,
              protected taskService: TaskService) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.setTopCenterContent();
    });
  }

  setTopCenterContent() {
    const componentInstance = this.getActivatedComponent(this.routerOutlet);
    if (componentInstance) {
      this.appService.topBarBottomMiddleContent = componentInstance.topBarBottomMiddleContent;
      //this.appService.topBarTabContent = componentInstance.topBarTabContent;
    }
  }

  async ngOnInit() {
    this.companyService.getUpcomingEmails().then((results) => {
      if (results && results.length > 0) {
        this.appService.showAlertMessage('There are upcoming scheduled emails for this record. Go to activity to check it out', 'success');
      }
    });
  }

  getActivatedComponent(routerOutlet: RouterOutlet): any {
    return routerOutlet && routerOutlet.isActivated ? routerOutlet.component : null;
  }

  async ngAfterViewInit() {
    this.appService.topBarLeftContent = this.topBarLeftContent;
    this.appService.backRowContent = this.backbarContent;
    this.appService.topBarTabContent = this.topBarTabContent;
    this.setTopCenterContent();
    await this.taskService.updateEverything();
  }

  ngOnDestroy() {
    this.appService.topBarLeftContent = null;
    this.appService.backRowContent = null;
    this.appService.topBarCenterContent = null;
    this.appService.topBarTabContent = null;
    this.appService.topBarBottomMiddleContent = null;
  }

  public async onBtnClick() {
    await this.navigateService.navigateToSearchPage();
  }

  public async directMessageVendor(event) {
    if (!this.companyService.isVendor) {
      return;
    }
    await this.companyService.getProfile();
    if (this.companyService.networkId === 0) {
      return;
    }
    event.stopPropagation();
    this.connectionLoaded = false;
    try {
      await this.phoenixWebService.getCreateVendorDirectConversation(this.companyService.networkId);
    }
    finally {
      await this.router.navigate([
        'main',
        'messages',
        'direct-messages',
        this.companyService.networkId
      ]);
    }
  }

}
