<ng-template #backRowContent>
  <button type="button" class="back-to-my-vendors-btn" (click)="onBtnClick()">
    < Back to My {{ 'Company' | plural:companyService.isVendor }} </button>
</ng-template>


<ng-template #topBarLeftContent>
    <div class="vendor-header-container">
      <div [ngTemplateOutlet]="complianceIcon"></div>
      <div class="vendor-name">{{ this.companyService.name }}</div>
      <div *ngIf="companyService.isVendor" class="chat-btn" [ngTemplateOutlet]="chatButton"></div>
    </div>
</ng-template>

<ng-template #topBarTabContent>
  <phx-tab-container>
    <phx-tab link="info">{{ 'Company' | singular: companyService.isVendor}} Profile</phx-tab>
    <phx-tab link="contacts">Contacts</phx-tab>
    <phx-tab link="compliance">{{ 'Certificate' | plural: companyService.isVendor }} & Compliance</phx-tab>
    <phx-tab link="documents">Documents</phx-tab>
    <phx-tab link="archives">Archives</phx-tab>
    <phx-tab link="activity">Activity</phx-tab>
  </phx-tab-container>
</ng-template>

<div [ngTemplateOutlet]="vendorContent"></div>
<ng-template #vendorContent>
  <div class="vendor-content">
    <router-outlet></router-outlet>
  </div>

</ng-template>
<ng-template #chatButton>
  <phx-button data-id="clientMyVendorsMessageBtn" color="light-blue" text="Chat" btnIcon="message-white" (click)="directMessageVendor($event)"
    class="message-button" *ngIf="
      userPermissionsService.canReadMessages() && companyService.networkId > 0
    ">
  </phx-button>
</ng-template>
<ng-template #complianceIcon>
  <div [ngSwitch]="companyService.complianceLevelId" class="compliance">
    <phx-icon customClass="standardImage" src="compliant-amber" *ngSwitchCase="4"></phx-icon>
    <phx-icon customClass="standardImage" src="compliant" *ngSwitchCase="3"></phx-icon>
    <phx-icon customClass="standardImage" src="compliant-amber" *ngSwitchCase="2"></phx-icon>
    <phx-icon customClass="standardImage" src="non-compliant" *ngSwitchCase="1"></phx-icon>
  </div>
</ng-template>
