export interface CertHolder {
  phoenixLocationId: number,
  certHolderId: number,
  certusClientId: number,
  certHolderName: string,
  certHolderAddress1: string,
  certHolderAddress2: string,
  certHolderCity: string,
  certHolderState: string,
  certHolderPostalCode: string,
  certHolderCountry: string,
  certusClientType: string,
  isRequired: boolean,
  certusLocationId: number
}

export interface CertHolderResult {
  unselected: GroupedCertHolders,
  selected: GroupedCertHolders
}

export interface State {
  stateId: number,
  countryId: number,
  shortName: string,
  name: string,
}

export interface Country {
  name: string,
  countryId: number,
  states: State[]
}

export interface StateAndCountry {
  stateId: number,
  countryId: number
}

export interface CreateCertHolderRequest {
  certHolderName: string,
  certHolderAddress1: string,
  certHolderAddress2: string,
  certHolderCity: string,
  certHolderState: string,
  certHolderZip: string,
  locationId?: number,
  isNew?: boolean,
  certusCertHolderIds: number[],
  certHolderPlaceId: string
}

export interface GroupedCertHolders {
  [certHolderName: string]: CertHolder[]
}


