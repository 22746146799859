import { Component, forwardRef, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CommonValueAccessorBase } from '../utils/CommonValueAccessorBase';
import { Utils } from '../shared/utils/utils';


@Component({
    selector: 'phx-input-number',
    templateUrl: './form-input-number.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FormInputNumberComponent),
            multi: true
        }
    ]
})
export class FormInputNumberComponent extends CommonValueAccessorBase<number> {
    public displayValue;
    @ViewChild('phxInput') phxInput;

    constructor() {
        super();
    }

    public afterSetValue() {
        if(this.value) {
            this.displayValue = Utils.formatNumberCleaned(this.value.toString());
        } else {
            this.displayValue = 0;
        }
    }

    public formatMoney() {
        const values = Utils.formatMoney(this.displayValue);

        this.displayValue = values.displayValue;
        this.value = values.value;
        this.phxInput.nativeElement.value = this.displayValue;
    }

}
