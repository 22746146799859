<div class="container-fluid">
  <div class="row">
    <table class="table" data-testid="phoenix-datatable">
      <thead [ngClass]="theadClass">
      <th *ngFor="let column of columns"
          [ngClass]="column.class"
          [style.width]="(column.type == columnType.DeficiencyStatus || column.type == columnType.Compliance) ? '50px' : 'auto'">
        <div [ngSwitch]="true" class="d-flex h-100">
          <div *ngSwitchCase="column.type === columnType.CheckBox" class="checkbox-container">
            <div *ngIf="showTitleForCheckbox"> {{ column.title }}</div>
            <div *ngIf="!showTitleForCheckbox">
              <phx-checkbox (changeEventEmitter)="onSelectAll($event, column.variable)"
                            [checked]="checkAllChecked(column.variable)"
                            [disabled]="disabled"></phx-checkbox>
            </div>
          </div>
          <div *ngSwitchDefault [ngClass]="{'m-auto': hasCenterTypes(column)}" class="th-container default">
            {{ column.title }}
            <div (click)="changeSorting(column)" *ngIf="column.sortable" class="sort-arrows">
              <phx-icon color="black" id="more-btn" ml="5" mr="10" size="10"
                        src="filled-dropdown-arrow"></phx-icon>
            </div>
          </div>
        </div>
      </th>
      <th *ngIf="accordionTemplate && !accordionToggleLeftSide"></th>
      </thead>
      <tbody [ngClass]="tbodyClass">
      <ng-container *ngFor="let row of rows; let i = index;">
        <tr #rowElement (click)="rowClick(row, rowElement)" [attr.data-testid]="'phoenix-datatable-row-' + i"
            [id]="'row-'+i"
            [ngClass]="{
						'collapsed': row['collapse'] != null,
						'rowClass': row['collapse'] == null,
						'row-active': row['rowSelected'] != null
					  }" class="phx-row" style="z-index: 10000;">
          <td *ngFor="let column of columns" [style.width]="column.columnWidth" class="column"
              style="max-width: 150px; overflow: inherit;">
            <div [ngSwitch]="column.type" class="d-flex">
              <!-- Start of new task types -->
              <div *ngSwitchCase="columnType.Status" class="status m-auto ">
                <input (click)="changeStatus($event, row)" [checked]="row[column.variable]"
                       class="checkbox-round" type="checkbox" />
              </div>
              <div *ngSwitchCase="columnType.ProgressStatus" class="m-auto">
                <phx-progress-status [dataRow]="row" [hasProgressStatusId]='false'></phx-progress-status>
              </div>
              <div *ngSwitchCase="columnType.TaskName" class="default">
                <div [ngClass]="row['isCompleted'] ? 'strikethrough' : ''"
                     tooltip="{{row[column.variable]}}">{{ row[column.variable] }}
                </div>
              </div>
              <div *ngSwitchCase="columnType.DocumentLink" class="default">
                <div (click)="openDocumentLink($event, row)"
                     class="bcs-link"
                     title="Click to navigate to Documents">
                  Document
                </div>
              </div>
              <div *ngSwitchCase="columnType.ActivityLink" class="default">
                <div (click)="openActivityLink($event, row)"
                     class="bcs-link"
                     title="Click to navigate to Activities">
                  Activity
                </div>
              </div>
              <div *ngSwitchCase="columnType.VendorLink" class="default" data-testid="company-name">
                <a (click)="openVendorLink($event, row)" class="account-link"
                   tooltip="{{row[column.variable]}}">{{ row[column.variable] }}</a>
              </div>
              <div *ngSwitchCase="columnType.DueDate" class="m-auto">
                <div [ngSwitch]="row[column.variable]">
                  <div *ngSwitchCase="'Due Today'"
                       class="due-date-status today">{{ row[column.variable] }}
                  </div>
                  <div *ngSwitchCase="'Overdue'"
                       class="due-date-status overdue">{{ row[column.variable] }}
                  </div>
                  <div *ngSwitchCase="'Upcoming'"
                       class="due-date-status upcoming">{{ row[column.variable] }}
                  </div>
                  <div *ngSwitchCase="'Completed'"
                       class="due-date-status completed">{{ row[column.variable] }}
                  </div>
                  <div *ngSwitchDefault class="due-date-status">{{ row[column.variable] | date }}</div>
                </div>
              </div>
              <!-- End of new task types -->
              <div *ngSwitchCase="columnType.ViewButton"
                   class="m-auto d-flex align-items-center justify-content-end">
                <phx-button (click)="viewClick(row)" color="blue" data-id="commonPhoenixDatatableViewBtn"
                            text="View"></phx-button>
              </div>
              <div *ngSwitchCase="columnType.CheckBox" class="checkbox-container">
                <phx-checkbox (changeEventEmitter)="onCheckBoxChanged($event, row, column.variable)"
                              [checked]="row.alwaysChecked ? row.alwaysChecked : row[column.variable]"
                              [disabled]="row.alwaysDisabled ? row.alwaysDisabled : disabled"></phx-checkbox>
              </div>
              <div *ngSwitchCase="columnType.Money" class="m-auto">
                {{ row[column.variable] | currency:'USD':'symbol':'1.0-0' }}
              </div>
              <div *ngSwitchCase="columnType.Rating" class="m-auto">
                <rating [(ngModel)]="row[column.variable]" max="5" readonly="true"
                        style="color: #FDAF29;font-size: 25px;"></rating>
              </div>
              <div *ngSwitchCase="columnType.Rows">
                <div *ngFor="let r of row[column.variable]">
                  {{ r || "-" }}
                </div>
              </div>
              <div *ngSwitchCase="columnType.RowsMoney">
                <div *ngFor="let r of row[column.variable]">{{
                    (r | currency:'USD':'symbol':'1.0-0')
                    || "-"
                  }}
                </div>
              </div>
              <div *ngSwitchCase="columnType.Compliance" class="m-auto compliance-container" data-testid="compliance-status">
                <div *ngIf="loadingCompliance; else complianceColumnTemplate" class="lds-ring">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <ng-template #complianceColumnTemplate>
                  <div *ngIf="row?.isPending || row?.isRegistrationExpired; else notPendingStatus;">Fee Due</div>
                  <ng-template #notPendingStatus>
                    <div *ngIf="row?.isActive; else inactiveStatus;" [ngSwitch]="row[column.variable]">
                      <phx-icon *ngSwitchCase="complianceLevelID.FullyCompliant" customClass="standardImage"
                                src="compliant"
                                tooltip="Fully Compliant"></phx-icon>
                      <phx-icon *ngSwitchCase="complianceLevelID.PartiallyCompliant" customClass="standardImage"
                                src="compliant-amber"
                                tooltip="Partially Compliant"></phx-icon>
                      <phx-icon *ngSwitchCase="complianceLevelID.MinorCompliant" customClass="standardImage"
                                src="compliant-amber"
                                tooltip="Minor Compliant"></phx-icon>
                      <phx-icon *ngSwitchDefault customClass="standardImage" src="non-compliant"
                                tooltip="Non-Compliant"></phx-icon>
                    </div>
                  </ng-template>

                  <ng-template #inactiveStatus>Inactive</ng-template>
                </ng-template>
                <div *ngIf="hasAiSummaryFeature()" class="riskbot-container">
                  <span (click)="onSummaryClicked($event, row)" class="hover-container">
                    <img alt="BCS AI Icon" class="bcs-ai" height="25" ngSrc="assets/images/riskbot-summary.svg"
                         width="25">
                    <img alt="BCS AI Icon" class="riskbot" height="25"
                         ngSrc="assets/images/riskbot-summary-hover.svg" width="25">
                  </span>
                </div>
              </div>
              <div *ngSwitchCase="columnType.DeficiencyStatus" class="m-auto compliance-container">
                <div *ngIf="row?.isPending; else notPendingStatus;">Fee Due</div>
                <ng-template #notPendingStatus>
                  <div *ngIf="row?.isActive; else inactiveStatus;" [ngSwitch]="row[column.variable]">
                    <phx-icon *ngSwitchCase="deficiencyStatusID.Compliant" customClass="standardImage"
                              src="compliant"
                              tooltip="Compliant"></phx-icon>
                    <phx-icon *ngSwitchCase="deficiencyStatusID.Minor" customClass="standardImage"
                              src="compliant-amber"
                              tooltip="Minor Compliant"></phx-icon>
                    <phx-icon *ngSwitchCase="deficiencyStatusID.Major" customClass="standardImage"
                              src="non-compliant"
                              tooltip="Non-Compliant"></phx-icon>
                    <div *ngSwitchCase="deficiencyStatusID.FeeDue">Fee Due</div>
                  </div>
                </ng-template>
                <ng-template #inactiveStatus>Inactive</ng-template>
                <div *ngIf="hasAiSummaryFeature()" class="riskbot-container">
                  <span (click)="onSummaryClicked($event, row)" class="hover-container">
                    <img alt="BCS AI Icon" class="bcs-ai" height="25" ngSrc="assets/images/riskbot-summary.svg"
                         width="25">
                    <img alt="BCS AI Icon" class="riskbot" height="25"
                         ngSrc="assets/images/riskbot-summary-hover.svg" width="25">
                  </span>
                </div>
              </div>
              <div *ngSwitchCase="columnType.CustomButton" class="m-auto">
                <ng-container
                  *ngTemplateOutlet="column.buttonCustomTemplate; context: { $implicit: row }">
                </ng-container>
              </div>
              <div *ngSwitchCase="columnType.JobTypes" class="ellipsis"
                   containerClass="service-tooltip" tooltip="{{row[column.variable] | jobTypePipe}}">
                {{ row[column.variable] | jobTypePipe }}
              </div>
              <div *ngSwitchCase="columnType.LeaseTypes" class="ellipsis"
                   containerClass="service-tooltip" tooltip="{{row[column.variable] | leaseTypePipe}}">
                {{ row[column.variable] | leaseTypePipe }}
              </div>
              <div *ngSwitchCase="columnType.JobTrades" class="ellipsis"
                   containerClass="service-tooltip" tooltip="{{row[column.variable] | jobTradePipe}}">
                {{ row[column.variable] | jobTradePipe }}
              </div>
              <div *ngSwitchCase="columnType.Ellipsis"
                   [attr.data-testid]="createDataTestId(column.variable) + '-property-' + i"
                   class="ellipsis"
                   containerClass="service-tooltip" tooltip="{{row[column.variable]}}">
                {{ row[column.variable] }}
              </div>
              <div *ngSwitchCase="columnType.Date" class="ellipsis"
                   containerClass="service-tooltip" tooltip="{{row[column.variable]}}">
                {{ row[column.variable] | date }}
              </div>
              <div *ngSwitchDefault
                   [attr.data-testid]="createDataTestId(column.variable) + '-property-' + i" class="default"
                   tooltip="{{row[column.variable]}}">
                {{ row[column.variable] }}
              </div>
            </div>
          </td>
          <td *ngIf="accordionTemplate && !accordionToggleLeftSide">
            <div [class.accordion-is-opened]="row.accordionExpanded" class="arrow-down"></div>
          </td>
        </tr>
        <tr *ngIf="accordionTemplate" class="accordion-container">
          <td [attr.colspan]="columns.length + 1" style="padding: 0; cursor: auto;">
            <div [collapse]="!(accordionTemplate && row.accordionExpanded)"
                 [isAnimated]="true" [ngClass]="row.accordionExpanded ? 'expanded' : ''">
              <ng-template *ngTemplateOutlet="accordionTemplate; context: { $implicit: row }">
              </ng-template>
            </div>
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </div>
</div>
