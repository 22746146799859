<div class="row budget-estimate py-3 m-0" *ngIf="!directMessage">
    <div class="form-group d-flex align-items-center justify-content-centercenter">
        <label for="budget-range" class="bold m-0 mr-3">Quoted Estimate</label>
        <div class="budget-range-control">
            <a class="mr-1">$</a>
            <input id="budget-low" type="number" class="form-control" placeholder="" [(ngModel)]="budgetRangeLow"
                [disabled]="phoenixChatService.appIdentifier != appIdentifier.VENDOR"
                (ngModelChange)="onBudgetChange()" />
            <a class="mx-2">-</a>
            <a class="mr-1">$</a>
            <input id="budget-high" type="number" class="form-control" placeholder="" [(ngModel)]="budgetRangeHigh"
                [disabled]="phoenixChatService.appIdentifier != appIdentifier.VENDOR"
                (ngModelChange)="onBudgetChange()" />
        </div>
        <button *ngIf="budgetChanged" type="button" class="btn btn-light ml-3" (click)="saveBudget()">Save</button>
    </div>
</div>
<phx-loader style="max-height: 1000px;" class="h-100 w-100" size="big" *ngIf="!chatConversationLoaded">
</phx-loader>
<div *ngIf="chatConversationLoaded" class="row chat-body m-0" [ngClass]="directMessage ? 'direct-message-body':''"
    #chatMessageContainer>
    <div class="col p-0" ngFileDrop [options]="options" (uploadOutput)="onUploadOutput($event)"
        [uploadInput]="uploadInput" [ngClass]="messages && messages.length == 0 ? 'flex-center':''"
        *ngIf="chatConversationLoaded">
        <div *ngFor="let message of messages; let i = index;" class="mt-2">
            <ng-container *ngIf="i == 0 || ((messages[i].timestamp - messages[i-1].timestamp) > fiveHours)">
                <div *ngIf="!isClientApp" class="message-time">{{message.timestamp | date: 'MM/dd/yyyy hh:mm'}}</div>
            </ng-container>
            <chat-message [message]="message"
                [messageOnly]="i > 0 && message.sender.id == messages[i-1].sender.id && ((messages[i].timestamp - messages[i-1].timestamp) < fiveHours)"
                (onImageLoaded)="scrollToBottom()"></chat-message>
        </div>
        <chat-message [message]="typingMessage" *ngIf="typing"></chat-message>
        <div class="no-conversation-selected" *ngIf="messages && messages.length == 0">
            There are no messages yet.
        </div>
    </div>
</div>
<div [style]="isClientApp ? 'margin-bottom: 33px; box-shadow: 0px -3px 6px #00000029;' : ''" class="chat-footer" *ngIf="!closedJobPostings && chatConversationLoaded && !readOnlyMode">
    <div class="col-10 d-flex">
        <ng-container *ngIf="!uploadFileSelected; else elseTemplate">
            <textarea [style]="isClientApp ? 'font-size: 14px;' : ''" class="h-100 w-100" placeholder="Please enter a message" [(ngModel)]="newMessageText"
                (keydown)="onTyping()" (keydown.enter)="sendMessage($event); false;">
            </textarea>
        </ng-container>
        <ng-template #elseTemplate>
            <div class="border-input mt-2">
                <div [ngSwitch]="currentFile.type">
                    <phx-icon [url]="mediaUrl" size="140" customClass="thumbnail" *ngSwitchCase="'image/png'"></phx-icon>
                    <phx-icon [url]="mediaUrl" size="140" customClass="thumbnail" *ngSwitchCase="'image/jpeg'"></phx-icon>
                    <phx-icon [url]="mediaUrl" size="140" customClass="thumbnail" *ngSwitchCase="'image/gif'"></phx-icon>
                    <phx-icon customClass="standardImage" src="chat-message-file" *ngSwitchCase="'application/pdf'"></phx-icon>
                    <phx-icon customClass="standardImage" src="chat-message-file" *ngSwitchDefault></phx-icon>
                </div>
            </div>
            <phx-icon src="cancel-white" style="cursor: pointer;" (click)="cancelUpload()"></phx-icon>
        </ng-template>
    </div>
    <div id="msgBtnArea" class="pt-2">
        <ng-container *ngIf="!uploadMode; else cancelTemplate">
            <label class="m-0">
                <phx-icon src="attachment-paperclip-white" mr="10" extraClass="hand" color="gray" ></phx-icon>
                <input hidden type="file" ngFileSelect [options]="options" (uploadOutput)="onUploadOutput($event)"
                    (change)="imagePreview($event)" [uploadInput]="uploadInput">
            </label>
        </ng-container>
        <phx-button data-id="commonMessagePanelSendBtn" text="Send" color="primary" btnIcon="send-white" (click)="sendMessage()">
        </phx-button>
        <ng-template #cancelTemplate>
            <div class="mt-1">
                <phx-button data-id="commonMessagePanelCancelBtn" btnIcon="cancel-white" text="Cancel" color="secondary"
                    (click)="cancelUpload()">
                </phx-button>
            </div>
        </ng-template>
    </div>
</div>
