<div class="input-group pointer" (click)="openModal(template)">
    <input type="text" class="form-control dropdown-toggle pointer" disabled [(ngModel)]="inputText"/>
    <div class="input-group-append">
        <button type="button" class="btn dropdown-toggle dropdown-toggle-split">
            <span class="sr-only">Toggle Dropdown</span>
        </button>
    </div>
</div>

<ng-template #template>
    <div class="modal-body">
        <phx-datatable thead-class="border-bottom" row-class="p-3 border-bottom text-nowrap" [columns]="columns" [rows]="rows" [multipleSelect]="multiple" (onChecked)="onChecked($event)"></phx-datatable>
    </div>
</ng-template>