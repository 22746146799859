import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';

/**
 * A tooltip component that shows a box of text on hover
 *
 * @example
 *  <phx-tooltip-link
 *    id="id"
 *    linkText="linkText"
 *    tooltipText="tooltipText"
 *    clickHereText="clickHereText"
 *    clickHereLink="clickHereLink"
 *    [iconOnly]="true"
 *    tooltipClass="className"
 *    iconClass="className"
 *    (clickEmitter)="onClick()">
 *  </phx-tooltip-link>
 */
@Component({
  selector: 'phx-tooltip-link',
  templateUrl: './phoenix-tooltip-link.component.html',
  styleUrl: './phoenix-tooltip-link.component.scss'
})
export class PhoenixTooltipLinkComponent implements OnInit, OnDestroy {
  /**
   * #### id: string
   *  The id
   * @type {string}
   */
  @Input() id: string;

  /**
   * #### linkText: string
   *  The display text for the link if not iconOnly
   * @type {string}
   */
  @Input() linkText: string;

  /**
   * #### tooltipText: string
   *  Text within the tooltip that is displayed on hover
   * @type {string}
   */
  @Input() tooltipText: string;

  /**
   * #### clickHereText: string
   *  The display text for the link that appears within the tooltip on hover
   * @type {string}
   */
  @Input() clickHereText: string;

  /**
   * #### clickHereLink: string
   * Route to navigate to on link click
   * @type {string}
   */
  @Input() clickHereLink: string;

  /**
   * #### iconOnly: boolean
   *  Determines whether only the icon is displayed or if the link is included alongside it.
   * @type {boolean}
   */
  @Input() iconOnly: boolean;

  /**
   * #### tooltipClass: string
   *  CSS class for the tooltip
   * @type {string}
   */
  @Input() tooltipClass: string;

  /**
   * #### iconClass: string
   *  CSS class for the icon
   * @type {string}
   */
  @Input() iconClass: string;

  /**
   * #### clickEmitter: EventEmitter<any>
   *  The click event that triggers when icon is clicked
   * @type {EventEmitter<any>}
   */
  @Output() clickEmitter: EventEmitter<any> = new EventEmitter<any>();
  private unListenMouseEnter: () => void;
  private unListenMouseLeave: () => void;

  constructor(private renderer: Renderer2,
              private el: ElementRef) {
  }

  ngOnInit(): void {
    this.setTooltipTimeout();
  }

  ngOnDestroy(): void {
    // Remove event listeners when the component is destroyed
    this.unListenMouseEnter();
    this.unListenMouseLeave();
  }

  setTooltipTimeout() {
    let hideTooltipTimeout: string | number | NodeJS.Timeout;

    const targetElement = this.renderer.selectRootElement(`#${ this.id }`, true);

    this.unListenMouseEnter = this.renderer.listen(targetElement, 'mouseover', (event) => {
      if (event.target.hasAttribute('data-tooltip')) {
        // Clear any existing timeout to ensure the tooltip doesn't hide immediately
        clearTimeout(hideTooltipTimeout);
        this.showTooltip();
      }
    });

    this.unListenMouseLeave = this.renderer.listen(targetElement, 'mouseout', (event) => {
      if (event.target.hasAttribute('data-tooltip')) {
        // Set a timeout to delay hiding the tooltip
        hideTooltipTimeout = setTimeout(() => {
          this.hideTooltip();
        }, 300); // Delay of 500ms (adjust as needed)
      }
    });
  }

  showTooltip() {
    const tooltipEl = this.el.nativeElement.querySelector('#' + this.id);
    if (tooltipEl) {
      this.renderer.setStyle(tooltipEl, 'display', 'inline-block');
    }
  }

  hideTooltip() {
    const tooltipEl = this.el.nativeElement.querySelector('#' + this.id);
    if (tooltipEl) {
      this.renderer.setStyle(tooltipEl, 'display', 'none');
    }
  }

  click() {
    this.clickEmitter.emit(true);
  }
}
