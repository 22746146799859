import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// Will add other codes here as we need them
export enum ErrorCode {
  NOT_FOUND = 404,
}

@Component({
  selector: 'error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {
  public errorMessage: string = null;
  public errorType: string = null;
  public errorCode: ErrorCode = null;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.errorMessage = params.errorMessage ? params.errorMessage : null;
      this.errorType = params.errorType ? params.errorType : null;
      this.errorCode = params.errorCode ? params.errorCode : null;
    });
  }

  public getErrorMessage() {
    if(this.errorMessage === null)
      return 'We could not find what you were looking for';
    else
      return this.errorMessage;
  }

  public getNotFoundMessage() {
    if(this.errorType === null)
      return 'Not Found';
    else
      return this.errorType;
  }

  public getErrorCode() {
    if(this.errorCode === null)
      return ErrorCode.NOT_FOUND;
    else
      return this.errorCode;
  }
}
