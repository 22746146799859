import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';

/**
 * A multi-select dropdown component enabling users to type and select their preferred options.
 *
 * @example
 *  <phx-multi-select
 *    dropdownId="this-is-the-id"
 *    [options]="optionsList"
 *    [selectedItems]="selectedDropdownItems"
 *    size="medium"
 *    idField="id"
 *    valueField="value"
 *    [isDisabled]="false"
 *    [hasError]="false"
 *    dataTestId="dataTestId"
 *    emptyDropdownMessage="No Options Found"
 *    (selectedItemsEventEmitter)="onItemsSelected($event)"
 *    (errorEventEmitter)="onError()">
 *  </phx-multi-select>
 */
@Component({
  selector: 'phx-multi-select',
  templateUrl: './phoenix-multi-select.component.html',
  styleUrls: ['./phoenix-multi-select.component.scss']
})
export class PhoenixMultiSelectComponent implements OnInit, OnChanges {
  /**
   * #### options: any[]
   *  The selectable options
   * @type {any[]}
   */
  @Input() options: any[] = [];

  /**
   * #### dropdownId: string
   *  The unique identifier for the multiselect component
   * @type {string}
   */
  @Input() dropdownId: string;

  /**
   * #### selectedItems: any[]
   *  The items that are selected
   * @type {any[]}
   */
  @Input() selectedItems: any[] = [];

  /**
   * #### size: string
   *  The size of the dropdown
   * @type {string}
   */
  @Input() size: string;

  /**
   * #### emptyDropdownMessage: string
   *  Message that displays when there are no more options to choose from
   * @type {string}
   */
  @Input() emptyDropdownMessage?: string = '';
  @Input() helpTipMessage = '';

  /**
   * #### idField: string
   *  The id of the option object you are selecting from
   * @type {string}
   */
  @Input() idField?: string = 'id';

  /**
   * #### valueField: string
   *  The value of the option object you are selecting from
   * @type {string}
   */
  @Input() valueField?: string = 'value';
  @Input() isDisabled = false;
  @Input() hasError = false;

  /**
   * #### dataTestId: string
   *  The data test id for system testing
   * @type {string}
   */
  @Input() dataTestId: string;

  /**
   * #### usePositionStatic: boolean
   *  Use Position static (use in modals where scrolling is needed. Example: FilterComponent)
   * @type {boolean}
   */
  @Input() usePositionStatic: boolean = false;

  /**
   * #### selectedItemsEventEmitter: EventEmitter<any>
   *  Event that's called when an option is selected
   * @type {EventEmitter<any>}
   */
  @Output() selectedItemsEventEmitter: EventEmitter<any> = new EventEmitter<any>();

  /**
   * #### errorEventEmitter: EventEmitter<any>
   *  Event that's called when an error occurs
   * @type {EventEmitter<any>}
   */
  @Output() errorEventEmitter: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('inputElement', { static: false }) inputElement: ElementRef;
  @ViewChild('dropdownContent', { static: false }) dropdownContent: ElementRef;
  @ViewChild('phxMultiSelect', { static: false }) phxMultiSelect: ElementRef;

  @HostListener('document:click', ['$event.target']) public onClick(targetElement: Element): void {
    if (!targetElement?.classList?.contains('phx-multi-select-selector') && !this.isDisabled) {
      this.hideDropdownContent();
    }
  }

  public input = '';
  public filteredOptions = [];
  public expandItems = false;

  ngOnInit(): void {
    this.filteredOptions = this.filterOptions();
  }

  ngOnChanges(): void {
    this.filteredOptions = this.filterOptions();
  }

  public showDropdownContent(evt: Event): void {
    evt.stopPropagation();

    if (this.isDisabled || (!this.filteredOptions?.length && this.emptyDropdownMessage === '')) {
      return;
    }

    const isDropdownVisible = this.dropdownContent?.nativeElement?.classList.contains('display-block');
    const inputHasFocus = this.inputElement?.nativeElement === document.activeElement;

    this.hideAllDropdowns();

    if (!isDropdownVisible || inputHasFocus) {
      this.dropdownContent?.nativeElement?.classList.add('display-block');
      this.inputElement?.nativeElement?.focus();
    } else {
      this.dropdownContent?.nativeElement?.classList.remove('display-block');
      this.inputElement?.nativeElement?.blur();
    }
  }

  public hideDropdownContent(): void {
    setTimeout(() => {
      this.dropdownContent?.nativeElement?.classList.remove('display-block');
    }, 100);
  }

  public hideAllDropdowns(): void {
    const elements = Array.from(document.getElementsByClassName('dropdown-content') as HTMLCollectionOf<HTMLElement>);
    elements.forEach(element => element.classList.remove('display-block'));
  }

  public onSearch(): void {
    this.filteredOptions = this.filterOptions();
  }

  public selectedItem(item: any): void {
    this.inputElement.nativeElement.focus();

    if (this.isDisabled) {
      return;
    }

    this.selectedItems ??= [];
    if (!this.selectedItems?.includes(item)) {
      this.selectedItems.push(item);
    }

    this.selectedItemsEventEmitter.emit(this.selectedItems);
    this.filteredOptions = this.filterOptions();
    this.hasError = false;
    this.input = '';
  }

  public removedItem(evt: Event,
                     item: any): void {
    evt.stopPropagation();
    this.inputElement.nativeElement.focus();

    if (this.isDisabled) {
      return;
    }

    this.selectedItems = this.selectedItems.filter(f => f[this.idField] !== item[this.idField]);
    this.filteredOptions = this.filterOptions();
    this.selectedItemsEventEmitter.emit(this.selectedItems);
  }

  public filterOptions() {
    if (!this.isDisabled) {
      return this.options?.filter((f) => this.filterDropdownOptions(f));
    }
  }

  public onMouseOver(event: any) {
    event.target.addEventListener('wheel', (evt: any) => {
      evt.preventDefault();
      event.target.scrollLeft += (evt.deltaY + 10);
    });
  }

  public onButtonPress(evt: KeyboardEvent) {
    if (this.isDisabled) {
      return;
    }

    if (this.emptyDropdownMessage === '') {
      this.hideDropdownContent();
    }

    const triggerKeys = [
      'Space',
      'Enter',
      'NumpadEnter',
      'Semicolon',
      'Comma'
    ];
    if (triggerKeys.includes(evt.code)) {
      // Remove the last character for certain keys
      if (![
        'Enter',
        'NumpadEnter',
        'Space'
      ].includes(evt.code)) {
        this.input = this.input.slice(0, -1);
      }

      const trimmedInput = this.input.trim();
      const matchedOption = this.options.find(o => o[this.valueField] === trimmedInput);

      if (matchedOption) {
        this.handleSelection(matchedOption);
      } else if (this.isValidEmail(trimmedInput)) {
        this.handleSelection({
          id: null,
          value: trimmedInput
        });
      } else {
        this.hasError = true;
        this.errorEventEmitter.emit();
      }
    }
  }

  public onKeyPress(evt: KeyboardEvent) {
    if (evt.code !== 'ArrowUp' && evt.code !== 'ArrowDown') {
      return;
    }

    const counter = evt.code === 'ArrowUp' ? -1 : 1;
    const tabs = Array.from(document.getElementsByClassName(`${ this.dropdownId }-option`) as HTMLCollectionOf<HTMLElement>);
    const lastTabIndex = this.filteredOptions.length;

    const currentElement = tabs.find(element => element === document.activeElement);
    let curIndex = currentElement?.tabIndex ?? 0;

    curIndex = (curIndex === lastTabIndex && counter > 0) ? 0 : (curIndex === 1 && counter < 0) ? lastTabIndex + 1
                                                                                                : curIndex;

    const nextElement = tabs.find(element => element.tabIndex === curIndex + counter);
    nextElement?.focus();
  }

  public selectItemWithKeyPress(evt: any, item: any) {
    if (evt.code === 'Enter' || evt.code === 'NumpadEnter') {
      this.selectedItem(item);
    }
  }

  public generateItemPlaceholder() {
    const items: string[] = this.selectedItems.slice(2, this.selectedItems.length).map(i => i[this.valueField]);
    return items.join(', ');
  }

  public expandAllItems() {
    this.expandItems = true;
  }

  private isValidEmail(input: string): boolean {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(input.toLowerCase());
  }

  private handleSelection(option: any) {
    this.hasError = false;
    this.selectedItem(option);
    setTimeout(() => {
      this.input = '';
    }, 100);
  }

  private filterDropdownOptions(option: any): boolean {
    const containsInputText = !this.input || option[this.valueField]
      ?.toLocaleLowerCase().includes(this.input.toLocaleLowerCase());
    const isNotSelected = !this.selectedItems?.includes(option);
    return containsInputText && isNotSelected;
  }
}
