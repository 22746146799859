import { Component, Input} from '@angular/core';


@Component({
  selector: 'phx-tab',
  templateUrl: './phoenix-tab.component.html',
  styleUrls: ['./phoenix-tab.component.scss']
})
export class PhoenixTabComponent {
  @Input('text') text: string;
  @Input('link') link: string;

}
