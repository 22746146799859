import { Injectable } from '@angular/core';
import { PhoenixWebService } from '@capp/providers/web.service';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { AppService } from '@capp/app/services/app.service';
import { DocumentParams } from '@capp/app/shared/models/interfaces/Document';

export enum CompanyCacheOption {
  Documents = 'Documents',
  Activities = 'Activities',
  Contacts = 'Contacts',
  Profile = 'Profile',
  Certificates = 'Certificates',
  UpcomingEmails = 'UpcomingEmails'
}

@Injectable({ providedIn: 'root' })
export class CompanyService implements Resolve<any> {

  public data = new Map();
  public dataPromises = new Map();
  public currentlyViewedCompanyId: number;
  public isVendor: boolean;
  public companyComplianceLevelId: number;
  public vendorOrTenantId: number;
  public vendorOrTenantName = '';
  public companyName = '';

  grabFreshData = {
    [CompanyCacheOption.Documents]: async () => {
      return await this.phoenixWebService.getDocuments(this.currentlyViewedCompanyId, this.isVendor, null);
    },
    [CompanyCacheOption.Activities]: async () => {
      if (this.isVendor) {
        return await this.phoenixWebService.getCompanyActivities(this.currentlyViewedCompanyId);
      } else {
        return await this.phoenixWebService.getTenantCompanyActivities(this.currentlyViewedCompanyId);
      }
    },
    [CompanyCacheOption.Profile]: async () => {
      const profile = await this.phoenixWebService.getCompanyById(this.currentlyViewedCompanyId);
      this.companyComplianceLevelId = profile.complianceLevelId;
      this.companyName = profile?.name;
      this.vendorOrTenantId = profile?.entityId;
      return profile;
    },
    [CompanyCacheOption.Contacts]: async () => {
      return await this.phoenixWebService.getContactsAndAgents(this.currentlyViewedCompanyId);
    },
    [CompanyCacheOption.UpcomingEmails]: async () => {
      return await this.phoenixWebService.getUpcomingEmailsByCompany(this.isVendor, this.currentlyViewedCompanyId);
    },
    [CompanyCacheOption.Certificates]: async () => {
      const certificates = await this.phoenixWebService.getCertificatesByCompanyId(this.currentlyViewedCompanyId);
      return certificates.map((certificate: any) => {
        if (this.isVendor) {
          certificate['jobTypes'] = certificate['networkTypes'];
          certificate['jobId'] = this.convertArrayToInt(certificate, 'networkId');
          certificate['isVendor'] = this.isVendor;
        } else {
          certificate['leaseTypes'] = certificate['networkTypes'];
          certificate['leaseId'] = this.convertArrayToInt(certificate, 'networkId');
          certificate['isVendor'] = this.isVendor;
        }
        return certificate;
      });
    }
  };

  constructor(public phoenixWebService: PhoenixWebService,
              private appService: AppService) {
  }

  get name() {
    return this.companyName;
  }

  get networkId() {
    return this.vendorOrTenantId;
  }

  get complianceLevelId() {
    return this.companyComplianceLevelId;
  }

  get networkName() {
    return this.vendorOrTenantName;
  }

  get profile() {
    return this.data.get(CompanyCacheOption.Profile);
  }

  get UpcomingEmails() {
    return this.data.get(CompanyCacheOption.UpcomingEmails);
  }

  get documents() {
    return this.data.get(CompanyCacheOption.Documents);
  }

  get activities() {
    return this.data.get(CompanyCacheOption.Activities);
  }

  get contacts() {
    return this.data.get(CompanyCacheOption.Contacts);
  }

  get certificates() {
    return this.data.get(CompanyCacheOption.Certificates);
  }

  public setComplianceLevelId(complianceLevelId: number) {
    this.companyComplianceLevelId = complianceLevelId;
  }

  setCompanyName(name: string) {
    this.companyName = name;
  }

  async getUpdatedComplianceLevel(companyId: number): Promise<number> {
    return await this.phoenixWebService.getCompanyComplianceLevel(companyId);
  }

  async resolve(route: ActivatedRouteSnapshot) {
    const accountId = route.params['accountId'];
    const isVendor = route.params['recordType'].toString().toLowerCase() == 'v';
    await this.setCurrentCompany(Number(accountId), isVendor);
  }

  async setCurrentCompany(certusCompanyId: any,
                          isVendor: any) {
    this.currentlyViewedCompanyId = certusCompanyId;
    this.isVendor = isVendor;
    this.data = new Map();
    this.dataPromises = new Map();
    // These are all promises so that we can lazy load the data.
    // This helps speed the feel of the initial navigation to the company
    Object.values(CompanyCacheOption).forEach(cacheOption => {
      const dataPromise = this.fetchData(cacheOption);
      this.dataPromises.set(cacheOption, dataPromise);
      dataPromise.then(data => {
                   this.data.set(cacheOption, data);
                 })
                 .catch(error => {
                   console.log(`Error in getting data for cache option ${ cacheOption }: ${ error }`);
                 });
    });
  }

  async fetchData(cacheOption: CompanyCacheOption) {
    const result = await this.grabFreshData[cacheOption]();
    this.data.set(cacheOption, result);
    return result;
  }

  async getProfile(refresh = false): Promise<any> {
    return await this.getDataByCacheOption(refresh, CompanyCacheOption.Profile);
  }

  async getUpcomingEmails(refresh = false): Promise<any> {
    return await this.getDataByCacheOption(refresh, CompanyCacheOption.UpcomingEmails);
  }

  async getDocuments(refresh = false): Promise<any> {
    return await this.getDataByCacheOption(refresh, CompanyCacheOption.Documents);
  }

  async getFilteredDocuments(params: DocumentParams): Promise<any> {
    return await this.phoenixWebService.getDocuments(this.currentlyViewedCompanyId, this.isVendor, params);
  }

  async getActivities(refresh = false): Promise<any> {
    return await this.getDataByCacheOption(refresh, CompanyCacheOption.Activities);
  }

  async getContacts(refresh = false): Promise<any> {
    return await this.getDataByCacheOption(refresh, CompanyCacheOption.Contacts);
  }

  async getCertificates(refresh = false): Promise<any> {
    return await this.getDataByCacheOption(refresh, CompanyCacheOption.Certificates);
  }

  async getDataByCacheOption(refresh: boolean,
                             option: CompanyCacheOption) {
    if (refresh) {
      return await this.fetchData(option);
    }
    return await this.getValueFromPromise(option);
  }

  async getValueFromPromise(cacheOption: CompanyCacheOption) {
    if (this.data.has(cacheOption)) {
      // Return the data if it's already loaded
      return Promise.resolve(this.data.get(cacheOption));
    } else if (this.dataPromises.has(cacheOption)) {
      // Wait for the data to be loaded if the promise exists
      return this.dataPromises.get(cacheOption);
    } else {
      throw new Error('Data not available and not loading');
    }
  }

  convertArrayToInt(obj: any,
                    propName: any) {
    const prop = obj[propName];
    // Check if the property is an array and has exactly one element
    if (Array.isArray(prop) && prop.length === 1) {
      // Convert the single array element to an integer
      return prop[0];
    } else if (prop === null || (Array.isArray(prop) && prop.length === 0)) {
      // Set the property to null if it's null or an empty array
      return null;
    }
    return prop;
  }

  // Helper function to replace Vendor or Tenant with current label for table header
  adjustColumnNameToFitLabels(presetColumn: any,
                              isVendor: boolean,
                              label: string): any {
    const titleName = isVendor ? 'Vendor' : 'Tenant';
    presetColumn.map(c => {
      if (c.title.includes(titleName)) {
        c.title.replace(titleName, label);
      }
      // this is a band-aid... when data is returned from the server via datatable it must uncapitalize the first letter
      // of columns to fit json conventions. unfortunately we named custom columns with the first letter capitalized
      // and this is the simplest fix
      c.variable = this.uncapitalizeFirstLetter(c.variable);
    });
    return presetColumn;
  }

  uncapitalizeFirstLetter(str: string): string {
    if (!str) {
      return str;
    }
    return str.charAt(0).toLowerCase() + str.slice(1);
  }
}
