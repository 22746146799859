import { Component, Input } from '@angular/core';


@Component({
    selector: 'phx-stepper',
    templateUrl: './phoenix-stepper.component.html',
    styleUrls: ['./phoenix-stepper.component.scss']
})
export class PhoenixStepperComponent {
    @Input() currentStep: RegistrationStatus = RegistrationStatus.NONE;
}



export enum RegistrationStatus {
    NONE = 0,
    CONNECTION_ACCEPTED = 1,
    REGISTRATION_COMPLETED = 2,
    COMPLIANCE = 3,
    WAIVER_REQUEST = 4,
    COMPLETE = 5,
}
