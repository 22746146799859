<div class="additional-insured-entities-form-container">
  <div
    *ngFor="let entity of additionalInsuredEntities"
    class="entity-container">
    <phx-input
      (changeEventEmitter)="onEntityNameChange($event, entity)"
      [disabled]="disabled"
      [hasError]="hasError"
      [inputValue]="entity.name"
      id="entity-{{entity.name}}"
      inputType="text"
      placeholder="Entity"
      size="w-100">
    </phx-input>
    <div
      (click)="deleteEntity(entity)"
      [ngClass]="disabled ? 'disabled' : ''"
      class="ml-2 delete-entity-icon">
<!--      This blob of random text is an x unicode character-->
      &#x2715;
    </div>
  </div>
  <div
    (click)="addEntity()"
    [ngClass]="disabled ? 'disabled' : ''"
    class="mt-2 bcs-link add-btn">
    Add Entity +
  </div>
</div>
