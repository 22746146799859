<ng-container *ngIf="isLoading">
  <phx-loader class="fullscreen-loader" size="big"></phx-loader>
</ng-container>

<div *ngIf="canSeeNotifications()" class="ml-3 mr-3 bell-icon">
  <phx-icon (click)="showNotifications = !showNotifications" customClass="standardImage pointer shake" size="34"
            src="notification-bell">
    <div *ngIf="bulkImportProcessItems?.length > 0" class="notification-dot">{{ bulkImportProcessItems?.length }}</div>
  </phx-icon>
</div>
<div *ngIf="showNotifications" class="sidebar">
  <div class="notifications-container">
    <span>Notifications</span>
  </div>
  <div *ngIf="bulkImportProcessItems?.length == 0" class="scroll-notifications-container w-100">
    <div class="date-container">
      <span class="bold">No Notifications</span>
    </div>
  </div>
  <div *ngIf="bulkImportProcessItems?.length > 0" class="scroll-notifications-container w-100">
    <div class="date-container">
      <span class="bold">Bulk Import Processes*</span>
    </div>
    <div (click)="bulkImportProcessItemClick(bulkImportItem)" *ngFor="let bulkImportItem of bulkImportProcessItems"
         class="description-container hand">
      <div class="tiny-text mt-2 d-flex">
        <div *ngIf="bulkImportItem?.createdAt" class="mr-2"> {{ bulkImportItem?.createdAt | date: 'MM/dd/yyyy hh:mm' }}</div>
        <div *ngIf="bulkImportItem?.uploaderName"> Uploaded by: {{ bulkImportItem?.uploaderName }}</div>
      </div>
      <span>
        {{ bulkImportItem.processedFiles }}
        {{ bulkImportItem.status === bulkImportProcessStatuses.READY ? ' files are ready for further processing.' : ' files are being processed right now.' }}
        <i *ngIf="bulkImportItem.status === bulkImportProcessStatuses.READY" class="small-text-relative">Click here for the final steps</i>
      </span>
    </div>
  </div>
</div>
