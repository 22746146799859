<div class="attachment-container">


  <!-- Button -->
  <div #attachmentBtn class="attachment-btn-container">
    <phx-icon mr="10" src="attachment"></phx-icon>

    <span (click)="selectAttachments()">Attachments</span>

    <div *ngFor="let attachment of attachments" [class]="attachment.selected ? 'attachmentLink' : 'hide'">
      <a (click)="viewAttachment(attachment)">{{attachment.fileName}}</a>
      <fa-icon class="remove-attachment-icon" (click)="selectAttachment(attachment.certusFileID)"
               [icon]="faTimes"></fa-icon>
    </div>
  </div>


  <!-- Popup dropdown -->
  <div class="attachment-popup-container" *ngIf="attachmentSelected">
    <div class="more-options attachment-option">
      <label class="reset-margin" style="cursor: pointer;">
        <div>Browse Computer Documents</div>
        <input hidden multiple type="file" ngFileSelect [options]="options" (uploadOutput)="onUploadOutput($event)"
               [uploadInput]="uploadInput">
      </label>
    </div>
    <div *ngFor="let attachment of attachments" [class]="attachment.selected ? 'selected' : ''"
         class="more-options attachment-option" (click)="selectAttachment(attachment.certusFileID)">
      <div class="attachment-text">{{attachment.fileName}}</div>
      <div *ngIf="attachment.selected ? 'selected' : ''" class="close-attachment">x</div>
    </div>
  </div>
</div>
