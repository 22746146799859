<div #circularButton
     (click)="emitOptionResponse()"
     [ngClass]="(selected ? 'selected ' : '') + (hasError ? 'error' : '')"
     class="phx-circular-radio-btn-container">
  <div class="radio-btn-container">
    <input #circularButtonRadio
           [checked]="selected"
           [ngClass]="selected ? 'selected' : ''" type="radio" />
  </div>
  <div
    [innerHtml]="name"
    [ngClass]="selected ? 'selected' : ''" class="text">
  </div>
  <div class="check-container">
    <img
      *ngIf="selected"
      alt="check"
      height="15"
      ngSrc="assets/images/pink-check.svg"
      width="15" />
    <div *ngIf="!selected"></div>
  </div>
</div>
<div
  *ngIf="this.selected && this.provideOptionalInput"
  class="optional-text-container">
  <input
    (input)="emitOptionResponse()"
    [(ngModel)]="optionalInput"
    [ngClass]="hasError ? 'error' : ''"
    [placeholder]="optionalInputPlaceholder ?? 'Enter Text'" type="text" />
</div>
