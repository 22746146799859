import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PhoenixWebService } from '@capp/providers/web.service';
import { AppService } from '@capp/app/services/app.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CertificateHolderDTO } from '@capp/app/shared/models/interfaces/LocationSettingInfo';

/**
 * Component for managing the certificate holder form.
 *
 * @example
 * <phx-certificate-holder-form
 *   [certificateHolder]="certificateHolderObj"
 *   [inputContainerClass]="className"
 *   [disabled]="false">
 * </phx-certificate-holder-form>
 */
@Component({
  selector: 'phx-certificate-holder-form',
  templateUrl: './certificate-holder-form.component.html',
  styleUrls: ['./certificate-holder-form.component.scss']
})
export class CertificateHolderFormComponent implements OnInit {
  /** Certificate holder data */
  @Input() certificateHolder: CertificateHolderDTO = null;

  /** CSS class for input container */
  @Input() inputContainerClass: string;

  /** Indicates if the form is disabled */
  @Input() disabled: boolean;

  /** Form validation and data properties */
  protected locationId: number;
  protected hasLocationError: boolean;
  protected hasNameError: boolean;
  protected hasCityError: boolean;
  protected hasStateError: boolean;
  protected hasZipCodeError: boolean;
  protected certificateHolderForm: FormGroup;
  protected certificateHolderDataCopy: CertificateHolderDTO = null;
  protected certificateHolderFormCopy: FormGroup;
  protected selectedState: any;

  /**
   * Constructor to initialize the component.
   * @param phoenixWebService Service for handling API requests.
   * @param appService Service for handling UI alerts.
   * @param modalRef Reference to the modal.
   * @param modalService Service for handling modal operations.
   */
  constructor(public phoenixWebService: PhoenixWebService,
              public appService: AppService,
              public modalRef: BsModalRef,
              public modalService: BsModalService) {}

  /**
   * Lifecycle hook - initializes the form and patches data if available.
   */
  async ngOnInit(): Promise<void> {
    this.createCertificateHolderForm();
    if (this.certificateHolder != null) {
      this.patchInExistingCertificateHolderData(this.certificateHolder);
      this.certificateHolderDataCopy = JSON.parse(JSON.stringify(this.certificateHolder));
      this.certificateHolderFormCopy = this.deepCopyFormGroup(this.certificateHolderForm);
    }
  }

  /**
   * Retrieves the certificate holder form data.
   * @returns The form group if valid, otherwise null.
   */
  public getCertificateHolderFormData(): FormGroup {
    return this.isCertificateHolderFormValid() ? this.certificateHolderForm : null;
  }

  /**
   * Resets the certificate holder form to its original state.
   */
  public resetCertificateHolder() {
    this.certificateHolder = JSON.parse(JSON.stringify(this.certificateHolderDataCopy));
    const formValue = this.deepCopyFormGroup(this.certificateHolderFormCopy)?.value;
    if (Object.keys(formValue ?? {}).length > 0) {
      this.certificateHolderForm?.setValue(formValue);
    }
    this.hasLocationError = false;
    this.hasNameError = false;
    this.hasCityError = false;
    this.hasStateError = false;
    this.hasZipCodeError = false;
  }

  /**
   * Patches existing certificate holder data into the form.
   * @param certHolder Certificate holder data.
   */
  public patchInExistingCertificateHolderData(certHolder: CertificateHolderDTO) {
    this.certificateHolderForm.patchValue({
      name: certHolder.name,
      address1: certHolder.address1,
      address2: certHolder.address2,
      state: certHolder.state,
      city: certHolder.city,
      zipCode: certHolder.zipCode,
      placeId: certHolder.placeId
    });
    this.selectedState = {
      stateId: certHolder.stateId,
      countryId: certHolder.countryId,
      name: certHolder.state,
      shortName: null
    };
  }

  /**
   * Handles location selection updates.
   * @param location Selected location object.
   */
  protected onLocationSelect(location: any) {
    if (!location) return;
    this.certificateHolderForm.patchValue({
      address1: location.address,
      city: location.city,
      state: location.state,
      zipCode: location.zip,
      placeId: location.placeId
    });
  }

  /**
   * Checks if a form field is valid.
   * @param field Field name.
   * @returns Boolean indicating validity.
   */
  protected isFormFieldValid(field: string): boolean {
    this.certificateHolderForm.updateValueAndValidity();
    return this.certificateHolderForm.get(field).invalid;
  }

  /**
   * Handles name change.
   * @param text Updated name value.
   */
  protected onNameChange(text: string) {
    this.certificateHolderForm.patchValue({ name: text });
  }

  /**
   * Handles Address Line 2 change.
   * @param text Updated address line 2 value.
   */
  protected onAddress2Change(text: string) {
    this.certificateHolderForm.patchValue({ address2: text });
  }

  /**
   * Handles city change.
   * @param text Updated city value.
   */
  protected onCityChange(text: string) {
    this.certificateHolderForm.patchValue({ city: text });
  }

  /**
   * Handles state change.
   * @param text Updated state value.
   */
  protected onStateChange(text: string) {
    this.certificateHolderForm.patchValue({ state: text });
    // Setting the place id to null if the state is changed
    this.certificateHolderForm.patchValue({ placeId: null });
  }

  /**
   * Handles ZIP code change.
   * @param text Updated ZIP code value.
   */
  protected onZipChange(text: string) {
    this.certificateHolderForm.patchValue({ zipCode: text });
  }

  /**
   * Creates the certificate holder form with validations.
   */
  private createCertificateHolderForm() {
    this.certificateHolderForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(3)]),
      address1: new FormControl('', [Validators.required]),
      address2: new FormControl(null),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      zipCode: new FormControl('', [Validators.required]),
      placeId: new FormControl(null)
    });
  }

  /**
   * Validates the certificate holder form.
   * @returns Boolean indicating form validity.
   */
  private isCertificateHolderFormValid() {
    if (this.certificateHolderForm.invalid) {
      this.certificateHolderForm.updateValueAndValidity();
      this.appService.showAlertMessage('The Certificate Holder information was incorrect. Please ensure a valid address was selected', 'danger');
      this.hasLocationError = this.isFormFieldValid('address1');
      this.hasNameError = this.isFormFieldValid('name');
      this.hasCityError = this.isFormFieldValid('city');
      this.hasStateError = this.isFormFieldValid('state');
      this.hasZipCodeError = this.isFormFieldValid('zipCode');

      return false;
    }

    return true;
  }

  /**
   * Creates a deep copy of a form group.
   * @param form Form group to copy.
   * @returns Copied form group.
   */
  private deepCopyFormGroup(form: FormGroup): FormGroup {
    const copiedControls: { [key: string]: FormControl } = {};
    Object.keys(form?.controls ?? {})?.forEach(key => {
      const control = form.get(key);
      if (control instanceof FormControl) {
        copiedControls[key] = new FormControl(control.value, control.validator, control.asyncValidator);
      }
    });

    return new FormGroup(copiedControls);
  }
}
