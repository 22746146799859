import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { UploadInput, UploadOutput } from 'ngx-uploader';
import { AppService } from '@capp/app/services/app.service';
import { CommonValueAccessorBase } from 'phoenix-common';

@Component({
  selector: 'phx-coi-upload',
  templateUrl: 'phoenix-coi-upload.component.html',
  styleUrls: ['phoenix-coi-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoenixCoiUploadComponent),
      multi: true
    }
  ]
})

export class PhoenixCoiUploadComponent extends CommonValueAccessorBase<any> {
  @Input() public isMultiple = false;
  @Input() public isDisabled = false;
  @Input() public isVendorImport = false;
  @Output('onFileUpload') onFileUpload = new EventEmitter<File[]>();

  public fileList: Array<File> = [];
  public dragOver: boolean;
  public uploadInput: EventEmitter<UploadInput> = new EventEmitter<UploadInput>();

  constructor(public appService: AppService) {
    super();
  }


  public sanitizeFileName(fileName: string): string {
    const illegalCharacters = /[\\/:"*?<>|+]/g; // Regular expression to match illegal characters

    // Replace illegal characters with an empty string
    const sanitizedFileName = fileName.replace(illegalCharacters, ' ');

    return sanitizedFileName;
  }

  public onUploadOutput(output: any, fileInput: HTMLInputElement): void {
    this.dragOver = (output.type != 'addedToQueue');


    if (output.type != 'addedToQueue') {
      return;
    }

    if (this.isDisabled) {
      return;
    }

    if (output?.file?.nativeFile !== undefined) {
      const file: File = output?.file?.nativeFile;
      if (this.isMultiple) {
        const modifiedFile = new File([file], this.sanitizeFileName(file.name), { type: file.type });

        const existingFile = this.fileList.find(f => f.name === modifiedFile.name);

        if (!existingFile) {
          this.fileList.push(modifiedFile);
        }

        this.value = this.fileList;
        this.onFileUpload.emit(this.value);
      } else {
        const modifiedFile = new File([file], this.sanitizeFileName(file.name), { type: file.type });
        this.fileList = [];
        this.fileList.push(modifiedFile);
        this.value = this.fileList;


        this.onFileUpload.emit(this.value);
      }
    }
    fileInput.value = '';
  }


  onDrop(event: DragEvent, fileInput: HTMLInputElement): void {
    event.preventDefault();
    this.dragOver = false;

    if (event.dataTransfer?.files && event.dataTransfer.files.length > 0) {
      // Check if multiple files are dragged in
      if (event.dataTransfer.files.length > 1 && !this.isMultiple) {
        // You can add any additional logic here, like showing an error message

        this.appService.showAlertMessage('You cannot upload multiple files', 'danger');
      }

      // If single file allowed or multiple files allowed, process files
      this.fileList = Array.from(event.dataTransfer.files);

      // Reset the file input control after processing
      fileInput.value = '';
    }
  }

  public removeFile(i, fileInput: HTMLInputElement) {
    if (this.isDisabled) {
      return;
    }
    if (this.fileList?.length <= 1) {
      this.fileList = [];
      fileInput.value = '';
    } else {
      this.fileList.splice(i, 1);
    }

    this.value = this.fileList;


    this.onFileUpload.emit(this.value);
  }

  public afterSetValue() {
  }
}
