import { Injectable } from '@angular/core';
import { AuthService } from '../../../../phoenix-common/src/lib/auth/auth-service';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  constructor(private authService: AuthService) {
  }

  async canActivate(): Promise<boolean> {
    try {
      const sessionIsValid = this.authService.isSessionValid();
      if (sessionIsValid) {
        return true;
      } else {
        this.authService.logout(true);
        return false;
      }
    } catch (e) {
      this.authService.logout(true);
      return false;
    }
  }
}
