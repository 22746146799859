export class AuthenticatedUser {
  public id: string;
  public email: string;
  public name: string;
  public phone: string;
  public title: string;
  public cognitoId: string;
  public certusSystemUserId: string;
  public certusUsername: string;
  public isBcsAdmin: boolean;
}
