import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ClientConfigurationService } from '@capp/app/services/client-configuration.service';


@Component({
  selector: 'app-dynamic-redirect',
  template: '',
})
export class DynamicRedirectComponent {
  constructor(private router: Router, private configService: ClientConfigurationService) {
    this.configService.getDefaultRoute().then(async defaultRouteResult => {
      await this.router.navigate([`/main/${defaultRouteResult.defaultView}`]);
    });
  }
}
