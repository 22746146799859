import { Component } from '@angular/core';
import { AIConversationType, AIThreadInfo, RiskBotService } from '@capp/app/services/risk-bot.service';
import { PhoenixCommonModule } from 'phoenix-common';
import { NgForOf } from '@angular/common';
import { ClientWebappCommonModule } from '@capp/app/common/client-webapp-common.module';

interface UserMessage {
  isAIResponse: boolean;
  name: string;
  message: string;
}

@Component({
  selector: 'phx-bcs-risk-bot-chat-box',
  templateUrl: './bcs-risk-bot-chat-box.component.html',
  standalone: true,
  imports: [
    PhoenixCommonModule,
    NgForOf,
    ClientWebappCommonModule
  ],
  styleUrls: ['./bcs-risk-bot-chat-box.component.scss']
})
export class BcsRiskBotChatBoxComponent {
  message: any;
  messages: UserMessage[];
  inputText: string;

  constructor(protected riskBotService: RiskBotService) {
  }

  closeChatBot() {
    this.riskBotService.toggleChatBot();
  }

  async sendMessageToChatBot() {
    document.getElementById('risk-bot-chat-input').blur();
    const storedMessage = this.inputText;
    this.inputText = '';
    const conversationType = this.riskBotService?.isCertificateConversation ? AIConversationType.Certificate : AIConversationType.Company;
    const id = this.riskBotService?.isCertificateConversation ? this.riskBotService?.certificateId : this.riskBotService?.companyId;
    this.riskBotService.addQuestionMessageToConversationThread(storedMessage, conversationType);
    setTimeout(() => {
      this.autoScrollDownMessage();
    }, 500);
    await this.riskBotService.getAISummary(id, conversationType, storedMessage, false);
  }

  async sendMessageToChatBotViaKeypress(evt: any) {
    if (evt.code === 'Enter') {
      await this.sendMessageToChatBot();
    }
  }

  retrieveMessageThread() {
    const id = this.riskBotService?.isCertificateConversation ? this.riskBotService?.certificateId : this.riskBotService?.companyId;
    if (this.riskBotService.conversationThreads[id]) {
      return this.riskBotService.conversationThreads[id]?.conversations;
    }
  }

  retrieveThreadInfo(): AIThreadInfo {
    const id = this.riskBotService?.isCertificateConversation ? this.riskBotService?.certificateId : this.riskBotService?.companyId;
    if (this.riskBotService.conversationThreads[id]) {
      return this.riskBotService.conversationThreads[id]?.threadInfo;
    }
  }

  hasThreadInfo(): boolean {
    const id = this.riskBotService?.isCertificateConversation ? this.riskBotService?.certificateId : this.riskBotService?.companyId;
    return this.riskBotService.conversationThreads[id]?.threadInfo !== undefined;
  }

  isInputDisabled() {
    const loaderIsPresent = this.riskBotService?.aiIsThinking || this.riskBotService?.isInitialRiskBotLoad;
    return !this.hasThreadInfo() || loaderIsPresent;
  }

  focusOrBlurRiskBotChatInput() {
    const riskBotChatInput = document.getElementById('risk-bot-chat-input');
    if (this.isInputDisabled()) {
      riskBotChatInput?.blur();
      return;
    }
    riskBotChatInput?.focus();
  }

  autoScrollDownMessage() {
    const id = this.riskBotService?.isCertificateConversation ? this.riskBotService?.certificateId : this.riskBotService?.companyId;
    const messageId: string = 'message-' + (this.riskBotService.conversationThreads[id]?.conversations?.length - 1);
    const chatBox = document.getElementById(messageId);
    chatBox?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'nearest'
    });
  }
}
