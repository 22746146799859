/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { BaseWebService, ResponsePaginated } from './base-web.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppService } from '@capp/app/services/app.service';
import { AuthService } from '../../../phoenix-common/src/lib/auth/auth-service';
import { RegistrationStatus, SortingDirection } from 'phoenix-common';
import { ExportType, InvitationType } from '../../../phoenix-common/src/lib/shared/utils/Enumerations';
import {
  CertHolderResult,
  CreateCertHolderRequest
} from '../../../phoenix-common/src/lib/shared/models/interfaces/CertHolder';
import { EntityRequiredStatus, OwnershipEntity } from '../../../phoenix-common/src/lib/shared/models/interfaces/Entity';
import {
  CertHolder,
  CertificateAdditionalInsuredEntity,
  CertificateAMBestRequirement,
  CertificateDocumentsAndScreening,
  CertificateInsuranceProvision,
  CertificatePolicyEndorsement,
  CertificatePolicyResult,
  CertificateProperties
} from '@capp/app/shared/models/interfaces/Certificate';
import { AccountInfo } from '@capp/app/shared/models/interfaces/AccountInfo';
import { ClientContact } from '@capp/app/shared/models/interfaces/Client';
import { CustomFieldProperty } from '@capp/app/shared/models/interfaces/CustomFieldProperty';
import { GetLocationsPaginatedRequest } from '@capp/app/shared/models/get-locations-paginated-request';
import {
  GetLocationsWithClientUserAuthorizedStatusRequest
} from '@capp/app/shared/models/get-locations-with-client-user-authorized-status-request';
import {
  GetUsersWithLocationAuthorizedStatusRequest
} from '@capp/app/shared/models/get-users-with-location-authorized-status-request';
import { DocumentParams } from '@capp/app/shared/models/interfaces/Document';
//THIS IS A SHARED SERVICE WITH VENDOR WEBAPP.
//DO NOT IMPORT ANY COMPONENTS FROM CLIENT WEBAPP OR VENDOR WEBAPP.
//IF YOU NEED TO USE A MODEL/INTERFACE/ENUM THEN IMPORT IT HOW WE DID THE ABOVE

@Injectable({ providedIn: 'root' })
export class PhoenixWebService extends BaseWebService {
  constructor(protected http: HttpClient, protected appService: AppService, protected authService: AuthService) {
    super(http, appService, authService);
    if (!(<any> window).clientWebService) {
      (<any> window).clientWebService = this;
    }
  }

  //JOB POSTING CALLS
  public async getOpenJobPostingMeta(searchParam: string) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/vnd.pgrst.object+json');

    const params = {
      'search_param': searchParam
    };
    const url = this.webServiceUri + '/rpc/OpenPostingMetaV1';
    return this.apiPostCall(url, params, headers);
  }

  public async getOpenPostings(page, perPage, params: any = null, today) {
    const rangeLow = (perPage * page) - perPage;
    const rangeHigh = (perPage * page) - 1;
    const rangeStr = `${rangeLow}-${rangeHigh}`;

    let headers = new HttpHeaders();
    headers = headers.set('Prefer', 'count=exact');
    headers = headers.set('Range', rangeStr);

    let searchParamsStr = '';
    if (params) {
      if (params['jobDescription']) {
        searchParamsStr += `&or=(title.ilike.*${params['jobDescription']}*,description.ilike.*${params['jobDescription']}*)`;
      }
    }

    if (params && params['order'] && params['orderColumn']) {
      searchParamsStr += `&order=${params['orderColumn']}.${params['order']}`;
    } else {
      searchParamsStr += `&order=createdAt.desc`;
    }

    const url = this.webServiceUri + `/ClientPostings?select=*,clientUser:ClientUsers(*),postingInvitations:ClientPostingInvitations(*,vendor:ClientVendors(*,vendorProfile:ClientVendorProfile(*))),postingBids:ClientPostingBids(*,vendor:ClientVendors(*,vendorProfile:ClientVendorProfile(*))),postingTrades:ClientPostingTrades(*),postingTypes:ClientPostingTypes(*),postingServiceTypes:ClientPostingServiceTypes(*),postingLocations:ClientPostingLocations(*,clientLocation:ClientLocations(*))&and=(awardedVendorId.is.null,awardedAt.is.null,expirationDate.gte.*${today}*,isClosed.is.false)${searchParamsStr}`;
    return await this.apiGetCallPaginated(url, headers);
  }

  public async getClosedPostings(page, perPage, params: any = null) {
    const rangeLow = (perPage * page) - perPage;
    const rangeHigh = (perPage * page) - 1;
    const rangeStr = `${rangeLow}-${rangeHigh}`;
    const today = new Date(Date.now()).toISOString();

    let headers = new HttpHeaders();
    headers = headers.set('Prefer', 'count=exact');
    headers = headers.set('Range', rangeStr);

    let searchParamsStr = '&order=createdAt.desc';
    if (params) {
      if (params['jobDescription']) {
        searchParamsStr += `&or=(title.ilike.*${params['jobDescription']}*,description.ilike.*${params['jobDescription']}*)`;
      }
    }

    const url = this.webServiceUri + `/ClientPostings?select=*,clientUser:ClientUsers(*),postingInvitations:ClientPostingInvitations(*,vendor:ClientVendors(*,vendorProfile:ClientVendorProfile(*))),postingBids:ClientPostingBids(*,vendor:ClientVendors(*,vendorProfile:ClientVendorProfile(*))),postingTrades:ClientPostingTrades(*),postingTypes:ClientPostingTypes(*),postingServiceTypes:ClientPostingServiceTypes(*),postingLocations:ClientPostingLocations(*,clientLocation:ClientLocations(*))&or=(isClosed.is.true,expirationDate.lt.*${today}*)${searchParamsStr}`;
    return await this.apiGetCallPaginated(url, headers);
  }

  public async getPostingById(postingId: number) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/vnd.pgrst.object+json');

    const url = this.webServiceUri + `/ClientPostings?select=*,clientUsers:ClientUsers(*),postingInvitations:ClientPostingInvitations(*,vendor:ClientVendors(*,vendorProfile:ClientVendorProfile(*))),postingBids:ClientPostingBids(*,vendor:ClientVendors(*,vendorProfile:ClientVendorProfile(*))),postingTrades:ClientPostingTrades(*),postingTypes:ClientPostingTypes(*),postingServiceTypes:ClientPostingServiceTypes(*),postingLocations:ClientPostingLocations(*,clientLocation:ClientLocations(*)),jobAttachment:ClientPostingAttachments(*)&postingId=eq.` + postingId;
    return await this.apiGetCall(url, headers);
  }

  public async updateJobPosting(params: any, jobId: number) {
    const url = this.middleWareUri + `/api/postings/${jobId}`;
    return await this.apiPostFormCall(url, params);
  }

  public async closePosting(jobId: number) {
    const url = this.middleWareUri + `/api/postings/close/${jobId}`;
    return await this.apiPostCall(url);
  }

  public async reopenPosting(jobId: number) {
    const url = this.middleWareUri + `/api/postings/reopen/${jobId}`;
    return await this.apiPostCall(url);
  }

  public async closeAndAwardJobPosting(bidId: number) {
    const url = this.middleWareUri + `/api/postings/award-job/${bidId}`;
    return await this.apiPostCall(url);
  }

  public async inviteMoreVendors(jobPostingId: number, params: any) {
    const url = this.middleWareUri + `/api/postings/vendors/invite/${jobPostingId}`;
    return await this.apiPostCall(url, params);
  }

  public async createNewJobPosting(params: any) {
    const url = this.middleWareUri + '/api/postings';
    return await this.apiPostFormCall(url, params);
  }

  public async getAllJobTrades() {
    const url = this.webServiceUri + '/ClientTrades';
    return await this.apiGetCall(url);
  }

  public async getAllJobTypes() {
    const url = this.webServiceUri + '/ClientJobTypes';
    return await this.apiGetCall(url);
  }

  public async getClientServiceTypes() {
    const url = this.middleWareUri + '/api/clients/service-types';
    return await this.apiGetCall(url);
  }

  public async getEmailConfiguration() {
    const url = this.middleWareUri + '/api/clients/email-configuration';
    return await this.apiGetCall(url);
  }

  public async updateEmailConfiguration(params) {
    const url = this.middleWareUri + '/api/clients/update-email-configuration';
    return await this.apiPostCall(url, params);
  }

  public async getReportTemplate(reportTemplateId: number, isVendor: boolean) {
    const url = this.middleWareUri + `/api/clients/reports/report-template/${reportTemplateId}?isVendor=${isVendor}`;
    return await this.apiGetCall(url);
  }

  public async getReportTemplates(isVendor: boolean) {
    const url = this.middleWareUri + `/api/clients/reports/report-templates?isVendor=${isVendor}`;
    return await this.apiGetCall(url);
  }

  public async getReport(isVendor: boolean, s3Url: string) {
    const url = this.middleWareUri + `/api/clients/reports/report?isVendor=${isVendor}&S3Url=${s3Url}`;
    return await this.apiGetCall(url);
  }

  public async getScheduledReportEmails(isVendor: boolean): Promise<any> {
    const url = this.middleWareUri + `/api/clients/reports?isVendor=${isVendor}`;
    return await this.apiGetCall(url);
  }

  public async createScheduledReportEmail(params: any) {
    const url = this.middleWareUri + '/api/clients/reports/report';
    return await this.apiPostCall(url, params);
  }

  async editScheduledReport(reportTemplateId: number, params: any) {
    const url = this.middleWareUri + `/api/clients/reports/report/${reportTemplateId}`;
    return await this.apiPutCall(url, params);
  }

  async deleteScheduledReport(reportTemplateId: number, isVendor: boolean) {
    const url = this.middleWareUri + `/api/clients/reports/report/${reportTemplateId}?isVendor=${isVendor}`;
    return await this.apiDeleteCall(url);
  }

  public async deleteAttachment(attachmentId) {
    const url = this.middleWareUri + `/api/postings/attachment/${attachmentId}`;
    return await this.apiDeleteCall(url);
  }

  public async deactivateJob(params) {
    const url = this.middleWareUri + '/api/clients/jobs/deactivate';
    return await this.apiPostFormCall(url, params);
  }

  public async deactivateLease(params) {
    const url = this.middleWareUri + '/api/clients/tenants/lease/deactivate';
    return await this.apiPostFormCall(url, params);
  }

  public async reactivateJob(params) {
    const url = this.middleWareUri + '/api/clients/jobs/reactivate';
    return await this.apiPostFormCall(url, params);
  }

  public async reactivateLease(params) {
    const url = this.middleWareUri + '/api/clients/tenants/lease/reactivate';
    return await this.apiPostFormCall(url, params);
  }

  /**
   * Checks for an email address already associated with a vendor to avoid duplicate vendor creation
   * @param params an object which contains an email property
   * @returns an object which contains the existence and any Vendor names associated
   */
  public async checkForExistingVendorByEmail(params: any) {
    const url = this.middleWareUri + '/api/clients/connections/vendor-email-exists';
    return await this.apiPostCall(url, params);
  }

  //THE NEXT 5 CALLS ARE ALL THE ADD RECORD FLOWS


  public async createAccountRecords(params: any) {
    const url = this.middleWareUri + `/api/clients/accounts/create`;
    return await this.apiPostFormCall(url, params);
  }

  // 1. THIS FLOW IS ADDING A BRAND-NEW VENDOR AND JOB
  public async sendAppInvitationByEmail(params: any) {
    const url = this.middleWareUri + '/api/clients/connections/vendor-email-invitation';
    return await this.apiPostFormCall(url, params);
  }

  // 2. THIS FLOW IS ADDING A NETWORK VENDOR TO THIS CLIENT
  public async sendConnectionRequest(params: any) {
    const url = this.middleWareUri + '/api/clients/connections/new';
    return await this.apiPostFormCall(url, params);
  }

  // 3. THIS FLOW IS ADDING A NEW JOB TO AN EXISTING VENDOR
  public async addJobConnection(params: any) {
    const url = this.middleWareUri + '/api/clients/connections/add-job';
    return await this.apiPostFormCall(url, params);
  }

  // 4. THIS FLOW IS CREATING A NEW TENANT/LEASE
  public async createClientTenant(params: any) {
    const url = this.middleWareUri + '/api/clients/tenants';
    return await this.apiPostFormCall(url, params);
  }

  // 5. THIS FLOW IS ADDING A NEW LEASE TO AN EXISTING TENANT
  public async createClientTenantLease(tenantId: number, params: any) {
    const url = this.middleWareUri + `/api/clients/tenants/${tenantId}/lease`;
    return await this.apiPostFormCall(url, params);
  }

  public async resendAppInvitationByEmail(params: FormData) {
    const url = this.middleWareUri + '/api/clients/connections/resend-email-invitation';
    const res = await this.apiPostFormCall(url, params);
    return res;
  }

  public async getClientComplianceRequirements(isTenantClient) {
    const url = this.middleWareUri + `/api/clients/compliance/requirements-list-options?isTenantClient=` + isTenantClient;
    return await this.apiGetCall(url);
  }

  public async getCountries() {
    const url = this.middleWareUri + `/api/clients/requirements/get-countries`;
    return await this.apiGetCall(url);
  }

  public async getRequirementProperties(requirementId: number) {
    const url = this.middleWareUri + `/api/clients/requirements/requirement-properties/${+requirementId}`;
    return await this.apiGetCall(url);
  }

  public async getAdditionalTermsForRequirementCategory(requirementId: number, isTenantClient) {
    const url = this.middleWareUri + `/api/clients/requirements/additional-terms/${+requirementId}?isTenantClient=` + isTenantClient;
    return await this.apiGetCall(url);
  }

  public async getCertHolderForRequirementCategory(requirementId: number, isTenantClient) {
    const url = this.middleWareUri + `/api/clients/requirements/cert-holder/${+requirementId}?isTenantClient=` + isTenantClient;
    return await this.apiGetCall(url);
  }

  public async getEndorsementsForRequirementCategory(requirementId: number, isTenantClient) {
    const url = this.middleWareUri + `/api/clients/requirements/endorsements/${+requirementId}?isTenantClient=` + isTenantClient;
    return await this.apiGetCall(url);
  }

  public async getPoliciesAndLimitsForRequirementCategory(requirementId: number, isTenantClient) {
    const url = this.middleWareUri + `/api/clients/requirements/policies-and-limits/${+requirementId}?isTenantClient=` + isTenantClient;
    return await this.apiGetCall(url);
  }

  public async getAMBestOptions() {
    const url = this.middleWareUri + `/api/clients/requirements/ambest-options`;
    return await this.apiGetCall(url);
  }

  public async getDocumentsForRequirementCategory(requirementId: number, isTenantClient) {
    const url = this.middleWareUri + `/api/clients/requirements/documents/${+requirementId}?isTenantClient=` + isTenantClient;
    return await this.apiGetCall(url);
  }

  public async deleteAdditionalTerm(additionalTermId: number) {
    const url = this.middleWareUri + `/api/clients/requirements/additional-terms/${additionalTermId}/delete-term`;
    return await this.apiDeleteCall(url);
  }

  public async getRequirementLimitsByReqId(requirementId: number) {
    const url = this.middleWareUri + `/api/clients/requirements/${+requirementId}/policy-templates`;
    return await this.apiGetCall(url);
  }

  public async getRequirementDataByReqId(requirementId: number, isVendor: boolean) {
    const url = this.middleWareUri + `/api/clients/requirements/${requirementId}?isVendor=${isVendor}`;
    return await this.apiGetCall(url);
  }

  // ------------------------------------- NEW JOB UI ------------------------------------
  /**
   * This method is behind a feature flag for New Job UI. Hits the CheckLocationBatches endpoint.
   * @param locations List of selected locations
   * @param requirementCategoryId The Insurance Requirement Category ID
   */
  public async checkLocationBatches(locations: number[], requirementCategoryId: number) {
    const url = this.middleWareUri + `/api/clients/new-compliance/location-batches?requirementCategory=${requirementCategoryId}`;
    return await this.apiPostCall(url, locations);
  }

  public async getCertificateProperties(companyCertificateId: number, isVendor: boolean): Promise<CertificateProperties> {
    const url: string = this.middleWareUri + `/api/clients/new-compliance/properties/${companyCertificateId}?isVendor=${isVendor}`;
    return await this.apiGetCall(url);
  }

  public async editCertificateProperties(params: any, isVendor: boolean): Promise<CertificateProperties> {
    const url: string = this.middleWareUri + `/api/clients/new-compliance/properties?isVendor=${isVendor}`;
    return await this.apiPutCall(url, params);
  }

  public async getCertificatePolicies(companyCertificateId: number, isVendor: boolean): Promise<CertificatePolicyResult> {
    const url: string = this.middleWareUri + `/api/clients/new-compliance/policy-data/${companyCertificateId}?isVendor=${isVendor}`;
    return await this.apiGetCall(url);
  }

  public async updateCertificatePolicies(params: any, companyCertificateId: number, isVendor: boolean): Promise<CertificatePolicyResult> {
    const url: string = this.middleWareUri + `/api/clients/new-compliance/policy-data/${companyCertificateId}?isVendor=${isVendor}`;
    return await this.apiPutCall(url, params);
  }

  public async getCertificatePolicyEndorsements(companyCertificateId: number) {
    const url = this.middleWareUri + `/api/clients/new-compliance/endorsements/${companyCertificateId}`;
    return await this.apiGetCall(url);
  }

  public async editCertificatePolicyEndorsements(companyCertificateId: number, isVendor: boolean, endorsements: CertificatePolicyEndorsement[]) {
    const url = this.middleWareUri + `/api/clients/new-compliance/endorsements/${companyCertificateId}?isVendor=${isVendor}`;
    return await this.apiPutCall(url, endorsements);
  }

  public async getCertificatePolicyCertHolders(companyCertificateId: number) {
    const url = this.middleWareUri + `/api/clients/new-compliance/cert-holders/${companyCertificateId}`;
    return await this.apiGetCall(url);
  }

  public async editCertificatePolicyCertHolders(isVendor: boolean, certHolders: CertHolder[]) {
    const url = this.middleWareUri + `/api/clients/new-compliance/cert-holders?isVendor=${isVendor}`;
    return await this.apiPutCall(url, certHolders);
  }

  public async getInsuranceProvisions(companyCertificateId: number, isVendor: boolean) {
    const url = this.middleWareUri + `/api/clients/new-compliance/insurance-provisions/${companyCertificateId}?isVendor=${isVendor}`;
    return await this.apiGetCall(url);
  }

  public async editInsuranceProvisions(companyCertificateId: number, isVendor: boolean, provisions: CertificateInsuranceProvision[]) {
    const url = this.middleWareUri + `/api/clients/new-compliance/insurance-provisions/${companyCertificateId}?isVendor=${isVendor}`;
    return await this.apiPutCall(url, provisions);
  }

  public async getAdditionalInsuranceEntities(companyCertificateId: number) {
    const url = this.middleWareUri + `/api/clients/new-compliance/additional-insured-entities/${companyCertificateId}`;
    return await this.apiGetCall(url);
  }

  public async editAdditionalInsuranceEntities(companyCertificateId: number, isVendor: boolean, entities: CertificateAdditionalInsuredEntity[]) {
    const url = this.middleWareUri + `/api/clients/new-compliance/additional-insured-entities/${companyCertificateId}?isVendor=${isVendor}`;
    return await this.apiPutCall(url, entities);
  }

  public async getDocumentsForCompliance(companyCertificateId: number, isVendor: boolean) {
    const url = this.middleWareUri + `/api/clients/new-compliance/documents/${companyCertificateId}?isVendor=${isVendor}`;
    return await this.apiGetCall(url);
  }

  public async editDocumentsForCompliance(companyCertificateId: number, isVendor: boolean, documentsAndScreenings: CertificateDocumentsAndScreening[]) {
    const url = this.middleWareUri + `/api/clients/new-compliance/documents/${companyCertificateId}?isVendor=${isVendor}`;
    return await this.apiPutCall(url, documentsAndScreenings);
  }

  public async getAmBestRequirements(companyCertificateId: number, isVendor: boolean) {
    const url = this.middleWareUri + `/api/clients/new-compliance/am-best-requirements/${companyCertificateId}?isVendor=${isVendor}`;
    return await this.apiGetCall(url);
  }

  public async searchForCarriers(companyCertificateId: number, searchText: any) {
    const url = this.middleWareUri + `/api/clients/new-compliance/am-best-requirements/${companyCertificateId}/carriers`;
    return await this.apiPostCall(url, searchText);
  }

  public async editAmBestRequirements(companyCertificateId: number, isVendor: boolean, amBestRequirements: CertificateAMBestRequirement) {
    const url = this.middleWareUri + `/api/clients/new-compliance/am-best-requirements/${companyCertificateId}?isVendor=${isVendor}`;
    return await this.apiPutCall(url, amBestRequirements);
  }

  async resetCertificateToOriginalComplianceRequirements(certificateId: number) {
    const url = this.middleWareUri + `/api/clients/new-compliance/reset-certificate/${certificateId}`;
    return await this.apiPostCall(url);
  }

  // ------------------------------------- END NEW JOB UI ------------------------------------

  public async addNewKeyword(keyword, isTenantClient) {
    const url = this.middleWareUri + `/api/clients/requirements/add-new-keyword?isTenantClient=` + isTenantClient;
    return await this.apiPostCall(url, keyword);
  }

  public async downloadComplianceReportPDF(certificateId) {
    const url = this.middleWareUri + `/api/clients/compliance/certificates/${certificateId}/pdfreport`;
    return await this.apiGetDownload(url);
  }

  public async getReportShareLink(certificateId) {
    const url = this.middleWareUri + `/api/clients/compliance/certificates/${certificateId}/share-report`;
    return await this.apiGetCall(url);
  }

  public async connectionSearchByVendorName(vendorName) {
    let searchParamsStr = '';
    if (vendorName) {
      searchParamsStr += `&vendorName=ilike.*${vendorName}*`;
    }

    const url = this.webServiceUri + `/ClientConnectionsSearch?select=*,vendor:ClientVendors(*,vendorProfile:ClientVendorProfile(*))${searchParamsStr}`;
    return await this.apiGetCall(url);
  }

  public async downloadExportedCsv(exportType: ExportType, exportAllColumns: boolean = true, columnsToExport: string[] = []) {
    const url = this.middleWareUri + `/api/clients/exports`;
    // Exporting all columns is the only option for now. When the edit columns feature is complete columnsToExport will take affect
    const result = await this.downloadFileAsBlob(url, { exportType, exportAllColumns: true, columnsToExport });

    return result;
  }


  // VENDOR CALLS
  public async getAccountSearch(params: any, page: number,
                                perPage: number,
                                sorting,
                                unlimited = false): Promise<ResponsePaginated> {
    const rangeLow = (perPage * page) - perPage;
    const rangeHigh = (perPage * page) - 1;
    const rangeStr = `${rangeLow}-${rangeHigh}`;
    const urlSearchParams: URLSearchParams = new URLSearchParams();

    let headers = new HttpHeaders();
    headers = headers.set('Prefer', 'count=exact');
    headers = headers.set('Range', rangeStr);


    if (sorting && sorting?.sortDirection !== SortingDirection.None) {
      urlSearchParams.append('order', `${sorting.variable}.${sorting.sortDirection}`);
    }

    const url = new URL(this.webServiceUri + '/rpc/ClientAccountSearch');

    url.search = urlSearchParams.toString();
    // Add unlimited search used when all vendors need to be seen
    const urlString = unlimited ? `${url.toString()}?limit=4294967295` : url.toString();
    return await this.apiPostCallPaginated(urlString, headers, params);
  }

  public async getPendingConnectionsSearch(params) {
    console.log(params);
    const url = this.middleWareUri + `/api/clients/accounts/pending`;
    const res = await this.apiPostCall(url, params);
    return res;


    // let perPage = +params['per_page'];
    // let page = +params['page'];
    // const rangeLow = (perPage * page) - perPage;
    // const rangeHigh = (perPage * page) - 1;
    // const rangeStr = `${rangeLow}-${rangeHigh}`;
    //
    // let headers = new HttpHeaders();
    // headers = headers.set('Prefer', 'count=exact');
    // headers = headers.set('Range', rangeStr);
    //
    // console.log(params);
    //
    // const url = this.webServiceUri + `/rpc/ClientPendingAccountSearch?order=${params['sort_col']}.${params['sort_dir']}`;
    // const { search_string } = params;
    // const searchParams = { search_string };
    // let response = await this.apiPostCallPaginated(url, headers, searchParams);
    // if (response.totalCount > 0) {
    //   response.items[0]['total_count'] = response.totalCount;
    // }
    // return response.items;
  }

  public async deletePendingConnections(connectionId: number, connectionType: InvitationType) {
    let url;
    if (connectionType == InvitationType.BCS) {
      url = this.webServiceUri + `/ClientConnections?connectionId=eq.${connectionId}`;
    } else if (connectionType == InvitationType.EMAIL) {
      url = this.webServiceUri + `/ClientVendorEmailInvitation?vendorEmailInvitationId=eq.${connectionId}`;
    }
    return await this.apiDeleteCall(url);
  }

  public async getVendorSearch(params, page, perPage) {
    const rangeLow = (perPage * page) - perPage;
    const rangeHigh = (perPage * page) - 1;
    const rangeStr = `${rangeLow}-${rangeHigh}`;

    let headers = new HttpHeaders();
    headers = headers.set('Prefer', 'count=exact');
    headers = headers.set('Range', rangeStr);

    const url = this.webServiceUri + '/rpc/vendor_search';

    return await this.apiPostCallPaginated(url, headers, params);
  }

  public async getVendorSearchByName(vendorName) {
    const params = {
      'search_name': vendorName
    };

    const url = this.webServiceUri + '/rpc/vendor_search_by_name';

    return await this.apiPostCall(url, params);
  }

  public async getVendorById(vendorId: any, includeProperties = false): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');

    const url = this.webServiceUri + `/ClientVendorsWithCompanyData?select=*,serviceAreas:ClientVendorServiceAreas(*),
        trades:ClientVendorTrades(tradeId),jobs:ClientJobsWithServiceTypes!FK_Jobs_Vendor(*,jobTypes:ClientJobType(*),jobTrades:ClientJobTrade(*),
        jobLocations:ClientJobLocation(*,clientLocation:ClientLocations(*))),connection:ClientConnections(accepted, connectionId),vendorContacts:ClientVendorContacts(*),
        vendorLocations:ClientVendorLocations!VendorLocation_VendorId_fkey(*),vendorCredentials:ClientVendorCredentials(*),vendorProfile:ClientVendorProfile(*)&and=(vendorId.eq.${vendorId})`;

    let res = null;

    let keepAttemptingToGetVendor = true;
    let attempts = 5;

    while (keepAttemptingToGetVendor && attempts > 0) {
      try {
        res = await this.apiGetCall(url, headers);
        res = res?.length >= 1 ? res[0] : [];
        keepAttemptingToGetVendor = false;
      } catch (error) {
        if (error?.status === 406) {
          attempts--;
          // Wait for some time before next request
          await new Promise(_ => setTimeout(_, 1000));
        } else {
          keepAttemptingToGetVendor = false;
        }
      }
    }

    if (res) {
      return await this.buildVendorSearchResults(res, headers, includeProperties);
    }
    throw new Error('could not retrieve the vendor');
  }

  public async getAllVendors() {
    let headers = new HttpHeaders();
    headers = headers.set('Range', '0-99');
    headers = headers.set('Prefer', 'count=exact');

    const url = this.webServiceUri + `/ClientConnectionsSearch?select=*,clientVendor:ClientVendorsWithCompanyData(companyId)&and=(accepted.not.is.null,accepted.eq.true,vendorName.not.is.null)`;
    const res = await this.apiGetCall(url, headers);

    return res;
  }

  public async getVendorPublicProfileWithConnections(vendorId) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/vnd.pgrst.object+json');

    const url = this.webServiceUri + `/rpc/VendorPublicProfileWithConnections?vendor_id=${vendorId}`;
    const res = await this.apiGetCall(url, headers);

    return res;
  }

  public async getVendorMedia(vendorId: number, mediaType: string, page, perPage) {
    const rangeLow = (perPage * page) - perPage;
    const rangeHigh = (perPage * page) - 1;
    const rangeStr = `${rangeLow}-${rangeHigh}`;

    let headers = new HttpHeaders();
    headers = headers.set('Prefer', 'count=exact');
    headers = headers.set('Range', rangeStr);

    const url = this.webServiceUri + `/rpc/VendorMedia?vendor_id=${vendorId}&media_type=${mediaType}`;

    return await this.apiGetCallPaginated(url, headers);
  }

  // CLIENT JOBS CALLS

  public async getSubaccounts(params) {
    const url = this.middleWareUri + `/api/clients/accounts/search`;
    const res = await this.apiPostCall(url, params);
    return res;
  }

  public async editVendorProfile(params: AccountInfo) {
    const url = this.middleWareUri + `/api/clients/vendors/edit-vendor-profile`;
    return await this.apiPostFormCall(url, params);
  }

  public async editVendorContact(params) {
    const url = this.middleWareUri + `/api/clients/vendors/edit-contact`;
    const res = await this.apiPostCall(url, params);
    return res;
  }

  public async addVendorContact(params) {
    const url = this.middleWareUri + `/api/clients/vendors/add-contact`;
    const res = await this.apiPostCall(url, params);
    return res;
  }

  public async addAgentContact(params) {
    const url = this.middleWareUri + `/api/clients/vendors/add-agent`;
    const res = await this.apiPostCall(url, params);
    return res;
  }

  public async editAgentContact(params) {
    const url = this.middleWareUri + `/api/clients/vendors/edit-agent`;
    const res = await this.apiPostCall(url, params);
    return res;
  }

  public async getContactsAndAgents(params) {
    const url = this.middleWareUri + `/api/clients/vendors/get-contacts-and-agents`;
    const res = await this.apiPostCall(url, params);
    return res;
  }

  public async getAgentPoliciesByCompanyId(companyId: number) {
    const url = this.middleWareUri + `/api/clients/contacts/certificates/agent-policies/${companyId}`;
    return await this.apiGetCall(url);
  }

  public async getReviewRequestById(reviewRequestId) {
    const url = this.middleWareUri + `/api/review/request/${reviewRequestId}`;
    const res = await this.apiGetCall(url);

    return res;
  }

  //REVIEW CALLS

  public async postNewReview(review: any) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/vnd.pgrst.object+json');
    const url = this.middleWareUri + '/api/client/review/send-review';
    return await this.apiPostCall(url, review);
  }

  public async postReviewByRequest(review: any) {
    const url = this.middleWareUri + `/api/review/review-by-request`;
    return await this.apiPostCall(url, review);
  }

  public async GetReviewsMeta(vendorId: number) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/vnd.pgrst.object+json');

    const params = {
      vendorId: vendorId
    };
    const url = this.webServiceUri + '/rpc/GetReviewsMeta';
    return await this.apiPostCall(url, params, headers);
  }

  public async getReviewsbyVendorId(vendorId, page, perPage) {
    const rangeLow = (perPage * page) - perPage;
    const rangeHigh = (perPage * page) - 1;
    const rangeStr = `${rangeLow}-${rangeHigh}`;

    let headers = new HttpHeaders();
    headers = headers.set('Prefer', 'count=exact');
    headers = headers.set('Range', rangeStr);

    const url = this.webServiceUri + `/ClientReviews?select=*,client:ClientInfo(name)&and=(vendorId.eq.${vendorId})&order=createdAt.desc`;
    return await this.apiGetCallPaginated(url, headers);
  }

  public async getReviewsByClientId(clientId, page, perPage) {
    const rangeLow = (perPage * page) - perPage;
    const rangeHigh = (perPage * page) - 1;
    const rangeStr = `${rangeLow}-${rangeHigh}`;

    let headers = new HttpHeaders();
    headers = headers.set('Prefer', 'count=exact');
    headers = headers.set('Range', rangeStr);

    const url = this.webServiceUri + `/ClientReviews?select=*,vendor:ClientVendors(*)&and=(clientId.eq.${clientId})&order=createdAt.desc`;
    return await this.apiGetCallPaginated(url, headers);
  }

  public async getClientLocationsPaginated(getLocationsPaginatedRequest: GetLocationsPaginatedRequest) {
    const url = this.middleWareUri + `/api/clients/locations/paginated?pageNumber=${getLocationsPaginatedRequest.pageNumber}&perPage=${getLocationsPaginatedRequest.perPage}&searchString=${getLocationsPaginatedRequest.searchString ?? ''}&orderBy=${getLocationsPaginatedRequest.sortColumn}&orderDirection=${getLocationsPaginatedRequest.sortDirection}`;
    return await this.apiGetCall(url);
  }

  public async getClientLocations(showInactive: boolean = false) {
    const url = this.middleWareUri + `/api/clients/locations`;
    return await this.apiGetCall(url);
  }

  //LOCATIONS CALLS

  public async getLocationStates() {
    const url = this.middleWareUri + `/api/clients/locations/states`;
    return await this.apiGetCall(url);
  }

  public async getLocationCities() {
    const url = this.middleWareUri + `/api/clients/locations/cities`;
    return await this.apiGetCall(url);
  }

  public async getClientLocationById(locationId) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/vnd.pgrst.object+json');

    const url = this.webServiceUri + `/ClientLocations?clientLocationId=eq.${locationId}`;
    return await this.apiGetCall(url, headers);
  }

  public async getLocationsWithClientUserAuthorizedStatus(request: GetLocationsWithClientUserAuthorizedStatusRequest) {
    let entityIdsString = '';
    request.entityIds.forEach(value => {
      entityIdsString += `entityIds=${value}&`;
    });
    let statesString = '';
    request.states.forEach(state => {
      statesString += `states=${state}&`;
    });

    let citiesString = '';
    request.cities.forEach(city => {
      citiesString += `cities=${city}&`;
    });
    const url = this.middleWareUri + `/api/clients/users/authorized-locations/${request.userId}?pageNumber=${request.pageNumber}&perPage=${request.perPage}&searchString=${request.searchString ?? ''}&${entityIdsString}${statesString}${citiesString}orderBy=${request.sortColumn}&orderDirection=${request.sortDirection}`;
    return await this.apiGetCall(url);
  }

  async getUsersWithLocationAuthorizedStatus(request: GetUsersWithLocationAuthorizedStatusRequest) {
    let userTypeIdsString = '';
    request.userTypes.forEach(value => {
      userTypeIdsString += `userTypes=${value}&`;
    });
    const url = this.middleWareUri + `/api/clients/users/authorized-locations/${request.locationId}?pageNumber=${request.pageNumber}&perPage=${request.perPage}&searchString=${request.searchString}&${userTypeIdsString}orderBy=${request.sortColumn}&orderDirection=${request.sortDirection}`;
    return await this.apiGetCall(url);
  }

  public async deleteClientLocation(locationId: number) {
    const url = this.middleWareUri + `/api/clients/locations/${locationId}`;
    return await this.apiDeleteCall(url);
  }

  public async toggleLocationActiveStatus(locationId: number, params: any) {
    const url = this.middleWareUri + `/api/clients/locations/${locationId}`;
    return await this.apiPutCall(url, params);
  }

  public async createNewLocation(location) {
    const url = this.middleWareUri + '/api/clients/locations';
    return await this.apiPostCall(url, location);
  }

  public async updateLocation(location, locationId: string) {
    const url = this.middleWareUri + `/api/clients/locations/${locationId}`;
    return await this.apiPutCall(url, location);
  }

  public async getClientContacts() {
    const url = this.webServiceUri + `/ClientContacts`;
    return await this.apiGetCall(url);
  }

  public async getApprovers(isVendor: boolean): Promise<ClientContact[]> {
    const url = this.middleWareUri + `/api/clients/contacts/approvers?isVendor=${isVendor}`;
    return await this.apiGetCall(url);
  }

  public async createNewClientContact(params) {
    const url = this.webServiceUri + `/ClientContacts`;
    return await this.apiPostCall(url, params);
  }

  public async updateClientContacts(params, contactId) {
    const url = this.webServiceUri + `/ClientContacts?clientContactId=eq.${contactId}`;
    return await this.apiPatchCall(url, params);

  }

  public async deleteClientContact(contactId: string) {
    const url = this.webServiceUri + `/ClientContacts?clientContactId=eq.${contactId}`;
    return await this.apiDeleteCall(url);
  }

  public async getLocationEntities(locationId: number): Promise<OwnershipEntity[]> {
    const url = this.middleWareUri + `/api/clients/locations/${locationId}/entities`;
    return await this.apiGetCall(url);
  }

  public async associateLocationEntity(locationId: number, entities: string[]) {
    const url = this.middleWareUri + `/api/clients/locations/${locationId}/entities`;
    return await this.apiPostCall(url, { entities });
  }

  public async associateLocationCertHolders(locationId: number, certHolders: any) {
    const url = this.middleWareUri + `/api/clients/locations/${locationId}/cert-holders`;
    return await this.apiPostCall(url, { certHolders });
  }

  public async updateRequiredEntityLocationAssociations(locationId: number, params: EntityRequiredStatus[]) {
    const url = this.middleWareUri + `/api/clients/locations/${locationId}/entity-associations`;
    return await this.apiPutCall(url, params);
  }

  public async updateRequiredCertHolderLocationAssociations(locationId: number, params: any) {
    const url = this.middleWareUri + `/api/clients/locations/${locationId}/cert-holder-associations`;
    return await this.apiPutCall(url, params);
  }

  public async deleteLocationEntity(locationId: number, entityName: string) {
    const url = this.middleWareUri + `/api/clients/locations/${locationId}/entities/${entityName}`;
    return await this.apiDeleteCall(url);
  }

  public async deleteLocationCertHolder(locationId: number, certHolderName: string) {
    const url = this.middleWareUri + `/api/clients/locations/${locationId}/cert-holders/${certHolderName}`;
    return await this.apiDeleteCall(url);
  }

  public async getClientLocationGroups() {
    const url = this.webServiceUri + '/ClientLocationGroups?select=*,locations:ClientLocations(*),mainContactName:ClientContacts(name)';
    return await this.apiGetCall(url);
  }

  // LOCATION GROUP CALLS

  public async getClientLocationGroupById(locationGroupId) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/vnd.pgrst.object+json');

    const url = this.webServiceUri + `/ClientLocationGroups?select=*,locations:ClientLocations(*)&clientLocationGroupId=eq.${locationGroupId}`;
    return await this.apiGetCall(url, headers);

  }

  public async deleteLocationGroup(locationGroupId) {
    const url = this.webServiceUri + `/ClientLocationGroups?clientLocationGroupId=eq.${locationGroupId}`;
    return await this.apiDeleteCall(url);
  }

  public async createNewLocationGroup(locationGroup) {
    const url = this.webServiceUri + '/ClientLocationGroups';
    return await this.apiPostCall(url, locationGroup);
  }

  public async updateLocationGroup(locationGroup, locationGroupId) {
    const url = this.webServiceUri + `/ClientLocationGroups?clientLocationGroupId=eq.${locationGroupId}`;
    return await this.apiPatchCall(url, locationGroup);
  }

  public async deleteLocationFromGroup(locationGroupId, locationId) {
    const url = this.middleWareUri + `/api/clients/location-groups/${locationGroupId}/location/${locationId}`;
    return await this.apiDeleteCall(url);
  }

  //Additional Insured Entities
  public async getOwnershipEntities() {
    const url = this.middleWareUri + `/api/clients/ownership-entities`;
    return await this.apiGetCall(url);
  }

  public async createOwnershipEntities(params: { entityName: string }) {
    const url = this.middleWareUri + `/api/clients/ownership-entities`;
    return await this.apiPostCall(url, params);
  }

  public async editOwnershipEntities(params: { entityName: string, endorsementEntityId: number }) {
    const url = this.middleWareUri + `/api/clients/ownership-entities`;
    return await this.apiPatchCall(url, params);
  }

  public async deleteOwnershipEntity(endorsementEntityId: number) {
    const url = this.middleWareUri + `/api/clients/ownership-entities/${endorsementEntityId}`;
    return await this.apiDeleteCall(url);
  }

  //Certificate Holders
  public async getCertHolders(): Promise<any[]> {
    const url = this.middleWareUri + `/api/clients/cert-holders`;
    return await this.apiGetCall(url);
  }

  public async getCertHoldersByLocation(locationId: number): Promise<CertHolderResult> {
    const url = this.middleWareUri + `/api/clients/cert-holders/${locationId}`;
    return await this.apiGetCall(url);
  }

  public async createOrUpdateCertHolders(params: CreateCertHolderRequest) {
    const url = this.middleWareUri + `/api/clients/cert-holders`;
    return await this.apiPostCall(url, params);
  }

  public async deleteCertHolder(params: any) {
    const url = this.middleWareUri + `/api/clients/cert-holders`;
    return await this.apiDeleteCall(url, null, params);
  }

  //this gets used even though itellisense cant find it
  public async twillioAuth() {
    const url = this.middleWareUri + '/api/clients/conversations/tokens';
    return await this.apiGetCall(url);
  }

  //
  // public async getClientConversationsByBidId(bidId) {
  //   const url = this.middleWareUri + `/api/clients/conversations/bids/${bidId}`;
  //   return await this.apiGetCall(url);
  // }

  public async getCreateVendorDirectConversation(vendorId) {
    const url = this.middleWareUri + `/api/clients/conversations/vendors/${vendorId}`;
    return await this.apiGetCall(url);
  }

  // CLIENT PROFILE CALLS
  public async getLoggedClientInfo() {
    const headers = new HttpHeaders();
    // headers = headers.set("Accept", "application/vnd.pgrst.object+json");

    const url = this.middleWareUri + '/api/clients/info';
    return await this.apiGetCall(url, headers);
  }

  public async updateClientInfo(params: any) {
    const url = this.middleWareUri + '/api/clients/info';
    return await this.apiPostFormCall(url, params);
  }

  public async getDocuments(companyId: number, isVendor: boolean, documentParams: DocumentParams = null) {
    const associatedJobsParams = Array.isArray(documentParams?.associatedJobs)
      ? documentParams.associatedJobs.map(job => `associatedJobs=${job}`).join('&')
      : '';

    let url = this.middleWareUri + `/api/clients/documents/get-documents/${companyId}?${associatedJobsParams}`;

    if (!isVendor) {
      url = this.middleWareUri + `/api/clients/documents/get-tenant-documents/${companyId}?${associatedJobsParams}`;
    }
    return await this.apiGetCall(url);
  }

  public async getExtractorLink(companyCertificateId, companyId, certusFileId) {
    let queryParams = new URLSearchParams();

    if (companyCertificateId != null) {
      queryParams.append('companyCertificateId', companyCertificateId);
    }
    queryParams.append('companyId', companyId);
    queryParams.append('certusFileId', certusFileId);

    const url = `${this.middleWareUri}/api/clients/documents/get-extractor-link?${queryParams.toString()}`;
    let link = await this.apiGetCall(url);
    return link['url'];
  }

  public async getComplianceItems(certificateId) {
    const url = this.middleWareUri + `/api/clients/compliance/get-detailed-compliance/${certificateId}`;
    return await this.apiGetCall(url);
  }

  public async getDocumentTypes(isTenantClient) {
    const url = this.middleWareUri + `/api/clients/documents/get-document-types?isTenantClient=` + isTenantClient;
    return await this.apiGetCall(url);
  }

  public async getPolicies(isTenantClient) {
    const url = this.middleWareUri + `/api/clients/policy-templates?isTenantClient=` + isTenantClient;
    return await this.apiGetCall(url);
  }

  public async getPreSignedUrl(recordId) {
    const url = this.middleWareUri + `/api/clients/documents/get-presignedurl/${recordId}`;
    return await this.apiGetCall(url);
  }

  public async saveDocument(params): Promise<any> {
    const url = this.middleWareUri + `/api/clients/documents/save-document`;
    return await this.apiPostCall(url, params);
  }

  public async UpdateWorkflowAttachmentIsReviewed(certusFileId: number, isReviewed: boolean): Promise<void> {
    const url = this.middleWareUri + `/api/clients/documents/update-is-reviewed?certusFileId=${certusFileId}&isReviewed=${isReviewed}`;
    return await this.apiPutCall(url);
  }

  public async editDocument(params): Promise<any> {
    const url = this.middleWareUri + `/api/clients/documents/edit-document`;
    return await this.apiPostCall(url, params);
  }

  public async clientPreauth(username: string, password: string) {
    const params = { username, password };
    const url = `${this.middleWareUri}/api/clients/login/preauth`;
    return await this.apiPutCall(url, params);
  }

  // BIDS CALLS

  public async getClientJobsWithBids(closedJobPostings: boolean, page: number, perPage: number, searchParam = ''): Promise<ResponsePaginated> {
    const today = new Date(Date.now()).toISOString();
    const rangeLow = perPage * page - perPage;
    const rangeHigh = perPage * page - 1;
    const rangeStr = `${rangeLow}-${rangeHigh}`;

    let headers = new HttpHeaders();
    headers = headers.set('Prefer', 'count=exact');
    headers = headers.set('Range', rangeStr);

    let searchParamsStr = '';
    if (!closedJobPostings) {
      searchParamsStr += `&and=(awardedAt.is.null,isClosed.is.false,expirationDate.gt.*${today}*))`;
    } else {
      searchParamsStr += `&or=(isClosed.is.true,expirationDate.lt.*${today}*)`;
    }
    if (searchParam && searchParam.length > 0) {
      searchParamsStr += `&or=(title.ilike.*${searchParam}*,description.ilike.*${searchParam}*)`;
    }

    const url = this.webServiceUri + `/ClientPostings?select=*,bids:ClientPostingBids(*,vendor:ClientVendorsActive(*,vendorProfile:ClientVendorProfile(*))),location:ClientPostingLocations(*)${searchParamsStr}`;
    return await this.apiGetCallPaginated(url, headers);
  }

  // CONVERSATIONS CALLS
  public async getClientDirectConversations(page, perPage, searchParam = '', vendorId: number = null) {
    const rangeLow = perPage * page - perPage;
    const rangeHigh = perPage * page - 1;
    const rangeStr = `${rangeLow}-${rangeHigh}`;

    let headers = new HttpHeaders();
    headers = headers.set('Prefer', 'count=exact');
    headers = headers.set('Range', rangeStr);

    let searchParamsStr = '';
    if (searchParam && searchParam.length > 0) {
      searchParamsStr += `&vendorName=ilike.*${searchParam}*`;
    }
    if (vendorId) {
      searchParamsStr += `&or=(vendorId.eq.${vendorId}, hasMessages.is.true)`;
    } else {
      searchParamsStr += `&hasMessages=is.true`;
    }
    const orderByStr = `&order=lastUpdated.desc`;
    const url = this.webServiceUri + `/ClientDirectConversations?select=*,vendor:ClientVendors(*,vendorProfile:ClientVendorProfile(*),connection:ClientConnections(accepted))${searchParamsStr}${orderByStr}`;
    return await this.apiGetCallPaginated(url, headers);
  }

  //CLIENT ADMIN CALLS
  public async getClientUsers() {
    const url = this.middleWareUri + `/api/clients/users`;
    return await this.apiGetCall(url);
  }

  public async getClientUserById(userId) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/vnd.pgrst.object+json');

    const url = this.webServiceUri + `/ClientUsers?ClientUserId=eq.${userId}`;
    return await this.apiGetCall(url, headers);
  }

  public async getClientUserWithClientUserRolesByUserId(userId) {
    const url = this.middleWareUri + `/api/clients/users/${userId}`;
    return await this.apiGetCall(url);
  }

  public async createNewClientUser(params) {
    const url = this.middleWareUri + `/api/clients/users/register`;
    return await this.apiPostCall(url, params);
  }

  public async addVendorSupport() {
    const url = this.middleWareUri + `/api/clients/registration/vendor`;
    return await this.apiPutCall(url);
  }

  public async addTenantSupport() {
    const url = this.middleWareUri + `/api/clients/registration/tenant`;
    return await this.apiPutCall(url);
  }

  public async updateClientUser(params, userId) {
    const url = this.middleWareUri + `/api/clients/users/${userId}`;
    return await this.apiPostCall(url, params);
  }

  public async updateClientUserLocationFiltersByUser(userId, data) {
    const url = this.middleWareUri + `/api/clients/users/authorized-locations/${userId}`;
    return await this.apiPostCall(url, data);
  }

  async updateClientUserLocationFiltersByLocation(locationId: number, data) {
    const url = this.middleWareUri + `/api/clients/users/authorized-locations/${locationId}`;
    return await this.apiPostCall(url, data);
  }

  public async updateClientUserPhoto(params, userId) {
    const url = this.middleWareUri + `/api/clients/users/${userId}/edit-user-avatar`;
    return await this.apiPostFormCall(url, params);
  }

  public async deleteClientUser(userId) {
    const url = this.middleWareUri + `/api/clients/users/${userId}`;
    return await this.apiDeleteCall(url);
  }

  public async deleteContact(contactId) {
    const url = this.middleWareUri + `/api/clients/contacts/company/${contactId}`;
    return await this.apiDeleteCall(url);
  }

  public async deleteAgent(agentIds: any): Promise<any> {
    const url = this.middleWareUri + `/api/clients/vendors/delete-agent`;
    return await this.apiPostCall(url, agentIds);
  }

  public async resetClientUserPassword(userId) {
    const url = this.middleWareUri + `/api/clients/users/${userId}/reset-password`;
    return await this.apiPostCall(url);
  }

  public async getClientUserRoles() {
    const url = this.middleWareUri + `/api/clients/users/all-roles`;
    return await this.apiGetCall(url);
  }

  public async getClientUserClientRoles() {
    const url = this.middleWareUri + `/api/clients/users/roles-for-users`;
    return await this.apiGetCall(url);
  }

  public async getClientUserStatuses() {
    const url = this.middleWareUri + `/api/clients/users/statuses`;
    return await this.apiGetCall(url);
  }

  public async getClientVendorProfileView(vendorId) {
    const params = {
      vendor_id: vendorId
    };
    const url = this.webServiceUri + `/rpc/ClientVendorProfileView`;
    return await this.apiPostCall(url, params);
  }

  // Tenant calls

  public async getAllTenants() {
    let headers = new HttpHeaders();
    headers = headers.set('Range', '0-99');
    headers = headers.set('Prefer', 'count=exact');

    const url = this.webServiceUri + `/ClientTenantConnections?select=*,clientTenant:ClientTenantWithCompanyData(name, companyId)&and=(status.eq.ACTIVE)`;
    const res = await this.apiGetCall(url, headers);

    return res;
  }

  public async getClientLeaseTypes() {
    const url = this.webServiceUri + '/ClientLeaseTypes';
    return await this.apiGetCall(url);
  }

  // Properties calls
  public async getProperties(propertyType: string): Promise<CustomFieldProperty[]> {
    const url = this.middleWareUri + `/api/clients/properties?propertyType=${propertyType}`;
    let result = await this.apiGetCall(url);
    return result;
  }

  // EOF Tenant calls

  public async saveForm(form: any, isVendor: boolean, formTemplateId: number) {
    return await this.apiPutCall(this.middleWareUri + `/api/clients/form-template/${formTemplateId}?isVendor=${isVendor}`, form);
  }

  public async getForm(isVendor: boolean) {
    const url = this.middleWareUri + `/api/clients/form-template?isVendor=${isVendor}`;
    return await this.apiGetCall(url);
  }

  public async addRequirementCategory(requirementForm) {
    const url = this.middleWareUri + `/api/clients/requirements/add-new-category`;
    return await this.apiPostCall(url, requirementForm);
  }

  //Requirement calls

  public async editRequirementCategory(requirementForm) {
    const url = this.middleWareUri + `/api/clients/requirements/edit-requirement-category`;
    return await this.apiPostCall(url, requirementForm);
  }

  public async updateComplianceByRequirementId(insReqCatId) {
    const url = this.middleWareUri + `/api/clients/requirements/update-compliance/${insReqCatId}`;
    return await this.apiPostCall(url);
  }

  public async deleteRequirement(requirementId) {
    const url = this.middleWareUri + `/api/clients/requirements/delete-requirement`;
    return await this.apiPostCall(url, requirementId);
  }

  public async newAdditionalTerm(additionalTerm, isTenantClient) {
    const url = this.middleWareUri + `/api/clients/requirements/new-additional-term?isTenantClient=` + isTenantClient;
    return await this.apiPostCall(url, additionalTerm);
  }

  public async deleteCompanyDocument(deleteRequest: any) {
    const url = this.middleWareUri + `/api/clients/documents`;
    return await this.apiDeleteCall(url, null, deleteRequest);
  }

  public async deleteDocument(documentId) {
    const url = this.middleWareUri + `/api/clients/requirements/delete-document`;
    return await this.apiPostCall(url, documentId);
  }

  public async createDuplicate(requirementId, isTenant) {
    const url = this.middleWareUri + `/api/clients/requirements/create-duplicate?isTenant=${isTenant}`;
    return await this.apiPostCall(url, requirementId);
  }

// Properties calls
  public async addCustomField(property: CustomFieldProperty, isVendor: boolean): Promise<CustomFieldProperty> {
    const url = this.middleWareUri + `/api/clients/properties/add-custom-field?isVendor=${isVendor}`;
    return await this.apiPostCall(url, property);
  }

  public async addCompanyGroupCategory(property: CustomFieldProperty, isVendor: boolean): Promise<CustomFieldProperty> {
    const url = this.middleWareUri + `/api/clients/properties/add-group-category?isVendor=${isVendor}`;
    return await this.apiPostCall(url, property);
  }

  public async addCertificateCustomField(property: CustomFieldProperty, isVendor: boolean): Promise<CustomFieldProperty> {
    const url = this.middleWareUri + `/api/clients/properties/add-certificate-custom-field?isVendor=${isVendor}`;
    return await this.apiPostCall(url, property);
  }

  public async addCertificateGroupCategory(property: CustomFieldProperty, isVendor: boolean): Promise<CustomFieldProperty> {
    const url = this.middleWareUri + `/api/clients/properties/add-certificate-group-category?isVendor=${isVendor}`;
    return await this.apiPostCall(url, property);
  }

  public async editCertificateCustomField(property: CustomFieldProperty, isVendor: boolean): Promise<void> {
    const url = this.middleWareUri + `/api/clients/properties/edit-certificate-custom-field?isVendor=${isVendor}`;
    await this.apiPostCall(url, property);
  }

  public async editCertificateGroupCategory(property: CustomFieldProperty, isVendor: boolean): Promise<void> {
    const url = this.middleWareUri + `/api/clients/properties/edit-certificate-group-category?isVendor=${isVendor}`;
    await this.apiPostCall(url, property);
  }

  public async editCustomField(property: CustomFieldProperty, isVendor: boolean): Promise<void> {
    const url = this.middleWareUri + `/api/clients/properties/edit-custom-field?isVendor=${isVendor}`;
    await this.apiPostCall(url, property);
  }

  public async editCompanyGroupCategory(property: CustomFieldProperty, isVendor: boolean): Promise<void> {
    const url = this.middleWareUri + `/api/clients/properties/edit-group-category?isVendor=${isVendor}`;
    await this.apiPostCall(url, property);
  }

  public async deleteProperty(property: CustomFieldProperty, isVendor: boolean): Promise<void> {
    const url = this.middleWareUri + `/api/clients/properties/delete-property?isVendor=${isVendor}`;
    await this.apiPostCall(url, property);
  }

  public async deleteCertificateProperty(property: CustomFieldProperty, isVendor: boolean): Promise<void> {
    const url = this.middleWareUri + `/api/clients/properties/delete-certificate-property?isVendor=${isVendor}`;
    await this.apiPostCall(url, property);
  }

  public async getUpcomingEmailsByCompany(isVendor: boolean, companyId: number) {

    const url = this.middleWareUri + `/api/clients/automation/company-upcoming-emails?isVendor=${isVendor}&companyId=${companyId}`;
    return await this.apiGetCall(url);
  }

  public async getAutomatedActionGroups(isVendor: boolean) {
    const url = this.middleWareUri + `/api/clients/automation/email-action-groups?isVendor=${isVendor}`;
    return await this.apiGetCall(url);
  }

  public async disableAutomatedActionGroup(isVendor: boolean, groupId: number) {
    const url = this.middleWareUri + `/api/clients/automation/disable-group?isVendor=${isVendor}&groupId=${groupId}`;
    return await this.apiPostCall(url);
  }

  public async enableAutomatedActionGroup(isVendor: boolean, groupId: number) {
    const url = this.middleWareUri + `/api/clients/automation/enable-group?isVendor=${isVendor}&groupId=${groupId}`;
    return await this.apiPostCall(url);
  }

  // BULK IMPORT
  public async coiImport(params: any, isVendor: boolean): Promise<any> {
    const url = this.middleWareUri + `/api/clients/bulk-import/coi-import?isVendor=${isVendor}`;
    return await this.apiPostCall(url, params);
  }

  public async checkLimits(bipi: number): Promise<any> {
    const url = this.middleWareUri + `/api/clients/bulk-import/check-limits/${bipi}`;
    return await this.apiGetCall(url);
  }

  public async bulkCompanyImport(params: any, bulkImportProcessItemId: number): Promise<any> {
    const url = this.middleWareUri + `/api/clients/bulk-import/bulk-company-import/${bulkImportProcessItemId}`;
    return await this.apiPostCall(url, params);
  }

  public async getS3Urls(params: any) {
    const url = this.middleWareUri + `/api/clients/bulk-import/get-s3-presigned-url`;
    return await this.apiPostCall(url, params);
  }

  public async getBulkImportProcessItems() {
    // TODO: Dustin delete ClientBulkImportProcessItem api call on pg-rest
    const url = this.middleWareUri + `/api/clients/bulk-import/items`;
    return await this.apiGetCall(url);
  }

  public async getFileDataByBulkImportProcessItemId(bulkImportProcessItemId: number) {
    const url = this.middleWareUri + `/api/clients/bulk-import/file-data/${bulkImportProcessItemId}`;
    return await this.apiGetCall(url);
  }

  public async discardBulkImport(itemId: number): Promise<any> {
    const url = this.middleWareUri + `/api/clients/bulk-import/discard-bulk-import/${itemId}`;
    return await this.apiPostCall(url);
  }

  public async getEmailIn(emailId) {
    const url = this.middleWareUri + `/api/clients/email/in/${emailId}`;
    return await this.apiGetCall(url);
  }

  public async getEmailOut(emailId) {
    const url = this.middleWareUri + `/api/clients/email/out/${emailId}`;
    return await this.apiGetCall(url);
  }

  public async uploadFileToS3(url: string, file: File) {
    return await this.apiUploadFileToS3(url, file);
  }

  // BILLING METHODS
  public async getCustomerByClientId() {
    const url = this.middleWareUri + `/api/clients/billing/customer-by-client-id`;
    return await this.apiGetCall(url);
  }

  public async getPricesByLookupKeys(lookupKeys: string[]) {
    let lookupKeysQueryParam = lookupKeys.join('&lookupKeys=');
    const url = this.middleWareUri + `/api/clients/billing/prices?lookupKeys=${lookupKeysQueryParam}&tiered=true`;
    return await this.apiGetCall(url);
  }

  public async getPriceById(priceId) {
    const url = this.middleWareUri + `/api/clients/billing/prices/${priceId}`;
    return await this.apiGetCall(url);
  }

  public async getInvoices() {
    const url = this.middleWareUri + `/api/clients/billing/invoices`;
    return await this.apiGetCall(url);
  }

  public async getActiveJobCount() {
    const url = this.middleWareUri + `/api/clients/jobs/get-active-job-count`;
    return await this.apiGetCall(url);
  }

  public async getActiveLeaseCount() {
    const url = this.middleWareUri + `/api/clients/tenants/leases/get-active-lease-count`;
    return await this.apiGetCall(url);
  }

  public async getUpcomingInvoice(subscriptionId = null) {
    const url = subscriptionId !== null ? `${this.middleWareUri}/api/clients/billing/upcoming-invoice/${subscriptionId}` : `${this.middleWareUri}/api/clients/billing/upcoming-invoice`;
    return await this.apiGetCall(url);
  }

  public async getCustomerPaymentMethods() {
    const url = this.middleWareUri + `/api/clients/billing/payment-methods`;
    return await this.apiGetCall(url);
  }

  public async getClientActiveAccountUsageBetween() {
    const url = this.middleWareUri + `/api/clients/client-active-usage/account-usage-between`;
    return await this.apiGetCall(url);
  }

  public async getClientActiveAccountUsage() {
    const url = this.middleWareUri + `/api/clients/client-active-usage/account-usage`;
    return await this.apiGetCall(url);
  }

  public async updateCustomerSubscriptionFromBasicToProfessional(subscriptionId, newPriceId, quantity, paymentMethodId = null) {
    const url = this.middleWareUri + `/api/clients/billing/subscriptions/${subscriptionId}/update-basic-to-professional`;
    return await this.apiPutCall(url, { newPriceId: newPriceId, paymentMethodId: paymentMethodId, quantity: quantity });
  }

  public async updateCustomerSubscriptionFromProfessionalToBasic(subscriptionId, newPriceId) {
    const url = this.middleWareUri + `/api/clients/billing/subscriptions/${subscriptionId}/update-professional-to-basic`;
    return await this.apiPutCall(url, { newPriceId: newPriceId });
  }

  public async updateBillingContactInfo(updateBillingContactInformationRequest): Promise<any> {
    const url = this.middleWareUri + `/api/clients/billing/update-billing-contact`;
    return await this.apiPostCall(url, updateBillingContactInformationRequest);
  }

  public async updateBillingInformation(updateBillingInformationRequest): Promise<any> {
    const url = this.middleWareUri + `/api/clients/billing/update-billing-information`;
    return await this.apiPostCall(url, updateBillingInformationRequest);
  }

  public async setCustomerDefaultPaymentMethod(setDefaultPaymentMethodRequest) {
    const url = this.middleWareUri + `/api/clients/billing/set-default-payment-method`;
    return await this.apiPostCall(url, setDefaultPaymentMethodRequest);
  }

  public async createSetupIntent() {
    const url = this.middleWareUri + `/api/clients/billing/setup-intent`;
    return await this.apiPostCall(url);
  }

  public async detachPaymentMethodFromCustomer(paymentMethodId) {
    const url = this.middleWareUri + `/api/clients/billing/payment-methods/${paymentMethodId}/detach`;
    return await this.apiPostCall(url, {});
  }

  public async getClientSwitcherClients(): Promise<{ availableClients: { phoenixClientId: number, name: string }[] }> {
    const url = this.middleWareUri + `/api/clients/cognito/client_switch`;
    return await this.apiGetCall(url);
  }

  public async switchPhoenixClients(newClientId: number): Promise<any> {
    const url = this.middleWareUri + `/api/clients/cognito/client_switch/${newClientId}`;
    return await this.apiPostCall(url);
  }

  // Client Onboarding
  public async clientRegistration(params): Promise<any> {
    const url = this.middleWareUri + `/api/clients/registration/register`;
    return await this.apiPostCall(url, params);
  }

  public async isClientRegistered(email): Promise<any> {
    const url = this.middleWareUri + `/api/clients/registration/is_registered?email=` + encodeURIComponent(email);
    return await this.apiGetCall(url);
  }

  //Settings calls
  public async getGeneralLabelSettings() {
    const url = this.middleWareUri + `/api/client/settings/general/labels`;
    return await this.apiGetCall(url);
  }

  // EOF Client Onboarding

  public async saveLabelSettings(selectedOptions: any) {
    let param = {
      ClientLabelOptions: selectedOptions
    };
    const url = this.middleWareUri + `/api/client/settings/general/labels/save`;
    return await this.apiPostCall(url, param);
  }

  async getEscalationStatuses(isVendor: boolean): Promise<any[]> {
    const url = this.middleWareUri + `/api/client/settings/general/escalation-statuses?isVendor=${isVendor}`;
    return await this.apiGetCall(url);
  }

  // VENDOR NOTES CALLS
  public async getCompanyActivities(companyId) {
    const url = this.middleWareUri + `/api/clients/vendors/${companyId}/activities`;
    return await this.apiGetCall(url);
  }

  public async getTenantCompanyActivities(companyId) {
    const url = this.middleWareUri + `/api/clients/tenant/${companyId}/activities`;
    return await this.apiGetCall(url);
  }

  public async getAllActivities(searchSettings) {
    const url = this.middleWareUri + `/api/clients/activities`;
    return await this.apiPostCall(url, searchSettings);
  }

  public async createTask(isTenantClient, taskToCreate, recordId) {
    let url = this.middleWareUri + `/api/clients`;
    if (isTenantClient) {
      url += `/tenants/${recordId}/create-task`;
    } else {
      url += `/vendors/${recordId}/create-task`;
    }

    return await this.apiPostCall(url, taskToCreate);
  }

  public async updateTask(isTenantClient, taskToUpdate, recordId) {
    let url = this.middleWareUri + `/api/clients`;
    if (isTenantClient) {
      url += `/tenants/tasks/${recordId}/update-task`;
    } else {
      url += `/vendors/tasks/${recordId}/update-task`;
    }

    return await this.apiPutCall(url, taskToUpdate);
  }

  public async updateTaskStatus(isComplete, taskId) {
    const url = this.middleWareUri + `/api/clients/tasks/${taskId}/update-status?isComplete=` + isComplete;
    return await this.apiPutCall(url);
  }

  public async updateEmailStatus(isComplete, emailId) {
    const url = this.middleWareUri + `/api/clients/emails/${emailId}/update-status?isComplete=` + isComplete;
    return await this.apiPutCall(url);
  }

  public async updateEmailAssociations(companyId, companyCertificateId, emailId) {
    let queryParams = [];
    if (companyId != null) {
      queryParams.push(`companyId=${encodeURIComponent(companyId)}`);
    }
    if (companyCertificateId != null) {
      queryParams.push(`companyCertificateId=${encodeURIComponent(companyCertificateId)}`);
    }
    const queryString = queryParams.join('&');
    const url = `${this.middleWareUri}/api/clients/emails/${encodeURIComponent(emailId)}/update-associations${queryString ? '?' + queryString : ''}`;
    return await this.apiPutCall(url);
  }

  public async deleteTask(isTenantClient, recordId) {
    let url = this.middleWareUri + `/api/clients`;
    if (isTenantClient) {
      url += `/tenants/tasks/${recordId}/delete-task`;
    } else {
      url += `/vendors/tasks/${recordId}/delete-task`;
    }

    return await this.apiDeleteCall(url);
  }

  public async getTask(isTenantClient, recordId) {
    let url = this.middleWareUri + `/api/clients`;
    if (isTenantClient) {
      url += `/tenants/tasks/${recordId}`;
    } else {
      url += `/vendors/tasks/${recordId}`;
    }
    return await this.apiGetCall(url);
  }

  public async deleteNote(isTenantClient, recordId, isCertificate) {
    let url = this.middleWareUri + `/api/clients`;
    if (isTenantClient) {
      url += `/tenants/notes/${recordId}/delete-note?hasAssociatedLease=` + isCertificate;
    } else {
      url += `/vendors/notes/${recordId}/delete-note?hasAssociatedJob=` + isCertificate;
    }
    return await this.apiDeleteCall(url);
  }

  public async createNote(isTenantClient, noteToCreate, recordId) {
    let url = this.middleWareUri + `/api/clients`;
    if (isTenantClient) {
      url += `/tenants/${recordId}/create-note`;
    } else {
      url += `/vendors/${recordId}/create-note`;
    }

    return await this.apiPostCall(url, noteToCreate);
  }

  public async updateNote(isTenantClient, noteToCreate, recordId) {
    let url = this.middleWareUri + `/api/clients`;
    if (isTenantClient) {
      url += `/tenants/notes/${recordId}/update-note`;
    } else {
      url += `/vendors/notes/${recordId}/update-note`;
    }

    return await this.apiPutCall(url, noteToCreate);
  }

  public async sendNotice(noticeToSend, companyId) {
    const url = this.middleWareUri + `/api/clients/company/${companyId}/send-notice`;
    return await this.apiPostFormCall(url, noticeToSend);
  }

  public async getSystemUsersWithTasks() {
    let url = this.middleWareUri + `/api/clients`;
    url += `/users/users-with-tasks`;

    return await this.apiGetCall(url);
  }

  public async getNoticeTemplates(isTenantClient, recordTypeId, recordId) {
    const url = this.middleWareUri + `/api/clients/email/get-notice-templates?isTenantClient=` + isTenantClient + `&recordTypeId=` + recordTypeId + `&recordId=` + recordId;
    return await this.apiGetCall(url);
  }

  public async getNoticeTemplateAttachments(noticeTemplateId, companyId, certificateId) {
    const url = this.middleWareUri + `/api/clients/email/get-notice-template-attachments?noticeTemplateId=${noticeTemplateId}&companyId=${companyId}&certificateId=${certificateId}`;


    let result = await this.apiGetCall(url);
    return result;
  }

  public async getNoticeTemplateContent(noticeTemplateId, companyId, certificateId) {
    const url = this.certusApiUri + `NoticeTemplate?noticeTemplateId=${noticeTemplateId}&companyId=${companyId}&certificateId=${certificateId}`;

    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');
    headers = headers.set('Api-Key', this.certusApiPublicKey);


    let result = await this.apiGetCall(url, headers);
    return result;
  }

  public async getRequirementTemplateBytes(certificateId) {
    const url = this.certusApiUri + `RequirementTemplate?certificateId=${certificateId}`;

    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');
    headers = headers.set('Api-Key', this.certusApiPublicKey);


    let result = await this.apiGetCall(url, headers);
    return result;
  }

  public async getUsersTiedToClient(isTenantClient) {
    let url = this.middleWareUri + `/api/clients`;
    url += `/users/all-system-users?isTenantClient=` + isTenantClient;

    return await this.apiGetCall(url);
  }

  public async getAttachments(jobId) {
    let url = this.middleWareUri + `/api/clients`;
    url += `/get-attachments/${jobId}`;

    return await this.apiPostCall(url);
  }

  public async getAllClientVendors(companyId: number) {
    const url = this.middleWareUri + `/api/clients/vendors/get-company-vendors/${companyId}`;
    return await this.apiGetCall(url);
  }

  public async getAllClientTenants(companyId: number) {
    const url = this.middleWareUri + `/api/clients/tenants/get-company-tenants/${companyId}`;
    return await this.apiGetCall(url);
  }

  public async getCompanyIdByTenantId(tenantId: number) {
    const url = this.webServiceUri + `/ClientTenantWithCompanyData?select=companyId&and=(tenantId.eq.${tenantId})`;
    const res = await this.apiGetCall(url);
    return res;
  }

  public async getCompanyById(companyId: number, useNew: boolean = true) {
    const url = this.middleWareUri + `/api/clients/company/${companyId}/properties`;
    return await this.apiGetCall(url);
  }

  getOptionNamesFromServiceTypes(groupOptions, categoryNameString): string {
    if (!groupOptions) {
      return '--';
    }
    for (const category of groupOptions) {
      if (category.categoryName === categoryNameString) {
        return category.options.map(option => option.optionName).join(', ');
      }
    }
    return '--';
  }

  public async getCertificatesByCompanyId(companyId) {
    let url = this.middleWareUri + `/api/clients/company/${companyId}/detailed-certificates`;
    let res = await this.apiGetCall(url);


    return res.map((certificate) => {

      certificate['serviceTypesStr'] = this.getOptionNamesFromServiceTypes(certificate.groupOptions, 'Service Types');
      //BELOW CODE IS TEMPORARY UNTIL WE GET RID OF ALL THE JobID REFERENCES...

      return certificate;
    });
  }

  public async getCompanyIdByVendorId(vendorId: number) {
    const url = this.webServiceUri + `/ClientVendorsWithCompanyData?select=companyId&and=(vendorId.eq.${vendorId})`;
    const res = await this.apiGetCall(url);
    return res;
  }

  public async getCompanyComplianceLevel(companyId: number) {
    let url = this.middleWareUri + `/api/clients/company/${companyId}/compliance`;
    return await this.apiGetCall(url);
  }

  private async buildVendorSearchResults(res: any, headers: HttpHeaders, includeProperties: boolean) {
    let modifiedJobs = res?.jobs?.map((job) => {
      let jobTypes = job.jobTypes ? job.jobTypes.map((jobType) => jobType.jobTypeId) : [];

      let serviceTypesStr = job.serviceTypes
        ? [...new Set(job.serviceTypes.map((serviceType) => serviceType.certificateGroupOptionName))].join(', ')
        : '';

      let locationsDisplayLabel = job.jobLocations
        ? [...new Set(job.jobLocations.map((location) => location?.clientLocation?.name))].join(', ')
        : '';

      let registrationStatus = RegistrationStatus.COMPLETE;
      if (res.isFeeDue) {
        registrationStatus = RegistrationStatus.CONNECTION_ACCEPTED;
      } else if (job.certificateId == null) {
        registrationStatus = RegistrationStatus.REGISTRATION_COMPLETED;
      } else if (job.backtoclientid != null) {
        registrationStatus = RegistrationStatus.WAIVER_REQUEST;
      } else if (job.complianceLevel == 0) {
        registrationStatus = RegistrationStatus.COMPLIANCE;
      }

      return {
        ...job,
        locationsDisplayLabel: locationsDisplayLabel,
        serviceTypesStr: serviceTypesStr,
        jobTypes: jobTypes,
        status: registrationStatus
      };
    });

    res = {
      ...res,
      jobs: modifiedJobs
    };

    return res;
  }
}
