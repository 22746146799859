import { Component, Output, EventEmitter, forwardRef, Input, Optional } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';

import { GoogleAPIService } from '../providers/google.api.provider';
import { Location } from '../models/location-model';
import { AuthenticatedUser } from '../shared/models/authenticated-user-model';
import { ValueAccessorBase } from '../shared/utils/ValueAccessorBase';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { ControlContainer, NgForm } from '@angular/forms';


export enum LocationTypeaheadFlavor {
  SHOW_FULL_ADDRESS_IN_INPUT,
  SHOW_ADDRESS1_IN_INPUT
}

@Component({
  selector: 'location-typeahead',
  templateUrl: './location-typeahead.component.html',
  styleUrls: ['./location-typeahead.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LocationTypeAheadComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LocationTypeAheadComponent),
      multi: true
    }
  ]
})

export class LocationTypeAheadComponent extends ValueAccessorBase<any> implements Validator {
  @Output('onLocationChange') onLocationChange: EventEmitter<Location> = new EventEmitter<Location>();
  @Output('onFocusOut') onFocusOut: EventEmitter<Location> = new EventEmitter<Location>();
  @Output('onSearchTextChanged') onSearchTextChanged: EventEmitter<string> = new EventEmitter<string>();
  @Input('classes') classes: string = ''; //big, large, medium, small, extra-small
  @Input() hasError: boolean = false;
  @Input() inputValue: string = '';
  @Input() readonly = null;
  @Input() placeholder: string = 'Location address';
  @Input() controlName: string;
  @Input() size: string;
  @Input() locationTypeaheadFlavor: LocationTypeaheadFlavor = LocationTypeaheadFlavor.SHOW_FULL_ADDRESS_IN_INPUT;
  @Input() doNotValidate = false;

  public locationObject: Location;
  public asyncSelected: string;
  public typeaheadLoading: boolean;
  public dataSource: Observable<any>;
  private internalForm: FormGroup;

  constructor(private formBuilder: FormBuilder, private googleAPIService: GoogleAPIService, @Optional() public controlContainer: ControlContainer) {
    super();
    this.dataSource = Observable.create((observer: any) => {
      // Runs on every search
      observer.next(this.asyncSelected);
    })
      .pipe(
        mergeMap((token: string) => this.googleAPIService.getAutoCompleteResult(token))
      );
  }

  getFormControl(): AbstractControl {

    if (this.controlContainer && this.controlName) {
      return this.controlContainer.control.get(this.controlName);
    } else {
      return this.internalForm.get('annon');
    }
  }

  validate(control: AbstractControl): ValidationErrors {
    if(this.doNotValidate) return null;
    if (!this.locationObject) {
      return { 'error': true };
    } else {
      return null;
    }
  }

  ngOnInit() {
    this.asyncSelected = this.inputValue;
    this.internalForm = this.formBuilder.group({
      annon: new FormControl(this.inputValue)
    });
  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  typeaheadOnSelect(e: TypeaheadMatch): void {
    this.getLocationDetails(e.item);
  }

  public keydown(changes: any) {
    this.locationObject = null;
    this.onLocationChange.emit(this.locationObject);
  }

  public focusout(): void {
    this.onFocusOut.emit(this.locationObject);
  }

  public afterSetValue() {
    this.locationObject = this.value;
  }

  getLongStateNameFromPlace(place: any): string {
    const addressComponents = place.address_components;
    for (let component of addressComponents) {
      const types = component.types;
      if (types.includes('administrative_area_level_1')) {
        return component.long_name;
      }
    }
    return '';
  }

  private async getLocationDetails(selectedLocation) {
    if (selectedLocation && selectedLocation['id']) {
      let res = await this.googleAPIService.getPlaceDetails(selectedLocation['id']);

      let location: Location = new Location(false);
      location.lat = res.geometry.location.lat();
      location.long = res.geometry.location.lng();
      location.longStateName = this.getLongStateNameFromPlace(res).toUpperCase();

      let streetNumber;
      let route;
      location.formattedAddr = res['formatted_address'];
      location.placeId = res['place_id'];
      for (let component of res['address_components']) {
        for (let type of component['types']) {
          if (type == 'street_number') {
            streetNumber = component['long_name'];
          }
          if (type == 'subpremise') {
            location.subpremise = component['long_name'];
          }
          if (type == 'route') {
            route = component['long_name'];
          }
          if (type == 'locality') {
            location.locality = component['long_name'];
          } else if (type == 'administrative_area_level_3') {
            location.locality = component['long_name'];
          }
          if (type == 'sublocality') {
            location.sublocality = component['long_name'];
          }
          if (type == 'administrative_area_level_1') {
            location.state = component['short_name'];
          }
          if (type == 'postal_code') {
            location.zip = component['short_name'];
          }
          if (type == 'country') {
            location.country = component['long_name'];
          }
        }
      }
      location.address = streetNumber ? streetNumber + ' ' + route : route;
      this.locationObject = location;
      this.value = this.locationObject;
      this.onLocationChange.emit(this.locationObject);

      if (this.locationTypeaheadFlavor == LocationTypeaheadFlavor.SHOW_ADDRESS1_IN_INPUT) {
        this.internalForm.patchValue({ 'annon': location.address });
      }
    }
  }

  searchInputChanged($event) {
    this.onSearchTextChanged.emit(this.asyncSelected);
  }
}
