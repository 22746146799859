import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { faFileAlt, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { PhoenixChatService } from '../../chat.service';
import { ChatMessage, ChatMessageType } from '../../models/chat-message-model';

@Component({
    selector: 'chat-message',
    templateUrl: './chat-message.component.html',
    styleUrls: ['./chat-message.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatMessageComponent {
    public faFilePdf = faFilePdf;
    public faFileAlt = faFileAlt;
    public userProfile: any = null;
    private _message: ChatMessage;
    ChatMessageType = ChatMessageType;
    public userProfileId: string;
    public profileSubscription: Subscription;
    public userProfilesCache: any = {};

    @Input('messageOnly') messageOnly: boolean = false;

    public mediaUrl: string = '';

    get message(): ChatMessage {
        return this._message;
    }

    @Input('message') set message(value: ChatMessage) {
        this._message = value;
        this.incoming = this.phoenixChatService?.twilioUser?.identity != this._message?.sender?.id;
        if (value.type == ChatMessageType.ChatMessageFile) {
            this.message.twilioChatMessage.media.getContentTemporaryUrl().then((url) => {
                this.mediaUrl = url;
                this.changeDetectorRef.markForCheck();
            })
        }

        if (value?.twilioChatMessage?.attributes?.['userId']) {
            this.userProfileId = value?.twilioChatMessage?.attributes?.['userId'];
            if (this.userProfilesCache[this.userProfileId]) {
                this.userProfile = this.userProfilesCache[this.userProfileId];
            } else {
                this.loadUserProfile();
            }
        }
    }

    @Output('onImageLoaded') onImageLoaded: EventEmitter<any> = new EventEmitter<any>();

    public incoming: boolean = false;

    ngOnDestroy() {
        this.profileSubscription.unsubscribe();
    }

    constructor(public phoenixChatService: PhoenixChatService, private changeDetectorRef: ChangeDetectorRef) {
        this.profileSubscription = this.phoenixChatService.userProfilesCacheObservable.pipe(map((val) => {
            this.userProfilesCache = val;
            if (this.userProfileId) {
                this.userProfile = val[this.userProfileId];
                this.changeDetectorRef.detectChanges();
            }
        })).subscribe();
    }

    public async loadUserProfile() {
        await this.phoenixChatService.getUserProfile(this.userProfileId);
        this.changeDetectorRef.detectChanges();
    }

    public openMedia() {
        window.open(this.mediaUrl, "_blank");
    }

    public imageLoaded() {
        this.onImageLoaded.emit(null);
        this.changeDetectorRef.detectChanges();
    }
}
