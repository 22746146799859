<div class="account-settings-container">
  <div *ngIf="appService.hasAiCompanySummaryFeature()" (click)="toggleRiskBotChat()" class="risk-bot-btn">
    <img ngSrc="assets/images/riskbot.svg" width="15" height="15" alt="BCS AI Icon"/>
    <div class="btn-text">Riskbot</div>
  </div>
  <div *ngIf="appService.isSelfService" class="notifications-panel-container">
    <app-notifications-panel></app-notifications-panel>
  </div>
  <li class="user-menu" dropdown>
    <a (click)="false" aria-controls="basic-link-dropdown" class="profile-icon-container" dropdownToggle>
      <phx-icon [url]="userAvatarURL" extraClass="circle" size="35" src="avatar-placeholder.png"></phx-icon>
    </a>
    <ul *dropdownMenu aria-labelledby="basic-link" class="dropdown-menu" role="menu">
      <li>
        <a class="dropdown-item first" routerLink="profile/settings">User Settings</a>
      </li>
      <li>
        <a class="dropdown-item" href="https://help.businesscredentialingservices.com/" target="_blank">Help</a>
      </li>
      <li *ngIf="appService.clientType !== 'FREEMIUM'">
        <a [href]="appService.client.clientCertusLink" class="dropdown-item" target="_blank">The BCS Database</a>
      </li>
      <li>
        <a (click)="contactUs(contactUsTemplate)" class="dropdown-item">Contact Us</a>
      </li>
      <li>
        <a (click)="logout()" class="dropdown-item last">Logout</a>
      </li>
    </ul>
  </li>
</div>

<ng-template #contactUsTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Contact us</h4>
    <button (click)="modalRef.hide()" aria-label="Close" class="close pull-right" type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3">
    <div *ngIf="appService.client?.contactPhone" class="row mb-2">
      <div class="col d-flex">
        <phx-icon customClass="standardImage" src="phone-icon"></phx-icon>
        <div>{{ appService.client.contactPhone }}</div>
      </div>
    </div>
    <div *ngIf="appService.client.workflowEmail" class="row mb-2">
      <div class="col d-flex">
        <phx-icon customClass="standardImage" src="mail-icon"></phx-icon>
        <a href="mailto:{{GlobalConstants.CLIENT_SUPPORT_EMAIL}}">{{ GlobalConstants.CLIENT_SUPPORT_EMAIL }}</a>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col d-flex">
        <phx-icon customClass="standardImage" src="globe-icon"></phx-icon>
        <a href="https://help.getbcs.com" target="_blank">help.getbcs.com</a>
      </div>
    </div>
  </div>
</ng-template>
