<div class="session-container">
    <div class="title">Session Information</div>
    <div class="name"><b>Name:</b> {{ decodedToken.name }}</div>
    <div class="user-id"><b>User ID:</b> {{ decodedToken.sub }}</div>
    <div class="role"><b>Role:</b> {{ decodedToken.role }}</div>
    <div class="event-id"><b>Event Id:</b> {{ decodedToken.event_id }}</div>
    <div class="user-email"><b>Email:</b> {{ decodedToken.email }}</div>
    <div class="email-verified">
        <b>Email Verified:</b> {{ decodedToken.email_verified }}
    </div>
    <div class="client-id"><b>Client Id:</b> {{ decodedToken.clientId }}</div>
    <div class="certus-username">
        <b>Certus Username:</b> {{ decodedToken.certusUsername }}
    </div>
    <div class="token"><b>Id Token:</b> {{ decodedToken.token }}</div>
</div>
