import { IBaseModel } from './base-model';
import { UserPermissionsOperation, UserPermissionsSecurable } from '../utils/constants';

export class UserPermissions implements IBaseModel {
    public securables: Map<UserPermissionsSecurable, Array<UserPermissionsOperation>> = new Map();

    constructor() { }

    public fromJSON(json: any): UserPermissions {
        if(!json){
            return this;
        }
        json.map((permission) => {
            const securable = permission["Securable"] ?? permission["securable"];
            const operation = permission["Operation"] ?? permission["operation"];
            if (Object.values(UserPermissionsSecurable).includes(securable) && Object.values(UserPermissionsOperation).includes(operation)) {
                if (this.securables.has(securable)) {
                    this.securables.get(securable).push(operation);
                } else {
                    this.securables.set(securable, [operation]);
                }
            }
        });
        return this;
    }

    public toJSON() {
        return {};
    }

    public hasPermission(securable: UserPermissionsSecurable, operation: UserPermissionsOperation): boolean {
        if (this.securables.has(UserPermissionsSecurable.ALL)) {
            const allowedOperations = this.securables.get(UserPermissionsSecurable.ALL);
            if (allowedOperations.includes(UserPermissionsOperation.ALL) || allowedOperations.includes(operation) || operation == UserPermissionsOperation.READ) {
                return true;
            }
        }
        if (this.securables.has(securable)) {
            const allowedOperations = this.securables.get(securable);
            if (allowedOperations.includes(UserPermissionsOperation.ALL) || operation == UserPermissionsOperation.READ) {
                return true;
            }
            return allowedOperations.includes(operation);
        }
        return false;
    }
}
