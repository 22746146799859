import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MapInfoWindow, MapMarker } from '@angular/google-maps';

export interface Marker {
  vendorId: number,
  vendorName: string,
  position: google.maps.LatLngLiteral
}

@Component({
  selector: 'phoenix-maps',
  templateUrl: 'phoenix-maps.component.html',
  styleUrls: ['./phoenix-maps.component.scss']
})
export class PhoenixMapsComponent {
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;
  @Input('options') options: google.maps.MapOptions = null;
  @Input('markers') markers: Marker[];
  @Input('markerOptions') markerOptions: google.maps.MarkerOptions = null;
  @Input('isClientApp') isClientApp = false;
  @Output('onMarkerClick') onMarkerClick: EventEmitter<Marker> = new EventEmitter<Marker>();

  public markerClicked(event: google.maps.MapMouseEvent, marker: Marker) {
    this.onMarkerClick.emit(marker);
  }
}
