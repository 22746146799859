import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';
import { AccountProfileComponent } from '@capp/app/features/profile-features/account-profile/account-profile.component';

export class AppReuseStrategy implements RouteReuseStrategy {

  storedRoutes: { [key: string]: DetachedRouteHandle } = {};

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    // Assuming that only children of AccountProfileComponent should be reused
    return route.parent && route.parent.component === AccountProfileComponent;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    // Use a compound key for storing child routes
    const path = this.getFullPath(route);
    this.storedRoutes[path] = handle;
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const path = this.getFullPath(route);
    return !!this.storedRoutes[path];
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    const path = this.getFullPath(route);
    return this.storedRoutes[path] || null;
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean {
    // This comparison is necessary to ensure that when navigating between child routes, Angular reuses the route if the future route is the same as the current
    return future.routeConfig === current.routeConfig;
  }

  private getFullPath(route: ActivatedRouteSnapshot): string {
    let path = route.pathFromRoot
        .map(v => v.url.map(segment => segment.toString()).join('/'))
        .join('/');
    return path;
  }
}
