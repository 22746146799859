import { Pipe } from '@angular/core';

@Pipe({
	name: "phone"
})
export class PhonePipe {
	transform(rawNum) {
		let cleaned = ('' + rawNum).replace(/\D/g, '');
		let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
		if (match) {
			let intlCode = (match[1] ? '+1 ' : '');
			return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
		} else {
			return rawNum;
		}
	}
}
