import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PhoenixChatService } from '../../chat.service';

@Component({
    selector: 'phx-conversation-select',
    templateUrl: 'conversation-select.component.html',
    styleUrls: ['./conversation-select.global.scss'],
})
export class ConversationSelectComponent {
    public searchStr: string = '';
    public currentConversation: any;

    @Input() jobPostings: Array<any>;
    @Input() toggle: boolean = true;
    @Input() conversationLoading: boolean = false;
    @Input('isClientApp') isClientApp: boolean = false;

    @Output() conversationSelected = new EventEmitter();
    @Output() groupToggled = new EventEmitter();
    @Output() scrollEnd = new EventEmitter();
    @Output() onSearchStringInput = new EventEmitter();
    constructor(public phoenixChatService: PhoenixChatService) { }

    ngOnChanges() {
        if (this.jobPostings != null) {
            this.jobPostings = this.phoenixChatService.processJobPostings(this.jobPostings);
        }
    }

    public selectConversation(conversation: any) {
        this.conversationSelected.emit(conversation);
    }

    public toggleGroup(group: any) {
        this.groupToggled.emit(group);
    }

    public bidScrolled() {
        this.scrollEnd.emit();
    }

    public searchStringInput() {
        this.onSearchStringInput.emit(this.searchStr);
    }
}
