import { Component, Input } from '@angular/core';
import { NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from "@angular/common";

@Component({
  selector: 'phx-progress-status',
  standalone: true,
  imports: [
    NgIf,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault
  ],
  templateUrl: './phoenix-progress-status.component.html',
  styleUrl: './phoenix-progress-status.component.scss'
})
export class PhoenixProgressStatusComponent {
  @Input() dataRow: any = {};
  @Input() hasProgressStatusId: boolean = false;
  getStatusType(row: any) {
    if(!row.isActive) {
      return 'N/A (Inactive)';
    }
    if(row.isPending) {
      return 'Fee Due';
    }
    switch (row?.certificate_progress_status) {
      case 'No Action Performed':
        return 'No Action Performed';
      case 'Requested Documents':
        return 'Request Sent';
      case 'Analyzing Documents':
        return 'Pending Review';
      case 'Waiting for Revisions':
        return 'Requesting Corrections';
      case 'Completed':
        return 'Complete';
      case 'Requested Renewal':
        return 'Requesting Renewal';
      default:
        return row?.certificate_progress_status;
    }
  }
}
