<div class="notification-wrapper" [class.read]="newsFeedItem?.read">
    <div class="row m-0 w-100">
        <div class="col-auto pl-0">
            <div class="notification-icon"></div>
        </div>
        <div class="col d-flex flex-wrap notification-body">
            <ng-container [ngTemplateOutlet]="notificationTemplate"></ng-container>
        </div>
        <div class="col-auto d-flex pr-0">
            <div class="action hand link" (click)="readNotification.emit(newsFeedItem)">View</div>
            <div class="action hand remove-icon" (click)="removeNotification.emit(newsFeedItem)">X</div>
        </div>
    </div>
</div>

<ng-template #newReviewTemplate>
    <div class="title col-xl-3 col-lg-4 col-md-4 col-sm-4 col-4 p-0">New Review</div>
    <div class="body ml-3">You have a new review from {{newsFeedItem?.clientName}}</div>
</ng-template>

<ng-template #newMessageTemplate>
    <div class="title col-xl-3 col-lg-4 col-md-4 col-sm-4 col-4 p-0">New Message</div>
    <div class="body ml-3">You have a new message from {{newsFeedItem?.clientName}}</div>
</ng-template>

<ng-template #connectionRequestTemplate>
    <div class="title col-xl-3 col-lg-4 col-md-4 col-sm-4 col-4 p-0">Connection Request</div>
    <div class="body ml-3">{{newsFeedItem?.clientName}} wants to add you as a Vendor</div>
</ng-template>
