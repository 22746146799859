import { Component, OnInit } from '@angular/core';
import { PhoenixWebService } from '@capp/providers/web.service';
import { AppService } from '../../services/app.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../../../../phoenix-common/src/lib/auth/auth-service';
import { UserPermissionsService } from '@capp/providers/user-permissions.service';
import { Router } from '@angular/router';
import { UserService } from '@capp/app/services/user.service';

@Component({
  selector: 'sidebar', templateUrl: './sidebar.component.html', styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public modalRef: BsModalRef;

  public image: any;
  public loading: boolean = false;
  public faEdit = faEdit;
  public companyName = '';
  public clientSwitcherClients: { phoenixClientId: number; name: string }[] = [];
  public currentClientId: number;
  public switchClientsDropdownShown: boolean = false;

  constructor(private phoenixWebService: PhoenixWebService, public appService: AppService, private authService: AuthService, public userPermissionsService: UserPermissionsService, public userService: UserService, public router: Router) {
    this.currentClientId = this.appService.phoenixClientId;
    this.companyName = this.appService.clientName;
  }

  async ngOnInit(): Promise<void> {
    await this.loadClientSwitcherClients();
  }

  public async loadClientSwitcherClients() {
    const user = this.authService.getCurrentUser();

    try {
      this.clientSwitcherClients = (await this.phoenixWebService.getClientSwitcherClients())?.availableClients;
    } catch {
      this.clientSwitcherClients = [];
    }

  }

  public showSwitchClientsDropdown() {
    if (this.clientSwitcherClients?.length > 1) {
      this.switchClientsDropdownShown = !this.switchClientsDropdownShown;
    }
  }

  public async switchClients() {
    if (this.currentClientId === null) {
      this.cancelClientSwitch();
      return;
    }
    const confirmResult = await this.appService.confirmModal('Are you sure you want to switch clients?');
    if (!confirmResult) {
      this.cancelClientSwitch();
    } else {
      try {
        await this.phoenixWebService.switchPhoenixClients(this.currentClientId);
        await this.router.navigate(['main', 'my-accounts', 'v']);
        this.authService.refreshSession(true);
      } catch (err) {
        this.appService.showAlertMessage('Failed switching clients.', 'danger');
      }
    }
  }

  public cancelClientSwitch() {
    this.currentClientId = this.appService.phoenixClientId;
    this.switchClientsDropdownShown = false;
  }

  public onFileUpload(file: File) {
    if (!file.name.match(/.(jpg|jpeg|png)$/i)) {
      this.appService.showAlertMessage('Invalid media format! Supported media formats: jpg,jpeg,png', 'danger');
      file = null;
    } else {
      this.image = file;
    }
  }

  public async saveClientInfo() {
    if (this.loading) {
      return;
    }

    this.loading = true;
    let formData: any = new FormData();
    formData.append('companyName', this.companyName);
    formData.append('companyLogo', this.image);

    try {
      await this.phoenixWebService.updateClientInfo(formData);
    } catch (err) {
      this.appService.showAlertMessage(err.error.detail || 'Something went wrong!', 'danger');
    } finally {
      this.loading = false;
      this.modalRef.hide();
    }
  }
}
