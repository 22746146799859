<div class="phx-button" [ngClass]="[color,size,disabled ? 'disabled':'']" (click)="onClick($event)"
     [outsideClick]="dropDown.length ? true : false" [popover]="dropDown.length ? moreBtnPopOver : null"
     containerClass="more-popover w-100"
     placement="bottom" #popover="bs-popover" [style.max-width]="customWidth ? customWidth : null">


  <ng-container *ngIf="!loading">
    <ng-content></ng-content>
    <phx-icon *ngIf="btnIcon" customClass="button" [src]="btnIcon"></phx-icon>

    <a *ngIf="text" class="button-text">{{text}}</a>

    <phx-icon *ngIf="dropDown.length" id="more-btn" src="filled-dropdown-arrow" mr="10" ml="26" size="8"></phx-icon>
  </ng-container>
  <div class="spinner" *ngIf="loading">
    <div class="bounce1"></div>
    <div class="bounce2"></div>
    <div class="bounce3"></div>
  </div>


</div>

<ng-template #moreBtnPopOver>
  <div class="w-100 h-100" style="height: 500px;">
    <div [class]="opt.disabled ? 'dropdown-btn-disabled' : ''"
         class="more-options w-100 hand d-flex align-items-center" *ngFor="let opt of dropDown"
         (click)="clickDropdownOpt(opt)">
      <phx-icon *ngIf="opt.iconClass" src="{{opt.iconClass}}"></phx-icon>
      {{opt.title}}
    </div>
  </div>
</ng-template>
