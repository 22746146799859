<div class="w-100 h-100" *ngIf="isClientApp">
  <google-map [options]="options">
    <ng-container *ngFor="let marker of markers">
      <map-marker #mapMarker="mapMarker" (mapClick)="markerClicked($event, marker)"
                  [position]="marker.position"
                  [title]="marker.vendorName"
                icon="assets/images/marker.svg"
      >
      </map-marker>
    </ng-container>
  </google-map>
</div>

<div class="w-100 h-100" *ngIf="!isClientApp">
  <google-map></google-map>
</div>
