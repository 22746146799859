<nav class="phx-topbar">


  <div class="top-section">

    <div class="top-left" *ngIf="appService.topBarLeftContent">
      <div class="back-to-section" >
        <ng-container *ngTemplateOutlet="appService.backRowContent"></ng-container>
      </div>
      <div class="top-left-section">
        <ng-container *ngTemplateOutlet="appService.topBarLeftContent"></ng-container>
      </div>
    </div>
    <div class="top-middle"  *ngIf="appService.topBarCenterContent">
      <ng-container *ngTemplateOutlet="appService.topBarCenterContent"></ng-container>
    </div>
    <div class="top-right" >
      <account-settings></account-settings>
    </div>
  </div>
  <div class="bottom-section" *ngIf="appService.topBarTabContent">
    <ng-container *ngTemplateOutlet="appService.topBarTabContent"></ng-container>

    <div class="bottom-middle-section" *ngIf="appService.topBarBottomMiddleContent">
      <ng-container *ngTemplateOutlet="appService.topBarBottomMiddleContent"></ng-container>
    </div>

  </div>

</nav>
