import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'metersToMiles' })
export class MetersToMilesPipe implements PipeTransform {

  transform(meters: number): number {
    if(meters) {
      return Math.round(meters * 0.000621371192);
    }
    return 0;
  }
}
