import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {PhoenixWebService} from "@capp/providers/web.service";
import { HttpHeaders } from "@angular/common/http";


@Injectable({ providedIn: "root" })
export class NavigationService {

  constructor( public router: Router,
               public phoenixWebservice: PhoenixWebService) {
  }



  get clientType() {

    let pathSegments = this.router.routerState.snapshot.url.toLowerCase().split("/");

    if (pathSegments.indexOf('t') > -1) {
      return "t";
    } else if (pathSegments.indexOf('tenant-lease') > -1) {
      return  "t"
    }

    return "v";
  }
  async navigateToAccountCompliance(companyId, clientType = this.clientType) {
    await this.router.navigate(this.getAccountParams(companyId, clientType, ['compliance']));
  }

  getAccountParams(companyId, clientType, extraParams = []) {
    let accountNav = ['main', 'my-accounts', clientType, 'account-profile', companyId];
    accountNav.push(...extraParams); // Use spread syntax to add items individually
    return accountNav;
  }
  async navigateToAccountDocuments(companyId, clientType = this.clientType) {
    await this.router.navigate(this.getAccountParams(companyId, clientType, ['documents']));
  }

  async navigateToAccountProfile(companyId, clientType = this.clientType){
    await this.router.navigate(this.getAccountParams(companyId, clientType, ['info']));
  }

  async navigateToAccountProfileByVendorId(recordId, isVendor){
    let companyId = await this.getCompanyIdFromNetworkId(recordId, isVendor);
    await this.navigateToAccountProfile(companyId, isVendor ? "v" : "t");
  }

  async navigateToAccountDocumentsByVendorId(recordId, isVendor){
    let companyId = await this.getCompanyIdFromNetworkId(recordId, isVendor);
    await this.navigateToAccountDocuments(companyId, isVendor ? "v" : "t");
  }

  async navigateToAccountComplianceByVendorId(recordId, isVendor){
    let companyId = await this.getCompanyIdFromNetworkId(recordId, isVendor);
    await this.router.navigate(this.getAccountParams(companyId, isVendor ? "v" : "t", ['compliance']));
  }


  async getCompanyIdFromNetworkId(recordId, isVendor) {
    let companyId;

    let result = null;
    let keepAttemptingToGetVendor = true;
    let attempts = 5;

    while (keepAttemptingToGetVendor && attempts > 0) {
      try {
        if (isVendor) {
          result = await this.phoenixWebservice.getCompanyIdByVendorId(recordId);
        } else {
          result = await this.phoenixWebservice.getCompanyIdByTenantId(recordId);
        }
      } catch (error) {
        //not yet there...
      }
      if (result && result[0] && result[0].hasOwnProperty('companyId')) {
        companyId = result[0].companyId;
        keepAttemptingToGetVendor = false;
      } else {
        attempts++;
        await new Promise(_ => setTimeout(_, 1000));
      }
    }

    return companyId;
  }

  async navigateToSearchPage(clientType = this.clientType) {
    await this.router.navigate(['main', 'my-accounts', clientType]);
  }



}
