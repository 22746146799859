import { AfterViewInit, Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'phx-counter',
  templateUrl: './phoenix-counter.component.html',
  styleUrls: ['./phoenix-counter.component.scss']
})
export class PhoenixCounterComponent implements AfterViewInit {
  @ViewChild('phxCounter', { static: false }) counterRef!: ElementRef;
  @ViewChild('hoverTextContainer', { static: false }) hoverTextContainerRef!: ElementRef;

  @Input() counter = 0;
  @Input() hoverMessageText: string;
  @Input() counterContainerStyles: {
    [key: string]: string
  } | null | undefined;
  @Input() counterStyles: {
    [key: string]: string
  } | null | undefined;

  constructor(private renderer: Renderer2) {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.hoverTextContainerRef?.nativeElement) {
        this.attachHoverEvents();
      }
    }, 1000);
  }

  private attachHoverEvents(): void {
    if (this.counterRef?.nativeElement) {
      this.renderer.listen(this.counterRef.nativeElement, 'mouseenter', () => {
        if (this.hoverMessageText?.length > 0 && this.hoverTextContainerRef?.nativeElement) {
          this.hoverTextContainerRef.nativeElement.style.display = 'block';
        }
      });

      this.renderer.listen(this.counterRef.nativeElement, 'mouseleave', () => {
        if (this.hoverMessageText?.length > 0 && this.hoverTextContainerRef?.nativeElement) {
          this.hoverTextContainerRef.nativeElement.style.display = 'none';
        }
      });
    }
  }
}
