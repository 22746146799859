import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../../phoenix-common/src/lib/auth/auth-service';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from '@capp/app/services/navigation.service';
import { PhoenixCommonModule } from 'phoenix-common';

@Component({
  selector: 'app-sso',
  standalone: true,
  imports: [
    PhoenixCommonModule
  ],
  templateUrl: './sso.component.html',
  styleUrl: './sso.component.scss'
})
export class SSOComponent implements OnInit {
  code: string;
  state: string;
  result: any;
  private readonly cognitoAppClientId: string;

  constructor(private activatedRoute: ActivatedRoute,
              private authService: AuthService,
              private navigationService: NavigationService) {
    const snapshot = this.activatedRoute.snapshot;
    this.cognitoAppClientId = snapshot.params?.cognitoAppClientId;
    this.code = snapshot.queryParams?.code;
    this.state = snapshot.queryParams?.state;
  }

  async ngOnInit(): Promise<void> {
    await this.authService.authenticateWithAccessCode(this.code, this.state, this.cognitoAppClientId);
    await this.navigationService.navigateToSearchPage('v');
  }
}
