import { Injectable } from '@angular/core';
import { PhoenixChatService } from 'phoenix-common';
import { AuthService } from '../../../../phoenix-common/src/lib/auth/auth-service';
import { AuthenticatedUser } from '../../../../phoenix-common/src/lib/shared/models/authenticated-user-model';

@Injectable({ providedIn: 'root' })
export class AuthenticatedUserService {
  public user: any;
  public userAvatar: any;

  constructor(private phoenixChatService: PhoenixChatService, private authService: AuthService) {
    const user = this.authService.getCurrentUser();
    this.user = user;
    const userId = user ? user['cognitoId'] : null;
    if (userId) {
      this.phoenixChatService.setSelfIdentity({ 'userId': userId });
    }
  }

  public getLoggedUser(): AuthenticatedUser {
    return this.authService.getCurrentUser();
  }
  public async getName(): Promise<string> {
    const user = await this.getLoggedUser();
    return user?.name;
  }

  public async getPhone() {
    const user = await this.getLoggedUser();
    return user?.phone;
  }

  public async getEmail() {
    const user = await this.getLoggedUser();
    return user?.email;
  }

  public async getCertusUserName() {
    const user = await this.getLoggedUser();
    return user?.certusUsername;
  }

  public async getTitle() {
    const user = await this.getLoggedUser();
    return user?.title;
  }

  public async getPhoenixClientId() {
    const user = await this.getLoggedUser();
    return user?.id;
  }

  public async getCertusSystemUserId() {
    const user = await this.getLoggedUser();
    return parseInt(user?.certusSystemUserId);
  }

  public async getCognitoId() {
    const user = await this.getLoggedUser();
    return user?.cognitoId;
  }

  get userAvatarSrc() {
    return this.userAvatar;
  }

  public setAvatarUrl(url: string) {
    this.userAvatar = url;
  }
}
