import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { PhoenixWebService } from '@capp/providers/web.service';
import { faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {
  FileDataToProcess,
  ImportedFileDataToProcess
} from '@capp/app/features/modal-features/bulk-import/coi-list-modal/bulk-import-list.component';
import { NavigationService } from '@capp/app/services/navigation.service';
import { AppService } from '@capp/app/services/app.service';

@Component({
  selector: 'app-create-company-table',
  templateUrl: './create-company-table.component.html',
  styleUrls: ['./create-company-table.component.scss']
})
export class CreateCompanyTableComponent implements OnInit {
  // initialState parameters from CoiListComponent
  isVendor: boolean;
  importedFileDataToProcesses: ImportedFileDataToProcess;
  bulkImportProcessItemId: number;

  loader = false;
  hoveringSrc: string = null;
  faFileInvoice = faFileInvoice;
  locationsList: any;
  requirementsList = [];
  locationNames = {};
  importCompanyForm = new FormGroup({
    importCompanyArray: new FormArray([])
  });
  companyLabelPlural: string;
  accountLabelPlural: string;
  companyLabelSingular: string;
  accountLabelSingular: string;

  constructor(public bsModalRef: BsModalRef,
              private phoenixWebService: PhoenixWebService,
              private appService: AppService,
              private navigationService: NavigationService) {
  }

  get importCompanyArray(): FormArray {
    return this.importCompanyForm.get('importCompanyArray') as FormArray;
  }

  async ngOnInit(): Promise<void> {
    this.loader = true;
    await this.loadRequirements();
    await this.loadLocations();
    await this.buildForm();
    this.companyLabelPlural = this.appService.pluralLabel('Company', this.isVendor);
    this.accountLabelPlural = this.appService.pluralLabel('Certificate', this.isVendor);
    this.companyLabelSingular = this.appService.label('Company', this.isVendor);
    this.accountLabelSingular = this.appService.label('Certificate', this.isVendor);
    this.loader = false;
  }

  public async buildForm(): Promise<void> {
    this.importedFileDataToProcesses?.fileDataToProcess?.forEach(file => {
      const companyExists = !!(file?.companyId ? file.companyId : null);
      const certificateDataIsPresent = Array.isArray(file?.certificateData) && file?.certificateData.length > 0;
      const isIgnored = file?.ignored ? file?.ignored : false;
      const importCompanyFormGroup = this.buildFormGroup(certificateDataIsPresent, isIgnored, file);

      this.setValidatorsAndDisabledState(companyExists, certificateDataIsPresent, importCompanyFormGroup);

      if (isIgnored) {
        this.setIgnoredRow(importCompanyFormGroup);
      }

      this.importCompanyArray.push(importCompanyFormGroup);
    });
  }

  public async loadRequirements(): Promise<void> {
    this.requirementsList = this.importedFileDataToProcesses?.allInsuranceRequirementCategories;
  }

  public async loadLocations(): Promise<void> {
    this.locationsList = this.importedFileDataToProcesses?.allLocations;
  }

  setHoverSrc(src: any): void {
    this.hoveringSrc = src;
  }

  public onClose(): void {
    this.bsModalRef.hide();
  }

  public async createRecords(): Promise<void> {
    if (!this.importCompanyForm.valid) {
      this.appService.showAlertMessage('Missing Data', 'danger');
      return;
    }
    this.loader = true;
    try {
      const params = this.importCompanyArray.value;
      await this.phoenixWebService.bulkCompanyImport(params, this.bulkImportProcessItemId);
      this.appService.showAlertMessage('Request received', 'success');
      this.bsModalRef.hide();
      await this.navigationService.navigateToSearchPage('v');
    } catch (err) {
      const errorMessage = err?.error?.detail ?? 'Something went wrong';
      this.appService.showAlertMessage(errorMessage, 'danger');
    } finally {
      this.loader = false;
    }
  }

  shouldGreyOutReqList(file: AbstractControl): boolean {
    return Array.isArray(file?.value.certificateData) &&
      file?.value.certificateData.length > 0 &&
      file.value.certificateData.every((c: any) => c.certificateId > 0);
  }

  private buildFormGroup(certificateDataIsPresent: boolean,
                         isIgnored: boolean,
                         file: FileDataToProcess): FormGroup {
    const companyId = file?.companyId ? file.companyId : null;
    const accountId = file?.accountId ? file.accountId : null;
    const documentTypeData = file?.documentType ? file.documentType : null;
    const certificateData = certificateDataIsPresent
      ? file.certificateData
      : [{ certificateId: 0 }] as any[];
    const fileName = file?.fileName ? file.fileName : null;
    const fileSource = file?.fileSource ? file.fileSource : null;
    const thumbnailSource = file?.thumbnailSource ? file.thumbnailSource : null;
    const certusFileId = file?.certusFileId ? file.certusFileId : null;
    const companyName = file?.companyName ? file.companyName : null;
    const contactName = file?.contactName ? file.contactName : null;
    const contactEmail = file?.contactEmail ? file.contactEmail : null;
    const contactPhone = file?.contactPhone ? file.contactPhone : null;
    const certificateName = certificateDataIsPresent
      ? certificateData.map(c => c.certificateName)
      : null;
    const reqCategory = certificateDataIsPresent ? certificateData.map(c => {
      return c?.insReqCategoryId;
    })[0] : null;
    const locations = file?.locations ? file?.locations : null;

    return new FormGroup({
      companyId: new FormControl(companyId),
      accountId: new FormControl(accountId),
      documentTypeData: new FormControl(documentTypeData),
      certificateData: new FormControl(certificateData),
      fileName: new FormControl(fileName),
      fileSource: new FormControl(fileSource),
      thumbnailSource: new FormControl(thumbnailSource),
      certusFileId: new FormControl(certusFileId),
      companyName: new FormControl(companyName),
      contactName: new FormControl(contactName),
      contactEmail: new FormControl(contactEmail),
      contactPhone: new FormControl(contactPhone),
      certificateName: new FormControl(certificateName),
      reqCategory: new FormControl(reqCategory),
      locations: new FormControl(locations),
      isIgnored: new FormControl(isIgnored)
    });
  }

  private setValidatorsAndDisabledState(companyExists: boolean,
                                        certificateDataIsPresent: boolean,
                                        importCompanyFormGroup: FormGroup): void {
    if (companyExists) {
      importCompanyFormGroup.get('companyName').disable();
      importCompanyFormGroup.get('contactName').disable();
      importCompanyFormGroup.get('contactEmail').disable();
      importCompanyFormGroup.get('contactPhone').disable();
    } else {
      importCompanyFormGroup.get('companyName').setValidators(Validators.required);
      importCompanyFormGroup.get('contactName').setValidators(Validators.required);
      importCompanyFormGroup.get('contactEmail').setValidators(Validators.required);
      importCompanyFormGroup.get('contactPhone').setValidators(
        [
          Validators.pattern('[- +()0-9]+'),
          Validators.required,
          Validators.minLength(10)
        ]);
      importCompanyFormGroup.get('reqCategory').setValidators(Validators.required);
      importCompanyFormGroup.get('locations').setValidators(Validators.required);
    }
    if (certificateDataIsPresent) {
      importCompanyFormGroup.get('certificateName').setValidators(null);
      importCompanyFormGroup.get('reqCategory').setValidators(null);
      importCompanyFormGroup.get('locations').setValidators(null);
      importCompanyFormGroup.get('certificateName').disable();
    } else {
      importCompanyFormGroup.get('certificateName').setValidators(Validators.required);
      importCompanyFormGroup.get('reqCategory').setValidators(Validators.required);
      importCompanyFormGroup.get('locations').setValidators(Validators.required);
    }
  }

  private setIgnoredRow(importCompanyFormGroup: FormGroup): void {
    importCompanyFormGroup.get('contactName').setValidators(null);
    importCompanyFormGroup.get('contactEmail').setValidators(null);
    importCompanyFormGroup.get('contactPhone').setValidators(null);
    importCompanyFormGroup.get('certificateName').setValidators(null);
    importCompanyFormGroup.get('reqCategory').setValidators(null);
    importCompanyFormGroup.get('locations').setValidators(null);
    importCompanyFormGroup.get('companyName').disable();
    importCompanyFormGroup.get('contactName').disable();
    importCompanyFormGroup.get('contactEmail').disable();
    importCompanyFormGroup.get('contactPhone').disable();
    importCompanyFormGroup.get('certificateName').disable();
    importCompanyFormGroup.get('reqCategory').disable();
    importCompanyFormGroup.get('locations').disable();
    importCompanyFormGroup.get('companyName').disable();
  }
}
