<div id="bcs-risk-bot-container">
  <div (click)="closeChatBot()" class="chat-bot-close">×</div>
  <div class="risk-bot-header">
    <div class="title-container">
      <phx-icon customClass="standardImage" mb="10" mr="0" size="40" src="riskbot-ai"></phx-icon>
      <h1 class="title">riskbot</h1>
    </div>
  </div>
  <h6 class="greeting-text">How can bcs riskbot help you?</h6>
  <div *ngIf="this.riskBotService.companyId == undefined && this.riskBotService.certificateId == undefined" class="no-company-message">
    <i>Please select an item marked with </i>
    <phx-icon customClass="standardImage" ml="10" size="20" src="riskbot-summary.svg"></phx-icon>
  </div>
  <div *ngIf="riskBotService.isInitialRiskBotLoad" class="chat-bot-content">
    <phx-loader [useCustomLoader]="true" size="medium"></phx-loader>
  </div>
  <div *ngIf="!riskBotService.isInitialRiskBotLoad" class="chat-bot-content">
    <div *ngIf="hasThreadInfo()" class="thread-info">
      <div><b>{{ 'Company' | singular: riskBotService?.isVendor }}:</b> {{ retrieveThreadInfo()?.companyName }}</div>
      <div><b>{{ 'Certificate' | singular: riskBotService?.isVendor }}:</b> {{ retrieveThreadInfo()?.certificateName }}</div>
      <div><b>Last Updated:</b> {{ retrieveThreadInfo()?.lastUpdatedDate | date }}</div>
    </div>
    <div *ngFor="let message of retrieveMessageThread(); let i = index;" [id]="'message-' + i"
         class="message-container">
      <div *ngIf="message.question != null" class="user-message">
        <div class="user">user</div>
        <p class="message">{{ message.question }}</p>
      </div>
      <div *ngIf="message.answer != null">
        <div class="user">riskbot</div>
        <p [innerHtml]="message.answer" class="message"></p>
      </div>
    </div>
    <div *ngIf="riskBotService.aiIsThinking">
      <phx-loader [useCustomLoader]="true" size="small"></phx-loader>
    </div>
  </div>
  <div class="risk-bot-footer">
    <div class="ai-search-container">
      <div (click)="focusOrBlurRiskBotChatInput()" [ngClass]="isInputDisabled() ? 'disabled' : ''" class='chat-input'>
        <input (keydown)="sendMessageToChatBotViaKeypress($event);" [(ngModel)]="inputText"
               id="risk-bot-chat-input" placeholder="Type a message"
               type='text' />
        <div (click)="sendMessageToChatBot();" class="send-btn">
          <phx-icon customClass="standardImage" h="16" mb="10" mr="0" src="send-icon" w="17"></phx-icon>
        </div>
      </div>
    </div>
    <div class="terms-text">
      AI-generated content may be inaccurate.
        <a class="bcs-link" href="https://www.getbcs.com/legal">See terms</a>
    </div>
  </div>
</div>
