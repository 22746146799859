import { HttpClientModule } from '@angular/common/http';
import { isDevMode, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ClientWebappCommonModule } from './common/client-webapp-common.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from '@capp/app/app-components/app-root/app.component';
import { MainComponent } from '@capp/app/app-components/main/main.component';
import { NotificationsPanelComponent } from '@capp/app/app-components/notifications-panel/notifications-panel.component';
import { SidebarComponent } from '@capp/app/app-components/sidebar/sidebar.component';
import { TopBarComponent } from '@capp/app/app-components/top-bar/top-bar.component';
import { SessionComponent } from '@capp/app/app-components/session/session.component';
import { AccountSettingsComponent } from '@capp/app/app-components/account-settings/account-settings.component';
import { RouteReuseStrategy } from '@angular/router';
import { AppReuseStrategy } from '@capp/app/routestrategies/app-reuse.strategy';
import { ErrorPageComponent } from '@capp/app/app-components/error-page/error-page.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ENVIRONMENT } from '../../../phoenix-common/src/lib/tokens/environment.token';
import { environment } from '@capp/environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    TopBarComponent,
    SidebarComponent,
    MainComponent,
    NotificationsPanelComponent,
    SessionComponent,
    AccountSettingsComponent,
    ErrorPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ClientWebappCommonModule,
    BsDropdownModule.forRoot(),
    AlertModule.forRoot(),
    BrowserAnimationsModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(), // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: AppReuseStrategy
    },
    {
      provide: ENVIRONMENT,
      useValue: environment
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
