<div class="stepper-wrapper">
    <div class="step-container" [class.completed]="currentStep > 0">
        <div [class.active]="currentStep > 1" [class.current-step]="currentStep == 1" class="icon connection-accepted"></div>
        <div class="step-title">1. Connection accepted</div>
    </div>
    <div class="line" [class.completed]="currentStep > 1"></div>
    <div class="step-container" [class.completed]="currentStep > 1">
        <div [class.active]="currentStep > 2" [class.current-step]="currentStep == 2" class="icon registration-completed"></div>
        <div class="step-title">2. Registration Completed</div>
    </div>
    <div class="line" [class.completed]="currentStep > 2"></div>
    <div class="step-container" [class.completed]="currentStep > 2">
        <div [class.active]="currentStep > 3" [class.current-step]="currentStep == 3" class="icon compliance"></div>
        <div class="step-title">3. Compliance Review</div>
    </div>
    <div class="line" [class.completed]="currentStep > 3"></div>
    <div class="step-container" [class.completed]="currentStep > 3">
        <div [class.active]="currentStep > 4" [class.current-step]="currentStep == 4" class="icon waiver-request"></div>
        <div class="step-title">4. Waiver Request</div>
    </div>
    <div class="line" [class.completed]="currentStep > 4"></div>
    <div class="step-container" [class.completed]="currentStep > 4">
        <div [class.active]="currentStep == 5" class="icon complete"></div>
        <div class="step-title">5. Complete</div>
    </div>
</div>
