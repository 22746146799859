import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { AppService } from '@capp/app/services/app.service';
import { AdditionalInsuredEntity } from '@capp/app/shared/models/interfaces/LocationSettingInfo';

/**
 * Component for managing additional insured entities form.
 *
 * @example
 * <phx-additional-insured-entities-form
 *    [additionalInsuredEntities]="entityArray"
 *    [disabled]="false">
 * </phx-additional-insured-entities-form>
 */
@Component({
  selector: 'phx-additional-insured-entities-form',
  templateUrl: './additional-insured-entities-form.component.html',
  styleUrls: ['./additional-insured-entities-form.component.scss']
})
export class AdditionalInsuredEntitiesFormComponent implements OnInit {
  /** List of additional insured entities */
  @Input() additionalInsuredEntities: AdditionalInsuredEntity[] = [];

  /** Indicates whether the form is disabled */
  @Input() disabled: boolean;

  /** Indicates if there is an error in the form */
  public hasError: boolean;

  /** Stores a copy of the original additional insured entities for reset purposes */
  protected additionalInsuredEntitiesCopy: AdditionalInsuredEntity[] = [];

  /**
   * Constructor to initialize the component.
   * @param appService Service for handling application-wide UI interactions.
   */
  constructor(private appService: AppService) {
  }

  /**
   * Lifecycle hook - initializes the component and stores a copy of the entities.
   */
  ngOnInit(): void {
    const jsonEntities = JSON.stringify(this.additionalInsuredEntities);
    this.additionalInsuredEntitiesCopy = jsonEntities ? JSON.parse(jsonEntities) : [];
  }

  /**
   * Retrieves all additional insured entities.
   * @returns The list of additional insured entities or null if validation fails.
   */
  public getAllAdditionalInsuredEntities() {
    const areThereAnyEmptyFields: boolean = this.additionalInsuredEntities?.filter(entity => entity.name?.length === 0)?.length > 0;
    if (areThereAnyEmptyFields) {
      this.appService.showAlertMessage('Please fill in all entity fields', 'danger');
      this.hasError = true;
      return null;
    }
    this.hasError = false;
    return this.additionalInsuredEntities;
  }

  /**
   * Resets the additional insured entities list to its original state.
   */
  public resetEntities() {
    this.additionalInsuredEntities = JSON.parse(JSON.stringify(this.additionalInsuredEntitiesCopy));
  }

  /**
   * Adds a new additional insured entity.
   */
  protected addEntity() {
    (this.additionalInsuredEntities ??= []).push({
      id: null,
      name: '',
      deleted: false
    });
  }

  /**
   * Handles changes to an entity's name.
   * @param text The updated text.
   * @param entity The additional insured entity being updated.
   */
  protected onEntityNameChange(text: any,
                               entity: AdditionalInsuredEntity) {
    entity.name = text;
  }

  /**
   * Deletes an entity. If the entity has an ID, it is marked as deleted; otherwise, it is removed from the list.
   * @param entity The additional insured entity to be deleted.
   */
  protected deleteEntity(entity: AdditionalInsuredEntity) {
    if (entity.id != null) {
      entity.deleted = true;
    } else {
      this.additionalInsuredEntities = this.additionalInsuredEntities?.filter(e => e !== entity);
    }
  }
}
