import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faFileAlt, faFilePdf, faPaperclip, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { UploaderOptions, UploadFile, UploadInput, UploadOutput } from 'ngx-uploader';
import { Subscription } from 'rxjs';
import { Message, Participant } from '@twilio/conversations';
import { AppIdentifier, Constants } from '../../../utils/constants';
import { PhoenixChatService } from '../../chat.service';
import { ChatMessage, ChatMessageType } from '../../models/chat-message-model';

@Component({
    selector: 'phx-message-panel',
    templateUrl: './message-panel.component.html',
    styleUrls: ['./message-panel.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PhoenixMessagePanelComponent {
    public chatConversationLoaded: boolean = false;

    public directMessage: boolean = true;

    public currentConversation: any = null;

    @ViewChild('chatMessageContainer', { static: false }) chatMessageContainer: ElementRef;
    @Output('onBudgetSave') onBudgetSave: EventEmitter<any> = new EventEmitter<any>();
    @Output('onImageLoaded') onImageLoaded: EventEmitter<any> = new EventEmitter<any>();
	@Input() readOnlyMode = false;
	@Input() isClientApp = false;

    public faPaperPlane = faPaperPlane;
    public faPaperclip = faPaperclip;
    public faFilePdf = faFilePdf;
    public faFileAlt = faFileAlt;

    public selectedLocation: Location = null;
    public senderIdentity: any;
    public newMessageText: string = "";
    public typing: boolean = false;
    public typingTimeOut;
    public typingMessage: ChatMessage;
    public vendor: any;
    public closedJobPostings: boolean = false;
    public budgetChanged: boolean = false;
    public appIdentifier = AppIdentifier;
    public budgetRangeLow: number;
    public budgetRangeHigh: number;
    public fiveHours: number = 1000 * 60 * 60 * 5;
    public uploadMode: boolean = false;
    public uploadFileSelected:boolean = false;
    public uploadFileName: string;
    public mediaUrl: string = '';

    public chatMessageSubscription: Subscription;
    public chatMessageHistorySubscription: Subscription;
    public currentConversationSubscription: Subscription;
    public chatTypingSubscription: Subscription;

    //Uploader
    public currentFile: UploadFile;
    public dragOver: boolean;
    public options: UploaderOptions;
    public uploadInput: EventEmitter<UploadInput> = new EventEmitter<UploadInput>();

    public messages: Array<ChatMessage> = []

    constructor(public phoenixChatService: PhoenixChatService,
        private changeDetectionRef: ChangeDetectorRef,
        private route: ActivatedRoute,
        private router: Router) {
        this.directMessage = !this.route.snapshot.params.jobId;
        this.options = {
            concurrency: Number.POSITIVE_INFINITY,
            allowedContentTypes: Constants.ALLOWED_FILE_TYPES
        }

        this.currentConversationSubscription = this.phoenixChatService.currentConversationSource.subscribe((conversation) => {
            if (conversation) {
                if (this.router.url.indexOf("/closed-job-postings") > -1) {
                    this.closedJobPostings = true;
                }
                this.currentConversation = conversation;
                this.budgetRangeLow = this.currentConversation.budgetRangeLow;
                this.budgetRangeHigh = this.currentConversation.budgetRangeHigh;
                this.chatConversationLoaded = false;
                this.vendor = this.currentConversation ? this.currentConversation.vendor : null;
                this.phoenixChatService.getConversationById(this.currentConversation.conversationId);
            }
        })
        this.chatMessageSubscription = this.phoenixChatService.chatMessageObservable.subscribe(this.onChatMessageReceived.bind(this));
        this.chatMessageHistorySubscription = this.phoenixChatService.chatMessageHistoryObservable.subscribe(this.onChatMessageHistoryReceived.bind(this));
        this.chatTypingSubscription = this.phoenixChatService.typingObservable.subscribe(this.setTypeIndicator.bind(this));

        if (!this.phoenixChatService.connected && !this.phoenixChatService.connecting) {
            this.phoenixChatService.connect(true);
        }
    }

    ngAfterViewInit() {
        this.scrollToBottom();
    }

    ngOnDestroy() {
        this.phoenixChatService.removeAllListeners();
        this.chatMessageHistorySubscription.unsubscribe();
        this.chatMessageSubscription.unsubscribe();
        this.chatTypingSubscription.unsubscribe();
        this.currentConversationSubscription.unsubscribe();
    }

    public setTypeIndicator(member: Participant) {
        if (this.typingTimeOut) {
            clearTimeout(this.typingTimeOut);
        }
        this.typing = true;
        const user = this.phoenixChatService.channelMembersMap[member.identity];
        this.typingMessage = new ChatMessage().fromJSON({
            sender: { id: member.identity, ...this.getSenderInfo(user) },
            content: "",
            timestamp: "",
            type: ChatMessageType.ChatMessageTyping,
            twilioChatMessage: null
        })
        this.changeDetectionRef.detectChanges();

        this.typingTimeOut = setTimeout(() => {
            this.typing = false;
            this.typingMessage = null;
            this.scrollToBottom();
        }, 4000);
        this.changeDetectionRef.detectChanges();
    }

    public onTyping() {
        this.phoenixChatService.sendTyping();
        this.phoenixChatService.activeChannel.typing();
    }

    public toggleGroup(jobPostingGroupd) {
        if (jobPostingGroupd) {
            jobPostingGroupd.expand = !jobPostingGroupd.expand;
        }
    }

    public sendMessage() {
        if (this.newMessageText) {
            this.phoenixChatService.sendMessage(this.newMessageText);
            this.newMessageText = "";
        }
        if (this.uploadFileSelected) {
            this.uploadFile();
            this.newMessageText = "";
            this.uploadFileSelected = false;
            this.uploadMode = false;
        }
    }

    public async onChatMessageHistoryReceived(messages: Array<Message>) {
        this.chatConversationLoaded = true;
        this.messages = [];
        for (let message of messages) {
            this.onChatMessageReceived(message);
        }
        this.changeDetectionRef.detectChanges();
        this.scrollToBottom();
    }

    onUploadOutput(output: UploadOutput): void {
        if (output.type === 'allAddedToQueue') {
            // when all files added in queue
        } else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') { // add file to array when added

            this.currentFile = output.file;

            this.uploadFileName = output.file.name;
            this.uploadFileSelected = true;
            this.uploadMode = true;

        } else if (output.type === 'rejected' && typeof output.file !== 'undefined') {
            alert("Unknown file type");
            return;
        } else if (output.type === 'dragOver') {
            this.dragOver = true;
        } else if (output.type === 'dragOut') {
            this.dragOver = false;
        } else if (output.type === 'drop') {
            this.dragOver = false;
        }
    }

    imagePreview(e) {
        const file = (e.target as HTMLInputElement).files[0];
        const reader = new FileReader();

        reader.onload = () => {
            this.mediaUrl = reader.result as string;
        }
        reader.readAsDataURL(file);
    }

    public async uploadFile() {
        if (this.currentFile) {
            this.phoenixChatService.sendFileMessage(this.currentFile);
        }
    }

    public cancelUpload() {
        this.newMessageText = "";
        this.uploadFileSelected = false;
        this.uploadMode = false;
    }

    public onChatMessageReceived(message: any) {
        clearTimeout(this.typingTimeOut);
        this.typing = false;
        this.typingMessage = null;
        this.scrollToBottom();
        const user = this.phoenixChatService.channelMembersMap[message.author];
        if (message.type === 'media') {
            //In case we have to handle media in specific way
            this.messages.push(new ChatMessage().fromJSON({
                sender: { id: message.author, ...this.getSenderInfo(user) },
                content: "Media message",
                timestamp: message.dateCreated,
                type: ChatMessageType.ChatMessageFile,
                twilioChatMessage: message
            }))
        } else {
            //Other messages
            this.messages.push(new ChatMessage().fromJSON({
                sender: { id: message.author, ...this.getSenderInfo(user) },
                content: message.body,
                timestamp: message.dateCreated,
                type: ChatMessageType.ChatMessageText,
                twilioChatMessage: message
            }))
            if (message.attributes["type"] == "change-quota") {
                this.currentConversation.budgetRangeLow = message.attributes['low']
                this.currentConversation.budgetRangeHigh = message.attributes['high']
                this.budgetRangeLow = this.currentConversation.budgetRangeLow;
                this.budgetRangeHigh = this.currentConversation.budgetRangeHigh;
            }
        }
        this.scrollToBottom();
    }



    public scrollToBottom() {
        if (this.chatMessageContainer) {
            setTimeout(() => {
                if (this.chatMessageContainer && this.chatMessageContainer.nativeElement) {
                    this.chatMessageContainer.nativeElement.scrollTop = this.chatMessageContainer.nativeElement.scrollHeight;
                }
            }, 200);
        }
    }

    public getSenderInfo(sender: any) {
        let senderInfo: any;
        const incoming = this.phoenixChatService?.twilioUser?.identity != sender?.identity;
        if (!incoming) {
            senderInfo = { 'name': this.phoenixChatService.selfIdentity.name || 'N/A', 'avatarUrl': this.phoenixChatService.selfIdentity.logoUrl || "/projects/phoenix-common/src/lib/assets/images/profile-placeholder.png" };
        } else {
            if (this.phoenixChatService.appIdentifier == AppIdentifier.CLIENT) {
                senderInfo = { 'name': this.currentConversation.vendor.name || 'N/A', 'avatarUrl': this.currentConversation?.vendor?.logoUrl || "/projects/phoenix-common/src/lib/assets/images/profile-placeholder.png" };
            } else if (this.phoenixChatService.appIdentifier == AppIdentifier.VENDOR) {
                senderInfo = { 'name': this.currentConversation?.client?.name || 'N/A', 'avatarUrl': this.currentConversation?.client?.config[0]?.logoUrl || "/projects/phoenix-common/src/lib/assets/images/profile-placeholder.png" };
            }
        }
        return senderInfo;
    }

    public onBudgetChange() {
        this.budgetChanged = true;
    }

    public saveBudget() {
        this.budgetChanged = false;
        this.onBudgetSave.emit({ budgetRangeLow: this.budgetRangeLow, budgetRangeHigh: this.budgetRangeHigh })
    }
}
