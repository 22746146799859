<ng-container *ngIf="isLoading">
  <phx-loader class="fullscreen-loader" size="big"></phx-loader>
</ng-container>

<div *ngIf="canSeeNotifications()" class="ml-3 mr-3">
    <phx-icon customClass="standardImage pointer shake" src="notification-bell" size="34" (click)="showNotifications = !showNotifications">
        <div class="notification-dot" *ngIf="bulkImportProcessItems?.length > 0">{{bulkImportProcessItems?.length}}</div>
    </phx-icon>
</div>
<div *ngIf="showNotifications" class="sidebar">
    <div class="notifications-container">
        <span class="bold ml-4">Notifications</span>
        <button type="button" class="close pull-right mr-2" aria-label="Close"
            (click)="showNotifications = !showNotifications">
            <span aria-hidden="true" class="mr-2 font-weight-normal" style="font-size: 35px;">&times;</span>
        </button>
    </div>
    <div class="scroll-notifications w-100">
        <div class="date-container" *ngIf="bulkImportProcessItems?.length > 0">
            <span class="bold">Bulk Import Processes*</span>
        </div>
        <div class="description-container hand" *ngFor="let bulkImportItem of bulkImportProcessItems"
            (click)="bulkImportProcessItemClick(bulkImportItem)">
            <div class="tiny-text mt-2 d-flex">
                <div class="mr-2"> {{ bulkImportItem.createdAt | date: 'MM/dd/yyyy hh:mm'}}</div>
                <div *ngIf="bulkImportItem.uploaderName"> Uploaded by: {{ bulkImportItem.uploaderName }}</div>
            </div>
            <span>
              {{ bulkImportItem.processedFiles}}
              {{ bulkImportItem.status === bulkImportProcessStatuses.READY ? ' files are ready for further processing.' : ' files are being processed right now.' }}
              <i *ngIf="bulkImportItem.status === bulkImportProcessStatuses.READY" class="small-text-relative">Click here for the final steps</i>
            </span>
        </div>
        <div class="date-container">
            <span class="bold">Today</span>
        </div>
    </div>
</div>
