<div #phxMultiSelect
     (keyup)="onKeyPress($event)"
     [ngClass]="size + (expandItems ? ' expand' : '')"
     class="phx-multi-select-selector phx-multi-select-container">
    <div (click)="showDropdownContent($event)"
         [ngClass]="(hasError ? 'error ' : '') + (isDisabled ? ' disabled-container' : '')"
         class="phx-multi-select-selector dropdown-container">
        <div (mouseenter)="onMouseOver($event)"
             [id]="dropdownId + '-selected-items'"
             [ngClass]="isDisabled ? 'disabled-input' : ''"
             class="phx-multi-select-selector selected-items">
            <ng-container *ngIf="expandItems">
                <div (click)="removedItem($event, item)"
                     *ngFor="let item of selectedItems"
                     [ngClass]="isDisabled ? 'disabled-item' : ''"
                     class="phx-multi-select-selector selected-item">
          <span [title]="item[valueField]"
                class="phx-multi-select-selector item-name">
            {{ item[valueField] }}
          </span>
                    <span class="phx-multi-select-selector close-btn">
            x
          </span>
                </div>
            </ng-container>
            <ng-container *ngIf="!expandItems">
                <div (click)="removedItem($event, item)"
                     *ngFor="let item of selectedItems | slice:0:2;"
                     [ngClass]="isDisabled ? 'disabled-item' : ''"
                     class="phx-multi-select-selector selected-item">
          <span [title]="item[valueField]"
                class="phx-multi-select-selector item-name">
            {{ item[valueField] }}
          </span>
                    <span class="phx-multi-select-selector close-btn">x</span>
                </div>
            </ng-container>
            <div (click)="expandAllItems()"
                 *ngIf="selectedItems?.length > 2 && !expandItems"
                 [ngClass]="isDisabled ? 'disabled-item' : ''"
                 [title]="generateItemPlaceholder()"
                 class="extra-items">
                +{{ selectedItems.length - 2 }}
            </div>
            <input #inputElement
                   (keyup)="onButtonPress($event)"
                   (ngModelChange)="onSearch()"
                   [(ngModel)]="input"
                   [attr.data-testid]="dataTestId"
                   [disabled]="isDisabled"
                   [ngClass]="isDisabled ? 'disabled-input' : ''"
                   class="phx-multi-select-selector dropdown-input"
                   type="text" />
        </div>
        <div [ngClass]="isDisabled ? 'disabled-input' : ''"
             class="phx-multi-select-selector arrow-container">
            <phx-icon *ngIf="!isDisabled"
                      color="black"
                      ml="5"
                      mr="10"
                      size="10"
                      src="filled-dropdown-arrow">
            </phx-icon>
            <phx-icon *ngIf="isDisabled"
                      color="dark-gray"
                      ml="5"
                      mr="10"
                      size="10"
                      src="filled-dropdown-arrow">
            </phx-icon>
        </div>
    </div>
    <div #dropdownContent
         *ngIf="!isDisabled"
         [id]="dropdownId"
         [ngClass]="usePositionStatic ? 'dropdown-content-position-static' : 'dropdown-content'">
        <div (click)="selectedItem(item)"
             (keyup)="selectItemWithKeyPress($event, item)"
             *ngFor="let item of filteredOptions; let index = index"
             [id]="dropdownId + '-option-' + index"
             [ngClass]="dropdownId + '-option'"
             [tabindex]="index + 1"
             class="phx-multi-select-selector option">
            {{ item[valueField] }}
        </div>
        <div *ngIf="filteredOptions?.length <= 0 && emptyDropdownMessage != ''"
             class="phx-multi-select-selector no-option">
            {{ emptyDropdownMessage }}
        </div>
    </div>
    <div *ngIf="helpTipMessage != ''" class="italic-message">
        <em>{{ helpTipMessage }}</em>
    </div>
</div>
