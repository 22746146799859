import { Component, Input } from "@angular/core";



@Component({
  selector: 'phx-tab-container',
  templateUrl: './phoenix-tab-container.component.html',
  styleUrls: ['./phoenix-tab-container.component.scss']
})

export class PhoenixTabContainerComponent {
  @Input('text') text: string;
  @Input('link') link: string;

}
