<ng-container *ngIf="standAlone">
  <ng-container *ngIf="isMultiple; else elseTemplate">
    <label class="btn btn-default btn-file">
      <phx-button data-id="commonPhoenixUploadSelectFileBtn" color="tertiary" text="Select File" btnIcon="attachment-paperclip-white" [disabled]="disabled">
      </phx-button>
      <input [disabled]="disabled" #file id="file" type="file" value="file" multiple="multiple" (change)="fileChange($event)" />
    </label>
    <div class="gray-label" *ngIf="fileList == 0">
      <fa-icon class="mr-2" [icon]="faTimes"></fa-icon>No document(s) uploaded
    </div>
    <div class="gray-label ellipsis" [tooltip]="file.name" *ngFor="let file of fileList; let i = index;">
      <div class="d-flex">
        <phx-icon src="cancel-white" style="cursor: pointer;" (click)="removeFile(i)"></phx-icon>
        {{file.name}}
      </div>
    </div>
  </ng-container>
  <ng-template #elseTemplate>
    <label [hidden]="hideButton" class="btn btn-default btn-file">
      <phx-button [ngClass]="!isVendorApp ? 'select-file-btn' : ''" data-id="commonPhoenixUploadSelectFileBtn" text="Select File" btnIcon="attachment-paperclip-white" color="tertiary" [disabled]="disabled">
      </phx-button>
      <input [disabled]="disabled" #file id="file" type="file" value="file"(change)="fileChange($event)" />
    </label>
    <div class="gray-label" *ngIf="file.files.length == 0">
      <fa-icon *ngIf="isVendorApp" class="mr-2" [icon]="faTimes"></fa-icon>No document uploaded
    </div>
    <div *ngIf="file.files.length > 0" class="gray-label ellipsis" [tooltip]="file.files[0]?.name">
      <div class="d-flex">
        <phx-icon src="cancel-white" style="cursor: pointer;" (click)="removeFile()"></phx-icon>
        {{file.files[0]?.name}}
      </div>
    </div>
  </ng-template>
</ng-container>

<ng-container *ngIf="!standAlone">
  <input style="display: none" type="file" (change)="fileChange($event)" #file>
  <a class="pointer" (click)="file.click()">
    <ng-content></ng-content>
  </a>
</ng-container>
