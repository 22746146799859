import { FormGroup, FormArray, FormControl, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import * as CompanyEmailValidator from 'company-email-validator'
import { GlobalConstants } from './Constants';

export class Utils {
    private static uniqCounter: number = -1;
    public static getUniqCounter(): number {
        return this.uniqCounter++;
    }

    public static getToken(): string {
        return localStorage.getItem('token');
    }

    public static setToken(token: string) {
        localStorage.setItem('token', token);
    }

    public static deleteToken() {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
    }

    public static isEmptyObj(obj) {
        for(let prop in obj) {
          // eslint-disable-next-line no-prototype-builtins
          if(obj.hasOwnProperty(prop)) {
            return false;
          }
        }

        return JSON.stringify(obj) === JSON.stringify({});
      }


    public static retry<T>(executor: (resolve: (value?: T | PromiseLike<T>) => void, reject: (reason?: any) => void) => void, options: any = {}, attempt: number = 0, error: any = null): Promise<T> {
        options.retries = options.retries || 10;
        options.randomize = options.randomize || true;
        options.min = options.min || 500;
        options.max = options.max || 20000;
        options.factor = options.factor || 2;

        return new Promise<T>((resolve, reject) => {

            let run = () => {
                if (attempt == options.retries) {
                    reject(error);
                } else {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const promise = new Promise(executor).then((result) => {
                        resolve(result);
                    }).catch((err) => {
                        error = err;
                        attempt++;
                        let delay: number = Utils.delay(attempt, options.randomize, options.factor, options.min, options.max);
                        setTimeout(() => {
                            run();
                        }, delay);
                    });
                }
            };

            run();
        });
    }

    private static delay(attempt: number, randomize = false, factor = 2, min = 1000, max = Infinity) {
        const random = randomize ? Math.random() + 1 : 1;
        if (attempt == 0) return 0;
        return Math.min(Math.round(random * min * Math.pow(factor, attempt)), max);
    }


    public static validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.updateValueAndValidity({onlySelf: false, emitEvent: true});
                control.markAsDirty({ onlySelf: false });
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            } else if (control instanceof FormArray) {
                for (let innerControl of control.controls) {
                    if (innerControl instanceof FormControl) {
                        innerControl.updateValueAndValidity({ onlySelf: false, emitEvent: true });
                        innerControl.markAsDirty({ onlySelf: false});
                    } else if (innerControl instanceof FormGroup) {
                        this.validateAllFormFields(innerControl);
                    }
                }
            }
        });
    }

    // public static async readUrl(files: Array<any>) {
    //     let base64Files = [];
    //     for (let file in files) {
    //         let reader = new FileReader();
    //         let ofile = file;
    //         reader.onloadend = (event) => {
    //             files[ofile].finished = true;
    //             let base64File = event.target["result"].split('base64,')[1];
    //             if (base64File) {
    //                 let doc = {
    //                     data: base64File,
    //                     name: files[ofile].name
    //                 }
    //                 if (!doc.name) doc.name = files[ofile].file.name;
    //                 base64Files.push(doc);
    //             }
    //         }
    //         reader.readAsDataURL(files[ofile].file);
    //     }
    //     await this.checkState(files);
    //     return base64Files;
    // }

    public static async checkState(files) {
        let unfinished: boolean = false;
        while (!unfinished) {
            for (let file of files) {
                if (!file.finished) unfinished = true;
            }
            if (unfinished) await (new Promise(resolve => setTimeout(resolve, 100)));
        }
    }

    public static validateEmailBlacklist(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;

            if (!value) {
                return null;
            }

            var domain = value.split('@')[1];

            var blacklisted = GlobalConstants.BLACKLISTED_DOMAINS.indexOf(domain) != -1;

            return blacklisted ?  { blacklisted:true } : null;
        }
    }

    public static validateBusinessEmail(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;

            if (!value) {
                return null;
            }

            var domain = value.split('@')[1];

            var isBusinessEmail = true;

            if(domain) {
                isBusinessEmail = CompanyEmailValidator.isCompanyDomain(domain);
            }

            return isBusinessEmail ? null : { nonBusinessEmail:true };
        }
    }

  public static formatNumberCleaned(n) {
    if (!n) return '';

    // Remove non-digit characters
    let cleaned = n.replace(/\D/g, "");

    // Slice to a maximum of 9 digits
    cleaned = cleaned.substring(0, 9);
    // Format and return
    return `$${cleaned.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  }

  public static formatNumber(n) {
    if (!n) return '';

    // Remove non-digit characters
    let cleaned = n.replace(/\D/g, "");

    // Format and return
    return `$${cleaned.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  }

  public static formatMoney(displayValue: string) {
    if (!displayValue) return;
    let newVal: string = displayValue.replace(/\D/g, '');
    let rawValue: string = newVal;
    if(newVal.length == 0) {
      newVal = '';
    } else {
      newVal = this.formatNumber(rawValue);
    }

    return {
      displayValue: newVal,
      value: +rawValue
    };
  }
}
