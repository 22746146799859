import { Component, Input } from '@angular/core';
import { AnimationItem } from 'lottie-web';

@Component({
    selector: 'phx-loader',
    templateUrl: './phoenix-loader.component.html',
    styleUrls: ['./phoenix-loader.component.scss']
})
export class PhoenixLoaderComponent {
    @Input('size') size: string = 'small'; // small || large || big
    @Input('inPlace') inPlace: boolean = false;
    @Input('useCustomLoader') useCustomLoader: boolean = false;

    animationCreated(animationItem: AnimationItem): void {
        animationItem.setSpeed(0.5);
    }
}
