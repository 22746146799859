import { Component, ContentChild, Directive, ElementRef, EventEmitter, Input, OnInit, TemplateRef } from '@angular/core';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { NgForOf, NgIf } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'phx-accordion',
  templateUrl: './phoenix-accordion.component.html',
  styleUrl: './phoenix-accordion.component.css'
})
export class PhoenixAccordionComponent implements OnInit {
  public constructor(private accordion: AccordionModule) {

  }

  ngOnInit() {

  }

  @Input() itemsData: any[];
  @Input() hidden: EventEmitter<any> = new EventEmitter();

  @ContentChild('titleTemplate') titleTemplate: TemplateRef<any>;
  @ContentChild('bodyTemplate') bodyTemplate: TemplateRef<any>;
}


