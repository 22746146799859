<div class="message" [ngClass]="{sent: !incoming}" *ngIf="message && message.sender" [ngSwitch]="message.type">
  <phx-icon size="50" ml="10" mr="10" extraClass="radius mx-3" *ngIf="!messageOnly"
            [url]="userProfile?.userAvatar ? userProfile?.userAvatar : message.sender.avatarUrl"></phx-icon>
  <div class="mx-3" style="min-width: 50px;" *ngIf="messageOnly"></div>
  <ng-container *ngSwitchCase="ChatMessageType.ChatMessageText">
    <div class="message-body">
      <div class="message-meta" *ngIf="!messageOnly">{{userProfile?.userName ? userProfile?.userName + ' @ ' +
        message.sender.name :
        message.sender.name}}: {{message.timestamp | date: 'MM/dd/yyyy - hh:mm' }}</div>
      <div class="message-balloon" [ngClass]="{'ml-auto outgoing': !incoming, 'mr-auto': incoming}">
        {{message.content}}
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="ChatMessageType.ChatMessageFile">
    <div class="message-body">
      <div
        class="message-meta">{{userProfile && userProfile.userName ? userProfile.userName + ' @ ' + message.sender.name :
        message.sender.name}}: {{message.timestamp | date: 'MM/dd/yyyy - hh:mm' }}</div>
      <div [ngClass]="{'ml-auto': !incoming, 'mr-auto': incoming}"
           [ngSwitch]="message.twilioChatMessage.media.contentType">
        <ng-container *ngIf="!mediaUrl; else elseTemplate">
          <div class="spinner"></div>
        </ng-container>
        <ng-template #elseTemplate>
          <phx-icon [url]="mediaUrl" h="140" w="160" customClass="thumbnail hand" (click)="openMedia()"
                    *ngSwitchCase="'image/png'"></phx-icon>
          <phx-icon [url]="mediaUrl" h="140" w="160" customClass="thumbnail hand" (click)="openMedia()"
                    *ngSwitchCase="'image/jpeg'"></phx-icon>
          <phx-icon [url]="mediaUrl" h="140" w="160" customClass="thumbnail hand" (click)="openMedia()"
                    *ngSwitchCase="'image/gif'"></phx-icon>
          <phx-icon customClass="standardImage" src="chat-message-file mt-2 mr-2" style="cursor: pointer;"
                    *ngSwitchCase="'application/pdf'" (click)="openMedia()"></phx-icon>
          <phx-icon customClass="standardImage" src="chat-message-file mt-2 mr-2" *ngSwitchDefault
                    (click)="openMedia()"></phx-icon>
        </ng-template>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="ChatMessageType.ChatMessageTyping">
    <div class="message-body">
      <div class="message-meta">{{message.sender.name}}</div>
      <div class="message-balloon" [ngClass]="{'ml-auto': !incoming, 'mr-auto': incoming}">Typing...</div>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="ChatMessageType.ChatSystemMessage">
    <div class="message-body">
      <div class="message-meta">{{message.sender.name}}: {{message.timestamp | date: 'MM/dd/yyyy hh:mm' }}</div>
      <div class="system-message" [ngClass]="{'ml-auto': !incoming, 'mr-auto': incoming}">{{message.content}}
      </div>
    </div>
  </ng-container>
</div>
