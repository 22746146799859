<div class="upload-container my-2">
    <div class="drag-drop-container" ngFileDrop (uploadOutput)="onUploadOutput($event, fileInput)"
    [uploadInput]="uploadInput" (drop)="onDrop($event, fileInput)"  [ngClass]="{ 'drop-over': dragOver }">
        <phx-icon *ngIf="!isVendorImport" customClass="standardImage" src="document-upload" mb="10" w="54" h="58" ></phx-icon>
        <phx-icon *ngIf="isVendorImport" customClass="standardImage" src="vendor-upload" mb="10" w="54" h="58" ></phx-icon>
        <ng-container>
            <span>
                Drag documents here or
                <label class="browse-text" style="cursor: pointer;">
                    Browse
                    <input hidden type="file" ngFileSelect [multiple]="isMultiple ? 'multiple' : null" (uploadOutput)="onUploadOutput($event, fileInput)"
                    [uploadInput]="uploadInput" [attr.disabled]="isDisabled ? '' : null" data-testid="browse-documents-button" #fileInput >
                </label>
            </span>
        </ng-container>
        <div class="d-flex flex-wrap flex-column mt-1 ml-2" *ngIf="isMultiple && fileList.length > 0 || !isMultiple && fileList.length == 1">
            <div *ngFor="let file of fileList; let i = index;">
                <div class="d-flex mr-2">
                    {{file.name}}
                    <phx-icon src="cancel-white" style="cursor: pointer;" (click)="removeFile(i, fileInput)"></phx-icon>
                </div>
            </div>
        </div>
    </div>
</div>
