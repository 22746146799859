import { Pipe, PipeTransform } from '@angular/core';
import { PipeService } from './pipes.service';

@Pipe({ name: 'jobTradePipe' })
export class JobTradePipe implements PipeTransform {
	constructor(private pipeService: PipeService) { }

	transform(jobTrades: Array<any>): string {
		if (!jobTrades) {
			return '';
		}
		const jobTradesRes: Array<any> = [];
		for (const jt of jobTrades) {
			jobTradesRes.push(this.pipeService.jobTradesMaps[jt]);
		}
		return jobTradesRes.join(', ');
	}
}
