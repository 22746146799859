import { Injectable } from '@angular/core';
import { CommonBaseWebService } from './common-base-web.service';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth-service';
import { EnvironmentService } from '../services/environment.service';

@Injectable({ providedIn: 'root' })
export class CommonPhoenixWebService extends CommonBaseWebService {
  constructor(protected http: HttpClient, protected authService: AuthService, protected environmentService: EnvironmentService) {
    super(http, authService, environmentService);
    if (!(<any>window).clientWebService) {
      (<any>window).clientWebService = this;
    }
  }

  async getEscalationStatuses(isVendor: boolean): Promise<any[]> {
    const url = this.middleWareUri + `/api/client/settings/general/escalation-statuses?isVendor=${ isVendor }`;
    return await this.apiGetCall(url);
  }
}
