import { Pipe, PipeTransform } from '@angular/core';
import { PipeService } from './pipes.service';

@Pipe({ name: 'leaseTypePipe' })
export class LeaseTypePipe implements PipeTransform {
	constructor(private pipeService: PipeService) { }

	transform(leaseTypes: Array<any>): string {

		if (!leaseTypes) {
			return '';
		}
		const leaseTypesRes: Array<string> = [];
		for (let lt of leaseTypes) {
			leaseTypesRes.push(this.pipeService.leaseTypesMap[lt]);
		}

		return leaseTypesRes.join(', ');
	}
}
