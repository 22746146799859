import { IBaseModel } from './base-model';

export class LocationGroup implements IBaseModel {

    public clientId: string;
    public clientLocationGroupId: string;
    public name: string;
    public mainContactId: any;

    public fromJSON(json: any): LocationGroup {
        if (json) {
            this.clientId = json["clientId"];
            this.clientLocationGroupId = json["clientLocationGroupId"];
            this.name = json["name"];
            this.mainContactId = json["mainContactId"];
        }

        return this;
    }

    public toJSON(): any {
        return {};
    }
}
