export interface BulkImportProcessItem {
    itemId?: number;
    uploaderName?: string;
    clientId?: number;
    createdAt?: Date;
    finishedAt?: Date;
    response?: any;
    status?: BulkImportProcessItemStatus;
    processedFiles?: number;
}

export enum BulkImportProcessItemStatus {
    PROCESSING = 'PROCESSING',
    READY = "READY",
    FINISHED = "FINISHED",
    ERROR = "ERROR"
}