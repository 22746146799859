import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { faFileInvoice, faPlus } from '@fortawesome/free-solid-svg-icons';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CreateCompanyTableComponent } from '../create-company-table/create-company-table.component';
import { PhoenixWebService } from '@capp/providers/web.service';
import { AppService } from '@capp/app/services/app.service';
import { Utils } from '../../../../../../../phoenix-common/src/lib/shared/utils/utils';


export interface SelectedCertificateData {
  certificateName: string;
  certificateId: number;
}

export interface ImportedFileDataToProcess {
  allLocations: any[];
  allInsuranceRequirementCategories: any[];
  fileDataToProcess: FileDataToProcess[];
}

export interface FileDataToProcess {
  certificateData?: SelectedCertificateData[];
  certusFileId: number;
  documentType: any;
  companyId?: number;
  accountId?: number;
  locations?: number[];
  companyName: string;
  contactEmail: string;
  contactName: string;
  contactPhone: string;
  fileName: string;
  fileSource?: string;
  ignored: boolean;
  thumbnailSource?: string;
}

@Component({
  selector: 'app-bulk-import-list',
  templateUrl: './bulk-import-list.component.html',
  styleUrls: ['./bulk-import-list.component.scss']
})
export class BulkImportListComponent implements OnInit {
  public faPlus = faPlus;
  public modal: BsModalRef;
  public faFileInvoice = faFileInvoice;
  public hoveringSrc: string = null;

  public bulkImportProcessItemId: number;
  public bulkImportProcessData: any;
  public allAccounts: Array<any> = [];
  public accountSubAccountsMap: { [key: string]: any } = {};
  public documentTypes: any;
  public createCompanyModal: BsModalRef;
  public loader = false;
  public groupAccounts = [];
  public matchedFilesForm = new FormGroup({
    filesFormArray: new FormArray([])
  });
  isVendor: boolean;
  companyLabelPlural: string;
  accountLabelPlural: string;
  companyLabelSingular: string;
  accountLabelSingular: string;

  constructor(public bsModalRef: BsModalRef,
              private phoenixWebService: PhoenixWebService,
              private modalService: BsModalService,
              private appService: AppService) {
  }

  get filesFormArray(): FormArray {
    return this.matchedFilesForm.get('filesFormArray') as FormArray;
  }

  async ngOnInit(): Promise<void> {
    this.loader = true;
    this.bulkImportProcessData = await this.phoenixWebService.getFileDataByBulkImportProcessItemId(this.bulkImportProcessItemId);

    this.isVendor = this.bulkImportProcessData?.isVendor;
    this.allAccounts = this.bulkImportProcessData?.allAccounts;
    this.documentTypes = this.bulkImportProcessData?.allDocumentTypes;
    this.buildFilesForm();
    this.companyLabelPlural = this.appService.pluralLabel('Company', this.isVendor);
    this.accountLabelPlural = this.appService.pluralLabel('Certificate', this.isVendor);
    this.companyLabelSingular = this.appService.label('Company', this.isVendor);
    this.accountLabelSingular = this.appService.label('Certificate', this.isVendor);
    this.groupAccounts = [`Matched ${this.companyLabelPlural}`, `All ${this.companyLabelPlural}`]
  }

  setHoverSrc(src): void {
    this.hoveringSrc = src?.get('thumbnailSource')?.value;
  }

  public buildFilesForm(): void {
    if (!this.bulkImportProcessData || !this.bulkImportProcessData?.files) {
      this.bsModalRef.hide();
      return;
    }

    const files = this.bulkImportProcessData.files || [];
    for (const file of files) {
      if(!file) continue;

      let accountMatch = file.accountMatch;

      let concatAccounts = [];
      const associatedAccounts = [];

      if (accountMatch) {
        associatedAccounts.push({
          accountId: accountMatch?.accountId,
          companyId: accountMatch?.companyId,
          accountName: accountMatch?.accountName,
          matchGroup: 0
        });
      }
      concatAccounts = [...associatedAccounts].concat(...this.allAccounts);

      if (accountMatch != null) {
        const associatedAccountId = accountMatch.companyId;
        this.accountSubAccountsMap[associatedAccountId] = accountMatch ?? [];
      }
      const fileFormGroup = this.getAccountsFormGroup(file, accountMatch, concatAccounts);
      this.filesFormArray.push(fileFormGroup);

      const page = file.firstCoiPage;
      const certusFileId = file.certusFileId;
      if (page > 0) {
        const thumbnailUrl = file.presignedThumbnailUrl; // await this.phoenixWebService.getPreSignedThumbnailUrl(path, page);
        this.setUrlValueInFormGroup(certusFileId, thumbnailUrl, true);
        const fileUrl = file.presignedFileUrl; // await this.phoenixWebService.getPreSignedFileUrl(path)
        this.setUrlValueInFormGroup(certusFileId, fileUrl, false);
      }
    }

    this.loader = false;
  }

  public async associatedAccountChange(index: number): Promise<void> {
    this.filesFormArray.controls[index].get('companyMatches').patchValue([]);
    if (this.filesFormArray.controls[index].get('associatedAccount').value) {
      this.filesFormArray.controls[index].get('associatedSubAccount').reset();
      this.filesFormArray.controls[index].get('associatedSubAccount').enable();
    } else {
      this.filesFormArray.controls[index].get('associatedSubAccount').reset();
      this.filesFormArray.controls[index].get('associatedSubAccount').disable();
    }
  }

  public associatedSubAccountChange(index: number): void {
    if (this.filesFormArray.controls[index].get('associatedSubAccount').value?.length === 0) {
      this.filesFormArray.controls[index].get('associatedSubAccount').reset();
    }
  }

  public async onDiscardBulkUpload(): Promise<void> {
    const closeConfirmRes = await this.appService.confirmModal('Are you sure you want to discard this Bulk import?');
    if (!closeConfirmRes) {
      return;
    }
    await this.phoenixWebService.discardBulkImport(this.bulkImportProcessData?.bulkImportProcessItemId);
    this.bsModalRef.hide();
  }

  GetButtonText(): string {
    return this.allFilesAreIgnored() ? 'Discard' : 'Continue';
  }

  public async submitFiles(): Promise<void> {
    Utils.validateAllFormFields(this.matchedFilesForm);

    if (this.filesFormArray.invalid) {
      this.appService.showAlertMessage('Missing Data', 'danger');
      return;
    }

    const action = this.GetButtonText();
    if (action === 'Discard') {
      this.loader = true;
      // everything is ignored... just discard the bulk upload
      await this.onDiscardBulkUpload();
      this.loader = false;
      this.bsModalRef.hide();
      return;
    }

    this.loader = true;
    const importedFileDataToProcesses = this.prepareDataForProcessing();
    const initialState = {
      importedFileDataToProcesses,
      bulkImportProcessItemId: this.bulkImportProcessData?.bulkImportProcessItemId,
      isVendor: this.isVendor
    };
    this.loader = false;
    this.bsModalRef.hide();
    this.createCompanyModal = this.modalService.show(CreateCompanyTableComponent, {
      class: 'modal-xl',
      initialState,
      ignoreBackdropClick: true,
      backdrop: false,
      keyboard: false
    });
  }

  allFilesAreIgnored(): boolean {
    const totalRowCount = this.filesFormArray.controls.length;
    const ignoredFiles = this.filesFormArray.controls.filter((fg: FormGroup) => {
      const ignoreFormGroup = fg.get('ignore') as FormGroup;
      return ignoreFormGroup.value && ignoreFormGroup.value === true;
    }) as FormGroup[];
    return ignoredFiles.length === totalRowCount;
  }

  onIgnore(index: number): void {
    const ignoredControl = this.filesFormArray.controls[index].get('ignore') as FormGroup;
    const isIgnored = ignoredControl.value;
    if (isIgnored) {
      const documentTypeForRow = this.filesFormArray.controls[index].get('documentType');
      documentTypeForRow.clearValidators();
      documentTypeForRow.updateValueAndValidity();
    } else {
      const documentTypeForRow = this.filesFormArray.controls[index].get('documentType');
      documentTypeForRow.setValidators(Validators.required);
      documentTypeForRow.updateValueAndValidity();
    }
  }

  private setUrlValueInFormGroup(certusFileId: any, url: string, isThumbnail: boolean): void {
    // Search for the right fileFormGroup based on certusFileId
    const targetFormGroup = this.filesFormArray.controls
      .find((fg: FormGroup) => fg.get('certusFileId').value === certusFileId);
    // If the target form group is found, update its thumbnailSource with the fetched URL
    if (targetFormGroup) {
      const source = isThumbnail ? 'thumbnailSource' : 'fileSource';
      targetFormGroup.get(source).setValue(url);
    }
  }

  private getAccountsFormGroup(file: any, accountMatch: any, concatAccounts: any[]): FormGroup {
    const initialSubAccountMatchState = accountMatch?.subAccountMatches?.length ? accountMatch?.subAccountMatches?.map(el => {
      return el?.certificateId;
    }) : [];

    const documentTypesDefaultValue = this.documentTypes?.length > 0
                                      ? this.documentTypes.find(d => d.name === 'COI' && d.isDocumentType == false)
                                      : null;
    return new FormGroup({
      documentType: new FormControl(documentTypesDefaultValue, Validators.required),
      fileName: new FormControl(file.fileName),
      certusFileId: new FormControl(file.certusFileId),
      companyMatches: new FormControl(file.companyMatches),
      associatedAccount: new FormControl(accountMatch || null),
      associatedSubAccount: new FormControl(initialSubAccountMatchState),
      ignore: new FormControl(false),
      fileSource: new FormControl(null),
      thumbnailSource: new FormControl(null),
      accounts: new FormControl(concatAccounts)
    });
  }

  private prepareDataForProcessing(): ImportedFileDataToProcess {
    const fileDataToProcess: FileDataToProcess[] = [];
    this.filesFormArray.value.forEach((el: any) => {
      const companyId = el?.associatedAccount?.companyId ? +el?.associatedAccount?.companyId : null;
      const companyData = el?.accounts?.find(a => a.companyId === companyId && a.matchGroup === 1);
      const companyCertificates = companyData?.subAccounts;
      const selectedCertificateData = companyCertificates?.filter(cc => el?.associatedSubAccount?.includes(cc?.certificateId));

      const fileData: FileDataToProcess = {
        certusFileId: el?.certusFileId ? +el?.certusFileId : null,
        documentType: el?.documentType ? el.documentType : null,
        companyId,
        accountId: el?.associatedAccount?.accountId ? +el?.associatedAccount?.accountId : null,
        companyName: el?.associatedAccount?.accountName ? el.associatedAccount?.accountName : null,
        contactEmail: companyData?.mainContact?.contactEmail ? companyData?.mainContact?.contactEmail : null,
        contactName: companyData?.mainContact?.contactName ? companyData?.mainContact?.contactName : null,
        contactPhone: companyData?.mainContact?.contactPhone ? companyData?.mainContact?.contactPhone : null,
        certificateData: selectedCertificateData,
        locations: selectedCertificateData?.length > 0 ? selectedCertificateData?.map(c => c?.locations).flat() : null,
        fileName: el?.fileName ? el.fileName : null,
        fileSource: el?.fileSource ? el.fileSource : null,
        ignored: el?.ignore && el.ignore === true,
        thumbnailSource: el?.thumbnailSource ? el.thumbnailSource : null
      };
      fileDataToProcess.push(fileData);
    });

    return {
      allLocations: this.bulkImportProcessData?.allLocations ?? [],
      allInsuranceRequirementCategories: this.bulkImportProcessData?.allInsuranceRequirementCategories ?? [],
      fileDataToProcess
    };
  }

  getCertificates(file: AbstractControl): any[] {
    const associatedCompanyId = file['controls']?.associatedAccount?.value?.companyId;
    const account = this.allAccounts.find(a => a.companyId === associatedCompanyId);

    return account?.subAccounts || [];
  }

  getCertificatesItems(file: AbstractControl): any {
    return file['controls'].accounts.value;
  }

  getUrlFromFile(file: AbstractControl) {
    return file['controls'].thumbnailSource.value;
  }

  getNameFromFile(file: AbstractControl) {
    return file['controls'].fileName.value;
  }

  getFileSource(file: AbstractControl) {
    return file['controls'].fileSource.value;
  }

  isFileIgnored(file: AbstractControl) {
    return file['controls'].ignore.value;
  }

  getAssociatedAccountFromFile(file: AbstractControl) {
    return file['controls'].associatedAccount.value;
  }

  getAssociatedSubAccountFromFile(file: AbstractControl) {
    return file['controls'].associatedSubAccount.value;
  }

  getAssociatedMessage(file: AbstractControl) {
    const fileLength = this.getAssociatedSubAccountFromFile(file).length > 1;

    return fileLength ? 'these ' + this.getAssociatedSubAccountFromFile(file).length + ' ' + this.accountLabelPlural
      : ' this ' + this.accountLabelSingular;
  }
}
