import { Component } from '@angular/core';
import { AppService } from '../../services/app.service';
import { PhoenixWebService } from '@capp/providers/web.service';
import { PipeService } from 'phoenix-common';
import { AuthenticatedUserService } from '@capp/app/services/authenticated-user.service';
import { ClientDataService } from '@capp/app/services/clientData.service';
import { UserPermissionsService } from '@capp/providers/user-permissions.service';
import { Router } from '@angular/router';
import { LocationService } from '@capp/app/services/location.service';
import { RiskBotService } from '@capp/app/services/risk-bot.service';

@Component({
  selector: 'phx-main',
  templateUrl: './main.component.html'
})
export class MainComponent {
  public loading = true;
  public isInitialized = false;

  constructor(private pipeService: PipeService,
              private router: Router,
              protected appService: AppService,
              private phoenixWebService: PhoenixWebService,
              private locationService: LocationService,
              private authenticatedUserService: AuthenticatedUserService,
              private clientDataService: ClientDataService,
              private userPermissionService: UserPermissionsService,
              private riskBotService: RiskBotService) {
    this.init();
    this.riskBotService.toggleRiskBotEventEmitter.subscribe(() => {
      const bcsChatBoxContainer = document.getElementById('bcs-global-chat-bot-container');
      bcsChatBoxContainer?.classList?.toggle('chat-box-visible');
      if (bcsChatBoxContainer?.classList.contains('chat-box-visible')) {
        this.riskBotService.setRiskBotChatShownFlag(true);
      } else {
        this.riskBotService.setRiskBotChatShownFlag(false);
      }
    });
  }

  public init(): void {
    Promise.all([
      this.loadClientData(),
      this.loadJobTrades(),
      this.loadJobTypes(),
      this.loadLeaseTypes(),
      this.loadUserAvatar()
    ]).then(() => {
      const clientSubscriptionInformation = this.appService.client.clientSubscriptionInformation;
      if ((clientSubscriptionInformation?.paymentMethodStatus === 'Invalid' && clientSubscriptionInformation?.billingEnabled && !clientSubscriptionInformation?.clientOnBasicPlan) && this.userPermissionService.isAdmin()) {
        this.appService.informationModal('You currently do not have a valid payment method saved to your account.');
      }
      if (this.appService.clientStatus === 'DELINQUENT') {
        this.HandleDelinquent();
      }
      this.isInitialized = true;

    });
  }

  public async loadUserAvatar() {
    let clientUser = await this.phoenixWebService.getClientUserById(await this.appService.userCognitoId());
    this.authenticatedUserService.setAvatarUrl(clientUser.AvatarUrl);
  }

  public async loadJobTypes(): Promise<void> {
    const jobTypesRes = await this.phoenixWebService.getAllJobTypes();
    this.appService.setJobTypes(jobTypesRes);
    this.pipeService.setJobTypes(jobTypesRes);
    this.clientDataService.jobTypes = jobTypesRes;
  }

  public async loadJobTrades(): Promise<void> {
    const jobTradesRes = await this.phoenixWebService.getAllJobTrades();
    this.appService.setJobTrades(jobTradesRes);
    this.pipeService.setJobTrades(jobTradesRes);
    this.clientDataService.jobTrades = jobTradesRes;
  }

  public async loadLeaseTypes(): Promise<void> {
    const leaseTypesRes = await this.phoenixWebService.getClientLeaseTypes();
    const transformedArray = leaseTypesRes.map(obj => ({
      id: obj['leaseTypeId'],
      name: obj.name
    }));
    this.appService.setLeaseTypes(transformedArray);
    this.pipeService.setLeaseTypes(transformedArray);
    this.clientDataService.leaseTypes = transformedArray;
  }

  public async loadInsReqCategories(isVendor): Promise<void> {
    let res = await this.phoenixWebService.getClientComplianceRequirements(!isVendor);
    if (isVendor) {
      this.clientDataService.vendorInsReqCategories = res['insReqCategories'];
    } else {
      this.clientDataService.tenantInsReqCategories = res['insReqCategories'];
    }
  }

  public async loadServiceTypes(): Promise<void> {
    let res = await this.phoenixWebService.getClientServiceTypes();
    this.clientDataService.serviceTypes = res['serviceTypes'];
  }

  public async loadLocations(): Promise<void> {
    const userId = await this.authenticatedUserService.getCognitoId();
    this.clientDataService.locations = await this.locationService.getClientLocations();
  }

  public async loadSubaccountProperties(isVendor): Promise<void> {
    await this.clientDataService.updateSubAccountPropertiesCache(isVendor, this.phoenixWebService);
  }

  public async loadAccountProperties(isVendor): Promise<void> {
    await this.clientDataService.updateAccountPropertiesCache(isVendor, this.phoenixWebService);
  }

  public async loadClientData(): Promise<void> {
    const client = await this.phoenixWebService.getLoggedClientInfo();

    this.clientDataService.setLoggedInClient(client);

    Promise.all([
      this.loadAccountProperties(true),
      this.loadAccountProperties(false),
      this.loadSubaccountProperties(true),
      this.loadSubaccountProperties(false),
      this.loadInsReqCategories(true),
      this.loadInsReqCategories(false),
      this.loadServiceTypes(),
      this.loadLocations()
    ]).catch(error => {
      console.error('An error occurred when loading client data:', error);
    });

  }

  private async HandleDelinquent() {
    let modalRes = await this.appService.clientDelinquentModal();
    if (modalRes) {
      this.router.navigate([
        'main',
        'company-profile',
        'settings',
        'account-billing',
        'payment-methods'
      ]);
      return;
    }
  }

}
