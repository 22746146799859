<div class="left-menu">
    <div class="left-menu-header">
        <phx-icon customClass="standardImage" w="158" h="49" mb="10" mr="0" src="bcs-new-logo"></phx-icon>
        <div class="header-title px-4 mt-10" (click)="showSwitchClientsDropdown()" *ngIf="!switchClientsDropdownShown; else switchClientsDropdown">
            <span class="mr-2">{{appService.clientName}}</span>
            <phx-icon size="8" src="filled-dropdown-arrow" mr="10" *ngIf="clientSwitcherClients?.length > 0" ></phx-icon>
        </div>
        <ng-template #switchClientsDropdown>
            <ng-select class="w-100 form-control p-0 jobtype-field header-dropdown" [items]="clientSwitcherClients" bindLabel="name"
                bindValue="phoenixClientId" [closeOnSelect]="true" [(ngModel)]="currentClientId" (change)="switchClients()">
            </ng-select>
        </ng-template>
    </div>

    <div class="left-menu-body">
      <div *ngIf="appService.clientStatus != 'DELINQUENT'">
        <div data-testid="vendors-menu-tab" class="menu-item" routerLinkActive="active">
          <a class="parent-item-my-vendor parent-item">
            <div class="parent-item-container">
              <phx-icon src="my-vendors" customClass="menu-icon" ></phx-icon>
              <div>My {{'Vendor' | plural }}</div>
            </div>
          </a>
          <div class="dropdown-content">
            <a data-testid="vendors-menu-item" id="my-accounts" class="dropdown-item" routerLinkActive="active-sublink" routerLink="my-accounts/v">
              {{'Vendor' | plural }}
            </a>
            <a data-testid="jobs-menu-item" id="jobs" [ngClass]="!appService.hasVendors ? 'disabled' : ''" class="dropdown-item" routerLinkActive="active-sublink" routerLink="my-accounts/j">
              {{'Job' | plural }}
            </a>
          </div>
        </div>
        <div data-testid="tenants-menu-tab" *ngIf="userPermissionsService.canReadTenants()" class="menu-item" routerLinkActive="active">
          <a class="parent-item">
            <div class="parent-item-container">
              <phx-icon src="my-tenants" customClass="menu-icon" ></phx-icon>
              <div>My {{'Tenant' | plural }}</div>
            </div>
          </a>
          <div class="dropdown-content">
            <a data-testid="my-tenants-tab" id="my-tenants" class="dropdown-item" routerLinkActive="active-sublink" routerLink="my-accounts/t">
              {{'Tenant' | plural }}
            </a>
            <a data-testid="my-leases-tab" id="leases" [ngClass]="!appService.hasTenants ? 'disabled' : ''" class="dropdown-item" routerLinkActive="active-sublink" routerLink="my-accounts/l">
              {{'Lease' | plural }}
            </a>
          </div>
        </div>
        <div class="menu-item" routerLinkActive="active">
          <a class="parent-item" routerLink="marketplace">
            <div class="parent-item-container">
              <phx-icon src="bcs" customClass="menu-icon" ></phx-icon>
              <div>BCS Network</div>
            </div>
          </a>
        </div>
        <div class="menu-item" routerLinkActive="active" *ngIf="userPermissionsService.canReadJobs()">
          <a class="parent-item" routerLink="job-posting">
            <div class="parent-item-container">
              <phx-icon src="job-postings" customClass="menu-icon" ></phx-icon>
              <div>My Postings</div>
            </div>
          </a>
        </div>
        <div class="menu-item" routerLinkActive="active" *ngIf="userPermissionsService.canReadMessages()">
          <a class="parent-item" routerLink="messages">
            <div class="parent-item-container">
              <phx-icon src="messages" customClass="menu-icon" ></phx-icon>
              <div>Messages</div>
            </div>
          </a>
        </div>
        <div data-testid="locations-tab" class="menu-item" routerLinkActive="active" *ngIf="userPermissionsService.canReadLocations()">
          <a class="parent-item" routerLink="locations">
            <div class="parent-item-container">
              <phx-icon src="locations" customClass="menu-icon" ></phx-icon>
              <div>My {{ 'Location' | plural }}</div>
            </div>
          </a>
        </div>
        <div class="menu-item" routerLinkActive="active" *ngIf="userPermissionsService.canReadJobs()">
          <a class="parent-item" routerLink="my-tasks">
            <div class="parent-item-container">
              <phx-icon src="task" customClass="menu-icon" ></phx-icon>
              <div>My Tasks</div>
            </div>
          </a>
        </div>
        <div class="menu-item" routerLinkActive="active" *ngIf="appService.canSeeAnalytics">
          <a class="parent-item" routerLink="analytics">
            <div class="parent-item-container">
              <phx-icon src="analytics" customClass="menu-icon" ></phx-icon>
              <div>Analytics</div>
            </div>
          </a>
        </div>
      </div>
      <div data-testid="settings-tab" class="menu-item" routerLinkActive="active"
           *ngIf="userPermissionsService.canReadClientSettings()">
        <a class="parent-item" routerLink="company-profile">
          <div class="parent-item-container">
            <phx-icon src="gear" customClass="menu-icon"></phx-icon>
            <div>Settings</div>
          </div>
        </a>
      </div>
    </div>
</div>


<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Company Info</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-4">Company Name</div>
            <div class="col-8">
                <input id="vendor-name" type="text" class="form-control" placeholder="Company Name"
                    [(ngModel)]="companyName">
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-4">
                Upload Logo
            </div>
            <div class="col-8">
                <phx-upload (onFileUpload)="onFileUpload($event)"></phx-upload>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <phx-button data-id="clientSidebarCancelBtn" text="Cancel" btnIcon="cancel-white" color="secondary" (click)="modalRef.hide()" size="medium">
        </phx-button>
        <phx-button data-id="clientSidebarSaveBtn" text="Save" btnIcon="circle-white-tick" [loading]="loading" color="primary" size="medium" (click)="saveClientInfo()">
        </phx-button>
    </div>
</ng-template>
