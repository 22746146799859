import { Component, ElementRef, EventEmitter, Input, ViewChild } from '@angular/core';

import { UploaderOptions, UploadInput, UploadOutput } from 'ngx-uploader';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { faTimes } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'phx-attachments',
  templateUrl: './phoenix-attachments.component.html',
  styleUrls: ['./phoenix-attachments.component.scss']
})
export class PhoenixAttachmentsComponent {
  public faTimes = faTimes;

  public attachmentSelected = false;
  public fileTooltip = "";
  @Input() attachments: any[];
  @ViewChild('attachmentBtn', { static: true }) attachmentBtn: ElementRef<any>;

  constructor(private sanitizer: DomSanitizer) {}
  //Uploader
  pdfUrl: SafeResourceUrl;
  public mediaUrl = '';
  public dragOver: boolean;
  public options: UploaderOptions;
  public uploadInput: EventEmitter<UploadInput> = new EventEmitter<UploadInput>();


  public selectAttachments() {
    this.attachmentSelected = !this.attachmentSelected;

    if(this.attachmentSelected) {
      this.attachmentBtn.nativeElement.classList.add('btn-selected');
    } else {
      this.attachmentBtn.nativeElement.classList.remove('btn-selected');
    }
  }



  public selectAttachment(id: number) {
    const currentSelectionType = this.attachments.find((a) => a.certusFileID == id).selected;
    this.attachments.find((a) => a.certusFileID == id).selected = !currentSelectionType;
    this.attachmentSelected = false;
  }

  public onUploadOutput(output: UploadOutput): void {
    let files = [];
    if (output.type === 'allAddedToQueue') {// when all files added in queue
    } else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') {
      // add file to array when added

      files.push(output.file);
    } else if (output.type === 'rejected' && typeof output.file !== 'undefined') {
      alert('Unknown file type');
      return;
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
      this.dragOver = false;
    } else if (output.type === 'drop') {
      this.dragOver = false;
    }

    for (const file of files) {
      this.attachments.push({
        certusFileID: -1,
        dateCreated: null,
        description: file.name,
        fileName: file.name,
        fileSize: 0,
        s3Directory: "",
        s3FilePath: "",
        s3PreviewUrl: "",
        base64String: "",
        file: file,
        selected: true
      });
    }
    this.attachmentSelected = false;
  }





  public viewAttachment(attachment) {

    let src = attachment.s3PreviewUrl;
    if (attachment.base64string && attachment.base64string.length > 0) {
      src = "data:application/pdf;base64," + attachment.base64string
    }

    if (attachment.file !== undefined) {
      const fileURL = URL.createObjectURL(attachment.file.nativeFile);
      window.open(fileURL, '_blank');
      return;
    }

    const newWindow = window.open('', attachment.fileName);
    newWindow.document.write(`
      <html>
      <head>
      <title>` + attachment.fileName + `</title>
      </head>
        <body>
        <a download="Requirement Template.pdf">
          <embed src="` + src + `" type="application/pdf" width="100%" height="100%">
        </a>
          </body>
      </html>
    `);
    newWindow.document.close();
  }



}
