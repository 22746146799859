import { Pipe, PipeTransform } from '@angular/core';
import { PipeService } from './pipes.service';

@Pipe({ name: 'jobTypePipe' })
export class JobTypesPipe implements PipeTransform {
	constructor(private pipeService: PipeService) { }

	transform(jobTypes: Array<any>): string {
		if (!jobTypes) {
			return '';
		}
		const jobTypesRes: Array<string> = [];
		for (let jt of jobTypes) {
			jobTypesRes.push(this.pipeService.jobTypesMap[jt]);
		}
		return jobTypesRes.join(', ');
	}
}
