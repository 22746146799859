<router-outlet></router-outlet>


<!-- Confirmation Modal -->
<ng-template #confirmationModal>
  <div class="modal-body text-center ">
      <span>{{confirmMessage}}</span>
    <div class="modal-footer d-flex align-items-end justify-content-center">
      <phx-button data-id="clientAppDeclineBtn" text="Decline" (click)="appService.decline()" btnIcon="cancel-white" color="secondary" class="mr-2">
      </phx-button>
      <phx-button data-id="clientAppConfirmBtn" text="Confirm" (click)="appService.confirm()" btnIcon="circle-white-tick" color="primary" class="mr-2">
      </phx-button>
    </div>
  </div>
</ng-template>

<!-- Information Modal -->
<ng-template #informationModal>
  <div class="modal-body text-center ">
    <span>{{infoMessage}}
      <div *ngIf="infoMessageLink" class="information-modal-nav-link">
        <a class="bcs-link information-modal-nav-link"
           [routerLink]="infoMessageLink"
           (click)="appService.confirm()">
          click here
        </a>
      </div>
    </span>
    <div class="mt-2 d-flex align-items-end justify-content-center">
      <phx-button data-id="clientAppConfirmBtn" text="Close" btnIcon="circle-white-tick" (click)="appService.confirm()" color="primary" class="mr-2">
      </phx-button>
    </div>
  </div>
</ng-template>

<ng-template #clientDelinquentModal>
  <div class="modal-body text-center ">
    <span>Your payment is more than 30 days overdue, please settle your outstanding invoice by <a (click)="appService.confirm()" [routerLink]="['main', 'company-profile', 'settings', 'account-billing', 'payment-methods']">updating your payment method</a> to restore your service. Alternatively, contact us at  <a
      [href]="'mailto:customersupport' + '&#64;' + 'getbcs.com'">customersupport&#64;getbcs.com</a></span>
    <div class="mt-2 d-flex align-items-end justify-content-center">
      <phx-button data-id="clientAppConfirmBtn" text="Close" btnIcon="circle-white-tick" (click)="appService.confirm()"
                  color="primary" class="mr-2">
      </phx-button>
    </div>
  </div>
</ng-template>

<!-- Alert for errors -->
<div class="bottom-container">
  <div class="alert-container" *ngFor="let alert of alerts">
    <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout"><strong>{{ alert.title }}</strong></alert>
  </div>
</div>
