import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgClass, NgIf, NgSwitchCase } from '@angular/common';
import { PhoenixCommonModule } from '../phoenix-common.module';

@Component({
  selector: 'phx-tooltip-link',
  standalone: true,
  imports: [
    TooltipModule,
    NgSwitchCase,
    PhoenixCommonModule,
    NgIf,
    NgClass
  ],
  templateUrl: './phoenix-tooltip-link.component.html',
  styleUrl: './phoenix-tooltip-link.component.scss'
})
export class PhoenixTooltipLinkComponent implements OnInit, OnDestroy {
  @Input('id') id: string = '';
  @Input('linkText') linkText: string;
  @Input('tooltipText') tooltipText: string;
  @Input('clickHereText') clickHereText: string;
  @Input('clickHereLink') clickHereLink: string;
  @Input('iconOnly') iconOnly: boolean = false;
  @Input('tooltipClass') tooltipClass: string = '';
  @Input('iconClass') iconClass: string = '';
  @Output('onClick') onClick: EventEmitter<any> = new EventEmitter<any>();
  private unlistenMouseEnter: () => void;
  private unlistenMouseLeave: () => void;

  constructor(private renderer: Renderer2, private el: ElementRef) { }

  ngOnInit(): void {
    const targetElement = this.renderer.selectRootElement(`#${this.id}`, true);
    // Listen for mouse enter events on elements with 'data-tooltip'
    this.unlistenMouseEnter = this.renderer.listen(targetElement, 'mouseover', (event) => {
      if (event.target.hasAttribute('data-tooltip')) {
        this.showTooltip();
      }
    });

    // Listen for mouse leave events to hide the tooltip
    this.unlistenMouseLeave = this.renderer.listen(targetElement, 'mouseout', (event) => {
      if (event.target.hasAttribute('data-tooltip')) {
        this.hideTooltip();
      }
    });
  }

  ngOnDestroy(): void {
    // Remove event listeners when the component is destroyed
    this.unlistenMouseEnter();
    this.unlistenMouseLeave();
  }

  showTooltip() {
    const tooltipEl = this.el.nativeElement.querySelector('#' + this.id);
    if (tooltipEl) {
      this.renderer.setStyle(tooltipEl, 'display', 'inline-block');
    }
  }

  hideTooltip() {
    const tooltipEl = this.el.nativeElement.querySelector('#' + this.id);
    if (tooltipEl) {
      this.renderer.setStyle(tooltipEl, 'display', 'none');
    }
  }

  click() {
    this.onClick.emit(true);
  }
}
