import { Injectable } from '@angular/core';
import { PhoenixWebService } from '@capp/providers/web.service';
import { ClientLocation } from '@capp/app/shared/models/client-location';
import { GetLocationsPaginatedRequest } from '@capp/app/shared/models/get-locations-paginated-request';
import {
  GetLocationsWithClientUserAuthorizedStatusRequest
} from '@capp/app/shared/models/get-locations-with-client-user-authorized-status-request';
import { GetUsersWithLocationAuthorizedStatusRequest } from '@capp/app/shared/models/get-users-with-location-authorized-status-request';

export enum LocationCacheOption {
  ClientLocations = 'ClientLocations'
}

@Injectable({ providedIn: 'root' })
export class LocationService {
  public data = new Map();
  activeLocations: ClientLocation[] = [];

  constructor(private phoenixWebService: PhoenixWebService) {
  }
  async getLocationsWithClientUserAuthorizedStatus(request: GetLocationsWithClientUserAuthorizedStatusRequest) {
    return await this.phoenixWebService.getLocationsWithClientUserAuthorizedStatus(request);
  }
  async getOwnershipEntities() {
    return await this.phoenixWebService.getOwnershipEntities();
  }

  async getLocationStates() {
    return await this.phoenixWebService.getLocationStates();
  }

  async getLocationCities() {
    return await this.phoenixWebService.getLocationCities();
  }

  async checkLocationBatches(locations: number[], requirementCategory: number): Promise<any> {
    return await this.phoenixWebService.checkLocationBatches(locations, requirementCategory);
  }

  async getClientLocations(showInactive: boolean = false) {
    return await this.phoenixWebService.getClientLocations(showInactive);
  }

  async getClientLocationsPaginated(getLocationsPaginatedRequest: GetLocationsPaginatedRequest) {
    return await this.phoenixWebService.getClientLocationsPaginated(getLocationsPaginatedRequest);
  }

  async getUsersWithLocationAuthorizedStatus(request: GetUsersWithLocationAuthorizedStatusRequest) {
    return await this.phoenixWebService.getUsersWithLocationAuthorizedStatus(request);
  }

  async doesCertificateHaveLocationBatches(locations: number[], requirementCategory: number, isVendor: boolean): Promise<any> {
    if(!isVendor) {
      return false;
    }
    const locationBatches = await this.checkLocationBatches(locations, requirementCategory);
    return locationBatches.length > 1;
  }
}
