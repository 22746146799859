import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[ngAutofocus]'
})
export class AutofocusDirective {
  constructor(private host: ElementRef) {
  }

  ngAfterViewInit() {
    if (this.host.nativeElement.localName == 'input') {
      this.host.nativeElement.focus();
    }
    const input = this.host.nativeElement.querySelector('input');
    if (input) {
      input.focus();
    }
  }
}
