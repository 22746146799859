import { Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { PhoenixWebService } from '@capp/providers/web.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UserPermissionsService } from '@capp/providers/user-permissions.service';
import { Subscription } from 'rxjs';
import { BulkImportProcessItem, BulkImportProcessItemStatus } from '../../../../../phoenix-common/src/lib/shared/models/interfaces/BulkImportProcessItem';
import { AppService } from '@capp/app/services/app.service';
import { ClientType } from '../../../../../phoenix-common/src/lib/shared/utils/Enumerations';
import { BulkImportListComponent } from '@capp/app/features/modal-features/bulk-import/coi-list-modal/bulk-import-list.component';

@Component({
  selector: 'app-notifications-panel',
  templateUrl: './notifications-panel.component.html',
  styleUrls: ['./notifications-panel.component.scss']
})
export class NotificationsPanelComponent implements OnInit, OnDestroy {
  public showNotifications = false;
  public bulkImportProcessItems: BulkImportProcessItem[] = [];
  public bulkImportProcessStatuses = BulkImportProcessItemStatus;
  public loadNotificationsInterval: NodeJS.Timeout;
  public stateSubscription: Subscription = new Subscription();
  public isLoading = false;

  constructor(private phoenixWebService: PhoenixWebService,
              private appService: AppService,
              private modalService: BsModalService,
              public userPermissionsService: UserPermissionsService,
              private eRef: ElementRef) {
  }

  @HostListener('document:click', ['$event'])
  handleClickOutside(event: Event) {
    if (this.showNotifications && (!this.eRef.nativeElement.querySelector('.sidebar')?.contains(event.target as Node) && !this.eRef.nativeElement.querySelector('.bell-icon')?.contains(event.target as Node))) {
      this.showNotifications = false;
    }
  }

  ngOnDestroy(): void {
    if (this.stateSubscription) {
      this.stateSubscription.unsubscribe();
      clearInterval(this.loadNotificationsInterval);
    }
  }

  public async ngOnInit(): Promise<void> {
    await this.loadNotifications();
    await this.pollForNotifications();
    this.stateSubscription = this.appService.notificationsPanelState.subscribe(async () => {
      await this.loadNotifications();
      this.showNotifications = !this.showNotifications;
    });
  }

  public async pollForNotifications(): Promise<void> {
    this.loadNotificationsInterval = setInterval(() => {
      if (this.appService.client) {
        this.loadNotifications();
      }
    }, 10000);
  }

  public async bulkImportProcessItemClick(bipi: BulkImportProcessItem): Promise<void> {
    if (bipi.status === BulkImportProcessItemStatus.READY) {
      if (this.appService.clientType === ClientType.FREEMIUM) {
        this.isLoading = true;
        this.showNotifications = !this.showNotifications;
        const limitCheckResult: {
          shouldDisplayWarning: boolean,
          totalCurrentCount: number,
          currentLimit: number
        } = await this.phoenixWebService.checkLimits(bipi.itemId);
        if (limitCheckResult.shouldDisplayWarning) {
          const warningMessage = `There are currently ${limitCheckResult.totalCurrentCount} active accounts.
         By importing these files you could potentially go over your limit of ${limitCheckResult.currentLimit}.
          Any files which would put the active accounts over the limit will not be processed.
          Do you wish to proceed?`;
          const confirmed = await this.appService.confirmModal(warningMessage);
          this.isLoading = false;
          if (!confirmed) {
            return;
          }
        }
      }

      const initialState = {
        bulkImportProcessItemId: bipi?.itemId
      };


      this.modalService.show(BulkImportListComponent, { class: 'modal-xl', initialState, ignoreBackdropClick: true, keyboard: false });
      this.showNotifications = false;
    } else if (bipi.status === BulkImportProcessItemStatus.PROCESSING) {
      this.appService.showAlertMessage('We are still processing this request.', 'warning');
    }
  }

  public async loadNotifications(): Promise<void> {
    try {
      if (this.appService.client !== null) {
        this.bulkImportProcessItems = await this.phoenixWebService.getBulkImportProcessItems();
      } else {
        clearInterval(this.loadNotificationsInterval);
      }
    } catch (err) {
      this.appService.showAlertMessage('Something went wrong when trying to load notifications', 'danger');
    }
  }

  canSeeNotifications() {
    return (this.userPermissionsService.canCreateVendors() || this.userPermissionsService.canCreateTenants()) &&
      (this.userPermissionsService.canCreateJobs() || this.userPermissionsService.canCreateLeases());
  }
}
