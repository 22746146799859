<phx-icon *ngIf="hoveringSrc" [url]="hoveringSrc" customClass="showOnHover" extraClass="hover" h="850"></phx-icon>

<div class="modal-header">
  <h4 class="modal-title pull-left">
    <fa-icon [icon]="faFileInvoice" class="mr-2"></fa-icon>
    COI(s)
  </h4>
  <button (click)="bsModalRef.hide()" aria-label="Close" class="close pull-right" type="button">
    <phx-icon src="exit" extraClass="standardImage light"></phx-icon>
  </button>
</div>


<div *ngIf="!loader" class="modal-body icon-position">
  <form [formGroup]="matchedFilesForm">
    <div class="row d-flex fs-18">
      <section formArrayName="filesFormArray" style="width: 100%;">
        <header class="table-row mb-2">
          <div class="col-3 text-center">File</div>
          <div class="col-2 text-center">Document Type</div>
          <div class="col-3 text-center">Associated {{ companyLabelPlural }}</div>
          <div class="col-3 text-center">Associated {{ accountLabelPlural }}</div>
          <div class="col-1 text-center">Ignore</div>
        </header>
        <div *ngFor="let file of filesFormArray.controls; let i = index" class="row mb-2 table-row">
          <ng-container [formGroupName]="i">
            <div class="col-3 d-flex align-items-center justify-content-center p-0 ohidden">
              <a
                (mouseenter)="setHoverSrc(file)"
                (mouseleave)="setHoverSrc(null)"
                [href]="getFileSource(file)"
                class="fileAnchor"
                target="_blank">
                <phx-icon [url]="getUrlFromFile(file)" extraClass="thumbnail" h="60" src="file-icon"
                          w="42"></phx-icon>
              </a>
              <a *ngIf="getFileSource(file)"
                 [href]="getFileSource(file)"
                 class="ellipsis link fileNameWidth"
                 target="_blank">{{ getNameFromFile(file) }}</a>
              <div *ngIf="!getFileSource(file)" class="ellipsis">{{ getNameFromFile(file) }}</div>
            </div>
            <div class="col-2 d-flex align-items-center justify-content-center">
              <ng-select *ngIf="!isFileIgnored(file)"
                         [closeOnSelect]="true"
                         [items]="documentTypes"
                         [multiple]="false"
                         style="position:fixed;"
                         bindLabel="name"
                         class="select-width form-control p-0 trades-field"
                         formControlName="documentType">
              </ng-select>
            </div>
            <div class="col-3 d-flex align-items-center justify-content-center">
              <ng-select *ngIf="!isFileIgnored(file)"
                         (change)="associatedAccountChange(i)"
                         [closeOnSelect]="true"
                         [items]="getCertificatesItems(file)"
                         [multiple]="false"
                         appendTo="body"
                         bindLabel="accountName"
                         class="select-width form-control p-0 trades-field"
                         formControlName="associatedAccount"
                         groupBy="matchGroup">
                <ng-template let-index="index" let-item="item" ng-optgroup-tmp>
                  {{ groupAccounts[item.matchGroup] }}
                </ng-template>
                <ng-template let-index="index" let-item="item" ng-option-tmp>
                  {{ item.accountName }}
                </ng-template>
              </ng-select>
              <div *ngIf="!getAssociatedAccountFromFile(file) && !isFileIgnored(file)" class="dropDownSubText">
                Leave this blank to create a new {{ companyLabelSingular }}
              </div>
            </div>
            <div class="col-3 d-flex align-items-center justify-content-center">
              <ng-select (change)="associatedSubAccountChange(i)"
                         *ngIf="!isFileIgnored(file)"
                         [closeOnSelect]="false"
                         [items]="getCertificates(file)"
                         [multiple]="true"
                         appendTo="body"
                         bindLabel="certificateName"
                         bindValue="certificateId"
                         class="select-width form-control p-0"
                         formControlName="associatedSubAccount">
                <ng-template let-clear="clear" let-items="items" ng-multi-label-tmp>
                  <div *ngFor="let item of items | slice:0:1" class="ng-value">
                    <span class="ng-value-label">{{ item?.certificateName }}</span>
                    <span (click)="clear(item)" aria-hidden="true" class="ng-value-icon right">x</span>
                  </div>
                  <div *ngIf="items.length > 1" class="ng-value">
                    <span class="ng-value-label">{{ items.length - 1 }} more...</span>
                  </div>
                </ng-template>
                <ng-template let-index="index" let-item="item" let-item$="item$" ng-option-tmp>
                  <input [ngModelOptions]="{standalone: true}" [ngModel]="item$.selected" id="item-{{index}}"
                         type="checkbox" /> {{ item?.certificateName }}
                </ng-template>
              </ng-select>
              <div *ngIf="isFileIgnored(file)" class="fileIgnored">File ignored</div>
              <div *ngIf="!getAssociatedSubAccountFromFile(file) && !isFileIgnored(file)" class="dropDownSubText">
                Leave this blank to create a new {{accountLabelSingular}}
              </div>
              <div *ngIf="getAssociatedSubAccountFromFile(file) && !isFileIgnored(file)" class="dropDownSubText">File
                will be attached
                to {{ getAssociatedMessage(file) }}
              </div>
            </div>
            <div class="col-1 d-flex align-items-center justify-content-center">
              <input (change)="onIgnore(i)" formControlName="ignore" style="height: 15px; width: 15px;" type="checkbox">
            </div>
          </ng-container>
        </div>
      </section>
    </div>
  </form>
</div>

<div *ngIf="!loader" class="modal-footer justify-content-center d-flex">
  <div class="row mt-1" style="display: contents;">
    <phx-button (click)="onDiscardBulkUpload()" *ngIf="!allFilesAreIgnored()" class="mr-2" color="secondary"
                size="big" style="width: 10%;" text="Discard">
    </phx-button>
    <phx-button (click)="submitFiles()" [text]="GetButtonText()" btnIcon="circle-white-tick" class="mr-2 "
                color="primary"
                size="big">
    </phx-button>
  </div>
</div>

<ng-container *ngIf="loader">
  <phx-loader size="big"></phx-loader>
</ng-container>
