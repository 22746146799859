import { Injectable } from '@angular/core';
import { CompanyService } from '@capp/app/services/company.service';
import { PhoenixWebService } from '@capp/providers/web.service';
import { SystemSettings } from '../../../../phoenix-common/src/lib/shared/models/interfaces/Activity';

@Injectable({ providedIn: 'root' })
export class TaskService {
  public activeTaskCount: number;
  public activeActivityCount: number;
  public activeDocumentCount: number;

  constructor(private companyService: CompanyService,
              private phoenixWebService: PhoenixWebService) {
  }

  async setUnreviewedEmailCount(): Promise<void> {
    const activities = await this.companyService.getActivities();
    this.activeActivityCount = activities.filter((a: any) => a.type == 'In-mail' && a.status != 'Complete')?.length;
  }

  async setPendingReviewDocumentsCount(): Promise<void> {
    const documents = await this.phoenixWebService.getPendingReviewDocuments(this.companyService.currentlyViewedCompanyId, this.companyService.isVendor);
    this.activeDocumentCount = documents?.length;
  }

  async setUncompletedTasksCount(): Promise<void> {
    const searchSettings: SystemSettings = {
      SystemUserIDs: null,
      PageNumber: 1,
      PageSize: 100,
      SortColumn: 'DueDate',
      SortDirection: 'DESC',
      SearchQuery: null,
      FilterTaskType: 'To do,Review Email,Review Document,Review Email and Document(s)',
      ShowPending: true,
      ShowCompleted: false,
      routeType: ''
    };
    const tasks = await this.phoenixWebService.getAllActivities(searchSettings);
    this.activeTaskCount = tasks.filter((t: any) => t?.status == 'Pending')?.length;
  }

  async updateEverything() {
    await this.setPendingReviewDocumentsCount();
    await this.setUnreviewedEmailCount();
    await this.setUncompletedTasksCount();
  }
}
