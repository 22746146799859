import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'phx-notification',
    templateUrl: './phoenix-notification.component.html',
    styleUrls: ['./phoenix-notification.component.scss']
})
export class PhoenixNotificationComponent {
    @Input() newsFeedItem: NotificationInterface;
    @Output() readNotification: EventEmitter<NotificationInterface> = new EventEmitter<NotificationInterface>();
    @Output() removeNotification: EventEmitter<NotificationInterface> = new EventEmitter<NotificationInterface>();

    @ViewChild('newReviewTemplate') newReviewTemplate: TemplateRef<any>;
    @ViewChild('newMessageTemplate') newMessageTemplate: TemplateRef<any>;
    @ViewChild('connectionRequestTemplate') connectionRequestTemplate: TemplateRef<any>;

    public notificationType = NotificationType;
    public notificationTemplate: TemplateRef<any>;
    public faTimes = faTimes;


    ngAfterViewInit(): void {
        switch (this.newsFeedItem.eventType) {
            case NotificationType.VENDOR_NEW_REVIEW:
                this.notificationTemplate = this.newReviewTemplate;
                break;
            case NotificationType.CLIENT_MESSAGED_VENDOR:
                this.notificationTemplate = this.newMessageTemplate;
                break;
            case NotificationType.CONNECTION_REQUEST:
                this.notificationTemplate = this.connectionRequestTemplate;
                break;
            default:
                break;
        }
    }
}


export interface NotificationInterface {
    clientId: number;
    clientUserId: string;
    eventId: number;
    eventType: NotificationType;
    jobId: number;
    messageId: number;
    time: string;
    vendorId: number;
    vendorUserId: string;
    client: any;
    conversationId: string;
    read: boolean;
}

export enum NotificationType {
    CLIENT_MESSAGED_VENDOR = "CLIENT_MESSAGED_VENDOR",
    CONNECTION_REQUEST = "CONNECTION_REQUEST",
    VENDOR_MESSAGED_CLIENT = "VENDOR_MESSAGED_CLIENT",
    JOB_BID = "JOB_BID",
    VENDOR_NEW_REVIEW = "VENDOR_NEW_REVIEW",
}
