import { Pipe, PipeTransform } from '@angular/core';
import { ClientDataService } from '@capp/app/services/clientData.service';

@Pipe({ name: 'singular' })
export class LabelPipe implements PipeTransform {
	constructor(private clientData: ClientDataService) { }

	transform(labelType:string, isVendor: boolean = false): string {
		return this.clientData.getLabel(labelType, false, isVendor);
	}
}
