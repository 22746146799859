import { Component} from '@angular/core';
import { AppService } from '../../services/app.service';


@Component({
    selector: 'top-bar',
    templateUrl: './top-bar.component.html',
    styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent {
    constructor(public appService: AppService) {  }

}
