import { Component, ElementRef, Input, ViewChild } from '@angular/core';

/**
 * The Phoenix Progress Bar Component is a customizable Angular component designed to visually represent the progress
 * of a specific task. It allows developers to track and display progress dynamically, making it suitable for
 * workflows, step-based processes, and loading indicators.
 *
 * Ensure that you include the following in your parent component:
 * @ViewChild(PhoenixProgressBarComponent) progressBar: PhoenixProgressBarComponent;
 * This allows you to properly access and interact with the Phoenix Progress Bar Component, ensuring it functions as
 *   intended within your application.
 *   The methods you'll have access to are:
 *   - increment(): Adds progress to the bar
 *   - decrement(): Removes progress to the bar
 *
 * @example
 * <phx-progress-bar
 *     [totalSteps]="20"
 *     [color]="'blue'">
 * </phx-progress-bar>
 */
@Component({
  selector: 'phx-progress-bar',
  templateUrl: './phoenix-progress-bar.component.html',
  styleUrls: ['./phoenix-progress-bar.component.scss']
})
export class PhoenixProgressBarComponent {
  /**
   * #### progressBarRef: ElementRef
   * Reference to the progress bar element in the template.
   * Used to dynamically update its width and color based on progress.
   * @type {ElementRef}
   */
  @ViewChild('progressBar', { static: true }) progressBarRef!: ElementRef;

  /**
   * #### totalSteps: number
   * The total number of steps required to complete the task (default: 10)
   * @type {number}
   */
  @Input() totalSteps = 10;

  /**
   * #### color: string
   * The custom color of the progress bar (default: blue)
   * @type {string}
   */
  @Input() color: string = null;
  progressStep = 0;

  increment() {
    if (this.progressStep == this.totalSteps) {
      return;
    }
    this.progressStep += 1;
    this.addProgressToBar();
  }

  decrement() {
    if (this.progressStep <= 0) {
      return;
    }
    this.progressStep -= 1;
    this.addProgressToBar();
  }

  protected addProgressToBar() {
    const progressPercentage = this.getProgressPercentage(this.progressStep, this.totalSteps);
    this.progressBarRef.nativeElement.style.width = progressPercentage + '%';
    if (this.color != null) {
      this.progressBarRef.nativeElement.style.backgroundColor = this.color;
    }
  }

  protected getProgressPercentage(progressStep: number, totalSteps: number) {
    if (totalSteps === 0) {
      return 0;
    }
    return Math.round((progressStep / totalSteps) * 100);
  }
}
