<phx-loader *ngIf="appService.displayClientSwitchLoader"
            class="h-100 w-100 position-absolute"
            size="big"
            style="z-index: 1000;">
</phx-loader>
<div *ngIf="isInitialized && !appService.displayClientSwitchLoader" class="d-flex"
     style="position: absolute; top:0; bottom: 0; right: 0; left: 0; min-width: 700px; overflow: hidden;">
  <phx-sidebar></phx-sidebar>
  <div class="w-100 d-flex flex-column" style="overflow: auto;">
    <phx-top-bar></phx-top-bar>
    <div class="h-100 main-container">
      <div class="main-content">
        <router-outlet></router-outlet>
      </div>
      <div id="bcs-global-chat-bot-container">
        <phx-bcs-risk-bot-chat-box></phx-bcs-risk-bot-chat-box>
      </div>
    </div>
  </div>
</div>
