<div class="d-flex">
    <div class="d-flex" *ngIf="appService.isSelfService">
        <app-notifications-panel></app-notifications-panel>
    </div>
    <li class="menu-item" dropdown>
        <a (click)="false" dropdownToggle aria-controls="basic-link-dropdown" class="profile-icon-container">
          <phx-icon src="avatar-placeholder.png" size="35" extraClass="circle" [url]="userAvatarURL" ></phx-icon>
        </a>
        <ul *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="basic-link">
            <li>
                <a class="dropdown-item" routerLink="profile/settings" >User Settings</a>
            </li>
            <li>
                <a class="dropdown-item" href="https://help.businesscredentialingservices.com/" target="_blank">Help</a>
            </li>
            <li *ngIf="appService.clientType !== 'FREEMIUM'">
                <a class="dropdown-item" [href]="appService.client.clientCertusLink" target="_blank">The BCS Database</a>
            </li>
            <li>
                <a class="dropdown-item" (click)="contactUs(contactUsTemplate)">Contact Us</a>
            </li>
            <li>
                <a class="dropdown-item" (click)="logout()">Logout</a>
            </li>
        </ul>
    </li>
</div>

<ng-template #contactUsTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Contact us</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-3">
        <div class="row mb-2" *ngIf="appService.client?.contactPhone">
            <div class="col d-flex">
                <phx-icon customClass="standardImage" src="phone-icon"></phx-icon>
                <div>{{appService.client.contactPhone}}</div>
            </div>
        </div>
        <div class="row mb-2" *ngIf="appService.client.workflowEmail">
            <div class="col d-flex">
                <phx-icon customClass="standardImage" src="mail-icon"></phx-icon>
                <a href="mailto:{{GlobalConstants.CLIENT_SUPPORT_EMAIL}}">{{GlobalConstants.CLIENT_SUPPORT_EMAIL}}</a>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col d-flex">
                <phx-icon customClass="standardImage" src="globe-icon"></phx-icon>
                <a target="_blank" href="https://help.getbcs.com">help.getbcs.com</a>
            </div>
        </div>
    </div>
</ng-template>
