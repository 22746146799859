import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private currentTheme = 'light-theme';

  setTheme(theme: string) {
    document.body.classList.remove(this.currentTheme);
    document.body.classList.add(theme);
    this.currentTheme = theme;
    localStorage.setItem('theme', theme); // Persist theme selection
  }

  getTheme(): string {
    return localStorage.getItem('theme') || 'light-theme';
  }

  applyStoredTheme() {
    this.setTheme(this.getTheme());
  }

  resetToOriginalTheme() {
    localStorage.removeItem('theme');
  }
}
