import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';

/**
 * Interface representing the structure of a circular button option response.
 */
export interface CircularButtonOptionResponse {
  /** Unique identifier for the option. */
  optionId: number;
  /** Name of the option. */
  name: string;
  /** Whether the option is selected or not. */
  selected: boolean;
  /** Text of the optional input textbox. */
  optionalInputString?: string;
}

/**
 * Component representing a circular radio button.
 *
 * This component is designed to function as a customizable circular button
 * with hover effects and emits data on click.
 *
 * @example
 * <phx-circular-button
 *     (circularButtonClick)="onOptionSelect($event)"
 *     optionId="daId"
 *     name="daName"
 *     [hasError]="false"
 *     [selected]="false">
 * </phx-circular-button>
 */
@Component({
  selector: 'phx-circular-button',
  templateUrl: './phoenix-circular-radio-button.component.html',
  styleUrls: ['./phoenix-circular-radio-button.component.scss']
})
export class PhoenixCircularRadioButtonComponent implements AfterViewInit {
  /**
   * #### circularButtonRef: ElementRef
   * Reference to the circular button element in the DOM.
   * @type {ElementRef}
   */
  @ViewChild('circularButton', { static: true }) circularButtonRef!: ElementRef;

  /**
   * #### circularButtonRadioRef: ElementRef
   * Reference to the circular button's radio input element.
   * @type {ElementRef}
   */
  @ViewChild('circularButtonRadio', { static: true }) circularButtonRadioRef!: ElementRef;

  /**
   * #### optionId: number
   * The ID of the option associated with this button.
   * @type {number}
   */
  @Input() optionId: number = null;

  /**
   * #### name: string
   * The name of the circular button option.
   * @type {string}
   */
  @Input() name?: string;

  /**
   * #### optionalInput: string
   * The optional input text.
   * @type {string}
   */
  @Input() optionalInput?: string;

  /**
   * #### selected: boolean
   * Whether the button is selected.
   * @type {boolean}
   */
  @Input() selected?: boolean;

  /**
   * #### provideOptionalInput: boolean
   * Determines whether a textbox appears to provide additional input
   * @type {boolean}
   */
  @Input() provideOptionalInput?: boolean;

  /**
   * #### optionalInputPlaceholder: string
   * Placeholder for the optional input field
   * @type {string}
   */
  @Input() optionalInputPlaceholder?: string;

  /**
   * #### hasError: boolean
   * Determines if button has an error. If so, we replace the outline with a red one
   * @type {boolean}
   */
  @Input() hasError: boolean;

  /**
   * #### circularButtonClick: EventEmitter<CircularButtonOptionResponse>
   * Event emitter is triggered when the circular button is clicked.
   *
   * Example of the emitted object:
   * {
   *   optionId: 1,
   *   name: 'Test',
   *   selected: true
   * }
   * @type {EventEmitter<CircularButtonOptionResponse>}
   */
  @Output() circularButtonClick: EventEmitter<CircularButtonOptionResponse> = new EventEmitter<CircularButtonOptionResponse>();

  /**
   * Constructs the circular button component.
   * @param renderer Renderer2 instance for manipulating DOM elements.
   */
  constructor(private renderer: Renderer2) {
  }

  /**
   * Lifecycle hook that runs after the component’s view has been initialized.
   *
   * Adds event listeners to handle hover effects for the circular button.
   */
  ngAfterViewInit() {
    this.renderer.listen(this.circularButtonRef.nativeElement, 'mouseenter', () => {
      if (!this.selected) {
        this.circularButtonRadioRef.nativeElement.style.borderColor = '#8C8C8C';
      }
    });

    this.renderer.listen(this.circularButtonRef.nativeElement, 'mouseleave', () => {
      if (!this.selected) {
        this.circularButtonRadioRef.nativeElement.style.borderColor = '#E0E2E4';
      }
    });
  }

  /**
   * Emits a `CircularButtonOptionResponse` event with the current option details.
   */
  protected emitOptionResponse() {
    const response: CircularButtonOptionResponse = {
      optionId: this.optionId,
      name: this.name,
      selected: this.selected,
      optionalInputString: this.provideOptionalInput ? this.optionalInput : null
    };

    this.circularButtonClick.emit(response);
  }
}

