<div class="loader-wrapper h-100 w-100" *ngIf="!inPlace && !useCustomLoader">
    <div class="lds-ring" [ngClass]="size">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>

<div class="w-100 h-100 text-center" *ngIf="inPlace">
    <div class="inplc-ring" [ngClass]="size">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>

<div class="w-100 h-100 text-center" *ngIf="useCustomLoader">
    <div class="custom-ring" [ngClass]="size">
        <img class="custom-loader" src="assets/animations/gifs/loader.gif" alt="Loading..."/>
    </div>
</div>

