import { IBaseModel } from './base-model';
import { LocationGroup } from './location-group-model';

export class Location implements IBaseModel {
    public get address(): string {
        return this._address;
    }

    public set address(value) {
        this._address = value;
    }

    public get city(): string {
        if (this.locality) {
            return this.locality;
        }
        return this.sublocality;
    }

    private _address: string;
    public name: string;
    public clientId: string;
    public clientLocationId: number;
    public locationGroup: LocationGroup;
    public locationGroupName: string;
    public locationGroupId: string;
    public state: string;
    public zip: string;
    public lat: number;
    public long: number;
    public locationType: string = 'Location Type';// HARDCODED
    public active: boolean = true;
    public route: string;
    public locality: string;
    public sublocality: string;
    public subpremise: string;
    public country: string;
    public placeId: string;
    public error: boolean = true;
    public formattedAddr: string;
    public longStateName: string;

    constructor(
        public partial = false
    ) {
    }


    public fromJSON(json: any): Location {
        if (json) {
            this.address = json['address'];
            this.lat = json['lat'];
            this.long = json['long'];
            this.clientLocationId = json['clientLocationId'];
            this.locality = json['city'] || '';
            this.state = json['state'] || '';
            this.zip = json['zip'] || '';
            this.name = json['name'] || '';
            this.active = json['active'];
            this.locationGroupId = json['clientLocationGroupId'] || '';
            this.locationGroup = json['locationGroup'] ? new LocationGroup().fromJSON(json['locationGroup']) : null;
            if (this.locationGroup) {
                this.locationGroupName = this.locationGroup.name;
            }
        }
        return this;
    }

    public toJSON() {
        return {
            address: this.address || null,
            city: this.city || null,
            lat: this.lat || null,
            long: this.long || null,
            state: this.state || null,
            zip: this.zip || null,
            name: this.name || null,
            active: this.active,
            clientId: this.clientId
        };
    }

    public valid(): boolean {
        this.error = false;
        if (!this.partial) {
            if (!this.zip) {
                this.error = true;
            }
            const zipPattern = new RegExp('^[0-9]{5}$');
            if (this.zip && (!zipPattern.test(this.zip) || this.zip.length != 5)) {
                this.error = true;
            }
        }
        // if (!this.state) this.error = true;
        if (!(this.locality || this.sublocality)) {
            this.error = true;
        }
        if (!this.lat && !this.long) {
            this.error = true;
        }

        return !this.error;
    }
}
