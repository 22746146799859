<div class="modal-header">
  <h4 class="modal-title pull-left">
    <fa-icon class="mr-2" [icon]="faFileInvoice"></fa-icon>
    COI(s)
  </h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<phx-icon customClass="showOnHover" extraClass="hover" h="850" *ngIf="hoveringSrc"  [url]="hoveringSrc"  ></phx-icon>


<div *ngIf="!loader">
  <div class="modal-body icon-position" [formGroup]="importCompanyForm">
    <form formArrayName="importCompanyArray">
      <div class="row d-flex fs-18">
        <div class="col-12">
          <section>
            <header class="table-row mb-2">
              <div class="col-2 text-center">File Name</div>
              <div class="col text-center">Company Name</div>
              <div class="col text-center">Contact Name</div>
              <div class="col text-center">Contact Email</div>
              <div class="col text-center">Contact Phone</div>
              <div class="col text-center">{{ accountLabelSingular }} Name</div>
              <div class="col text-center">Requirement Category</div>
              <div class="col-2 text-center">{{ accountLabelSingular }} Location(s)</div>
            </header>
            <div class="row mb-2 table-row" *ngFor="let file of importCompanyArray.controls; let i = index">
              <ng-container [formGroupName]="i">
                <div class="col-2 d-flex align-items-center justify-content-start p-0">
                  <a *ngIf="file.value?.thumbnailSource != null" class="fileAnchor"
                     (mouseenter)="setHoverSrc(file.value?.thumbnailSource)"
                     (mouseleave)="setHoverSrc(null)"
                     [href]="file.value.fileSource"
                      target="_blank" >
                    <phx-icon [url]="file.value?.thumbnailSource" h="60" w="42" extraClass="thumbnail" src="file-icon" ></phx-icon>
                  </a>
                  <a class="ellipsis link" *ngIf="file.value?.fileSource" [href]="file.value.fileSource"
                     target="_blank">{{file.value?.fileName}}</a>
                  <div class="ellipsis" *ngIf="!file.value?.fileSource">{{file.value.fileName}}</div>
                </div>
                <div *ngIf="!file.value?.isIgnored" class="col d-flex align-items-center justify-content-center">
                  <input class="w-100 input-height form-control p-0 ellipsis" formControlName="companyName" type="text">
                </div>
                <div *ngIf="!file.value.isIgnored" class="col d-flex align-items-center justify-content-center">
                  <input class="w-100 input-height form-control p-0 ellipsis" formControlName="contactName" type="text">
                </div>
                <div *ngIf="!file.value.isIgnored" class="col d-flex align-items-center justify-content-center">
                  <input class="w-100 input-height form-control p-0 ellipsis" formControlName="contactEmail" type="email" email>
                </div>
                <div *ngIf="!file.value.isIgnored" class="col d-flex align-items-center justify-content-center">
                  <input class="w-100 input-height form-control p-0 ellipsis" formControlName="contactPhone" type="text">
                </div>
                <div *ngIf="!file.value.isIgnored" class="col d-flex align-items-center justify-content-center">
                  <input class="w-100 input-height form-control p-0 ellipsis" formControlName="certificateName" type="text">
                </div>
                <div *ngIf="!file.value.isIgnored" class="col d-flex align-items-center justify-content-center">
                  <ng-select class="form-control req-width p-0"
                             [readonly]="shouldGreyOutReqList(file)"
                             [items]="requirementsList"
                             [multiple]="false"
                             [closeOnSelect]="true"
                             bindValue="insuranceRequirementCategoryId"
                             bindLabel="insuranceRequirementCategoryName"
                             appendTo="body"
                             formControlName="reqCategory">
                  </ng-select>
                </div>
                <div *ngIf="!file.value.isIgnored" class="col-2 d-flex align-items-center justify-content-center">
                  <ng-select class="w-100 form-control p-0"
                             [readonly]="shouldGreyOutReqList(file)"
                             [items]="locationsList"
                             [multiple]="true"
                             [selectableGroup]="true"
                             [selectableGroupAsModel]="false"
                             [closeOnSelect]="false"
                             appendTo="body"
                             bindLabel="locationName"
                             formControlName="locations"
                             bindValue="locationId">
                    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                      <div class="ng-value overflowEllipsis" *ngFor="let item of items | slice:0:1">
                        <span class="ng-value-label">{{item.locationName}}</span>
                        <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">x</span>
                      </div>
                      <div class="ng-value" *ngIf="items.length > 1">
                        <span class="ng-value-label">{{items.length - 1}} more...</span>
                      </div>
                    </ng-template>
                    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                      <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                             [ngModelOptions]="{standalone: true}" />
                      {{locationNames[item.clientLocationGroupId]}}
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                      <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                             [ngModelOptions]="{standalone: true}" /> {{item.locationName}}
                    </ng-template>
                  </ng-select>
                </div>
                <div class="col-8 d-flex align-items-center justify-content-center" *ngIf="file.value.isIgnored">
                  This file will be ignored
                </div>
                <div class="col-2 d-flex" *ngIf="file.value.isIgnored">
                </div>
              </ng-container>
            </div>
          </section>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer justify-content-center d-flex" *ngIf="!loader">
    <div class="row mt-1" style="display: contents;">
      <phx-button text="Cancel" size="big" color="secondary"
                  class="mr-2" style="width: 10%;" (click)="onClose()">
      </phx-button>
      <phx-button text="Upload Document(s)" (click)="createRecords()" btnIcon="circle-white-tick" size="big" color="primary"
                  class="mr-2">
      </phx-button>
    </div>
  </div>
</div>

<ng-container *ngIf="loader">
  <phx-loader size="big"></phx-loader>
</ng-container>
