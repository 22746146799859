<div class="conversation-list-wrapper" infiniteScroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="100"
    (scrolled)="bidScrolled()" [scrollWindow]="false">
    <div class="conversation-accordion border-bottom p-2">
        <div class="input-group search-input box-shadow">
            <div class="input-group-prepend bg-transparent">
                <span class="input-group-text" style="border: none; background-color: #ffff; ">
                    <phx-icon customClass="standardImage" src="search"></phx-icon>
                </span>
            </div>
            <input type="text" class="form-control" placeholder="Search messages" style="border: none;"
                [(ngModel)]="searchStr" [disabled]="conversationLoading" (input)="searchStringInput()">
        </div>
    </div>
    <ng-container *ngIf="toggle">
        <div class="conversation-accordion border-bottom"
            *ngFor="let jobPosting of jobPostings | conversationFilter:search">
            <div class="conversation-group" (click)="toggleGroup(jobPosting)">
                <div class="mx-3">
                    <div class="job-posting-icon"></div>
                </div>
                <div class="mr-3 middle-container">
                    <div class="group-title">{{jobPosting.title}}</div>
                    <div class="d-flex group-details">
                        <!-- <div class="job-locations">{{jobPosting.location.name}}</div> -->
                        <!-- <div class="conversations-count">{{jobPosting.bids.length}} conversations</div> -->
                    </div>
                </div>
                <div class="mr-3">
                    <div class="group-conv-counter">{{jobPosting.bids.length || 0}}</div>
                </div>
            </div>
            <div [collapse]="!(jobPosting.expand)" [isAnimated]="true">
                <div class="conversation" *ngFor="let conversation of jobPosting.bids"
                    [ngClass]="{selected: currentConversation && currentConversation.conversationId == conversation.conversationId}"
                    (click)="selectConversation(conversation)">
                    <div class="mx-3">
                      <phx-icon src="profile-placeholder.png" size="50" [url]="conversation?.logoUrl" ></phx-icon>
                    </div>
                    <div class="mr-3 middle-container">
                        <div class="conversation-title">{{conversation.title}}</div>
                        <div class="d-flex group-details">
                            <!-- <div class="message-caption">{{conversation.messageCaption}}</div> -->
                        </div>
                    </div>
                    <div class="mr-3">
                        <div class="group-conv-counter">{{conversation?.unreadMessageCounter}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div style="width: 100%; height: 80px;" *ngIf="conversationLoading">
            <phx-loader class="h-100 w-100 mt-3" size="big" [inPlace]="true"></phx-loader>
        </div>
    </ng-container>
    <ng-container *ngIf="!toggle">
        <div class="conversation-accordion border-bottom"
            *ngFor="let conversation of jobPostings | conversationFilter:search">
            <div class="conversation"
                [ngClass]="{selected: currentConversation && currentConversation.conversationId == conversation.conversationId}"
                (click)="selectConversation(conversation)">
                <div class="mx-3">
                  <phx-icon src="profile-placeholder.png" size="50" [url]="conversation?.logoUrl" ></phx-icon>
                </div>
                <div class="mr-3 middle-container">
                    <div class="conversation-title">{{conversation.title}} {{conversation.job ? (' - ' +
                        conversation.job.title) : ''}}</div>
                    <div class="d-flex group-details">
                        <!-- <div class="message-caption">{{conversation.messageCaption}}</div> -->
                    </div>
                </div>
                <div class="mr-3">
                    <div class="group-conv-counter">{{conversation?.unreadMessageCounter || 0}}</div>
                </div>
            </div>
        </div>
        <div style="width: 100%; height: 80px; display: flex;">
            <phx-loader class="h-100 w-100 mt-3" size="big" [inPlace]="true" *ngIf="conversationLoading">
            </phx-loader>
        </div>
    </ng-container>
</div>
