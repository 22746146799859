<span class="phx-tooltip-link">
  <div class="phx-tooltip-link-icon-container">
    <span (click)="click()" *ngIf="!iconOnly"
          class="bcs-link">{{ linkText }}</span>
    <span [ngClass]="iconClass" class="tooltip-icon" data-tooltip>
          <phx-icon [isTooltipComponent]="true"
                    customClass="standardImage"
                    size="12"
                    src="question">
          </phx-icon>
    </span>
  </div>
  <div [id]="id" [ngClass]="(iconOnly ? 'icon-only-tooltip-content' : 'tooltip-content') + ' ' + tooltipClass"
       data-tooltip>
    {{ tooltipText }}
    <a [href]="clickHereLink ?? '#'" data-tooltip target="_blank">{{ clickHereText }}</a>
  </div>
</span>
