export class Utils {
    public static retry<T>(executor: (resolve: (value?: T | PromiseLike<T>) => void, reject: (reason?: any) => void) => void, options: any = {}, attempt: number = 0, error: any = null): Promise<T> {
        options.retries = options.retries || 10;
        options.randomize = options.randomize || true;
        options.min = options.min || 500;
        options.max = options.max || 20000;
        options.factor = options.factor || 2;

        return new Promise<T>((resolve, reject) => {

            let run = () => {
                if (attempt == options.retries) {
                    reject(error);
                } else {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const promise = new Promise(executor).then((result) => {
                        resolve(result);
                    }).catch((err) => {
                        error = err;
                        attempt++;
                        let delay: number = Utils.delay(attempt, options.randomize, options.factor, options.min, options.max);
                        setTimeout(() => {
                            run();
                        }, delay);
                    });
                }
            };

            run();
        });
    }

    private static delay(attempt: number, randomize = false, factor = 2, min = 1000, max = Infinity) {
        const random = randomize ? Math.random() + 1 : 1;
        if (attempt == 0) return 0;
        return Math.min(Math.round(random * min * Math.pow(factor, attempt)), max);
    }


    private static fallbackCopyTextToClipboard(text) {
        let textArea = document.createElement("textarea");
        textArea.value = text;
        
        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";
      
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
      
        try {
          let successful = document.execCommand('copy');
          let msg = successful ? 'successful' : 'unsuccessful';
        } catch (err) {
          console.error('Fallback: Oops, unable to copy', err);
        }
      
        document.body.removeChild(textArea);
      }

      public static copyTextToClipboard(text) {
        if (!navigator.clipboard) {
          this.fallbackCopyTextToClipboard(text);
          return;
        }
        navigator.clipboard.writeText(text).then(function() {
        }, function(err) {
          console.error('Async: Could not copy text: ', err);
        });
      }
}
