import { Component, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'phx-current-conversation',
    templateUrl: 'current-conversation.component.html'
})

export class CurrentConversationComponent implements OnChanges {
    @Input() currentConversation: any;


    constructor(private router: Router) { }

    ngOnChanges() {
        if (this.currentConversation != null) {
        }
    }
}
