import { AfterContentInit, Component, ContentChild, Directive, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { ControlContainer, FormGroupDirective, NgForm } from '@angular/forms';
import { isValid } from 'ngx-bootstrap/chronos/create/valid';

@Component({
  selector: 'phoenix-inline-edit',
  templateUrl: './phoenix-inline-edit.component.html',
  styleUrl: './phoenix-inline-edit.component.css',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]

})
export class PhoenixInlineEditComponent implements AfterContentInit {
  @Input('actionTemplate') actionTemplate: TemplateRef<any>;
  @Input('editTemplate') editTemplate: TemplateRef<any>;
  @Input('isValid') isFormValid: () => boolean;

  @Output() onEdit: EventEmitter<any> = new EventEmitter<void>();
  @Output() onCancel: EventEmitter<any> = new EventEmitter<void>();
  @Output() onConfirm: EventEmitter<any> = new EventEmitter<any>();

  public isEditing = false;

  constructor(private parentF: FormGroupDirective) {
  }

  ngAfterContentInit(): void {

  }

  public edit() {
    this.isEditing = true;
    this.onEdit.emit(null);
  }

  public cancel() {
    this.isEditing = false;
    this.onCancel.emit(null);
  }

  public confirm() {
    if (this.isFormValid()) {
      this.isEditing = false;
      this.onConfirm.emit(null);
    }
  }
}
