import { Injectable } from '@angular/core';
import { PhoenixWebService } from '@capp/providers/web.service';

@Injectable({ providedIn: 'root' })
export class ClientConfigurationService {
  constructor(public phoenixWebService: PhoenixWebService) {
  }

  public async saveLabelSettings(selectedOptions: any) {
    await this.phoenixWebService.saveLabelSettings(selectedOptions);
  }

  public async saveAutomaticVendorCertificateNameSettings(value) {
    await this.phoenixWebService.saveAutomaticVendorCertificateNameSettings(value);
  }

  public async saveAutomaticTenantCertificateNameSettings(value) {
    await this.phoenixWebService.saveAutomaticTenantCertificateNameSettings(value);
  }

  public async getClientConfigurationSettings() {
    return await this.phoenixWebService.getClientConfigurationSettings();
  }

  async getDefaultRoute(): Promise<any> {
    const defaultViewConfig = await this.phoenixWebService.getDefaultViewConfiguration();

    return defaultViewConfig ?? { defaultView: defaultViewConfig };
  }

  async saveDefaultRoute(params: any): Promise<void> {
    const defaultViewConfig = await this.phoenixWebService.saveDefaultViewConfiguration(params);

    return defaultViewConfig ?? { defaultView: defaultViewConfig };
  }

  public async updateTrackingMultipleLocations(trackingMultipleLocations: boolean) {
    return await this.phoenixWebService.updateTrackingMultipleLocations(trackingMultipleLocations);
  }
  public async getTrackingMultipleLocationsSettings() {
    return await this.phoenixWebService.getTrackingMultipleLocationsSettings();
  }
}
