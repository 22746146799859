<div #phxMultiSelect class="phx-single-select-container" [ngClass]="customClass" dropdown
     #dropdown="bs-dropdown"
     container="body">
  <div class="dropdown-container" [ngClass]="size + ' ' + (error ? 'error' : '')"
  >
    <div (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()" [id]="dropdownId + '-selected-item'"
         class="selected-item-container">
      <div [ngClass]="itemClass" class="selected-item" (click)="removedItem($event)"
           *ngIf="selectedItem && !isDisabled">
        <span class="item-name">{{ selectedItem[textField]}}</span>
      </div>
      <input #inputElement *ngIf="!selectedItem || isDisabled"

             [disabled]="isDisabled"
             [placeholder]="placeholder"
             (click)="dropdown.show();"
             (focus)="dropdown.show()"
             (focusout)="checkLeaveFocus($event);"
             [ngClass]="(isDisabled ? 'disabled ' : '') + itemClass"
             class="focusableItems dropdown-input phx-input"
             type="text"
             [(ngModel)]="input"
             [value]="input"
             (ngModelChange)="onSearch()"
             [attr.data-testid]="dataTestId"
      />
    </div>
    <div class="focusableItems arrow-container" *ngIf="options != null && !disabled"
         (mousedown)="dropdown.toggle(true); $event.preventDefault();"
    >
      <phx-icon
        src="filled-dropdown-arrow" mr="10" ml="5" color="black" size="10"></phx-icon>
    </div>
  </div>
  <div *dropdownMenu [id]="dropdownId" class="focusableItems dropdown-content"
       [ngClass]="customClass">
    <div *ngFor="let item of filteredOptions; let index = index"
         [id]="dropdownId + '-option-' + index"
         [ngClass]="dropdownId + '-option'"
         [attr.data-testid]="dataTestId + '-option'"
         class="focusableItems option dropdown-item"
         (click)="$event.preventDefault();onSelectedItem($event, item, true); dropdown.hide();"
         (keyup)="selectItemWithKeyPress($event, item)"
         [tabindex]="index + 1">{{item[textField]}}</div>
    <div *ngIf="filteredOptions?.length <= 0"
         class="no-option">{{ emptyDropdownMessage }}</div>
  </div>
</div>
