import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'phx-search',
  templateUrl: './phoenix-search.component.html',
  styleUrls: ['./phoenix-search.component.scss'],
})
export class PhoenixSearchComponent implements OnInit {
  @Input() searchInputValue: string = '';
  @Output('onSearch') onSearchClickEmitter = new EventEmitter<string>();
  @Output('onEnter') onEnterClickEmitter = new EventEmitter();
  @Input('searchId') searchId: string = '';

  constructor() {}

  ngOnInit(): void {}

  public async onInputSearch() {
    this.onSearchClickEmitter.emit(this.searchInputValue);
  }

  public async onEnter() {
    this.onEnterClickEmitter.emit();
  }
}
