import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { ColumnType, ComplianceLevelID, DeficiencyStatusID, SortingDirection } from '../utils/constants';
import { IColumnStruct } from '../interfaces/column';

@Component({
  selector: 'phx-vendor-datatable',
  templateUrl: './phoenix-vendor-data-table.component.html',
  styleUrls: ['./phoenix-vendor-data-table.component.scss']
})

export class PhoenixVendorDataTableComponent {
  columnType = ColumnType;
  complianceLevelID = ComplianceLevelID;
  deficiencyStatusID = DeficiencyStatusID;

  //compliance icons
  public faCheckCircle = faCheckCircle;
  public faTimesCircle = faTimesCircle;

  @Input('selectable') selectable = false;
  @Input('thead-class') theadClass: string = '';
  @Input('tbody-class') tbodyClass: string = '';
  @Input('row-class') rowClass: string = '';
  @Input('disabled') disabled: boolean = false;
  @Input() certificateLabel?: string;

  @Input('columns') columns: Array<IColumnStruct> = [];
  @Input('multipleSelect') multipleSelect: boolean = true;

  @Input('accordionTemplate') accordionTemplate: TemplateRef<any>;
  @Input('scrollRowIntoView') scrollRowIntoView: boolean = false;
  @Input('accordionToggleLeftSide') accordionToggleLeftSide: boolean = false;
  @Input('autoExpandFirstRow') autoExpandFirstRow: boolean = false;

  @Input('loadingCompliance') loadingCompliance: boolean = false;
  @Input('showTitleForCheckbox') showTitleForCheckbox: boolean = false;

  @Input('rowIdToExpand') set rowIdToExpand(value) {
    if (value || value === 0) {
      setTimeout(() => {
        let elem: Element = document.getElementById('row-' + value);
        this.rowClick(this.rows[value], elem);
      }, 300);
    }
  }

  private _rows: Array<any> = [];
  get rows(): Array<any> {
    return this._rows;
  }

  @Input('rows')
  set rows(value: Array<any>) {
    this._rows = value;
  }

  private _rowsStyle: Array<any> = [];
  get rowsStyle(): Array<any> {
    return this._rowsStyle;
  }

  @Input('rowsStyle')
  set rowsStyle(value: Array<any>) {
    this._rowsStyle = value;
  }

  @Output('onStatusChange') onStatusChange = new EventEmitter();
  @Output('onRowClick') onRowClickEmitter = new EventEmitter();
  @Output('onViewClick') onViewClickEmitter = new EventEmitter();
  @Output('onCheckboxChanged') onCheckboxChangedEmitter = new EventEmitter();
  @Output() summaryClicked = new EventEmitter();
  @Output('onSelectAllChecked') onSelectAllCheckedEmitter = new EventEmitter();
  @Output('onSortingChange') onSortingChange = new EventEmitter<IColumnStruct>();

  public sortingDirection = SortingDirection;
  @Input() features: any = [];
  @Input() phoenixClientId: number;

  constructor(private router: Router) {

  }

  ngAfterViewInit() {
    if (this.accordionTemplate && this.autoExpandFirstRow) {
      setTimeout(() => {
        if (this.rows && this.rows[0]) {
          const row = this.rows[0];
          row['accordionExpanded'] = true;
          var x = this.rows.every(value => value);
          this.onRowClickEmitter.emit(row);
        }
      }, 0);
    }
  }

  public checkAllChecked(property: string) {
    return this.rows.every(row => row[property]);
  }

  public onCheckBoxChanged(event,
                           row,
                           variable) {
    row[variable] = row.alwaysChecked ? row.alwaysChecked : event.target.checked;
    this.onCheckboxChangedEmitter.emit(row);
  }

  public onSelectAll(event,
                     variable) {
    for (let row of this.rows) {
      row[variable] = row.alwaysChecked ? row.alwaysChecked : event.target.checked;
      this.onCheckboxChangedEmitter.emit(row);
    }
    this.onSelectAllCheckedEmitter.emit({
      checked: event.target.checked,
      variable: variable
    });
  }

  public rowClick(row,
                  rowElement) {
    row.isClosing = rowElement.classList.contains('row-active');
    let phxRows = document?.getElementsByClassName('phx-row');

    if (phxRows.length > 0) {
      for (let i = 0; i <= phxRows.length - 1; i++) {
        phxRows[i].classList.remove('row-active');
      }
    }

    if (!row.accordionExpanded && this.selectable) {
      if (!rowElement.classList.contains('row-active')) {
        rowElement.classList.add('row-active');
      }
    }

    if (this.accordionTemplate) {
      if (this.scrollRowIntoView) {
        setTimeout(() => {
          rowElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          });
        }, 500);
      }
      const rowState = row?.accordionExpanded ?? false;
      for (let i = 0; i < this.rows.length; i++) {
        this.rows[i].accordionExpanded = false;
      }
      row.accordionExpanded = !rowState;
    }
    this.onRowClickEmitter.emit(row);
  }

  public viewClick(row) {
    this.onViewClickEmitter.emit(row);
  }

  public changeSorting(column: IColumnStruct) {
    if (!column.sortDirection || column.sortDirection == SortingDirection.None) {
      column.sortDirection = SortingDirection.Asc;
    } else if (column.sortDirection == SortingDirection.Asc) {
      column.sortDirection = SortingDirection.Desc;
    } else if (column.sortDirection == SortingDirection.Desc) {
      column.sortDirection = SortingDirection.None;
    }
    this.onSortingChange.emit(column);
  }

  public hasCenterTypes(column) {
    const type = column.type;

    if (column.centered) {
      return true;
    }

    const hasCenterTypes =
      (type === ColumnType.DueDate
       || type === ColumnType.Status
       || type === ColumnType.Compliance
       || type === ColumnType.DeficiencyStatus
      );
    return hasCenterTypes;
  }

  public changeStatus(evt: any,
                      row) {
    this.onStatusChange.emit(row);
    evt.stopPropagation();
  }

  createDataTestId(text: any) {
    text = text.replace(/([a-z])([A-Z])/g, '$1 $2');
    return text.toLowerCase().replaceAll(/( \/ |[ /])/g, '-');
  }
}
