import { Injectable } from '@angular/core';
import { PhoenixWebService } from '@capp/providers/web.service';

export class AuthorizedLocation {
  userId: string = null;
  locationId: number = null;
  authorized: boolean;
}

export class UpdateUserLocationFiltersRequest {
  authorizedLocations: AuthorizedLocation[];
  allEntitiesSelected: boolean;
  allEntitiesDeselected: boolean;
}

@Injectable({ providedIn: 'root' })
export class UserService {

  constructor(public phoenixWebService: PhoenixWebService) {
  }

  async getUsers(){
    return await this.phoenixWebService.getClientUsers()
  }

  async getUserByUserId(userId: string){
    return await this.phoenixWebService.getClientUserWithClientUserRolesByUserId(userId);
  }

  async createUser(params) {
    return await this.phoenixWebService.createNewClientUser(params);
  }

  async updateUser(params: any, userId: string){
    return await this.phoenixWebService.updateClientUser(params, userId);
  }

  async updateUserLocationFiltersByUser(userId: string, updateUserLocationFiltersRequest: UpdateUserLocationFiltersRequest) {
    await this.phoenixWebService.updateClientUserLocationFiltersByUser(userId, updateUserLocationFiltersRequest)
  }
  async updateUserLocationFiltersByLocation(locationId: number, updateUserLocationFiltersRequest: UpdateUserLocationFiltersRequest) {
    await this.phoenixWebService.updateClientUserLocationFiltersByLocation(locationId, updateUserLocationFiltersRequest)
  }

  async getClientUserRoles(){
    return await this.phoenixWebService.getClientUserRoles();
  }

  async resetUserPassword(userId: string) {
    await this.phoenixWebService.resetClientUserPassword(userId);
  }

  async deleteUser(userId: string){
    await this.phoenixWebService.deleteClientUser(userId);
  }

  async getUsersClientRoles() {
    return await this.phoenixWebService.getClientUserClientRoles();
  }

  async getUserStatuses() {
    return await this.phoenixWebService.getClientUserStatuses();
  }
}
