<span class="phx-tooltip-link">
  <span *ngIf="!iconOnly" (click)="click()" class="link">{{ linkText }}</span>
  <span class="tooltip-icon" [ngClass]="iconClass" data-tooltip>
        <phx-icon [isTooltipComponent]="true"
                  customClass="standardImage"
                  src="question"
                  size="12">
        </phx-icon>
  </span>
</span>
<div [id]="id" [ngClass]="(iconOnly ? 'icon-only-tooltip-content' : 'tooltip-content') + ' ' + tooltipClass" data-tooltip>
  {{ tooltipText }}
  <a [href]="clickHereLink ?? '#'" target="_blank" data-tooltip>{{ clickHereText }}</a>
</div>
