import { Component, forwardRef, Input, TemplateRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValueAccessorBase } from '../shared/utils/ValueAccessorBase';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { IColumnStruct } from '../interfaces/column';
import { Subscription } from 'rxjs';

@Component({
    selector: 'phx-select',
    templateUrl: './phoenix-select.component.html',
    styleUrls: ['./phoenix-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PhoenixSelectComponent),
            multi: true
        }
    ]
})
export class PhoenixSelectComponent extends ValueAccessorBase<any> {
    @Input('columns') columns: Array<IColumnStruct> = [];
    @Input('rows') rows: Array<any> = [];
    @Input('multiple') multiple: boolean = true;

    public modalRef: BsModalRef;
    public checkedRows: Array<any>;
    public inputText: string;

    constructor(private modalService: BsModalService) {
        super();
    }

    openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template, { class: 'modal-auto-width' });
        let modalSub: Subscription = this.modalService.onHide.subscribe(() => {
            this.value = this.checkedRows;
            modalSub.unsubscribe();
        })
    }

    onChecked() {
        this.checkedRows = this.rows.filter(row => row.checked);
        this.inputText = this.checkedRows.map(row => row.name).join(', ');
    }

    public afterSetValue() {
        this.onChecked();
    }
}
