import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: "root" })
export class PipeService {

    public doReload: false;

    private labels;


    public jobTradesArr: Array<any>;
    public jobTradesMaps = {};
    public jobTypesArr: Array<any>;
    public jobTypesMap = {};

    public leaseTypesArr: Array<any>;
    public leaseTypesMap = {};


	  public jobTradesSource = new Subject<any>();
    public jobTypesSource = new Subject<any>();
    public leaseTypesSource = new Subject<any>();


	  public jobTrades = this.jobTradesSource.asObservable();
    public jobTypes = this.jobTypesSource.asObservable();
    public leaseTypes = this.leaseTypesSource.asObservable();

  constructor() {

  }



  setJobTypes(jobTypes: Array<any>) {
        this.jobTypesArr = jobTypes;

        for(let jt of this.jobTypesArr) {
            this.jobTypesMap[jt["id"]] = jt["name"];
        }

        this.jobTypesSource.next(this.jobTypesArr);
    }


    getJobTypes(callback: (jobTypes: Array<any>) => void = null): any {
        if (callback) {
            if (this.jobTypesArr) {
                callback(this.jobTypesArr);
            } else {
                this.jobTypes.subscribe(callback);
            }

            return;
        }

        return this.jobTypesArr;
    }

    setJobTrades(jobTrades: Array<any>) {

        this.jobTradesArr = jobTrades;

        for(let jt of this.jobTradesArr) {
            this.jobTradesMaps[jt["id"]] = jt["name"];
        }

        this.jobTradesSource.next(this.jobTradesArr);
    }

    getJobTrades(callback: (jobTrades: Array<any>) => void = null): any {
        if (callback) {
            if (this.jobTradesArr) {
                callback(this.jobTradesArr);
            } else {
                this.jobTrades.subscribe(callback);
            }

            return;
        }

        return this.jobTradesArr;
    }


    setLeaseTypes(leaseTypes: Array<any>) {
        this.leaseTypesArr = leaseTypes;

        for(let lt of this.leaseTypesArr) {
            this.leaseTypesMap[lt["id"]] = lt["name"];
        }

        this.leaseTypesSource.next(this.leaseTypesArr);
    }

    getLeaseTypes(callback: (leaseTypes: Array<any>) => void = null): any {
        if (callback) {
            if (this.leaseTypesArr) {
                callback(this.leaseTypesArr);
            } else {
                this.leaseTypes.subscribe(callback);
            }

            return;
        }

        return this.leaseTypesArr;
    }


}
